import React from 'react';

import { Sort } from '@styled-icons/fa-solid/Sort';
import { SortUp } from '@styled-icons/fa-solid/SortUp';
import { SortDown } from '@styled-icons/fa-solid/SortDown';
import { UITooltipWrapper } from 'kit';


// Define a default UI for filtering
export function SortIcons(
	{ column, isSortAlreadyAppliedAnywhere }
: {
	column: any
	isSortAlreadyAppliedAnywhere: boolean
}) {

	const sortProps = column.getSortByToggleProps();

	const activeSortElement = column.isSortedDesc
		? <SortDown className="active" {...sortProps} />
		: <SortUp className="active" {...sortProps} />

	const finalSortElement = column.isSorted 
		? activeSortElement
		:  <Sort {...sortProps} />
	
	if (isSortAlreadyAppliedAnywhere && !column.isSorted ) {
		return (
			<UITooltipWrapper 
				tooltipContent="Hold down shift while clicking to apply sort criteria on multiple columns" 
			>
				{finalSortElement}
			</UITooltipWrapper>
		)
	}
	//else 
	return finalSortElement;
}

export function convertSortStateToURL(state) {
	let sortURLValue = ''
	if (typeof state?.sortBy !== 'undefined') {
		state.sortBy.forEach( (sortData: { id: string, desc: boolean }) => {
			if (sortURLValue !== '') {
				sortURLValue += ','
			}
			sortURLValue += `${sortData.id}:${sortData.desc ? 'desc' : 'asc'}`	
		});
	}
	return sortURLValue;
}

export function convertURLToSortState(urlParam: string = '') {
	if (urlParam === null) { return [] }
	return urlParam.split(',').map( sortString => {
		const keyValArr = sortString.split(':');
		if (keyValArr?.[1] === 'asc' || keyValArr?.[1] === 'desc') {
			return {
				id: keyValArr[0],
				desc: keyValArr[1] === 'desc' ? true : false
			}
		}
	}).filter( sortObj => typeof sortObj?.id === 'string' ); // make sure only well formed sort criteria are returned
}