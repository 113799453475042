import { apolloClient } from 'services/apollo';
import { gql } from '@apollo/client';
import { inventoryUniqueAllColumns } from '@constants';
import { GET_INVENTORY_UNIQUE_BY_IDS } from 'services/inventoryUniqueFetch';

import { toast } from 'react-toastify';
import { arrayString, emptyPromise, deepCopy } from 'utility';
import { InventoryUniqueItem, WorkflowOptions, ConvertPlan } from 'types';
import { GET_JOB_WIP } from './stepStart';

const ATTACH_INVENTORY = gql`
	mutation ATTACH_INVENTORY ( 
		$newIdsToAttach: [Int!]
		#$allAttachedIds: _int4
		$jobId: Int!
		#$stepHistoryId: Int!
	) {
		update_inventory_unique (
			where: { 
				id: { _in: $newIdsToAttach }
				job_id: { _is_null: true }
				status: { _eq: "stock" }
			},
			_set: {
				job_id: $jobId
				status: "processing"
			}
		) {
			returning {
				${inventoryUniqueAllColumns}
			}
			affected_rows
		}
		# This used to be needed when attaching was done at step stop
		# update_step_history (
		# 	where: { 
		# 		id: { _eq: $stepHistoryId } 
		# 	}
		# 	_set: {
		# 		inventory_unique_items: $allAttachedIds
		# 	}
		# ) {
		# 	returning {
		# 	}
		# }
	}
`;

export function attachInventory (
	workflowOptions: WorkflowOptions,
	idsToAttach: number[],
	jobId: number
): Promise<boolean> {
	const numStagedItems = idsToAttach.length || 0;
	if (numStagedItems === 0) {
		toast.error('No items were selected to attach', {
			position: toast.POSITION.BOTTOM_CENTER,  autoClose: 4000
		});
		return emptyPromise().then( () => false	);
	}

	return apolloClient.query({ 
		query: GET_INVENTORY_UNIQUE_BY_IDS, 
		variables: { 
			ids: idsToAttach, 
			type_id: workflowOptions.inventory_unique?.type_id,
			stage_ids: workflowOptions.inventory_unique?.attach?.stage_ids?.length > 0 ? workflowOptions.inventory_unique?.attach?.stage_ids : void 0,
			status: 'stock'
		},
		fetchPolicy: 'network-only'
	}).then( (queryResponse) => {
		const numAvailableItems = queryResponse.data.inventory_unique.length;
		if (numStagedItems === numAvailableItems) {
			return apolloClient.mutate({ 
				mutation: ATTACH_INVENTORY, 
				variables: { 
					newIdsToAttach: idsToAttach, 
					jobId: jobId
				},
				refetchQueries: [{ query: GET_JOB_WIP, variables: { jobId: jobId } }]
			}).then( () => {
				return true;
			}).catch( () => {
				return false;
			});
		}

		toast.error(`Only ${numAvailableItems} items were available to attach. Please check the staged items and try again.`, {
			position: toast.POSITION.BOTTOM_CENTER,  autoClose: 4000
		});
		return false;
	});
}

export function detachInventoryFragment(
	detachStatus: WorkflowOptions['inventory_unique']['detach']['status'],
	attachedItems: Array<InventoryUniqueItem>
) {
	// HASURA_TODO: If it was possible to append to completed jobs array, we wouldn't have to look ....but array operators don't exist right now.
	let gqlQueryFragment = '';
	for (let index = 0; index < attachedItems.length; index++) {
		const uniqueItem = attachedItems[index];
		let newCompletedJobs = (deepCopy(uniqueItem.completed_job_ids) || []);
		newCompletedJobs.push(uniqueItem.job_id);

		gqlQueryFragment += `
			inventory_unique_${uniqueItem.id}: update_inventory_unique (
				where: { 
					id: { _eq: ${uniqueItem.id} }
				},
				_set: {
					job_id: null
					completed_job_ids: "${arrayString(newCompletedJobs)}"
					status: "${detachStatus}"
				}
			) {
				returning {
					${inventoryUniqueAllColumns}
				}
			}
		`; 


	}
	return gqlQueryFragment;
}

export function convertInventoryFragment(
	convertPlan: ConvertPlan,
	attachedItems: number[]
) {
	if (!attachedItems || attachedItems.length === 0) { return ''; }
	const gqlQueryFragment = `
		inventory_unique_convert: update_inventory_unique (
			where: { 
				id: { _in: [${attachedItems.toString()}] }
			},
			_set: {
				stage_id: ${convertPlan.dest_stage_id}
			}
		) {
			returning {
				${inventoryUniqueAllColumns}
			}
		}
	`; 
	return gqlQueryFragment;
}
