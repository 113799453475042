import React from 'react';
import styled from 'styled-components';

import { UIButton } from 'kit';
import { ChevronLeft } from '@styled-icons/fa-solid/ChevronLeft';

import { NavbarPortal } from 'components/Navigation/NavbarPortal';

import { useCurrentMainRoute, useDocumentTitle } from 'routing';
import { useBreadcrumbFetch } from './breadcrumbServices';


export const Breadcrumbs = () => {
	const currentRoute = useCurrentMainRoute();
	const { backlinkText, backlinkNavFunc, labelText } = useBreadcrumbFetch();

	useDocumentTitle('main', [backlinkText, labelText]);

	return (
		<div>
			{currentRoute?.backlink && 
			<StyledButton 
				quiet
				icon={<ChevronLeft />}
				label={`Back to ${backlinkText}`} 
				onClick={backlinkNavFunc} 
			/>}

			<h1>{labelText || currentRoute.label}</h1>
							

			<NavbarPortal>
				{currentRoute.NavbarComponent}		
			</NavbarPortal>
		</div>
	)
};




const StyledButton = styled(UIButton)`
	/* font-size: 1.3em; */
	color: ${props => props.theme.colors.baseTextLight};
	svg {
		height: 12px;
		width: 12px;
		margin-right: -4px !important; /**To counteract material UI's spacing between icon and text */
	}
`;
