import React from 'react';

import styled from 'styled-components';
import { StyledHTag } from './Heading';

// ----------------------------------------------------------------------------------------

interface UITextStyleProps {
	inline?: boolean
	center?: boolean
}

export const UIText = (
	props: {
		text: string
	} & UITextStyleProps
) => {
	const { text, ...styleProps } = props;
	return (		
		<DivUIText {...styleProps}>
			{text}
		</DivUIText>
    );
}


const DivUIText = styled.div<UITextStyleProps>`
    font-size: 1em;
	font-family: ${props => props.theme.font.body};
	min-height: calc(1em + 4px); /** Makes divs with no value have the same nominal height */

	display: ${props => (props.inline && 'inline-block')};
	margin-left: ${props => (props.inline && '20px')};
	margin-top: 0.25em;
	
	text-align: ${props => (props.center && 'center')};
`


// ----------------------------------------------------------------------------------------


export const UITitle = styled.div`
	color: ${props => props.theme.colors.primary};
	font-family: ${props => props.theme.font.title};
	font-size: 1em;
	font-weight: ${props => props.theme.font.boldWeight};
	margin-bottom: 5px;
`

export const UISubtitle = styled.div`
	color: ${props => props.theme.colors.baseTextLight};
	font-size: 0.9em;
	${StyledHTag} ~ & {
		font-size: 1em;
		margin-top: -1rem;
		margin-bottom: 1.5rem;
	}
	
`


// ----------------------------------------------------------------------------------------



interface UIKeyValueStyleProps {
	line?: boolean
	justify?: boolean
}

export const UIKeyValue = (
	props: {
		title: string
		text: string
	} & UIKeyValueStyleProps
) => {
	const { title, text, ...styleProps } = props;
	return (
		<StyledDiv {...styleProps} >
			<UITitle>{title}</UITitle>
			<UIText text={text} />
		</StyledDiv>
    );
}

const StyledDiv = styled.div<UIKeyValueStyleProps>`
	width: 100%;
	box-sizing: border-box;
	margin: 0 0 8px;
	padding: 0 0 8px 6px;
	div:nth-of-type(2) {
		margin-top: 2px;
	}
	border-bottom: ${props => props.line && `1px solid ${props.theme.colors.colorDefinitions.grey1}`};
`;


export const UIKeyValueHorizontal = (
	props: {
		title: string | number
		text: string | number
		justify?: boolean
	} & UIKeyValueStyleProps
) => {
	const { title, text, ...styleProps } = props;
	return (
		<HorizontalDiv 
			{...styleProps} 
		>
			<span>{title}</span>
			<span>{text}</span>
		</HorizontalDiv>
    );
}

const HorizontalDiv = styled.div<UIKeyValueStyleProps>`
	width: 100%;
	box-sizing: border-box;
	margin: 0px 0 8px;
	&:last-of-type {
		margin-bottom: 0;
	}
	padding: 0 0 0px 0px;
	border-bottom: ${props => props.line && `1px solid ${props.theme.colors.colorDefinitions.grey1}`};

	display: grid;
	grid-template-columns: 120px auto;
	grid-column-gap: 10px;
	span:first-of-type{
		color: ${props => props.theme.colors.baseTextLight};
		font-family: ${props => props.theme.font.title};
	}
	span:nth-of-type(2){
		text-align: ${props => props.justify && 'right'};
	}
`;

export const UIFadedTitle = styled.div`
	font-size: 0.9em;
	color: ${props => props.theme.colors.colorDefinitions.grey4};
	text-transform: uppercase; 
	margin-bottom: 0.5rem;
`