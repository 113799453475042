import React from 'react';

import { UIHeading, UIPage, UICard, UIKeyValue, UIFlexbox } from 'kit';
import { useFormDefinitions } from 'hooks';

import { arrayToString } from 'utility';
import { FormDefinition } from 'types';

import { NavbarPortal } from 'components/Navigation/NavbarPortal';
import { AddNewItemButton } from 'components/AddNewItemButton';

import { useNavMain } from 'routing';
import { thingLookup } from '@constants';

export function FormDefinitionPage() {	
	const navigateToFormBuilder = useNavMain('formBuilderDraft');
	const navigateToFormBuilderbyId = useNavMain('formBuilderById');

	function navToFormBuilderById(formDef: FormDefinition){
		navigateToFormBuilderbyId(formDef.id);
	}
	
	return (
		<UIPage id="form-definitions" contentContainerSize="medium">
			<NavbarPortal>
				<AddNewItemButton type="form" onClick={navigateToFormBuilder.bind(this)} />
			</NavbarPortal>

			<FormDefinitionListAllTypes onClick={navToFormBuilderById} />
			
		</UIPage>
	)
}

export function FormDefinitionListAllTypes(
	props: {
		onClick?(formDefinition: FormDefinition): void
	}
) {
	const { formDefinitions } = useFormDefinitions('all');

	const stepForms = formDefinitions.filter( def => def.foreign_table === thingLookup.step_history.sharedTableId);
	const jobForms = formDefinitions.filter( def => def.foreign_table === thingLookup.job.sharedTableId);
	const inventoryForms = formDefinitions.filter( def => def.foreign_table === thingLookup.inventory_unique.sharedTableId);
	const orderForms = formDefinitions.filter( def => def.foreign_table === thingLookup.order.sharedTableId);
	
	return (
		<UIFlexbox flexDirection="column" asFlexbox contentContainerSize="medium">

			{stepForms.length > 0 &&
				<FormDefinitionList headingText="Step Forms" formDefinitions={stepForms} onClick={props.onClick} />
			}

			{jobForms.length > 0 &&
				<FormDefinitionList headingText="Job Forms" onClick={props.onClick} />
			}

			{inventoryForms.length > 0 &&
				<FormDefinitionList headingText="Inventory Forms" formDefinitions={inventoryForms} onClick={props.onClick} />
			}

			{orderForms.length > 0 &&
				<FormDefinitionList headingText="Order Forms" formDefinitions={orderForms} onClick={props.onClick} />
			}
		</UIFlexbox>
	)
}

export function FormDefinitionList (
	props: {
		headingText: string
		formDefinitions: Array<FormDefinition>
		onClick?(formDefinition: FormDefinition): void
	}
) {
	return (
		<React.Fragment>
			<UIHeading>{props.headingText}</UIHeading>
			{props.formDefinitions.map( (formDefinition) => {
				return (
					<UICard
						key={formDefinition.id}
						onClick={typeof props.onClick === 'function' && props.onClick.bind(this, formDefinition)}
						flexJustify="flex-start"
						flexDirection="row"
						width="100%"
					>
						<UIKeyValue title={formDefinition.name}
							text={arrayToString(formDefinition.fields.filter( item => item.status === 'active' ).map( item => { return item.name }))}
						/>

					</UICard>
				)
			})}
		</React.Fragment>
	)
}

FormDefinitionList.defaultProps = {
	formDefinitions: []
}
