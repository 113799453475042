import React from 'react';
import styled from 'styled-components';

import { UIHeading, useModal, UIModalForm, UIInput, UICard, GridContainer, GridRow } from 'kit';
import { OperateTaskButton } from 'components/OperateStep/TaskBannerPresentational';

import { updateFormInstance, addFormInstance } from 'services/formSubmit';

import { InventoryUniqueItem, FormInstance, StepPlan, InventoryUniqueFormEffect, FormFieldSpec } from 'types';
import { useFormDefinition } from 'hooks';
import { getObjectFromArray } from 'utility';
import { thingLookup } from '@constants';


//display attach inventory form
export const OperateFormUniqueInventory = (
	props: {
		uniqueItems: InventoryUniqueItem[]
		stepPlan: StepPlan, 
		disabled?
		viewMode: 'card' | 'button'
	}
) => {
	const modalProps = useModal();
	
	const formEffect = props.stepPlan.inventory_unique_form;
	
	const { formDefinition } = useFormDefinition(formEffect?.form_definition_id);
	
	// If no fields are specified, supply all the fields in the definition.
	const formFieldKeys = (!formEffect?.fields || formEffect?.fields?.length === 0) ? 
		(formDefinition.fields || []).filter( def => def.status !== 'archive' ).map( def => def.key ) : 
		formEffect.fields;

	function submit(formData) {
		//Each unique inventory item has its own formw
		props.uniqueItems.map( item => {
			const enteredFormData = formData[item.id];
			const existingFormInstance = getExistingForm(item, formEffect.form_definition_id);
			
			// Merge existing values and the newly entered values, since the existing form may have data that was not asked for in this form
			const newFormValues = { ...(existingFormInstance.values || {}), ...enteredFormData };

			const tableId = thingLookup.inventory_unique.sharedTableId;
			if (existingFormInstance.id) {
				updateFormInstance(existingFormInstance.id, newFormValues, tableId, item.id);
			}
			else {
				addFormInstance(formEffect.form_definition_id, newFormValues, tableId, item.id).then( () => {
					//props.setIsFormComplete(true);
				});
			}
		})
		modalProps.toggleModal();
	}

	return (
		<React.Fragment>
			{props.viewMode === 'button' && 
				<OperateTaskButton 
					label={formDefinition.name}
					onClick={modalProps.toggleModal}
				/>
			}

			{props.viewMode === 'card' && formFieldKeys.length > 0 &&
				<UICard title="Inventory Form Data">
					<OperateStepUniqueInventoryFormDataGrid 
						formEffect={formEffect}
						formFieldKeys={formFieldKeys}
						uniqueItems={props.uniqueItems}
						isEditable={false}
					/>
				</UICard>
			}

			<UIModalForm
				onSubmit={submit}
				HeaderCenter={<UIHeading noMarginBottom>{formDefinition.name}</UIHeading>}
				{...modalProps}
			>
				<OperateStepUniqueInventoryFormDataGrid 
					formEffect={formEffect}
					formFieldKeys={formFieldKeys}
					uniqueItems={props.uniqueItems}
					isEditable={true}
				/>
			</UIModalForm>
		</React.Fragment>
	)
}

function getExistingForm(uniqueInvItem: InventoryUniqueItem, formDefId: number): FormInstance {
	return uniqueInvItem?.forms?.filter( 
		(formInstance: FormInstance) => formInstance.form_definition_id === formDefId
	)?.[0] ?? {} as FormInstance;
}


const OperateStepUniqueInventoryFormDataGrid = (
	{ uniqueItems, formEffect = {} as InventoryUniqueFormEffect, formFieldKeys, isEditable }
	: {
		uniqueItems: InventoryUniqueItem[]
		formEffect: InventoryUniqueFormEffect
		formFieldKeys: string[]
		isEditable: boolean
	}
) => {

	const { formDefinition } = useFormDefinition(formEffect?.form_definition_id);

	return (
		<GridContainer columns={formFieldKeys.length + 1}>
			<GridRow isTitleRow>
				<div>Item</div>
				{ formFieldKeys.map( fieldKey => {
					const formField: FormFieldSpec = getObjectFromArray(formDefinition.fields, 'key', fieldKey);
					return <div key={'field' + fieldKey}> {formField.name} </div> 
				}) }
			</GridRow>

			{uniqueItems.map( item => {
				const formInstance = getExistingForm(item, formEffect.form_definition_id);
				const formValues = formInstance.values || {};

				return (
					<GridRow key={'item_' + item.id}>
						<div>{item.name}</div>
						{ formFieldKeys.map( fieldKey => {
							if (isEditable) {
								return (
									<StyledInput
										key={`${item.id}.${fieldKey}`}											
										name={`${item.id}.${fieldKey}`}											
										defaultValue={formValues[fieldKey]}
								/>)
							}
							//else
							return <div key={`${item.id}.${fieldKey}`}	>{formValues[fieldKey]}</div>
						})
						}
					</GridRow>
				)
			} )}
		</GridContainer>
	)
}

const StyledInput = styled(UIInput)`
	margin: 0;
`;