import React, { useContext } from 'react';
import styled from 'styled-components';

import { UIButton, UISwitch, UISelect, useUIForm, UIForm2, UICard, UINumberInput, useModal, UIModal, useFormCustomRegister } from 'kit';
import { Trash } from '@styled-icons/boxicons-regular/Trash';

import { useDynamicList, DynamicListContext, useThingById, useThingsAll } from 'hooks';

import { getObjectFromArray } from 'utility';
import { Part, WorkflowProductPlan } from 'types';
import { WorkflowEditorContext } from './WorkflowEditorContext';


export const EditProductPlans = ({ existingPlans = [] }: {
	existingPlans: WorkflowProductPlan[];
}) => {
	const { isAmendment } = useContext(WorkflowEditorContext);
	const modalProps = useModal<{ plan: WorkflowProductPlan, index: number }>();

	const { updateFormValue: updateFormValue_Plans } = useFormCustomRegister('product.plans');
	const { updateFormValue: updateFormValue_Ids } = useFormCustomRegister('product.fixed_part_ids');


	const dynamicListContext =  useDynamicList<WorkflowProductPlan>(existingPlans, { onUpdate: updatePlans });
	const { rows: plans, isAdding, addRow, AddButton } = dynamicListContext;


	function updatePlans(newPlans: WorkflowProductPlan[]) {
		// undefined is ok for newPlans argument
		updateFormValue_Plans(newPlans);
		updateFormValue_Ids(newPlans && newPlans.map(plan => plan.part_id));
	}


	function toggleEdit(plan, index){
		modalProps.setModalData({ plan, index });
		modalProps.toggleModal();
	}

	function toggleAdd(){
		const newPlan = {} as WorkflowProductPlan;
		const newIndex = plans.length;
		addRow(newPlan);
		toggleEdit(newPlan, newIndex);
	}

	return (<>
		{plans.map((plan, index) => {
			return (<ProductPlanView 
				key={`${plan.part_id}-${index}`} 
				plan={plan} 
				onClickEdit={!isAmendment && toggleEdit.bind(this, plan, index)} 
				/>
			);
		})}

		{!isAmendment && !isAdding && <AddButton onClick={toggleAdd} />}

		<UIModal {...modalProps} width="small"> 
			<ProductPlanEdit 
				onSubmit={modalProps.toggleModal} 
				index={modalProps.modalData?.index} 
				dynamicListContext={dynamicListContext}
			/>
		</UIModal>
	</>);
};


const ProductPlanView = ({ plan = {} as WorkflowProductPlan, onClickEdit }: {
	plan: WorkflowProductPlan
	onClickEdit
}) => {
	
	const { item: part } = useThingById('partProduct', plan.part_id);
	
	return (
		<UICard title={part.name} actionOnClick={onClickEdit} width="100%">
			<div>Default Qty: {plan.default_qty ? plan.default_qty : 'Not set'}</div>
			<div>Enforce BOM: {plan.enforce_bom ? 'Yes' : 'No'}</div>
			<div>Products will be added to FGI {plan.transact_in_step ? 'at specified step' : 'at end of job'}</div>
		</UICard>
	);
};


const ProductPlanEdit = (
	{
		index,
		dynamicListContext,
		onSubmit = () => {}
	}: {
		index: number
		dynamicListContext: DynamicListContext<WorkflowProductPlan>
		onSubmit?(): void
	}
) => {

	const { editRow, deleteRow, rows } = dynamicListContext;
	const plan = rows[index] || {} as WorkflowProductPlan;
	const usedPartIds = rows.map( row => row.part_id );
	
	const { items: parts } = useThingsAll('partProduct');

	const unusedParts = parts.filter(part => {
		return (usedPartIds.indexOf(part.id) === -1 // Include parts that are not already selected 
			|| part.id === plan.part_id // But do include the part id that was originally selected
		);
	});

	const { formMethods } = useUIForm<WorkflowProductPlan>(plan);
	
	const selectedPartId = formMethods.watch('part_id');
	const selectedPart: Part = getObjectFromArray(parts, 'id', selectedPartId);
	
	/**Can't use type = submit right now because this is inside another form */
	function saveProductPlan() {
		const formValues = formMethods.getValues();
		formMethods.trigger().then(validationSuccessful => {
			if (validationSuccessful) {
				editRow(index, formValues);
				onSubmit();
			}
		});
	}

	function onClickDelete(){
		deleteRow(index);
		onSubmit();
	}
	
	return (
	<StyledCard title="Edit Product" actionOnClick={onClickDelete} overrideEditIcon={<Trash />} width="100%" >
		<UIForm2 formMethods={formMethods}>
			<UISelect 
				name="part_id" 
				label="Product" 
				options={unusedParts} 
				defaultValue={plan.part_id} 
				hasConditionalComponents={false} 
				required 
			/>

			<UINumberInput name="default_qty" label="Default Quantity" endLabel={selectedPart.uom} defaultValue={plan.default_qty} />

			<UISwitch label="Enforce BOM consumption" name="enforce_bom" disabled />
			<UISwitch label="Release to FGI before end of job" name="transact_in_step" disabled />

			<UIButton label="Submit" onClick={saveProductPlan} /**Can't use type = submit right now because this is inside another form */ />
		</UIForm2>
	</StyledCard>);
};

const StyledCard = styled(UICard)`
	box-shadow: none;
	border: none;
	margin: 0;
`;