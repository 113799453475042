
import { useSearchParams, useLocation } from 'react-router-dom';
import { setStoreQueryString, getStoreQueryStringForPath } from 'store';
import { useEffect } from 'react';


export const useQueryString = () => {
	let [searchParams, setSearchParams] = useSearchParams();

	const location = useLocation();
	const { pathname } = location
	
	// apply query string if there is one in the store for this path
	useEffect(() => {
		if (location.key !== 'default') { 
			/** i think that location.key is default when the page is loaded for the first time
			 * We don't want this effect to run the first time, because we are assuming that if you go to this specific url, that you want it to load as written
			 * The most notable behavior that would happen if the first load check is not done, is that something like /steps/7 would be replaced with /steps/7?sort=job_step_index%3Aasc (if it was in localStorage)
			 * 		There is something about how react-table initialized, and the table wouldn't reflect the filter anyway
			*/
			const qs = getStoreQueryStringForPath(location.pathname);
			if (qs) {
				searchParams = new URLSearchParams(qs);
				setSearchParams(searchParams, { replace: true })
			}
		}
	}, [location.pathname]);

	function updateURLParam(key, newValue){
		if (typeof newValue === 'undefined' || newValue === '') {
			searchParams.delete(key);
		}
		else {
			searchParams.set(key, newValue);
		}
		setSearchParams(searchParams, { replace: true });
		setStoreQueryString(pathname, searchParams.toString())
	}

	const queryString = searchParams.toString();

	return { queryString, searchParams, updateURLParam }
}