import React from 'react';
import ReactDOM from 'react-dom';
import { PanelContext } from './PanelState';

export class LeftPortal extends React.Component {
	constructor(props) {
		super(props);
		// Create a div that we'll render the modal into. Because each
		// Modal component has its own element, we can render multiple
		// modal components into the modal container.
		//@ts-ignore
		this.el = document.createElement('div');
		//@ts-ignore
		this.el.className = 'leftpanel';
	}

	componentDidMount() {
		// Append the element into the DOM on mount. We'll render
		// into the modal container element (see the HTML tab).
		const leftPortal = document.getElementById('left-portal');
		//@ts-ignore
		leftPortal.appendChild(this.el);
		this.context.setLeftPanelUsed(true);
		this.context.openLeftPanel();
	}

	componentWillUnmount() {
		// Remove the element from the DOM when we unmount
		const leftPortal = document.getElementById('left-portal');
		//@ts-ignore
		leftPortal.removeChild(this.el);
		this.context.setLeftPanelUsed(false);
		this.context.closeLeftPanel();
	}

	render() {
		// Use a portal to render the children into the element
		return ReactDOM.createPortal(
			// Any valid React child: JSX, strings, arrays, etc.
			this.props.children,
			// A DOM element
			//@ts-ignore
			this.el
		);
	}
}
LeftPortal.contextType = PanelContext;