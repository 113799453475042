import { WorkflowDatabaseStep, WorkflowStepModType, SupplementalStepInfo } from 'types';
import { deepCopy } from 'utility';
import { useAuthStore } from 'store';

type Mod = SupplementalStepInfo['workflow_mod'];


export function generateMod(existingMod: Mod, type: WorkflowStepModType, extraData?): Mod {
	const currentUserId = useAuthStore.getState().internalUser.id;

	let modObject = existingMod ? deepCopy(existingMod) : {} as Mod;

	modObject.user_id = currentUserId;
	modObject.time = new Date();

	switch (type) {
		case 'add':
			modObject.add = true;

			delete modObject.skip;
			break;
		case 'skip':
			modObject.skip = true;
			delete modObject.add;
			break;
		case 'reorder':
			modObject.reorder = extraData;
			break;
		default:
			break;
	}

	return modObject;
}

export function removeMod(existingMod: Mod, type: WorkflowStepModType): Mod {
	const currentUserId = useAuthStore.getState().internalUser.id;

	let modObject = deepCopy(existingMod);

	modObject.user_id = currentUserId;
	modObject.time = new Date();

	delete modObject[type];

	if (Object.keys(modObject).length === 2) {
		modObject = void 0;
	}

	return modObject;
}

export function unReorder(existingMod: Mod): Mod {
	const currentUserId = useAuthStore.getState().internalUser.id;

	let modObject = deepCopy(existingMod);

	modObject.user_id = currentUserId;
	modObject.time = new Date();

	delete modObject.reorder;

	if (Object.keys(modObject).length === 2) {
		modObject = void 0;
	}

	return modObject;
}

export function updateSuppReorder(originalSteps: WorkflowDatabaseStep[], reorderedItem: WorkflowDatabaseStep, originalIndex0: number) {
	let nextStepId = -1;
	for (let index = originalIndex0 + 1; index < originalSteps.length; index++) {
		const dStep = originalSteps[index];
		if (!dStep?.supp?.workflow_mod?.add && !dStep?.supp?.workflow_mod?.reorder) {
			nextStepId = dStep.step_id;
			break;
		}
	}

	let prevStepId = -1;
	for (let index = originalIndex0 - 1; index >= 0; index--) {
		const dStep = originalSteps[index];
		if (!dStep?.supp?.workflow_mod?.add && !dStep?.supp?.workflow_mod?.reorder) {
			prevStepId = dStep.step_id;
			break;
		}
	}

	const reorderData: SupplementalStepInfo['workflow_mod']['reorder'] = {
		next_step_id: nextStepId,
		prev_step_id: prevStepId
	}

	const reorderMod = generateMod(reorderedItem.supp?.workflow_mod, 'reorder', reorderData);
	return reorderMod
}

export function wasOriginalOrderRestored(newSteps: WorkflowDatabaseStep[], reorderedItem: WorkflowDatabaseStep, newIndex0: number) {
	const prevStepId = reorderedItem?.supp?.workflow_mod?.reorder?.prev_step_id;
	const nextStepId = reorderedItem?.supp?.workflow_mod?.reorder?.next_step_id;

	let doesNextStepMatch = false;
	if (nextStepId === -1 && newIndex0 === (newSteps.length - 1)) {
		doesNextStepMatch = true;
	}
	else {
		for (let index = newIndex0 + 1; index < newSteps.length; index++) {
			const dStep = newSteps[index];
			if (dStep?.supp?.workflow_mod?.add || dStep?.supp?.workflow_mod?.reorder) {
				// ignore this one, check the next one
			}
			else if (dStep?.step_id === nextStepId) {
				doesNextStepMatch = true;
			}
			else {
				// you got to a step that is legit, but doesn't match. Break and keep flag as false
				break;
			}
		}
		if (nextStepId === -1) {
			// you looped through all the steps, skipping adds and reorders, and got to the end. So this is effectively the original last step.
			doesNextStepMatch = true;
		}
	}
	
	let doesPrevStepMatch = false;
	if (prevStepId === -1 && newIndex0 === 0) {
		doesPrevStepMatch = true;
	}
	else {
		for (let index = newIndex0 - 1; index >= 0; index--) {
			const dStep = newSteps[index];
			if (dStep?.supp?.workflow_mod?.add || dStep?.supp?.workflow_mod?.reorder) {
				// ignore this one, check the next one
			}
			else if (dStep?.step_id === prevStepId) {
				doesPrevStepMatch = true;
			}
			else {
				break;
			}
		}
		if (prevStepId === -1) {
			// you looped through all the steps, skipping adds and reorders, and got to the beginning. So this is effectively the original first step.
			doesPrevStepMatch = true;
		}
	}

	return (doesNextStepMatch && doesPrevStepMatch)
}

export function isStepSkipped(step){
	return typeof step?.supp?.workflow_mod?.skip !== 'undefined';
}

export function isLastStepInJob(steps: WorkflowDatabaseStep[], stepIndex1){
	const nextStepIndex = getNextAvailableStep(steps, stepIndex1 - 1, stepIndex1 - 1, true);
	return nextStepIndex === -1;
}

/** Anchor step will not be evaluated. Next available step must be either above or below the anchor */
export function getNextAvailableStep(steps: WorkflowDatabaseStep[], firstAmendableStep0, anchorIndex0, directionUp = true){
	if (directionUp) {
		for (let i = anchorIndex0 + 1; i < steps.length; i++) {
			const dStep = steps[i];
			if (dStep && !isStepSkipped(dStep)) {
				return i + 1; // return as index 1
			}
		}
	}
	else {
		for (let i = anchorIndex0 - 1; i >= firstAmendableStep0; i--) {
			const dStep = steps[i];
			if (dStep && !isStepSkipped(dStep)) {
				return i + 1; // return as index 1
			}
		}
	}
	return -1;
}