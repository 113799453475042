import { css, DefaultTheme } from 'styled-components';
// import { createMuiTheme } from '@material-ui/core'; // using require helped with intellisense speed https://github.com/microsoft/TypeScript/issues/34801#issuecomment-633622448
const createMuiTheme = require('@material-ui/core/styles/createMuiTheme').default
import { StyledIconBase } from '@styled-icons/styled-icon'
//@ts-ignore
import tinycolor from 'tinycolor2'

export const gradientBlue = 'linear-gradient(310deg, #0ea7f3 30%, #61d6fd)';

export const convertHexToRGBA = (hex, opacity) => {
	const tempHex = hex.replace('#', '');
	const r = parseInt(tempHex.substring(0, 2), 16);
	const g = parseInt(tempHex.substring(2, 4), 16);
	const b = parseInt(tempHex.substring(4, 6), 16);
  
	return `rgba(${r},${g},${b},${opacity})`;
};
const titleFont = 'Poppins, -apple-system,BlinkMacSystemFont,Segoe UI,Helvetica Neue,Helvetica,Arial,sans-serif';
const bodyFont =  'Poppins, -apple-system,BlinkMacSystemFont,Segoe UI,Helvetica Neue,Helvetica,Arial,sans-serif'; // Make sure to change this in index.css too
const tableFont = 'Poppins, -apple-system,BlinkMacSystemFont,Segoe UI,Helvetica Neue,Helvetica,Arial,sans-serif';


const primary = '#2D7FF9'

const colorDefinitions = {
	aqua: '#0DD5E8',
	aquaLight: '#ccfaff',
	red: '#f98080',
	redLight: 'hsl(0, 98%, 98%)',
	orange: '#F39237',
	orangeLight: '#fff7d9',
	purple: '#d672ff',
	blue: '#7BB9FA',
	green: '#4fd676',
	greenLight: 'hsl(155, 95%, 95%)',
	greyblue: '#c7d7de',
	grey1: '#f5f8fc',
	grey2: '#f0f4f9',
	grey3: '#d4d6d8',
	grey4: '#98A7B9',
	grey5: '#84878a',
	slate: 'slategray'
}


const colors = {
	baseText: '#2c2c30', // target hsl,  L = 0.15 for good contrast
	baseTextLight: '#707076', //'#5d5d65', // target hsl,  L = 0.35
	primary: primary,
	// primary: colorDefinitions.blue,
	primaryLightAlpha: tinycolor(primary).lighten(35).setAlpha(0.7).toRgbString(),
	primaryLight1: tinycolor(primary).lighten(40).toHexString(),
	primaryLight2: tinycolor(primary).lighten(20).toHexString(),
	primaryDark: tinycolor(primary).darken(10).toHexString(),
	secondary: colorDefinitions.grey4,
	secondaryLight: colorDefinitions.aquaLight,
	secondaryDark: '',
	accent1: '',
	accent2: '',
	accent3: '',
	
	success: colorDefinitions.green,
	successLight: colorDefinitions.greenLight,
	successDark: tinycolor(colorDefinitions.green).darken(15).toHexString(),
	error: colorDefinitions.red,
	errorLight: colorDefinitions.redLight,
	errorDark: tinycolor(colorDefinitions.red).darken(25).toHexString(),
	warningLight: colorDefinitions.orangeLight,
	warning: colorDefinitions.orange,
	muted: colorDefinitions.grey4,
	mutedLight: colorDefinitions.grey2,
	mutedDark: colorDefinitions.grey5,
	
	components: {
		navbar: 'ghostwhite'
	},
	
	brand: 'primary', //used by grommet set brand to primary	
	'accent-1': '#ff3a68', //used by grommet 
	'accent-2': '#f597f6', //used by grommet 
	'accent-3': '#86dd7c', //used by grommet 
	'focus': 'transparent' //used by grommet 
};


/** Needs to get applied to the app wrapper, and anything that gets portaled out of the main app div */
export const baseAppStyles = css`
	/** This should match index.css */
	font-size: 13px;
	font-family: ${bodyFont};
	/** This should match index.css */
	line-height: 1.4;
	color: ${props => props.theme.colors.baseText};
	${StyledIconBase} {
		margin: 0 4px;
		&.warning {
			color: ${colors.warning};
		}
		&.success {
			color: ${colors.success};
		}
		&.error {
			color: ${colors.error};
		}
	}
`;


export const mUiTheme = createMuiTheme({
	props: {
		// Name of the component ⚛️
		MuiButtonBase: {
			// The properties to apply
			disableRipple: true // No more ripple, on the whole application!
		}
	},
	typography: {
		fontFamily: bodyFont
	},
	palette: {
		primary: {
			main: colors.primary
		},
		secondary: {
			main: colors.secondary
		},
		text: {
			primary: colors.baseText
		}
	},
	shape: {
		borderRadius: 4
	}
});

export const theme: DefaultTheme = {	
	font: {
		title: titleFont,
		body: bodyFont,
		table: tableFont,
		boldWeight: 500
	},
	'colors': { ...colors, colorDefinitions },
	'rounding': 10,
	'spacing': 18,	
	margin: {
		none: css`
			margin: 0px;
		`,
		medium: css`
			margin: 1.5rem; /**1.5rem is the standard line height */
		`,
		large: css`
			margin: 3rem;
		`,
		mediumVertical: css`
			margin: 0 0 1.5rem 0;
		`,
		largeVertical: css`
			margin: 0 0 3rem 0;
		`
	},
	padding: {
		small: css`
			box-sizing: border-box;
			padding: 0.8rem;
		`,
		medium: css`
			box-sizing: border-box;
			padding: 1rem;
			@media (min-width: 1000px) {
				padding: 1.5rem 1.5rem;
			}
		`,
		large: css`
			box-sizing: border-box;
			padding: 2rem;
			@media (min-width: 1000px) {
				padding: 2.5rem;
			}
		`,
		mainContainer:  css`
			box-sizing: border-box;
			padding: 1.5rem 2rem;
			@media (min-width: 1000px) {
				padding: 2.6rem 6rem;
			}
		`
	},
	positionMainContainerCounter: { // Make these match above
		top: css`
			top: -1.5rem;
			@media (min-width: 1000px) {
				top: -2.6rem;
			}
		`,
		left: css`
			left: calc(-2rem - 1px);
			@media (min-width: 1000px) {
				left: calc(-6rem - 1px);
			}
		`
	},
	container: {
		baseStyles: 	css`
			box-sizing: border-box;
			/* margin: 0 auto; */
		`,
		get default() { return this.large },
		get xlarge() { return css`
			${this.baseStyles}
			max-width: 1800px;
		`},
		get large() { return css`
			${this.baseStyles}
			max-width: 1200px;
		`},
		get medium() { return css`
			${this.baseStyles}
			max-width: 800px;
		`},
		get small() { return css`
			${this.baseStyles}
			max-width: 500px;
		`}
	},
	shadow: {
		depth1: css`box-shadow: 0  1px  3px rgba(0,0,0,0.16);`,
		depth2: css`box-shadow: 0  4px  5px rgba(0,0,0,0.18);`,
		depth3: css`box-shadow: 0  5px 10px rgba(0,0,0,0.25);`,
		depth4: css`box-shadow: 0  7px 14px rgba(0,0,0,0.25);`,
		depth5: css`box-shadow: 0  9px 20px rgba(0,0,0,0.30);`,
		bottomOnly2: css`box-shadow: 0  1px 1px rgba(0,0,0,0.18);`,
		rightOnly2: css`box-shadow: 2px 0px 1px -1px rgba(0,0,0,0.18);`
	},
	mediaQuery: {
		breakpoints: {
			mobile: 600,
			full: 1400
		},
		get mobile() { return `(max-width: ${this.breakpoints.mobile}px)`},
		get moreThanMobile() { return `(min-width: ${this.breakpoints.mobile}px)`},
		get lessThanFull() { return `(max-width: ${this.breakpoints.full}px)`},
		get full() { return `(min-width: ${this.breakpoints.full}px)`}
	},
	scrollbar: {
		baseShape: 	css`
			scrollbar-width: thin;
			&::-webkit-scrollbar {
				width: 16px;
				max-width: 1vw;
				min-width: 10px;

				height: 16px;
				max-height: 1vw;
				min-height: 10px;
			}
			&::-webkit-scrollbar-track {
				border-radius: 16px;
			}
			&::-webkit-scrollbar-thumb {
				border-radius: 16px;
				@media (min-width: 1600px) {
					border-width: 4px;
				}
			}
			&::-webkit-scrollbar-button {
				height: 2px;
				width: 2px;
				color: transparent;
				border-radius: 16px;
			}
		`,
		get light() { return css`
			${this.baseShape}
			scrollbar-color: ${colorDefinitions.grey3} ${colorDefinitions.grey1};
			&::-webkit-scrollbar-track {
				background: ${colorDefinitions.grey1};
			}
			&::-webkit-scrollbar-thumb {
				background-color: ${colorDefinitions.grey3} ;
				border: 3px solid ${colorDefinitions.grey1};
			}
		`},
		get dark() { return css`
			${this.baseShape}
			scrollbar-color: ${colorDefinitions.grey4} #212a2d;
			&::-webkit-scrollbar-track {
				background: #212a2d;
			}
			&::-webkit-scrollbar-thumb {
				background-color: ${colorDefinitions.grey4} ;
				border: 3px solid #212a2d;
			}
		`}
	},
	presets: {
		inputDisabled: css `
			background-color: ${colorDefinitions.grey1};
			color: ${colors.baseTextLight};
			cursor: not-allowed;
		`,
		overflowEllipsis: css`
			text-overflow: ellipsis;
			width: 100%;
			/* Required for text-overflow to do anything */
			white-space: nowrap;
			overflow: hidden;
		`
	},
	'global': {
		'colors': colors,		
		'font': {
			'family': bodyFont,
			'size': 'unset', // you need this to make the font not fefault to a large font that didn't look right
			'height': 'unset'// you need this to make the font not fefault to a large font that didn't look right
			// 'maxWidth': '243px',
			// 'weight': '400'
			//'face': 'Red Hat Display'
			//'face': '/* latin-ext */\n@font-face {\n  font-family: \'Poppins\';\n  font-style: normal;\n  font-weight: 400;\n  src: local(\'Poppins\'), local(\'Poppins\'), url(https://fonts.gstatic.com/s/redhatdisplay/v1/8vIQ7wUr0m80wwYf0QCXZzYzUoTg8z6hVYs.woff2) format(\'woff2\');\n  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;\n}\n/* latin */\n@font-face {\n  font-family: \'Poppins\';\n  font-style: normal;\n  font-weight: 400;\n  src: local(\'Red Hat Display\'), local(\'RedHatDisplay-Regular\'), url(https://fonts.gstatic.com/s/redhatdisplay/v1/8vIQ7wUr0m80wwYf0QCXZzYzUoTg_T6h.woff2) format(\'woff2\');\n  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;\n}\n'
		},
		'control': {
			'border': {
				'radius': '10px'
			}
		},
		drop: {
			border: {
				radius: '10px'
			},
			zIndex: 10000
		},
		'borderSize': {
			'xsmall': '1px',
			'small': '2px',
			'medium': '3px',
			'large': '9px',
			'xlarge': '18px'
		},
		'breakpoints': {
			'small': {
				'value': 576,
				'borderSize': {
					'xsmall': '1px',
					'small': '2px',
					'medium': '3px',
					'large': '4.5px',
					'xlarge': '9px'
				},
				'edgeSize': {
					'none': '0px',
					'hair': '1px',
					'xxsmall': '2px',
					'xsmall': '2.25px',
					'small': '4.5px',
					'medium': '9px',
					'large': '18px',
					'xlarge': '36px'
				},
				'size': {
					'xxsmall': '18px',
					'xsmall': '36px',
					'small': '72px',
					'medium': '144px',
					'large': '288px',
					'xlarge': '576px',
					'full': '100%'
				}
			},
			'medium': {
				'value': 1152
			},
			'large': {}
		},
		'edgeSize': {
			'none': '0px',
			'hair': '1px',
			'xxsmall': '2.25px',
			'xsmall': '4.5px',
			'small': '9px',
			'medium': '18px',
			'large': '36px',
			'xlarge': '72px',
			'responsiveBreakpoint': 'small'
		},
		'input': {
			'padding': '9px',
			'weight': 600
		},
		'spacing': '18px',
		'size': {
			'xxsmall': '36px',
			'xsmall': '72px',
			'small': '144px',
			'medium': '288px',
			'large': '576px',
			'xlarge': '864px',
			'xxlarge': '1152px',
			'full': '100%'
		}
	},
	'button': {
		'border': {
			'width': '2px',
			'radius': '13.5px'
		},
		'padding': {
			'vertical': '2.5px',
			'horizontal': '16px'
		},		
		'color': 'white'
	},
	'paragraph': {
		'small': {
			'size': '10.5px',
			'height': '15px',
			'maxWidth': '189px'
		},
		'medium': {
			'size': '13.5px',
			'height': '18px',
			'maxWidth': '243px'
		},
		'large': {
			'size': '16.5px',
			'height': '21px',
			'maxWidth': '297px'
		},
		'xlarge': {
			'size': '19.5px',
			'height': '24px',
			'maxWidth': '351px'
		},
		'xxlarge': {
			'size': '25.5px',
			'height': '30px',
			'maxWidth': '459px'
		}
	},
	'text': {
		'xsmall': {
			'size': '9px',
			'height': '13.5px',
			'maxWidth': '162px'
		},
		'small': {
			'size': '10.5px',
			'height': '15px',
			'maxWidth': '189px'
		},
		'medium': {
			'size': '13.5px',
			'height': '18px',
			'maxWidth': '243px'
		},
		'large': {
			'size': '16.5px',
			'height': '21px',
			'maxWidth': '297px'
		},
		'xlarge': {
			'size': '19.5px',
			'height': '24px',
			'maxWidth': '351px'
		},
		'xxlarge': {
			'size': '25.5px',
			'height': '30px',
			'maxWidth': '459px'
		}
	},
	'checkBox': {
		'check': {
			'radius': '10px'
		},
		'toggle': {
			'radius': '18px',
			'size': '36px'
		},
		'size': '18px'
	}
};