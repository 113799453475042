import React from 'react';
import { UIHeading, UIPage, UIKeyValue } from 'kit';

import { useQuery, gql } from '@apollo/client';

import { User } from 'types';
import { dateFormat } from 'utility';
import { useAuthStore } from 'store';


const usersAllColumns = `
	id
	created_at
	updated_at
	first_name
	last_name
	email
`;

export const GET_USER_BY_ID = gql`
	query GET_USERS (
		$id: String!
	) {
    	user (
			where: { id: { _eq: $id } }
		){
			${usersAllColumns}
		}
	}
`;

export function UserSettings() {	
	const userProfile = useAuthStore(state => state.internalUser);
	const setValue = useAuthStore(state => state.setValue);

	const { loading, error } = useQuery(GET_USER_BY_ID,
		{
		variables: {
			id: userProfile.id
		},
		onCompleted: response => {
			const fetchedProfile: User = response?.user?.[0];
			setValue('internalUser', fetchedProfile);
		}
	});

	if (loading || error) { return <div>...</div> }

	return (
		<UIPage id="user" contentContainerSize="small">
			<UIHeading>User Profile</UIHeading>
			<UIKeyValue title="Name" text={userProfile.first_name + ' ' + userProfile.last_name} line />
			<UIKeyValue title="Created" text={dateFormat(userProfile.created_at, 'dateTimeFull')} />
		</UIPage>
	)
}