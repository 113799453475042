import { gql } from '@apollo/client';

import { stepAllColumns } from '@constants';

export const GET_STEPS = gql`
	query GET_STEPS (
		$id: Int 
	){
		step (
			order_by: { name: asc }, 
			where: { id: { _eq: $id } } 
		) {
			${stepAllColumns}
		}
	}
`;

export const GET_STEPS_IN = gql`
	query GET_STEPS_IN (
		$ids: [Int!]
	){
		step (
			order_by: { name: asc }, 
			where: { id: { _in: $ids } } 
		) {
			${stepAllColumns}
		}
	}
`;


export const GET_STEPS_FOR_RECIPE = gql`
	query GET_STEPS_FOR_RECIPE (
		$id: jsonb
	){
		step (
			where: { 
				recipes: { _contains: $id }
			}
		) {
			${stepAllColumns}
		}
	}
`;

export const GET_STEPS_FOR_TOOL = gql`
	query GET_STEPS_FOR_TOOL (
		$id: jsonb
	){
		step (
			where: { 
				tools: { _contains: $id }
			}
		) {
			${stepAllColumns}
		}
	}
`;

export const GET_STEPS_FOR_PART = gql`
	query GET_STEPS_FOR_PART (
		$id: jsonb
	){
		step (
			where: { 
				raw_parts: { _contains: $id }
			}
		) {
			${stepAllColumns}
		}
	}
`;

export const GET_STEPS_FOR_FORM = gql`
	query GET_STEPS_FOR_FORM (
		$id: jsonb
	){
		step (
			where: { 
				forms: { _contains: $id }
			}
		) {
			${stepAllColumns}
		}
	}
`;


export const ADD_STEP = gql`
	mutation ADD_STEP ( 
		$insertingObjs: [step_insert_input!]!
	) {
		insert_step (
			objects: $insertingObjs
		) {
			returning {
				${stepAllColumns}
			}
		}
	}
`;


export const UPDATE_STEP = gql`
	mutation UPDATE_STEP ( 
		$id: Int!
		$changes: step_set_input!
	) {
		update_step (
			where: { id: { _eq: $id } },
			_set: $changes
		) {
			returning {
				${stepAllColumns}
			}
		}
	}
`;


export const UPDATE_STEP_ADD_RECIPE = gql`
	mutation UPDATE_STEP_ADD_RECIPE ( 
		$id: Int!
		$recipeId: jsonb!
	) {
		update_step (
			where: { id: { _eq: $id } }
			_append: { recipes: $recipeId }
		) {
			returning {
				${stepAllColumns}
			}
		}
	}
`;

export const UPDATE_STEP_ADD_TOOL = gql`
	mutation UPDATE_STEP_ADD_TOOL ( 
		$id: Int!
		$toolId: jsonb!
	) {
		update_step (
			where: { id: { _eq: $id } }
			_append: { tools: $toolId }
		) {
			returning {
				${stepAllColumns}
			}
		}
	}
`;

export const UPDATE_STEP_ADD_RAW_PART = gql`
	mutation UPDATE_STEP_ADD_RAW_PART ( 
		$id: Int!
		$partId: jsonb!
	) {
		update_step (
			where: { id: { _eq: $id } }
			_append: { raw_parts: $partId }
		) {
			returning {
				${stepAllColumns}
			}
		}
	}
`;

export const UPDATE_STEP_ADD_FORM = gql`
	mutation UPDATE_STEP_ADD_FORM ( 
		$id: Int!
		$formId: jsonb!
	) {
		update_step (
			where: { id: { _eq: $id } }
			_append: { forms: $formId }
		) {
			returning {
				${stepAllColumns}
			}
		}
	}
`;
