import React from 'react';

import { MessageComponent } from 'components/Messages/Message';
import { AddMessage } from './AddMessage';

import { useQuery } from '@apollo/client';
import { GET_MESSAGES_BY_THREAD } from 'services/messageFetch';

import { Thread, Message } from 'types';
import { UIFlexChild } from 'kit';
import styled from 'styled-components';


export const ThreadsContainer = styled(UIFlexChild)`
	overflow-y: auto;
	${props => props.theme.padding.medium}
`;

export const ThreadsList = (
	props: {	
		threads: Thread[]
		referenceId: number
		referenceType: 'step_history' | 'job' | 'inventory_unique' | 'order'
		newThreadEffect?(): void
	}
) => {
	if (props.threads.length === 0) {
		return (
			<React.Fragment>
				<p>No comments yet.</p>
				<AddMessage 
					referenceType={props.referenceType} 
					referenceId={props.referenceId} 
					newThreadEffect={props.newThreadEffect} 
				/>
			</React.Fragment>
		);
	}
	//else
	return (
		<React.Fragment>
			{props.threads.map( thread => (
				<ExistingThread 
					key={thread.id} 
					thread={thread} 
					referenceId={props.referenceId} 
				/>
			))}
		</React.Fragment>
	);
}

ThreadsList.defaultProps = {
	threads: []
}

export const ExistingThread = (
	props: {	
		thread: Thread
		referenceId: number
	}
) => {
	const { loading, error, data } = useQuery(GET_MESSAGES_BY_THREAD, { variables: { thread_id: props.thread.id } });
	const messages = data?.message ?? [];

	if (loading || error) { return <div>...</div> }

	return (
		<React.Fragment>
			{messages.map( (message: Message) => {
				return <MessageComponent message={message} key={message.id} />
			})
			}
			<AddMessage threadId={props.thread.id} referenceId={props.referenceId} />
		</React.Fragment>
	);
}