import { StepPlan } from 'types';

import { Tools } from '@styled-icons/entypo/Tools';
import { Colours } from '@styled-icons/entypo/Colours';
import { Beaker } from '@styled-icons/octicons/Beaker';
import { ArchiveOut } from '@styled-icons/boxicons-regular/ArchiveOut';
import { File } from '@styled-icons/boxicons-regular/File';
import { StepLinkableThing } from 'root/components/StepLink/LinkStepsFromItem';

export type StepFormCategory = Exclude<keyof StepPlan, 'version'> | 'overview';

export const stepFormCategoryLookup: {
	[key in StepFormCategory]: {
		display: string
		displayPlural: string
		thingName: StepLinkableThing
		subtitle: string
		long_description: string
		icon: any /** The icon function */
	};
} = {
	overview: {
		display: 'Overview',
		displayPlural: 'Overview',
		thingName: 'tool', // this is just a dummy
		subtitle: '',
		long_description: '',
		icon: null
	},
	tool: {
		display: 'Tool',
		thingName: 'tool',
		displayPlural: 'Tools',
		subtitle: '',
		long_description: 'Tools are pieces of equipment that your process runs on. Turn on tool tracking to enable tool traceability in this step.',
		icon: Tools
	},
	recipe: {
		display: 'Recipe',
		displayPlural: 'Recipes',
		thingName: 'recipe',
		subtitle: '',
		long_description: 'Recipes describe the processing that occur. They can be the name of a file that gets loaded on a tool, or descriptive name of the procedure. Recipes are useful for succintly distinguishing the processing of a given step. Turn on recipe tracking to enable traceability in this step.',
		icon: Beaker
	},
	inventory_effect: {
		display: 'Materials',
		displayPlural: 'Materials',
		thingName: 'partRaw',
		subtitle: '',
		long_description: 'Materials are raw inventory that are utilized during the step. They can be integrated into the product that is being manufactured, or they can be consumed during the step. Turn on material tracking to keep track of how many parts are being utilized, and to update inventory quantities as this step is run.',
		icon: ArchiveOut
	},
	forms: {
		display: 'Step Form',
		displayPlural: 'Step Forms',
		thingName: 'form',
		subtitle: '',
		long_description: 'Forms provide a fully customizable method of data collection. With a form, the user performing the step can input a variety of data, such as characterization data, visual inspection results, sampling data, information from the tool, perform a checklist, and so on. Turn on form tracking to require that form(s) are filled out during this step.',
		icon: File
	},
	inventory_unique_form: {
		display: 'Inventory Fields',
		thingName: 'form', // TODO - this is going away maybe??
		displayPlural: 'Inventory Fields',
		subtitle: '',
		long_description: 'Inventory fields provide a means of associating data to a specific inventory item. Turn on inventory field tracking to assign data to the inventory items that are in process in the job.',
		icon: Colours
	}
};
