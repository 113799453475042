import React, { useContext } from 'react';

import styled, { css } from 'styled-components';

import { UIPage, UIIconButton, UIFlexbox, UIButton } from 'kit';

import { LeftMenu } from 'components/Navigation/LeftMenu';
import { PanelContext } from 'components/Navigation/PanelState';
import { MobileNavbar } from 'components/Navigation/MobileNav';

import { Expand } from '@styled-icons/boxicons-regular/Expand';
import { Collapse } from '@styled-icons/boxicons-regular/Collapse';
import { Close } from '@styled-icons/material/Close';

import { MainContainerRoutes, FullPageRoutes, Breadcrumbs } from 'routing';
import { isMobileScreen } from 'utility';
import { useAuth } from 'hooks';


const App = () => {
	const { isLoading, isAuthenticated, errMsg, logout } = useAuth();
	if (errMsg) {
		return (
			<UIPage id="error" fullHeight width="100%" asFlexbox flexDirection="column" flexAlign="center" flexJustify="center">
				<div>{errMsg}</div>
				<UIButton onClick={logout} label="Try again with a different login" margin="medium" />
				<div>
					<a href="https://google.com">If you're currently logged into one Google account, sign in to an elibigle account at google.com</a>
				</div>
			</UIPage>
		);
	}
	if (isLoading || !isAuthenticated) {
		return (
			<UIPage id="loading" fullHeight width="100%" asFlexbox flexDirection="column" flexAlign="center" flexJustify="center">
				<div>Loading</div>
			</UIPage>
		);
	}
	if (!isAuthenticated) {
		return <div>no auth</div>;
		// return <div>Oops... {error.message}</div>;
	}
	return (
		<UIPage id="app" fullHeight>
			<FullPageRoutes />
		</UIPage>
	);
}

export default App;


export const Main = () => {
	const { isLeftMenuOpen, closeLeftMenu, isLeftPanelOpen, isRightPanelOpen, closeRightPanel, isFullWidth, toggleFullWidth } = useContext(PanelContext);

	const FullWidthToggleButton = isFullWidth ? <UIIconButton icon={<Collapse/>} onClick={toggleFullWidth}/>
		: <UIIconButton icon={<Expand/>} onClick={toggleFullWidth} />

	return (
			<GridContainer
				isLeftMenuOpen={isLeftMenuOpen} 
				isLeftPanelOpen={isLeftPanelOpen} 
				isRightPanelOpen={isRightPanelOpen}
				isFullWidth={isFullWidth}
			>

				{/* <div id="left-bar">
					
					<LeftBar />
				</div> */}

				<div id="left-menu">
					{isMobileScreen() && <CloseMenuButton icon={<Close size={16} />} onClick={closeLeftMenu} />}
					<LeftMenu />
				</div>

				<div id="left-portal"> </div>



				<div className="mainpage">
					{isMobileScreen() && <MobileNavbar />}
					<PageTitleFlexbox flexJustify="space-between" flexAlign="flex-start" flexWrap="wrap" margin="largeVertical">
						<Breadcrumbs/>
						<UIFlexbox>
							<div id="navbar-portal"> </div>
							{!isMobileScreen() && FullWidthToggleButton}
						</UIFlexbox>
					</PageTitleFlexbox>
					<MainContainerRoutes />						
				</div>



				<div id="right-portal" className="rightpanel"> 
					{isMobileScreen() && <CloseMenuButton icon={<Close size={16} />} onClick={closeRightPanel} />}
				</div>


			</GridContainer>
	);
}


type GridContainerProps = {
	isLeftMenuOpen: boolean
	isLeftPanelOpen: boolean
	isRightPanelOpen: boolean
	isFullWidth: boolean
}

const GridContainer = styled.div<GridContainerProps>`
	display: grid;

	background-color: white;
	height: 100vh;
	width: 100vw;


	
	grid-template-columns: 224px ${props => props.isLeftPanelOpen ? '224px' : 0} 1fr ${props => props.isRightPanelOpen ? '400px' : 0};
	@media ${props => props.theme.mediaQuery.lessThanFull} {
		grid-template-columns: 224px ${props => props.isLeftPanelOpen ? '224px' : 0} 1fr ${props => props.isRightPanelOpen ? '300px' : 0};
	}
	@media ${props => props.theme.mediaQuery.mobile} {
		grid-template-columns: 100vw;
	}
	grid-template-rows: 100vh;
	

	#left-bar {
		display: flex;

		grid-column: 1;
		@media ${props => props.theme.mediaQuery.mobile} {
			grid-column: none;
			display: none;
		}
		z-index: 10;
	}

	#left-menu {
		display: flex;
		grid-column: 1;
		@media ${props => props.theme.mediaQuery.mobile} {
			display: ${props => !props.isLeftMenuOpen && 'none'};
			grid-column: ${props => props.isLeftMenuOpen && 1};
		}
		z-index: 10;
	}

	#left-portal {
		display: ${props => props.isLeftPanelOpen ? 'block' : 'none'};

		grid-column: 2;
		@media ${props => props.theme.mediaQuery.mobile} {
			grid-column: 1;
		}

		position: relative;
		z-index: 5;
		.leftpanel {
			height: 100vh;
			${props => props.theme.scrollbar.light}
			overflow: auto;
			border-right: 1px solid ${props => props.theme.colors.colorDefinitions.grey3};
		}
	}
	.rightpanel {
		display: block;

		grid-column: 4;
		@media ${props => props.theme.mediaQuery.mobile} {
			display: ${props => !props.isRightPanelOpen && 'none'};
			grid-column: ${props => props.isRightPanelOpen && 1};
		}

		overflow-y: auto;
		border-left: 1px solid ${props => props.theme.colors.colorDefinitions.grey3};
		${props => props.theme.scrollbar.light}
		z-index: 10;
	}

	.mainpage {
		grid-column: 3;
		@media ${props => props.theme.mediaQuery.mobile} {
			grid-column: 1;
			display: ${props => props.isRightPanelOpen && 'none'}; /**Since this and right panel will be competing for grid col 1, set display = none on this so that right panel can take the grid col */
		}
		overflow: auto;

		z-index: 0;
		position: relative;
		${props => props.theme.padding.mainContainer}
		${props => props.theme.scrollbar.light}
	}

	/**Full width prop overrides all other menu open/close states, so make sure this section comes after all othe other css */
	${props => props.isFullWidth && css`
		grid-template-columns: 100vw !important; /**Use important to override specificity */
		#left-bar, #left-menu, #left-portal, #right-portal {
			grid-column: none;
			display: none;
		}
		.mainpage {
			grid-column: 1;
		}
	`}
`;

const CloseMenuButton = styled(UIIconButton)`
	position: absolute;
	top: 24px;
	right: 28px;
	z-index: 199;
`;

const PageTitleFlexbox = styled(UIFlexbox)`
	position: sticky;
	left: 0;
`;