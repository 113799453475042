import React, { useContext } from 'react';

import { UIPage, UIButton, Placeholder } from 'kit';

import { ArrowheadLeftOutline } from '@styled-icons/evaicons-outline/ArrowheadLeftOutline';
import { ArrowheadRightOutline } from '@styled-icons/evaicons-outline/ArrowheadRightOutline';

import { StepTxTable } from 'root/components/StepSetup/StepTransactionTable';


import { PanelContext } from 'components/Navigation/PanelState';
import { LeftPortal } from 'components/Navigation/LeftPortal';
import { NavbarPortal } from 'root/components/Navigation/NavbarPortal';
import { LeftPanelListItem, LeftPanel } from '../components/Navigation/LeftPanel';

import { useParams } from 'react-router';

import { isMobileScreen } from 'utility';
import { Step } from 'types';
import { useThingsAll } from 'hooks';

export function StepPage() {
	const { id } = useParams();
	const savedStepId = parseInt(id);

	const { isLeftPanelOpen, toggleLeftPanel } = useContext(PanelContext);


	return (
		<UIPage id="stepPage" contentContainerSize="xlarge">
			<LeftPortal>
				<StepMenu />
			</LeftPortal>

			{isMobileScreen() && 
				<NavbarPortal>
					<UIButton
						icon={isLeftPanelOpen ? <ArrowheadLeftOutline/> : <ArrowheadRightOutline/>}
						onClick={toggleLeftPanel}
						label="Step List"				
					/>
				</NavbarPortal>
			}

			{savedStepId > 0 ?
			<StepTxTable
				savedStepId={savedStepId}
			/>
			: <Placeholder text="Select a step from the list in the left panel" />}

		</UIPage>
	)
}


const StepListLabel = ({ step }: { step: Step }) => {
	return (
		<LeftPanelListItem
			labelComponent={
				<div>
					<div>{step.name}</div>
					{/* <UISubtitle>{arrayToString(def.fields.filter(item => item.status === 'active').map(item => { return item.name; }))}</UISubtitle> */}
				</div>
			}
			linkToRoute="stepHistoryById"
			linkToVars={{ id: step.id }}
		/>
	)
}
const StepMenu = () => {

	const { items: steps } = useThingsAll('step');

	const menuButtons = steps.map( step => {
			return (
				<StepListLabel key={step.id} step={step} />						
			)
		}
	)

	return (
		<LeftPanel
			menuButtons={menuButtons}
		/>
	)
}
