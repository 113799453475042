import React from 'react';
import styled, { css } from 'styled-components';

import { UIIconButton, GridRow } from 'kit';

import { ArrowNarrowDown } from '@styled-icons/heroicons-outline/ArrowNarrowDown';
import { AddCircle } from '@styled-icons/remix-line/AddCircle';

import { StepCard } from 'components/WorkflowEditor/StepCard';
import { WorkflowEditorRowActionButtons } from './WorkflowEditorRowActionButtons';
import { SidecarContentsInventoryUnique } from './WorkflowEditorStepInventoryUnique';
import { WorkflowEditorColumn, ViewMode } from './WorkflowEditorMainPanel';
import { isStepSkipped } from './modFunctions';


import { UIDraggable, useThingById } from 'hooks';
import { SupplementalStepInfo, WorkflowDatabaseStep } from 'types';
import { EngineerSelectReview, ToolRecipePreview, MaterialPreview, FormPreview, InvUnFormPreview } from './WorkflowEditorDetailPreview';


export const WorkflowEditorRow = React.memo( function WorkflowEditorRow(	
	{ 
		dbStep, index, viewMode, visibleCols, isCompleted, isLast, isSelected, onStepClick, workflowValidationErrors
	}: {
		dbStep: WorkflowDatabaseStep
		index: number
		viewMode: ViewMode
		visibleCols: WorkflowEditorColumn[]
		isCompleted: boolean
		isLast: boolean
		isSelected: boolean
		onStepClick: (index: number) => void
		workflowValidationErrors
	} 
) {
	const { item: step } = useThingById('step', dbStep.step_id);
	const { plan: stepPlan, supp } = dbStep;
	const isSkipped = isStepSkipped(dbStep);

	let indicator: any = index + 1;
	if (isCompleted) { indicator = 'complete' }
	else if (isSkipped) { indicator = 'skip' }
	else if (workflowValidationErrors?.[index]?.hasBrokenLink) { indicator = 'alert' }

	const onStepClickCall = React.useCallback( () => {
		onStepClick(index);
	}, [])

	const WorkflowStep = (
		<WorkflowStepContainer className="steprow" shim={isCompleted}>
			<ModWorkflowStepCard
				className="step-card"
				onClick={onStepClickCall} 
				isSelected={isSelected} 
				indicator={indicator} 
				stepInfo={step}
				stepPlan={stepPlan}
				noMargin
				isCompact={viewMode === 'detailed'}

				isCompleted={isCompleted}
				workflow_mod={supp?.workflow_mod}
			/>

			<WorkflowEditorRowActionButtons dbStep={dbStep} index={index}/>

			
			{visibleCols.includes('inventory_lifecycle') && 
				<Sidecar>
					<SidecarContentsInventoryUnique 
						index0={index} 
					/>
				</Sidecar>
			}
			{visibleCols.includes('engineer_select') && 
				<Sidecar>
					<EngineerSelectReview stepId={dbStep.step_id} stepPlan={stepPlan} supp={supp} index={index} />
				</Sidecar>
			}
			{visibleCols.includes('tool') && 
				<Sidecar>
					<ToolRecipePreview type="tool" stepPlan={stepPlan} />
				</Sidecar>
			}
			{visibleCols.includes('recipe') && 
				<Sidecar>
					<ToolRecipePreview type="recipe" stepPlan={stepPlan} />
				</Sidecar>
			}
			
			{visibleCols.includes('inventory_effect') && 
				<Sidecar>
					<MaterialPreview stepPlan={stepPlan} />
				</Sidecar>
			}
			
			{visibleCols.includes('forms') && 
				<Sidecar>
					<FormPreview stepPlan={stepPlan} />
				</Sidecar>
			}
			{visibleCols.includes('inventory_unique_form') && 
				<Sidecar>
					<InvUnFormPreview stepPlan={stepPlan} />
				</Sidecar>
			}
		</WorkflowStepContainer>
	)
	return (
		<React.Fragment>
			{	isCompleted ? 
				WorkflowStep :
				<UIDraggable  
					index={index}
					name={ 'dest_' + (dbStep.step_id).toString() + '_' + index}
					clone={false}
					content={WorkflowStep}
				/>
			}
			<StepListInterstitial 
				isLast={isLast} 
				viewMode={viewMode}
			/>
		</React.Fragment>
	)
})

const ModWorkflowStepCard = styled(StepCard)<{ isCompleted: boolean, workflow_mod: SupplementalStepInfo['workflow_mod'] }>`
	border: 1px solid transparent;
	${props => props.isCompleted && css`
		border-color: ${props.theme.colors.colorDefinitions.grey3};
		background: ${props.theme.colors.colorDefinitions.grey1};
	`};
	${props => props.workflow_mod?.skip && css`
		border-color: ${props.theme.colors.colorDefinitions.grey4};
		border-style: dashed;
		color: ${props.theme.colors.colorDefinitions.grey5};
		background-color: ${props.theme.colors.colorDefinitions.grey1};
	`};
	${props => props.workflow_mod?.add && css`
		border-color: ${props.theme.colors.success};
	`};
	${props => props.workflow_mod?.rev_change && css`
		border-color: ${props.theme.colors.colorDefinitions.orange};
	`};
	${props => props.workflow_mod?.reorder && css`
		border-color: ${props.theme.colors.colorDefinitions.purple};
	`};

`;

const dragIconWidth = '63px'; // This was determined emperically

const WorkflowStepContainer = styled(GridRow)<{ shim: boolean }>`
	padding-left: ${props => props.shim && dragIconWidth};
	& > div {
		flex: none; 
	}
	.action-buttons {
		width: 100%;
		text-align: center;
		button {
			margin: 5px;
		}
	}
`;

const Sidecar = styled.div`
	.droppable-source-dragging & {
		opacity: 0;
	}
	width: 270px;
	margin: 0 20px;
`;




const StepListInterstitial = ({ isLast, showAdd = false, onClickAdd = () => {}, viewMode }: {
	isLast: boolean
	showAdd?: boolean
	onClickAdd?
	viewMode: ViewMode
}) => {
	const AddButton = showAdd && <UIIconButton icon={<AddCircle size={26} />} onClick={onClickAdd} />;
	return (
		<InterstitialContainer viewMode={viewMode}>
			{ isLast ? 
				AddButton
				: 
				<HoverContainer>
					{AddButton}
					<ArrowNarrowDown size={18} />
				</HoverContainer>
			}
		</InterstitialContainer>
	)
}

const HoverContainer = styled.div`
	position: relative;
	width: 100%;
	:hover button {
		opacity: 1;
		background-color: white;
	}
	button {
		transition: all 0.2s;
		opacity: 0;
		margin: 0;
		svg {
			margin: 0;
		}
		position: absolute;
		top: -2px;
	}
`;

const InterstitialContainer = styled.div<{ viewMode: ViewMode }>`
	width: ${props => props.viewMode === 'detailed' ? '300px' : '500px'};
	color: lightgrey;
	text-align: center;
	padding: 6px 0;
	padding-left: ${dragIconWidth};
	.droppable-source-dragging & {
		opacity: 0;
	}
`;
