import React from 'react';
import styled from 'styled-components';

import { FormInputContainer, FormLabel } from 'kit';

import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';

import { useFormContext, Controller } from 'react-hook-form';

import debounce from 'lodash.debounce';

function stringToNumber(stringValue) {
	// convert string to number.  This will return 0 if the number is invalid.
	const numberValue = isNaN(stringValue * 1) ? void 0 : stringValue * 1;
	return numberValue;
}

export const UINumberInputControlled = (	
	{
		defaultValue, onChange,
		min = 0,
		max = 999999,
		endLabel = '',		
		disabled = false,
		label, showLabel = true,
		className
	}: { 
		defaultValue: number
		onChange: (newValue: number) => void
		min?: number
		max?: number
		endLabel?: string
		disabled?: boolean
		label?: string
		showLabel?: boolean
		className?: string
	} 
) => {
	const updateValue = (event) => {
		const rawValue = event?.target?.value;
		if (rawValue === '') {
			//this is the target value when number input value is not a valid number
			// just don't register anything
		}
		else {
			let newValue = stringToNumber(rawValue);
			// The input props restrict the spinner from putting a number outsize min and max, but you could still type in a value outside of the bounds.
			if (newValue > max) {
				newValue = max;
			}
			else if (newValue < min) {
				newValue = min;
			}
			if (typeof newValue === 'number') {
				onChange(newValue);
			}
		}
	}

	const handleFocus = (event) => event.target.select(); // select all on focus

	return (
		<FormInputContainer className={className}>
			<FormLabel name={name} label={label} required={false} showLabel={label?.length > 0} skipRegister />
			<FormControl variant="outlined">
				<CustomOutlinedInput
					type="number"
					defaultValue={defaultValue}
					inputProps={{ min: min, max: max }}
					endAdornment={endLabel && <InputAdornment position="end">{endLabel}</InputAdornment>}
					labelWidth={0}
					onChange={updateValue}
					onFocus={handleFocus}
					disabled={disabled}
				/>
			</FormControl>
		</FormInputContainer>
	)
}

export const UINumberInput = (	
	props: { 
		name: string
		label: string
		defaultValue?: string | number
		showLabel?: boolean
		required?: boolean
		min?: number
		max?: number
		endLabel?: string
		className?: string
	} 
) => {
	const formContext = useFormContext();
	const { trigger, control  } = formContext;
	//@ts-ignore
	const autoSave = formContext.autoSave;
	//@ts-ignore
	const autoSaveSubmit = formContext.onAutoSave;


	const performAutoSave = () => {
		trigger().then( validateSuccess => {
			if (validateSuccess) {
				autoSaveSubmit();
			}
		});
	}

	const onChange = (e) => {
		// Never tested if autosave works
		if (autoSave) {
			debounce(performAutoSave, 400)
		}
		const rawValue = e?.target?.value;
		const newValue = stringToNumber(rawValue);
		return newValue;
	}

	return (
		<FormInputContainer className={props.className}>
			<FormLabel name={props.name} label={props.label} required={props.required} showLabel={props.showLabel} />
			<Controller 
				as={<FormControl variant="outlined">
					<CustomOutlinedInput
						type="number"
						inputProps={{ min: props.min, max: props.max }}
						defaultValue={props.defaultValue}
						endAdornment={props.endLabel && <InputAdornment position="end">{props.endLabel}</InputAdornment>}
						labelWidth={0}
					/>
				</FormControl>} 
				name={props.name}
				rules={{ required: props.required }}
				control={control} 
				onChange={onChange}
			/>
		</FormInputContainer>		
	)
}

UINumberInput.defaultProps = {
	min: 0,
	max: 999999
}

const CustomOutlinedInput = styled(OutlinedInput)`
	line-height: unset;
	input {
		height: unset;
		padding: 9.5px 10px;
		text-align: right;

		&::-webkit-textfield-decoration-container {
			flex-direction: row-reverse
		}
	}
	.MuiOutlinedInput-inputAdornedEnd {
		padding-right: 0;
	}
`;