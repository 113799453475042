import React, { useMemo } from 'react';

import { Placeholder } from 'kit';

import { Table } from 'components/Table/Table';
import { orderCols, transformOrderData } from 'components/Table/tableRef';

import { useQuery } from '@apollo/client';
import { GET_ORDERS } from 'services/orderFetch';

import { Order } from 'types';


const orderTableColumnDef = [	
	orderCols.name,
	orderCols.startTime,
	orderCols.customer,
	orderCols.po,
	orderCols.dueDate,
	orderCols.fulfillStatus
];


export function OrdersTable(
	props: {
		statusFilter: string
	}
) {
	const { 
		loading,
		error,
		data: { order: orders } = { order: [] as Array<Order> }
	} = useQuery(GET_ORDERS, { variables: { status: props.statusFilter === 'all' ? null : props.statusFilter } });

	const columns = useMemo(() => orderTableColumnDef, []);
	let data = useMemo(() => transformOrderData(orders), [ orders.length]);
	
	if (loading) { return <p>Loading...</p>; }
	else if (error) { return <p>Error :(</p>; }
	
	
	return (
		<React.Fragment>
			{orders.length > 0 ?
			<Table
				columns={columns}
				data={data}
				hasSortableColumns
				hasFilterableColumns
			/>
			: <Placeholder text="No orders found"/>
			}
		</React.Fragment>
	)
}
