import React, { useContext } from 'react';
import { UIModalSimpleAction, ModalHookProps, UISelectControlled, useUIForm, UIForm2, UISelect, UIIconButton } from 'kit';

import { Tag } from '@styled-icons/bootstrap/Tag';

import { useTags } from 'hooks';
import { WorkflowEditorContext } from '.';
import { Job } from 'types';

const cTitle = 'Record Workflow Deviation';
const cDescription = 'Tag this job with a workflow deviation, if applicable. This helps identify that the workflow was altered, for reporting or review purposes.';
const cName = 'deviation_tags';

export const TagWorkflowDeviationModal = (
	{ modalProps }: 
	{
		modalProps: ModalHookProps
	}
) => {
	// const navigateToJobById = useNavMain('jobById');
	
	const { deviationTags, setDeviationTags } = useContext(WorkflowEditorContext);

	const { isTagsLoading, tags } = useTags('deviation_tag');


	return (
		<UIModalSimpleAction 
			{...modalProps} 
			Icon={<Tag />}
			title={cTitle}
			description={cDescription}
			buttonText="OK"
			showCancel
			supplementalContent={
				<UISelectControlled
					isMulti
					name={cName} label=""
					defaultValue={deviationTags}
					onChange={setDeviationTags}
					options={tags}
					isLoading={isTagsLoading}
				/>
			}
		/>
	);
};

export const TagWorkflowDeviationButtonModalFromJob = (
	{ job, modalProps }: 
	{
		job: Job
		modalProps: ModalHookProps
	}
) => {
	const { formMethods, isDirty } = useUIForm<any>();

	const { isTagsLoading, tags } = useTags('deviation_tag');

	return (
		<>
			<UIIconButton icon={<Tag />} onClick={modalProps.openModal} />
			<UIModalSimpleAction 
				{...modalProps} 
				Icon={<Tag />}
				title={cTitle}
				description={cDescription}
				buttonText="Save"
				showCancel
				supplementalContent={
					<UIForm2 formMethods={formMethods}>
						<UISelect
							isMulti
							name={cName} label=""
							defaultValue={job[cName]}
							options={tags}
							isLoading={isTagsLoading}
						/>
					</UIForm2>
				}
				clickOutsideToClose={!isDirty}
			/>
		</>
	);
};
