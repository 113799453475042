import React from 'react';

import { useModal, UIModal, UIButton } from 'kit';

import { OperateTaskButton } from 'components/OperateStep/TaskBannerPresentational';
import { PartRawEffectEdit } from 'components/StepSetup/StepFormPartRaw'

import { StepPlan } from 'types';


export const OperatePartRaw = (
	{ stepPlan, updateInvEffects }: {
		stepPlan: StepPlan
		updateInvEffects
	}
) => {
	const modalProps = useModal();
	const count = stepPlan?.inventory_effect?.items?.length;
	
		return (	
		<>
			<OperateTaskButton
				label={count + ' item' + (count !== 1 ? 's' : '')}
				onClick={modalProps.toggleModal}
			/>

			<UIModal 
				width="medium"
				{...modalProps} 
			>
				<PartRawEffectEdit stepPlan={stepPlan} onChange={updateInvEffects} />
				<UIButton full label="OK" onClick={modalProps.toggleModal} />
			</UIModal>	
		</>
	)
}