import React from 'react';

import styled, { css } from 'styled-components';

import { FormInputContainer } from 'kit';


export const GridContainer = (
	props: {
		columns: number
		children
	} & GridContainerProps
) => {
	const { children, ...styleGridContainerProps } = props;

	return (
		<StyledGridContainer 
			isOutermostContainer 
			{...styleGridContainerProps} 
		>
			{children}
		</StyledGridContainer>
	)
}

GridContainer.defaultProps = {
	disableHover: true
}

interface GridContainerProps {
	columns?: number
	gridTemplateColumns?: string
	gridRowGap?: string
	containerBorder?: boolean
	startSlot?: boolean
	endSlot?: boolean
	disableHover?: boolean
	fullWidth?: boolean
	className?: string
}

interface StyledGridProps extends GridContainerProps {
	isOutermostContainer?: boolean
	hasBottomBorder?: boolean // From GridRow
}

const StyledGridContainer = styled.div<StyledGridProps>`
	display: grid;
	grid-row-gap: ${props => props.gridRowGap || '5px'};
	width: ${props=> props.isOutermostContainer && 'fit-content'};
	min-width: ${props=> props.fullWidth && '100%'};

	border-bottom: ${props => props.hasBottomBorder && '1px solid grey'};
	border: ${props => props.containerBorder && '1px solid grey'};
	border-radius: ${props => props.containerBorder && '5px'};

	${FormInputContainer} {
		margin-bottom: 0;
	}

	${props => props.isOutermostContainer && css`
		margin-bottom: 1.5rem;
		.row {
			
			--columnDefault: repeat(${props.columns}, minmax(50px, 1fr));
			--addColumn: ${props.startSlot && '50px'} var(--columnDefault);
			--column: var(--addColumn) ${props.endSlot && '50px'};
	
			grid-column: 1 / -1;
			display: grid;
			grid-template-columns: var(--column);
			grid-template-columns: ${props.gridTemplateColumns};
			grid-column-gap: 10px;
	
			align-items: center;
			justify-items: flex-start;
	
			span {
				place-self: center;
			}
			&.header {
				font-weight: ${props.theme.font.boldWeight};
			}
		}
	`}
	${props => props.isOutermostContainer && !props.disableHover && css`
		.row:hover:not(.header) {
			color: white;
			border-radius: 10px;
			background-color: ${props.theme.colors.primary};
		}
	`}
`



export const GridRow = (
	props: {		
		hasBottomBorder?: boolean
		isTitleRow?: boolean
		children
		onClick?
		className?: string
	}
) => {
	const { children, isTitleRow, ...restProps } = props;

	return (
		<StyledGridContainer 
			{...restProps} 
			className={`row${isTitleRow ? ' header' : ''} ${props.className}`} 
		>
			{children}
		</StyledGridContainer>
	)
}