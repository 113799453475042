import React, { useContext, useState, useEffect } from 'react';
import styled from 'styled-components';

import { UIHeading, UIButton, UICard, UIFlexbox, UISubtitle, UIAlert } from 'kit';

import { StepFormCategory, stepFormCategoryLookup } from '@constants';
import { deepCopy } from 'utility';
import { StepFormContext } from './StepFormContext';
import { getStepColName } from 'root/services/stepLink';
import { LinkedAssets } from 'pages';


export const StepFormMain = ({ catName, formComponents, isTabActive }: {
	catName: StepFormCategory
	formComponents: JSX.Element | JSX.Element[]
	isTabActive: boolean
}
) => {
	const { 
		step, sectionIsTracked, setSectionIsTracked
	} = useContext(StepFormContext);
	
	const [isLinksShown, setisLinksShown] = useState(false);
	useEffect(() => { setisLinksShown(false); }, [isTabActive])

	const lookup = stepFormCategoryLookup[catName];
	const isTracked = sectionIsTracked[catName] || false;

	function turnOnTracking() {
		let newObj = deepCopy(sectionIsTracked);
		newObj[catName] = true;
		setSectionIsTracked(newObj);
	}
	function turnOffTracking() {
		let newObj = deepCopy(sectionIsTracked);
		newObj[catName] = false;
		setSectionIsTracked(newObj);
	}

	const stepColName = getStepColName(lookup.thingName);
	const stepHasLinkedItems = step[stepColName]?.length > 0
	const requireLinkedItems = catName !== 'inventory_unique_form'

	return (
		<>
			<SectionTitle flexAlign="flex-start" flexJustify="space-between">
				<div>
					<UIHeading>{lookup.display}</UIHeading>
					<UISubtitle>{isTracked && lookup.subtitle}</UISubtitle>
				</div>
				{isTracked && catName !== 'overview' && <UIButton outline label={`Don't track in this step`} size="small"  onClick={turnOffTracking} />}
			</SectionTitle>
			{catName === 'overview' ? formComponents : 
			<>
				{!isTracked &&
					<UICard margin="largeVertical" padding="large" width="auto">
						
						<p>{lookup.long_description}</p>

						{stepHasLinkedItems || !requireLinkedItems ? 
							<UIButton 
								label={`Track ${lookup.display} in this step`} 
								full 
								disabled={requireLinkedItems && !stepHasLinkedItems}
								onClick={turnOnTracking} 
							/>
							:
							<>
								<UIAlert 
									title={`There are no ${lookup.displayPlural} currently linked to this step. Link some first in order to track  ${lookup.displayPlural} in this step.`} 
									variant="warning" 
									actionOnClick={setisLinksShown.bind(this, true)} 
									actionLabel="Link Now" 
								/>
							</>
						}
					</UICard>
				}

				{isTracked  && 
					<>
						{requireLinkedItems && 
							<LinkMessage flexAlign="center" flexJustify="space-between">
								<div>{step[stepColName]?.length} {lookup.displayPlural} are linked for use in this step.</div>
								<UIButton label="Link More" onClick={setisLinksShown.bind(this, true)} size="small" linkish />
							</LinkMessage>
						}
						{formComponents}
					</>
				}

			{isLinksShown && 
				<LinkOverlay>
					<UIFlexbox flexAlign="flex-start" flexJustify="space-between">
						<UIHeading>{`${lookup.display} Links`}</UIHeading>
						<UIButton label="Done Linking" onClick={setisLinksShown.bind(this, false)} size="small" />
					</UIFlexbox>
					<UIAlert title={`Step Link changes made here will apply to all workflows using step "${step.name}"`} />
					<LinkedAssets stepId={step.id} linkedThing={lookup.thingName} />
				</LinkOverlay>
			}

			</>
			}
		</>
	);
};

const SectionTitle = styled(UIFlexbox)`
	margin-bottom: 16px;
`;

const LinkMessage = styled(UIFlexbox)`
	margin-bottom: 16px;
`;

const LinkOverlay = styled.div`
	position: absolute;
	top: 0;
	width: 100%;
	height: 100%;
	background: white;
	.alert-banner {
		margin-bottom: 2rem;
	}
`;