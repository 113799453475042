import React, { useState } from 'react';

import { UICard, Placeholder, GridContainer, GridRow, UIMenu, useModal, UIModal, UIButton, UIIconButton } from 'kit';
import { Collapse } from 'react-collapse';
import { ChevronDown } from '@styled-icons/boxicons-solid/ChevronDown';
import { Dot } from '@styled-icons/octicons/Dot';

import { EditOrderLineItems } from './EditOrderLineItem';

import { useQuery } from '@apollo/client';
import { GET_ORDER_ITEMS_BY_ORDER } from 'services/orderFetch';

import { useFulfill } from 'hooks';
import { FulfillLineItem, ReserveLineItem } from './FulfillReserveLineItem';


import { OrderItem } from 'types';
import { useNavMain, UIRoutedLink } from 'routing';
import { dateFormat } from 'utility';


export const OrderLineItems = (
	{ orderId, isEditable = false }: {
		orderId: number
		isEditable?: boolean
	}
) => {
	const modalPropsFulfillReserve = useModal();
	const [ModalContent, setModalContent] = useState(null);
	const modalPropsEdit = useModal();

	const {
		data: { order_item: orderItems } = { order_item: [] as OrderItem[] }
	} = useQuery(GET_ORDER_ITEMS_BY_ORDER, { variables: { order_id: orderId } });

	function showFulfill(lineItem: OrderItem) {
		setModalContent(
			<FulfillLineItem
				orderItemId={lineItem.id}
				orderId={orderId}
				onAction={modalPropsFulfillReserve.closeModal}
			/>
		)
		modalPropsFulfillReserve.toggleModal(true);
	}

	function showReserve(lineItem: OrderItem) {
		setModalContent(
			<ReserveLineItem
				orderItemId={lineItem.id}
				orderId={orderId}
				onAction={modalPropsFulfillReserve.closeModal}
			/>
		)
		modalPropsFulfillReserve.toggleModal(true);
	}



	return (
		<UICard
			title="Line Items"
			actionOnClick={isEditable && modalPropsEdit.toggleModal}
			width="100%"
		>
			<GridContainer
				columns={6}
				gridTemplateColumns="1fr 80px 100px 150px 100px 50px"
				fullWidth
			>
				<GridRow isTitleRow>
					<div>Name</div>
					<div>Quantity</div>
					<div>Status</div>
					<div>Associated Jobs</div>
					<div>  </div>
				</GridRow>

				{orderItems.length === 0 ? <Placeholder text="No items found" isRow />
				: orderItems.map((item) => {
						return (
							<LineItem
								key={item.id}
								orderItem={item}
								fulfillAction={showFulfill.bind(this, item)}
								reserveAction={showReserve.bind(this, item)}
							/>
						)
					})
				}
			</GridContainer>

			<UIModal {...modalPropsFulfillReserve} width="small">
				{ModalContent}
			</UIModal>

			<UIModal {...modalPropsEdit}>
				<EditOrderLineItems
					isNewOrder={false}
					orderId={orderId}
					existingLineItems={orderItems}
					onSave={modalPropsEdit.toggleModal}
				/>
			</UIModal>

		</UICard>
	)
};

const LineItem = (
	{
		orderItem = {} as OrderItem,
		fulfillAction,
		reserveAction
	}:
	{
		orderItem: OrderItem
		fulfillAction
		reserveAction
	}
) => {
	const navJobDraft = useNavMain('jobNewDraft');

	const [isExpanded, setIsExpanded] = useState(false);

	function toggleExpanded() {
		setIsExpanded(!isExpanded);
	}

	const { fulfillmentsWithJobs, reservationsWithJobs } = useFulfill(orderItem.id, orderItem.status !== 'fulfilled');

	return (
		<>
			<GridRow>
				<div>{orderItem.part.name}</div>
				<div>{orderItem.qty}</div>
				<div>{orderItem.status}</div>
				<div>
					{fulfillmentsWithJobs.map( tx => <UIRoutedLink key={`tx${tx.id}`} display="block" text={tx.job?.name} linkToRoute="jobById" linkToVars={{ id: tx.job_id }} />)}
					{reservationsWithJobs.map( wip => <UIRoutedLink key={`w${wip.id}`} display="block" text={wip.job?.name} linkToRoute="jobById" linkToVars={{ id: wip.job_id }} />)}
				</div>
				<div>
					{orderItem.status !== 'fulfilled' && 
					<UIMenu
						options={[
							{ label: 'Fulfill From Inventory', onClick: fulfillAction },
							{ label: 'Manage Reservations', onClick: reserveAction },
							{ label: 'Create Job', onClick: navJobDraft }
						]}
						overrideButton={<UIButton label="Fulfill..." outline />}
					/>}
				</div>
				<div>
					{orderItem.part_txs?.length > 0  && <UIIconButton icon={<ChevronDown />} onClick={toggleExpanded} /> }
				</div>
			</GridRow>
			<Collapse isOpened={isExpanded}>
				<GridContainer
					columns={3}
					gridTemplateColumns="50px 200px 1fr"
					gridRowGap="20px"
					fullWidth
				>
					{orderItem.part_txs.map( tx => {
						return(
							<TxItem orderItem={orderItem} tx={tx} key={tx.id}/>
						)
					})}
				</GridContainer>
			</Collapse>
		</>
	);
}

const TxItem = (
	{
		orderItem = {} as OrderItem,
		tx
	}:
	{
		orderItem: OrderItem,
		tx?
	}
) => {	
	const unit = orderItem.part.uom;
	
	return (
		<GridRow>
			<Dot size="20px"/>
			<div>{dateFormat(tx.created_at, 'dateTimeSlash')}</div>

			{tx.job ?
			<div>
				{tx.qty_change + ' ' + unit}
				{' fullfilled from job '}
				<UIRoutedLink key={`tx${tx.id}`} text={tx.job?.name} linkToRoute="jobById" linkToVars={{ id: tx.job_id }} />
			</div>
			:
			<div>
				{tx.qty_change + ' ' + unit}
				{' fullfilled from inventory'}
			</div>}
		</GridRow>
	)
}