import { useNavigate } from 'react-router-dom';
import { getObjectFromArray } from 'utility';
import { mainRoutes } from 'routing/routes';
import { fullRoutes } from 'routing/fullRoutes';
import { mainRouteKeys, fullRouteKeys, MainRouteName, FullRouteName, RouteDefinition, URLParamVariable } from 'routing/routeInterfaces';

export function getRoutePath(routeName: MainRouteName | FullRouteName, vars?: { id?: number, index?: number }) {
	let route: RouteDefinition;
	//@ts-ignore
	if (mainRouteKeys.includes(routeName)) {
		//@ts-ignore
		route = getObjectFromArray(mainRoutes, 'id', routeName);
	}
	//@ts-ignore
	else if (fullRouteKeys.includes(routeName)) {
		//@ts-ignore
		route = getObjectFromArray(fullRoutes, 'id', routeName);
	}

	let path = route.path;

	// Has variable params
	const pathChunks = route.path.split('/').filter(chunk => chunk.length > 0);
	const varNeeded = pathChunks
		.filter(chunk => chunk.indexOf(':') > -1) // filter down to chunks with ':'
		.map(chunk => chunk.substr(1)) as URLParamVariable[]; // remove the ':' (assuming it's first char in string)


	varNeeded.forEach( variableName => {
		switch (variableName) {
			case 'id':
				if (typeof vars?.id !== 'number') { console.error(`Route ${routeName}: id not provided.`) }
				path = path.replace(':id', vars?.id?.toString() );
				break;
			case 'index':
				if (typeof vars?.index !== 'number') { console.error(`Route ${routeName}: index not provided.`) }
				path = path.replace(':index', vars?.index?.toString() );
				break;
			default:
				break;
		}
	})

	return path;
}

export function useNavMain(routeName: MainRouteName) {
	const navigate = useNavigate();
	const route = getObjectFromArray(mainRoutes, 'id', routeName);

	let navFunc;

	// No variables
	if (route.path.indexOf(':') === -1) {
		navFunc = () => navigate(route.path);
		return navFunc;
	}

	// Has variable params
	const pathChunks = route.path.split('/').filter(chunk => chunk.length > 0);
	const varNeeded = pathChunks
		.filter(chunk => chunk.indexOf(':') > -1) // filter down to chunks with ':'
		.map(chunk => chunk.substr(1)) as URLParamVariable[]; // remove the ':' (assuming it's first char in string)


	if (varNeeded.length === 1) {
		const variableName = varNeeded[0];
		switch (variableName) {
			case 'id':
				navFunc = (id) => navigate(route.path.replace(':id', id));
				break;
			case 'index':
				navFunc = (index) => navigate(route.path.replace(':index', index));
				break;
			default:
				break;
		}

		return navFunc;
	}
	else if (varNeeded.length === 2) {
		navFunc = (id, index) => navigate(route.path.replace(':id', id).replace(':index', index));
		return navFunc;
	}

	console.error('Something went wrong');
}


export function useNavFull(routeName: FullRouteName) {
	const navigate = useNavigate();
	const route = getObjectFromArray(fullRoutes, 'id', routeName);

	let navFunc;

	// No variables
	if (route.path.indexOf(':') === -1) {
		navFunc = () => navigate(route.path);
		return navFunc;
	}

	// Has variable params
	const pathChunks = route.path.split('/').filter(chunk => chunk.length > 0);
	const varNeeded = pathChunks
		.filter(chunk => chunk.indexOf(':') > -1) // filter down to chunks with ':'
		.map(chunk => chunk.substr(1)) as URLParamVariable[]; // remove the ':' (assuming it's first char in string)


	if (varNeeded.length === 1) {
		const variableName = varNeeded[0];
		switch (variableName) {
			case 'id':
				navFunc = (id) => navigate(route.path.replace(':id', id));
				break;
			case 'index':
				navFunc = (index) => navigate(route.path.replace(':index', index));
				break;
			default:
				break;
		}

		return navFunc;
	}
	else if (varNeeded.length === 2) {
		navFunc = (id, index) => navigate(route.path.replace(':id', id).replace(':index', index));
		return navFunc;
	}

	console.error('Something went wrong');
}

