import { WorkflowOptions } from 'types';

export function updateWorkflowOptionsToCurrentVersion(workflowOptions): WorkflowOptions{
	if (workflowOptions.version === '1') {
		// return migrateV1toV2(workflowOptions)
	}
	return workflowOptions
}

// function migrateV1toV2(workflowOptions: WorkflowOptionsV1): WorkflowOptionsV2 {
// 	const { ...existingProperties } = workflowOptions;
// 	let newWorkflowOptions: WorkflowOptionsV2 = {
// 		...existingProperties,
// 		product_enabled: false,
// 		version: '2'
// 	};
// 	return newWorkflowOptions
// }