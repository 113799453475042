import React, { useState, useContext } from 'react';

import { UIFlexbox, UISelect, UIButton, UIAlertContainer } from 'kit';

import { AddCircle } from '@styled-icons/remix-line/AddCircle';

import { useThingsIn } from 'hooks';
import { StepFormContext } from './StepFormContext';
import { useFormContext } from 'react-hook-form';
import { StepPlan } from 'types';
import { removeFromArrayByValue } from 'utility';
import { BrokenLinkAlertBanner } from './BrokenLinkAlertBanner';


export const AttachFormToStep = (	
	{ existingForms = [] }: {
		existingForms: Array<number>
	}
) => {	
	const { step, validationErrors } = useContext(StepFormContext);
	const formMethods = useFormContext();
	const { isLoading, items: formDefinitions } = useThingsIn('form', step.forms);

	const [showAdd, setShowAdd] = useState(existingForms === null ? true : existingForms.length === 0);

	const linkErrorMessages = validationErrors?.linkErrors?.forms || [];

	function removeByFormId(formId: number){
		const currentFormState = formMethods.getValues() as StepPlan;
		const currentFormValues = currentFormState.forms || [];
		const newFormValues = removeFromArrayByValue(currentFormValues, formId);

		formMethods.setValue('forms', newFormValues, { shouldValidate: true, shouldDirty: true });
		//@ts-ignore
		formMethods.onAutoSave(); //The normal auto-save / auto-revalidate doesn't happen on it's own because the value was changed programmatically and not via an onChange event
	}

	if (showAdd) {
		return (
			<UIFlexbox flexAlign="flex-end" >
				<UIButton quiet icon={<AddCircle/>} label="Add Form" onClick={setShowAdd.bind(this, false)} />
			</UIFlexbox>
		)
	}
	return (		
	<>
		<UIAlertContainer>
			{linkErrorMessages.map( err => {
				const removeFunc = removeByFormId.bind(this, err.invalidLinkId);
				return (
					<BrokenLinkAlertBanner 
						key={err.invalidLinkId} 
						categoryName="forms"
						removeFunc={removeFunc} 
						err={err}
					/> 
				)
			})
			}
		</UIAlertContainer>
		
		<UISelect
			isMulti
			name="forms"
			label="Add a step form"
			isLoading={isLoading}
			options={formDefinitions}
			defaultValue={existingForms}
			required={false}
		/>
	</>
	)
}
