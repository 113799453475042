
export const mainRouteKeys = [
	'home', 'jobs', 'jobHistory', 'jobNewDraft', 'jobDraftById', 'jobById', 'jobReviewById',
	'operateStep', 'orders', 'orderNewDraft', 'orderById', 
	'contacts', 'products', 'inventoryUnique', 'inventoryUniqueById', 'inventoryUniqueSetup',
	'partRaw', 'workflows', 
	'stepSetup', 'stepSetupById', 'steps', 'stepHistoryById', 'formInstances', 'formInstanceById', 'formBuilderDraft', 'formSetup', 'formBuilderById', 'tools', 'recipes', 'userSettings'
] as const;
// Take the routeKeys array and make a type out of it
export type MainRouteName = typeof mainRouteKeys[number];


export const fullRouteKeys = [
	'workflowEditorWorkflow', 'newWorkflowEditor', 'workflowEditorJob'] as const;
// Take the routeKeys array and make a type out of it
export type FullRouteName = typeof fullRouteKeys[number];


export interface RouteDefinition {
	id: FullRouteName | MainRouteName
	path: string
	RouteContent
}


export interface MainRouteDefinition extends RouteDefinition {
	id: MainRouteName
	label: string
	icon
	documentTitle?: string 
	leftMenu: {
		isVisible: boolean
		order?: number
		mainMenu: 'manufacturing' | 'orders' | 'inventory' | 'setup' | 'settings' | 'user'
	}
	backlink?: BreadcrumbDef
	labelVar?: BreadcrumbDef
	NavbarComponent?
}


export interface FullRouteDefinition extends RouteDefinition {
	id: FullRouteName
	documentTitle: string
}

// If anything gets added to URLParams, make sure to update logic in routing functions -> useNav !!!
export const URLParamVariables = ['id', 'index'] as const;
export type URLParamVariable = typeof URLParamVariables[number];




export type BreadcrumbQueryType = 'job' | 'step' | 'order' | 'inventory_unique' | 'workflow' | 'form';

export interface BreadcrumbDef {
	queryType?: BreadcrumbQueryType
	pathVariable?: string 
	queryResultKey?: string
	crumbTitlePrependText?: string
	linkTo?: MainRouteName
}

