import React, { useContext } from 'react';
import { UISelect } from 'kit';

import { useFormDefinitions } from 'hooks';

import { useFormContext } from 'react-hook-form';
import { getObjectFromArray } from 'utility';
import { StepFormContext } from './StepFormContext';

export const FormFieldSelection = ( ) => {
	const { stepPlan } = useContext(StepFormContext);

	const { isLoading, formDefinitions } = useFormDefinitions('table', { tableName: 'inventory_unique' });

	const { watch } = useFormContext();

	const selectedFormDefId = watch(`inventory_unique_form.form_definition_id`);
	const selectedFormDefinition = getObjectFromArray(formDefinitions, 'id', selectedFormDefId);
	const availableFields = selectedFormDefinition?.fields?.filter(
			def => def.status !== 'archive' 
		)
		.map( def => {
			const { options, ...restDef } = def;
			// Omit options from the object because that creates a group option in react-select
			// Maybe this will be fixed one day.  https://github.com/JedWatson/react-select/issues/3706
			return restDef; 
		} 
	) ?? [];
	
	return (
		<React.Fragment>
			<UISelect
				name="inventory_unique_form.form_definition_id"
				label="Inventory Form" 
				subtitle="Select a form to be filled out during this step."
				options={formDefinitions}
				defaultValue={stepPlan.inventory_unique_form?.form_definition_id}
				hasConditionalComponents={true}
				isLoading={isLoading}
			/>

			{selectedFormDefId &&
				<UISelect
					isMulti
					key={selectedFormDefId}
					name="inventory_unique_form.fields"
					label="Inventory Form Fields" 
					subtitle="Choose the individual fields to be filled out during this step. If the selection is left empty, all fields will be shown."
					options={availableFields}
					valueAccessor="key"
					defaultValue={selectedFormDefId === stepPlan.inventory_unique_form?.form_definition_id ? stepPlan.inventory_unique_form?.fields : []}
					placeholder="All fields in form"
					isLoading={isLoading}
				/>
			}
		</React.Fragment>
	)
}