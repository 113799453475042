import React from 'react';
import styled from 'styled-components';

import { UIFlexbox, UIButton } from 'kit';

import { InfoCircle } from '@styled-icons/boxicons-solid/InfoCircle';
import { CircleWithCross } from '@styled-icons/entypo/CircleWithCross';
import { AlertCircleOutline } from '@styled-icons/evaicons-outline/AlertCircleOutline';

interface UIAlertProps {
	variant?: 'primary' | 'success' | 'error' | 'warning'
	title?: string | JSX.Element
	actionOnClick?: () => void
	actionLabel?: string
}

export const UIAlert = (	
	{ variant = 'primary', title, actionLabel, actionOnClick }: UIAlertProps
) => {
	let icon = <InfoCircle size={16} />;
	if (variant === 'error') {
		icon = <CircleWithCross size={16} />;
	}
	else if (variant === 'warning') {
		icon = <AlertCircleOutline size={16} />;
	}
	return (
		<StyledFlexbox 
			flexAlign="baseline" 
			title={title}
			variant={variant}
			className="alert-banner"
		>
			<div className="feature-icon">{icon}</div>
			<div>
				<p className="alert-title">{title}</p>
				{actionOnClick && 
					<UIButton 
						linkish
						label={actionLabel}
						onClick={actionOnClick}
						size="small"
					/>
				}
			</div>

		</StyledFlexbox>
	);
}

const StyledFlexbox = styled(UIFlexbox)<UIAlertProps>`
	position: relative;
	box-sizing: border-box;
	width: 100%;
	border-radius: 4px;
	padding: 12px 12px 12px 8px;
	margin-bottom: 8px;

	background-color: ${props => props.variant === 'primary' && props.theme.colors.primaryLight1};
	background-color: ${props => props.variant === 'error' && props.theme.colors.errorLight};
	background-color: ${props => props.variant === 'warning' && props.theme.colors.warningLight};

	border: 1px solid ${props => props.theme.colors.colorDefinitions.grey1};

	&, button {
		color: ${props => props.variant === 'primary' && props.theme.colors.primary};
		color: ${props => props.variant === 'error' && props.theme.colors.error};
		color: ${props => props.variant === 'warning' && props.theme.colors.warning};
	}

	.feature-icon {
		margin-right: 8px;
	}
	.alert-title {
		&:not(:last-child){
			margin-bottom: 0.5rem;
		}
	}
	button {
		margin-left: -10px;
	}
`

export const UIAlertContainer = styled.div<{ noMarginBottom?: boolean }>`
	margin-bottom: ${props => props.noMarginBottom ? '0' : '32px'};
	.alert-banner:last-child {
		margin-bottom: 0;
	}
`;