import React from 'react';

import { UIKeyValueHorizontal, UICard } from 'kit';

import { useChange, mergeWithDefault, ChangeSpec, UseChangeOptions } from './useChange';

import { RadioOption } from 'types';
import { getObjectFromArray, dateFormat } from 'utility';
import get from 'lodash.get';


export const CardViewChange = <T, TChanges>(
	{ 
		canEdit = true,
		viewPrepend = null,
		...useChangeOpts
	}: 
	{ 
		canEdit?: boolean
		viewPrepend?: JSX.Element | JSX.Element[]
	} & UseChangeOptions<T, TChanges>
) => {
	const { openEditWindow, ChangeModal } = useChange(useChangeOpts);	

	return (
		<>
			<UICard 
				title={useChangeOpts.title} 
				actionOnClick={canEdit && openEditWindow}
				width="100%"
			>
				{viewPrepend}
				{useChangeOpts.specList.map( spec => {
					const mergedSpec = mergeWithDefault(spec);
					return (
						<UIKeyValueHorizontal 
							key={mergedSpec.key} 
							title={mergedSpec.name} 
							text={getCardViewValue(useChangeOpts.item, mergedSpec)} 
						/>
					)
				})}
			</UICard>


			{ChangeModal}
		</>
	);
}

function getCardViewValue(item, mergedSpec: ChangeSpec<any, any> ){
	function getValue(){
		const rawValue = get(item, mergedSpec.valueDisplayAccessor);
		if (mergedSpec.formatValue === 'date') {
			return dateFormat(rawValue, 'dateWithYear')
		}
		else if (mergedSpec.formatValue === 'datetime') {
			return dateFormat(rawValue, 'dateTimeShort')
		}
		// else
		return rawValue;
	}

	if (mergedSpec.valueDisplayAccessor !== mergedSpec.key) { 
		//an override value was specificied, use it
		return getValue();
	}
	else if (mergedSpec.input_type === 'select' || mergedSpec.input_type === 'radio') {
		//Get the display value by transforming the options. Assumes standard id/name structure.
		const rawValue = get(item, mergedSpec.key);
		const option = getObjectFromArray(mergedSpec.options as RadioOption[], 'id', rawValue);
		return option.name ?? getValue();
	}
	// else
	return getValue();
}