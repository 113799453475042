import React, { useEffect } from 'react';
import styled from 'styled-components';


import { UIHeading, UIInput, UISelect, useUIForm, UIForm2, UIDrawer, DrawerHookContext, SaveButtonBlock, UICheckbox } from 'kit';


import { convertStringToKey } from 'utility';
import { useDynamicList, DynamicListContext } from 'hooks';
import { FormFieldSpec } from 'types';
import { FormFieldSelectOptionsDragDrop } from './FormFieldSelectOptionsDragDrop';
import { toast } from 'react-toastify';

export const EditFieldForm = (
	{ drawerContext, dynamicListContext: fieldDynamicListContext }: {
		drawerContext: DrawerHookContext
		dynamicListContext: DynamicListContext<FormFieldSpec>
	}
) => {
	const { addRow, editRow, rows: fieldList, selectedIndex, setSelectedIndex } = fieldDynamicListContext;
	const selectedField = fieldList[selectedIndex] || {} as FormFieldSpec;
	const isNewField = !selectedField.key;
	
	const optionsDynamicListContext = useDynamicList<string>(selectedField.options as string[] || [''], { allowZeroLengthArray: false });
	const { rows: selectOptionsList } = optionsDynamicListContext;
	const { formMethods, isDirty } = useUIForm<FormFieldSpec>(selectedField, { resetTrigger: selectedIndex });


	useEffect(() => {
		if (!drawerContext.isDrawerOpen) {
			setSelectedIndex(-1);
		}
	}, [drawerContext.isDrawerOpen])

	const isSelectType = formMethods.watch('input_type') === 'select';
	const isRadioType = formMethods.watch('input_type') === 'radio';

	/** Have to handle submit manually because this component appears as a child of another form*/
	const submit = formMethods.handleSubmit(() => {
		//@ts-ignore
		const formData: FormFieldSpec = formMethods.getValues();
		const optionsList = selectOptionsList.filter(item => item.length > 0);
		if ((formData.input_type === 'select' || formData.input_type === 'radio') && optionsList.length === 0) {
			toast.error('At least one option must be provided.', {
				position: toast.POSITION.BOTTOM_CENTER
			});
		}
		else {
			if (isNewField) {
				addRow( {
					key: convertStringToKey(formData.name, fieldList.map( field => field.key )),
					name: formData.name,
					input_type: formData.input_type,
					value_type: 'string',
					is_required: true,
					status: 'active',
					options: optionsList
				});
			} 
			else {
				const newFieldObj: FormFieldSpec = {
					...selectedField,
					...formData,
					options: optionsList
				}
				editRow(selectedIndex, newFieldObj);
			} 
			drawerContext.closeDrawer();
		}

	});


	return (
		<StyledDrawer {...drawerContext} isFormDirty={isDirty} >
			<UIForm2 formMethods={formMethods}>
				<UIHeading level="3">{selectedField.name ? 'Edit ' : 'Add '} Field</UIHeading>
				<UIInput name="name" label="Field Name" required />
				<UISelect
					options={[
						{ id: 'input', name: 'Text Input' },
						{ id: 'select', name: 'Select Dropdown' }, 
						{ id: 'textarea', name: 'Text Input (Multi Line)' }, 
						{ id: 'radio', name: 'Radio List' }
					]}
					name="input_type" 
					label="Field Type" 
					required
					defaultValue={selectedField.input_type}
					hasConditionalComponents={true} 
				/>

				<UICheckbox 
					name="is_required"
					label="Input is required"
					defaultValue={selectedField.is_required ?? true}
				/>

				{(isSelectType || isRadioType) &&
					<FormFieldSelectOptionsDragDrop dynamicListContext={optionsDynamicListContext} />
				}

				<SaveButtonBlock 
					isTypeSubmit={false}
					onSave={submit}
					overrideSaveText="OK"
					onCancel={drawerContext.closeDrawer} 
				/>

			</UIForm2>

		</StyledDrawer>
	);
};

const StyledDrawer = styled(UIDrawer)`
	.drawer-container {
		width: 500px;
	}
`;


