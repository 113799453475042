import React, { useState } from 'react';
import styled from 'styled-components';

import { UIFlexbox, UICard, UITitle, UIFadedTitle } from 'kit';
import { Tools } from '@styled-icons/entypo/Tools';
import { Colours } from '@styled-icons/entypo/Colours';
import { Beaker } from '@styled-icons/octicons/Beaker';
import { ArchiveIn } from '@styled-icons/boxicons-regular/ArchiveIn';
import { ArchiveOut } from '@styled-icons/boxicons-regular/ArchiveOut';

import { useStepDetails, usePartsInJob } from 'hooks';
import { useQuery } from '@apollo/client';
import { GET_JOB_WIP } from 'services/stepStart';
import { GET_RECIPES, GET_TOOLS } from '@constants/gql';


import { useChange, ChangeSpec } from 'components/common/useChange';
import { changeStepHistory } from 'services/stepMutate';


import { UIRoutedLink } from 'routing';
import { capitalizeFirstLetter, getObjectFromArray } from 'utility';
import { StepPlan, StepHistoryRaw, StepHistoryChanges, SupplementalStepInfo, InventoryUniqueItem, PartWIP, Part, WorkflowOptions } from 'types';


export const StepInfoBlocks = (
	{ jobId, stepIndex1 }: {
		jobId: number
		stepIndex1: number
	}
) => {
	const{
		job,
		isStepStartable,
		hasStepStarted,
		hasStepFinished,
		stepPlan, supp, 
		stepHistory, 
		uniqueItems
	} = useStepDetails(jobId, stepIndex1);

	const { 
		data: { 
			inventory_unique: uniqueItemWIP, 
			part_wip: partWip 
		} = { 
			inventory_unique: [] as InventoryUniqueItem[], 
			part_wip: [] as PartWIP[]
		}
	} = useQuery( GET_JOB_WIP, {
		variables: { jobId: jobId, wipType: 'wip' },
		skip: hasStepStarted || typeof jobId !== 'number'
	} );

	
	const { parts } = usePartsInJob(job);

	const uniqueItemsArray = isStepStartable ? uniqueItemWIP : uniqueItems;

	return (<>
		<UIFlexbox width="100%" flexWrap="wrap" flexJustify="space-between">

			<ParameterStepBlock
				parameterType="tool"
				stepPlan={stepPlan}
				stepHistory={stepHistory}
				hasStepStarted={hasStepStarted}
				supp={supp}
			/>
			<ParameterStepBlock
				parameterType="recipe"
				stepPlan={stepPlan}
				stepHistory={stepHistory}
				hasStepStarted={hasStepStarted}
				supp={supp}
			/>

			

			<StepBlockCard>
				<div className="feature-icon"><ArchiveIn size={24} /></div>
				<div className="body">
					<UIFadedTitle>Materials</UIFadedTitle>
					{!stepPlan.inventory_effect && <p>No materials used in this step.</p>}
					{stepPlan.inventory_effect && 
						<p>{stepPlan.inventory_effect?.items?.length} item(s) defined.</p>
					}
				</div>
			</StepBlockCard>

			
			<StepBlockCard>
				<div className="feature-icon"><ArchiveOut size={24} /></div>
				<div className="body">
					<UIFadedTitle>Products</UIFadedTitle>
					{!hasStepStarted && partWip.length > 0 &&
						<>
							<p>WIP will be moved into this step upon starting.</p>
							{partWip.map( wip => <div key={wip.id}>{wip.part.name} {wip.qty}</div>)}
						</>
					}
					{hasStepStarted && stepHistory.step_history_yields?.length > 0 &&
						stepHistory.step_history_yields.map( qtys => {
							const part: Part  = getObjectFromArray(parts, 'id', qtys.part_id);
							return (
								<div key={qtys.part_id}>
									<UITitle>{part.name}</UITitle>
									<div>Qty In {qtys.qty_in}</div>
									<div>Qty Out {qtys.qty_out}</div>
								</div>
							)}
						)
					}
				</div>
			</StepBlockCard>

			<StepBlockCard>
				<div className="feature-icon"><Colours size={24} /></div>
				<div className="body">
					<UIFadedTitle>Inventory</UIFadedTitle>
					{isStepStartable && uniqueItemWIP.length > 0 && 
						<p>WIP will be moved into this step upon starting.</p>
					}
					{(isStepStartable || hasStepStarted) && !hasStepFinished && uniqueItemsArray.length === 0 && 
						<p>No inventory currently attached to this job.</p>
					}
					{uniqueItemsArray.length > 0 && 
						<p>{uniqueItemsArray.length} item(s).</p>
					}
					{ uniqueItemsArray.map( item => {
						return (
							<UIRoutedLink 
								key={item.id}
								text={item.name} 
								linkToRoute="inventoryUniqueById"
								linkToVars={{ id: item.id }}
								display="block"
							/>  
						)
					}) }
					<ul>
						{job.workflow_options?.inventory_unique?.attach?.step_index === stepIndex1 && <li>Attach{hasStepStarted ? 'ed' : 'ing'} at the beginning of the step</li>}
						{isConverting(stepIndex1, job.workflow_options) && <li>Convert{hasStepFinished ? 'ed' : 'ing'} at the end of the step</li>}
						{job.workflow_options?.inventory_unique?.detach?.step_index === stepIndex1 && <li>Detach{hasStepFinished ? 'ed' : 'ing'} at the end of the step</li>}
					</ul>
				</div>
			</StepBlockCard>


		</UIFlexbox>
	</>);
};

function isConverting(stepIndex, workflowOptions: WorkflowOptions) {
	
	for (let index = 0; index < workflowOptions?.inventory_unique?.converts.length; index++) {
		const convert = workflowOptions?.inventory_unique?.converts[index];
		if (convert.step_index === stepIndex) {
			return true;
		}
	}
	return false;
}


const StepBlockCard = styled(UICard).attrs( props => ({
	asFlexbox: true,
	flexDirection: 'row',
	flexAlign: 'flex-start'
}))`
	.feature-icon {
		margin-right: 20px;
	}
	.body {
	}
	ul {
		margin-top: 1rem;
	}
	min-width: 200px;
	width: 48%;
	@media ${props => props.theme.mediaQuery.mobile} {
		width: 100%;
	}
	button { /**The edit button */
		top: 7px;
		right: 7px;
	}
	padding-right: 50px;
`;


function ParameterStepBlock(
	props: {
		parameterType: 'recipe' | 'tool'
		stepPlan: StepPlan
		stepHistory: StepHistoryRaw
		supp: SupplementalStepInfo
		hasStepStarted: boolean
	}
) {
	const plan = props.stepPlan[props.parameterType];
	let iconComponent, FETCH_QUERY, planMessage;
	switch (props.parameterType) {
		case 'recipe':
			iconComponent = <Beaker size={24} />
			FETCH_QUERY = GET_RECIPES;
			break;
		case 'tool':
			iconComponent = <Tools size={24} />
			FETCH_QUERY = GET_TOOLS;
			break;
		default:
			break;
	}

	let paramId;
	switch (plan?.method) {
		case 'fixed':
			paramId = plan?.value;
			break;
		case 'flex_engineer':
			paramId = props.supp?.[`${props.parameterType}_value`];
			break;
		default:
			break;
	}

	const { 
		data: { [props.parameterType]: [item = {}] } = { [props.parameterType]: [] }
	} = useQuery(FETCH_QUERY, { 
		variables: { 
			id: paramId
		},
		skip: typeof paramId !== 'number'
	});

	if (plan) {
		switch (plan.method) {
			case 'fixed':
				planMessage = item.name;
				break;
			case 'flex_operator':
				planMessage = capitalizeFirstLetter(props.parameterType) + ' will be selected by operator at step start.';
				break;
			case 'flex_engineer':
				planMessage = capitalizeFirstLetter(props.parameterType) + ' will be selected by engineer before step start. ' + item.name + ' is currently selected.';
				break;
		
			default:
				break;
		}
	}

	const [hasEditWindowBeenOpened, setHasEditWindowBeenOpened] = useState(false);

	const { 
		data: { [props.parameterType]: allItems } = { [props.parameterType]: [] }
	} = useQuery(FETCH_QUERY, { 
		variables: { /* id: paramId */ },
		skip: !hasEditWindowBeenOpened
	});		
	
	const specList: ChangeSpec<StepHistoryRaw, StepHistoryChanges>[] = [
		{
			//@ts-ignore
			key: `${props.parameterType}_id`,
			name: capitalizeFirstLetter(props.parameterType),
			// valueDisplayAccessor: 'stage.name',
			input_type: 'select',
			options: allItems || []
		}
	]

	const { OpenChangeButton, ChangeModal } = useChange({
		title: `Edit ${capitalizeFirstLetter(props.parameterType)}`,
		specList: specList,
		item: props.stepHistory, 
		saveFunction: changeStepHistory,
		onEditOpen: setHasEditWindowBeenOpened.bind(this, true)
	});	


	const isParameterInStep = typeof props.stepPlan?.[props.parameterType] !== 'undefined';

	return (
		<>
			<StepBlockCard>
				{isParameterInStep && props.hasStepStarted && OpenChangeButton}
				<div className="feature-icon">{iconComponent}</div>
				<div className="body">
					<UIFadedTitle>{capitalizeFirstLetter(props.parameterType)}</UIFadedTitle>
					{!isParameterInStep && `No ${props.parameterType} in this step.`}
					{props.hasStepStarted && plan && props.stepHistory[`${props.parameterType}`]?.name}
					{!props.hasStepStarted && plan && planMessage}
				</div>
			</StepBlockCard>
			{ChangeModal}
		</>
	)
}
