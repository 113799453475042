import React from 'react';
import styled from 'styled-components';

import { UIPage, UICard, UITitle, UIText } from 'kit';

import { useQuery } from '@apollo/client';
import { GET_WORKFLOWS } from 'services/workflowFetch';

import { useNavFull } from 'routing';

import { Workflow } from 'types';


export function SavedWorkflowsPage() {
	const { loading, error, data: queryData } = useQuery(GET_WORKFLOWS);

	if (loading) {return <p>Loading...</p>; }
	if (error) {return <p>Error :( ${error.message}</p>; }

	return (
		<UIPage id="saved-workflows" contentContainerSize="medium">			
			{queryData.workflow.map(
				dWorkflow => 
					(<WorkflowCard 
						key={'workflow_' + dWorkflow.id} 
						workflow={dWorkflow} 
					/>)
			)}
		</UIPage>
	)
}

const WorkflowCard = (	
	props: { 
		workflow: Workflow
	} 
) => {
	const navigateToWorkflowEditor = useNavFull('workflowEditorWorkflow');
	function navToWorkflowEditor(workflowId){
		navigateToWorkflowEditor(workflowId);
	}

	return (
		<StyledUICard onClick={navToWorkflowEditor.bind(this, props.workflow.id)} flexAlign="flex-start"  width="100%">
			<UITitle>{props.workflow.name}</UITitle>
			<UIText text={props.workflow.description} />
		</StyledUICard>
	);
}

const StyledUICard = styled(UICard)`
	font-size: 1.1em;
`;
