import React, { useMemo } from 'react';

import { UIPage, useModal, UIFlexbox } from 'kit';

import { Table } from 'components/Table/Table';
import { TableActionButton } from 'components/Table/TableActionButton';

import { EditParameterForm } from 'components/AddItem';

import { NavbarPortal } from 'components/Navigation/NavbarPortal';
import { AddNewItemButton } from 'components/AddNewItemButton';


import { useTags, EditTags } from 'hooks';
import { useQuery } from '@apollo/client';
import { GET_RECIPES, GET_TOOLS } from '@constants/gql';

import { ProcessParameter } from 'types';
import { arrayOfAllColumns, toolRecipeCols } from 'root/components/Table/tableRef';



export function Tools() {
	
	return (
		<ParameterTable type="tool" />
	)
}

export function Recipes() {
	
	return (
		<ParameterTable type="recipe" />
	)
}

const parameterColumnDefs = arrayOfAllColumns(toolRecipeCols);


export function ParameterTable(props: {
	type: 'tool' | 'recipe'
}) {
	const modalProps = useModal();

	const QUERY = (props.type === 'tool' && GET_TOOLS) || (props.type === 'recipe' && GET_RECIPES);
	
	const { // Despite setting the default to an empty array here, itemList can still be undefined when switching between tool and recipe. Make sure undefined is accounted for below.
		data: { [props.type]: itemList } = { [props.type]: [] as Array<ProcessParameter> }
	} = useQuery(QUERY);

	const { tags, tableHelperMemoToTagNames } = useTags(props.type);

	function toggleEdit(item) {
		modalProps.setModalData(item);
		modalProps.toggleModal();
	}
	
	const columns = useMemo(() => [	{
			Header: '',
			id: 'actions',
			className: 'center-align show-on-hover',
			Cell: table => {
				const item = table.row.original;
				return (
					<TableActionButton 
						type="edit"
						onClick={toggleEdit.bind(this, item)}
					/> 
				)
			}
			//@ts-ignore
		}].concat(parameterColumnDefs)
	, []);

	const data = useMemo(() => {		
		return tableHelperMemoToTagNames(itemList);
	}, [itemList.length, tags.length]);	

	return (
		<UIPage id={`${props.type}-table`} contentContainerSize="large">
			<NavbarPortal>
				<UIFlexbox flexAlign="center">
					<AddNewItemButton type={props.type} onClick={toggleEdit.bind(this, {})} />
					<EditTags tagType={props.type}/>
				</UIFlexbox>
			</NavbarPortal>

			<Table
				columns={columns}
				data={data}
				hasSortableColumns
				hasGlobalFilter
				hasPagination
				hasSelectablePageSize
				hasFilterableColumns
			/>

			<EditParameterForm modalProps={modalProps} parameterName={props.type} />
		</UIPage>
	)
}

