import React from 'react';
import { UIForm2, useUIForm, UILine, UIKeyValue } from 'kit';

import { ParameterSelectSingle } from 'components/StepSetup/ParameterComponentsSelectSingle';

import { useHydrateStepFromJob } from 'services/stepHydrate';

import { StepPlan, SupplementalStepInfo } from 'types';


export const JobReviewStepForm = (	
	{ jobId, index0, updateSuppPartial }: {
		updateSuppPartial
		jobId: number
		index0: number
	}
) => {
	const { getWorkflowStepFull } = useHydrateStepFromJob(jobId);
	const { stepInfo, stepPlan, supp } = getWorkflowStepFull(index0)
	const { formMethods } = useUIForm(supp, { resetTrigger: index0 });


	const saveSupp = formMethods.handleSubmit((formData) => { //do this to get past useLeavePage, even though all we are doing is updateding state in useWorkflowEdit
		updateSuppPartial(formData);
	})

	return (
		<UIForm2
			formMethods={formMethods}
			onSubmit={saveSupp}
			autoSave
		>

			<UIKeyValue title="Name" text={stepInfo.name} line  />
			<UIKeyValue title="Description" text={stepInfo.description} line />
							
			<EngineerSelectDropdowns 
				stepId={stepInfo.id}
				stepPlan={stepPlan}
				supp={supp}
			/>
				
		</UIForm2>
	);
}


export const EngineerSelectDropdowns = (	
	{ stepId, stepPlan, supp }: {
		stepId: number
		stepPlan: StepPlan
		supp: SupplementalStepInfo
	}
) => {

	const isToolReviewNeeded = stepPlan?.tool?.method === 'flex_engineer';
	const isRecipeReviewNeeded = stepPlan?.recipe?.method === 'flex_engineer';

	return (<>
		{isToolReviewNeeded ? 
			<React.Fragment>
				<ParameterSelectSingle
					parameterName="tool"
					nameForForm="tool_value"
					defaultValue={supp.tool_value}
					label={'Use tool:'}
					stepId={stepId}
					required
				/>
				<UILine margin="15px" />
			</React.Fragment>
		: null}
	
		
		{isRecipeReviewNeeded ? 
			<React.Fragment>
				<ParameterSelectSingle
					parameterName="recipe"
					nameForForm="recipe_value"
					defaultValue={supp.recipe_value}
					label={'Use recipe:'}
					stepId={stepId}
					required
				/>
				<UILine margin="15px" />
			</React.Fragment>
		: null}
	</>)
}