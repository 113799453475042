import React, { useContext, useState } from 'react';
import styled from 'styled-components';

import { UIFlexbox, UIIconButton, UIHeading, DrawerHookContext, GridContainer, GridRow } from 'kit';

import { DocumentAdd } from '@styled-icons/typicons/DocumentAdd';

import { WorkflowEditorContext } from './WorkflowEditorContext';
import { WorkflowEditorRow } from './WorkflowEditorRow';
import { WorkflowEditorSaveBlock } from './WorkflowEditorSaveBlock';

import { StepFormCategory } from '@constants';


import { UIDroppable } from 'hooks';
import { prettifyKey } from 'utility';
import { WorkflowOptions } from 'types';
import { WorkflowEditType } from './useWorkflowEdit';


export type ViewMode = 'setupMinimum' | 'detailed';
export type WorkflowEditorColumn = StepFormCategory | 'inventory_lifecycle' | 'engineer_select';

const allWFEColumns: WorkflowEditorColumn[] = ['inventory_lifecycle', 'engineer_select', 'tool', 'recipe', 'inventory_effect', 'forms', 'inventory_unique_form'];

function getColumns(workflowOptions: WorkflowOptions, viewMode: ViewMode, isAmendment: boolean, type: WorkflowEditType): WorkflowEditorColumn[] {
	let cols = allWFEColumns;
	if (viewMode === 'setupMinimum') {
		cols = ['inventory_lifecycle', 'engineer_select'];
	} 
	if (type === 'workflow') {
		// remove the eng select column
		const engSelectIndex = cols.indexOf('engineer_select');
		if (engSelectIndex > -1) {
			cols.splice(engSelectIndex, 1);
		}
	}
	if (!workflowOptions.inventory_unique_enabled) {
		// remove the inv lifecycle
		const uiSelectIndex = cols.indexOf('inventory_lifecycle');
		if (uiSelectIndex > -1) {
			cols.splice(uiSelectIndex, 1);
		}
	}
	return cols;
}



export const WorkflowDragDrop = React.memo( function WorkflowDragDrop(	
	{ 
		showStepsList, drawerContextForm
	}: {
		showStepsList: () => void
		drawerContextForm: DrawerHookContext
	} 
) {
	const { 
		dynamicListContext, isAmendment, workflowOptions, type, firstAmendableStep0, workflowValidationErrors
	} = useContext(WorkflowEditorContext);

	const { rows: databaseSteps, selectedIndex, setSelectedIndex } = dynamicListContext;

	
	const [viewMode, setViewMode] = useState<ViewMode>('setupMinimum');

	const cols = React.useMemo(
		() => { return getColumns(workflowOptions, viewMode, isAmendment, type) }
	, [viewMode, type, workflowOptions.hasOwnProperty('inventory_unique')]);
	
	const onStepClick = React.useCallback( (index) => {
		setSelectedIndex(index);
		drawerContextForm.openDrawer();
	}, [])
	
	return (
		<>
			<GutterLeft>
				<WorkflowEditorSaveBlock viewMode={viewMode} setViewMode={setViewMode} />
			</GutterLeft>

			
				<DragDropEditorContainer columns={6} disableHover gridTemplateColumns={`${viewMode === 'setupMinimum' ? 500 : 300}px 100px ${cols.length > 0 ? `repeat(${cols.length}, 230px)` : ''}`}>

					{databaseSteps.length > 0 &&
						<EditorTitleContainer>
							<EditorSectionTitle>Step</EditorSectionTitle>
							<div></div>
							{cols.map( col => {
								return <EditorSectionTitle key={col}>{prettifyKey(col)}</EditorSectionTitle>
							})}
						</EditorTitleContainer>
					}
				
					<StyledDroppable 
						name="destination" 
						readonly={false} 
					>
						{databaseSteps.length === 0 && <EmptyWorkflowMessage onClickAdd={showStepsList} /> }

						{databaseSteps.map( (dbStep, index) => (
							<WorkflowEditorRow
								key={ 'dest_' + (dbStep.step_id).toString() + '_' + index}
								dbStep={dbStep}
								index={index}
								viewMode={viewMode}
								visibleCols={cols}
								isCompleted={index < firstAmendableStep0}
								isLast={index === databaseSteps.length - 1}
								isSelected={index === selectedIndex && drawerContextForm.isDrawerOpen}
								onStepClick={onStepClick}
								workflowValidationErrors={workflowValidationErrors}
							/>
						))}
					</StyledDroppable>
				</DragDropEditorContainer>	
	</>
	)
})

const GutterLeft = styled.div`
	position: sticky;
	top: 0px;
	left: 0;
	z-index: 16;
	padding: 0 20px;
	height: 100%;
	background-color: ${props => props.theme.colors.colorDefinitions.grey1};
	> button {
		display: block;
	}
	> button:first-child {
		margin-top: 20px;
	}
`;


const EditorTitleContainer = styled(GridRow)`
	padding-left: 63px; /**Shim for draggable handle */
	padding-bottom: 1rem;
	position: sticky;
	top: 0;
	z-index: 15;
	background-color: ${props => props.theme.colors.colorDefinitions.grey1};
	padding-top: 20px;
`;

const EditorSectionTitle = styled.div`
	border-bottom: 2px solid ${props => props.theme.colors.primaryLight2};
	font-size: 1.2rem;
	padding: 1rem;
	box-sizing: border-box;
	width: 100%;
	font-weight: ${props => props.theme.font.boldWeight};
`;

const StyledDroppable = styled(UIDroppable)`
	padding-top: 1rem;
	box-sizing: border-box;
	position: relative;
	height: fit-content;
	min-height: calc(100vh - 150px);
`;

const DragDropEditorContainer = styled(GridContainer)`
	margin: 0 auto;
	min-width: 80%;
	.row {
		grid-column-gap: 30px;
	}
	padding-right: 6rem;
	
	.step-card, ${EditorSectionTitle}:first-child {
		position: sticky;
		left: 80px;
	}
	.step-card {
		z-index: 10;
	}
	${EditorSectionTitle}:first-child {
		z-index: 18;
		background-color: ${props => props.theme.colors.colorDefinitions.grey1};
	}
`;



//empty workflow
const EmptyWorkflowMessage = ({ onClickAdd }) => {
	return (
		<UIFlexbox flexDirection="column" flexJustify="center" flexAlign="center" padding="large">
			<UIHeading noMarginBottom>Add a step to the workflow by dragging it from the steps list.</UIHeading>
			<LargeIcon icon={<DocumentAdd />} onClick={onClickAdd} margin="largeVertical" />
			<UIHeading level="3">Workflow must contain at least one step </UIHeading>
		</UIFlexbox>
	)
}

const LargeIcon = styled(UIIconButton)`
	svg {
		height: 110px;
		width: 110px;
	}
`;