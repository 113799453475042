import React, { useState, useCallback } from 'react';
import styled from 'styled-components';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/themes/light-border.css';
import 'tippy.js/animations/shift-away.css';

// https://atomiks.github.io/tippyjs/
// https://github.com/atomiks/tippyjs-react

import { InfoOutline } from '@styled-icons/evaicons-outline/InfoOutline'


export const UITooltipWrapper = (
	{
		tooltipContent,
		children,
		placement = 'top',
		delayOnEntry = false,
		useRefHelper = false,
		isVisible,
		onClickOutside
	} : 
	{
		tooltipContent: JSX.Element | JSX.Element[] | string
		children: JSX.Element
		placement?: 'top' | 'bottom' | 'left' | 'right' | 'auto' // There are more, if needed refer to docus
		delayOnEntry?: boolean
		useRefHelper?: boolean
		/**Makes this a controlled component */
		isVisible?: boolean
		onClickOutside?
	}
) => (
	<div style={{ display: 'inline-block' }}> 
		<Tippy 
			content={tooltipContent} 
			placement={placement}
			offset={[0, 10]} 
			delay={delayOnEntry && [800, null]}
			theme="light-border"
			animation="shift-away"
			interactive={typeof isVisible !== 'undefined'}
			visible={isVisible} 
			onClickOutside={onClickOutside}
		>
			{useRefHelper ? 
				<span>{children}</span> 
				: children
			}
		</Tippy>
	</div>
);

export const UITooltipIcon = (
	{
		tooltipContent,
		iconType = 'info',
		delayOnEntry = false
	} : 
	{
		tooltipContent: JSX.Element | string
		iconType?: 'info'
		delayOnEntry?: boolean
	}
) => (
	
	<UITooltipWrapper 
		tooltipContent={tooltipContent} 
		delayOnEntry={delayOnEntry}
	>
		<StyledIcon size={16} />
	</UITooltipWrapper>
);

const StyledIcon = styled(InfoOutline)`
	color: ${props => props.theme.colors.muted};
`;


interface TooltipMenuHookProps {
	isTooltipMenuVisible: boolean
	openTooltipMenu: () => void
	closeTooltipMenu: () => void
	forceTooltipUpdateKey: number
}

export const useTooltipMenu = (initialIsOpen?: boolean): TooltipMenuHookProps & {
	UITooltipMenu: (props: UITootipMenuProps) => JSX.Element 
} => {
	const [isTooltipMenuVisible, setIsVisible] = useState(initialIsOpen || false);
	const [forceTooltipUpdateKey, setforceTooltipUpdateKey] = useState(0);

	const openTooltipMenu = useCallback(
		() => {
			setIsVisible(true);
			setforceTooltipUpdateKey(forceTooltipUpdateKey + 1);
		}
	, [forceTooltipUpdateKey]);

	const closeTooltipMenu = useCallback(
		() => {
			setIsVisible(false);
			setforceTooltipUpdateKey(forceTooltipUpdateKey + 1);
		}
	, [forceTooltipUpdateKey]);

    return { isTooltipMenuVisible, openTooltipMenu, closeTooltipMenu, forceTooltipUpdateKey, UITooltipMenu };
}


interface UITootipMenuProps extends TooltipMenuHookProps {
	tooltipContent: JSX.Element | string
	entryElement: JSX.Element
} 

const UITooltipMenu = (
	{
		tooltipContent,
		entryElement,
		...tooltipMenuProps
	} : UITootipMenuProps
) => {
	const entryElWithOpen = React.cloneElement(entryElement, { onClick: tooltipMenuProps.openTooltipMenu });
	return (
		<UITooltipWrapper 
			tooltipContent={tooltipContent} 
			useRefHelper 
			placement="bottom"
			isVisible={tooltipMenuProps.isTooltipMenuVisible} 
			onClickOutside={tooltipMenuProps.closeTooltipMenu}
		>
			{entryElWithOpen}
		</UITooltipWrapper>
	)
};
