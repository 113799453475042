import React, { useState, useEffect } from 'react';

import { UIButton, UIIconButton } from 'kit';
import { MarginProps } from '@constants';
import { useDragDrop, DragDropOptions } from 'hooks';

import { AddToQueue } from '@styled-icons/boxicons-regular/AddToQueue'
import { Trash } from '@styled-icons/boxicons-regular/Trash'
import { deepCopy } from 'utility';
import isEqual from 'lodash.isequal';

export const useDynamicList = <RowObject, >( // trailing comma needed to sidestep the JSX ambiguity.
	startingRows: RowObject[] = [],
	options?: {
		onUpdate?: (rows: RowObject[]) => void
		onDelete?: (newRows: RowObject[], deletedRow: RowObject, deletedIndex0) => void
		onAdd?: (newRows: RowObject[], addedRow: RowObject, addedIndex0) => void
		allowZeroLengthArray?: boolean
		hideAddButtonWhileAdding?: boolean
		dragDropOptions?: DragDropOptions
	}

): DynamicListContext<RowObject> => {
	const { 
		onUpdate = () => {}, 
		onDelete = () => {}, 
		onAdd = () => {}, 
		allowZeroLengthArray = true,
		hideAddButtonWhileAdding = true,
		dragDropOptions
	} = options || {};

	const [isInitComplete, setIsInitComplete] = useState(false);
	const [isAdding, setIsAdding] = useState(false);
	const [selectedIndex, setSelectedIndex] = useState(-1);
	const [rows, setRows] = useState(startingRows);
	
	useEffect(() => {
		setRows(startingRows);
		if (!isInitComplete) {
			onUpdate(startingRows);
			setIsInitComplete(true);
		}
	}, [startingRows.length])

	const { handleDragEvent } = useDragDrop(dragDropOptions);
	
	const onDragEnd = dragEvent => {
		const { newDestinationList, newSelectedIndex } = handleDragEvent(dragEvent, rows, selectedIndex);

		if (!isEqual(newDestinationList, rows)) {
			setRows(newDestinationList);
			onUpdate(newDestinationList);
		}
		setSelectedIndex(newSelectedIndex);
		return { newDestinationList, newSelectedIndex };
	}


	const AddButton = ( { label = 'Add New', onClick = () => {}, margin }: { 
		label: string
		onClick?:() => void 
	} & MarginProps ) => {
		function triggerAdd(){
			setIsAdding(true);
			onClick();
		}

		if (hideAddButtonWhileAdding && isAdding) { return null; }
		return (
			<UIButton 
				quiet 
				icon={<AddToQueue />} 
				label={label}
				onClick={triggerAdd} 
				margin={margin}
			/>
		)
	}

	const DeleteButton = ( { index, margin } : { index: number } & MarginProps ) => {
		return (
			<UIIconButton 
				muted
				icon={<Trash />} 
				onClick={deleteRow.bind(this, index)}
				margin={margin}
			/>
		)
	}

	function editRow(index: number, row: RowObject) {
		let newRows = rows?.slice() ?? []; //duplicate
		newRows[index] = row;
		setRows(newRows);
		onUpdate(newRows);
		return newRows;
	}

	function editSingleValueInRow<KeyName extends keyof RowObject>(index: number, key: KeyName, value: RowObject[KeyName]) {
		let newRow: RowObject = deepCopy(rows[index]);
		newRow[key] = value;
		return editRow(index, newRow);
	}

	/** Delete product selections. For flex_job method. */
	function deleteRow(index: number){
		let newRows = rows?.slice() ?? []; //duplicate		
		const deletedRows = newRows.splice(index, 1);
		if (newRows.length === 0 && !allowZeroLengthArray) {
			newRows = void 0;
		}
		setRows(newRows);
		onUpdate(newRows);
		onDelete(newRows, deletedRows[0], index);
	}
	
	function addRow(row: RowObject, index?: number) {
		let newRows = rows?.slice() ?? []; //duplicate	
		let addedIndex;
		if (typeof index !== 'number') {
			// no index identified, add the row to the end
			newRows.push(row);
			addedIndex = newRows.length - 1;
			
		}
		else {
			// insert _after_ specified position
			newRows.splice(index + 1, 0, row);
			addedIndex = index;
		}
		setRows(newRows);
		onUpdate(newRows);
		setIsAdding(false);
		onAdd(newRows, row, addedIndex);
	}

	return {
		rows, setRows,
		selectedIndex, setSelectedIndex,
		addRow, editRow, editSingleValueInRow, deleteRow,
		isAdding, AddButton,
		DeleteButton,
		onDragEnd
	};
};


export interface DynamicListContext<RowObject>{
	rows: RowObject[]
	setRows
	/**selectedIndex will be -1 if nothing selected */
	selectedIndex: number
	setSelectedIndex: React.Dispatch<number>
	addRow: (row: RowObject, index?: number) => void
	editRow: (index: number, row: RowObject) => RowObject[]
	editSingleValueInRow: <KeyName extends keyof RowObject>(index: number, key: KeyName, value: RowObject[KeyName]) => RowObject[]
	deleteRow: (index: number) => void
	isAdding: boolean
	DeleteButton: (props: { index: number } & MarginProps) => JSX.Element
	AddButton: (props: { label?: string, onClick?: () => void } & MarginProps) => JSX.Element
	onDragEnd
}