
import { gql } from '@apollo/client';
import { apolloClient } from 'services/apollo';
import Bowser from 'bowser';
import Ajv from 'ajv';
import { toast } from 'react-toastify';

const LOG_ERROR = gql`
mutation LOG_ERROR ( 
	$error: String!
	$stack: String!
	$platform: jsonb!
	$location: jsonb!
) {
	insert_error_log (
		objects: {
			error: $error
			stack: $stack
			platform: $platform
			location: $location
		}
	) {
		returning {
			id
			created_at
		}
	}
}
`;

export function logError(error: string, stack: string, location: Object): Promise<string> {
	return apolloClient.mutate({ 
		mutation: LOG_ERROR, 
		variables: { 
			error, 
			stack, 
			platform: Bowser.parse(window.navigator.userAgent),
			location
		}
	}).then( response => {
		return response.data?.insert_error_log?.returning?.[0]?.id;
	});
}

export function logWorkflowValidationError(nameString, erroredStepPlan, errors: Ajv.ErrorObject[]) {
	let errorMessage = '';
	errors.map( error => {
		console.error('validation error: ', erroredStepPlan, error);
		errorMessage += error.schemaPath + ' ' + error.dataPath + ' ' + error.message + '; ';
	});

	toast.error(`Failed to validate. ${process.env.NODE_ENV !== 'production' && errorMessage} `, {
		position: toast.POSITION.BOTTOM_CENTER,  autoClose: 10000
	});

	logError(errorMessage, nameString, erroredStepPlan)
}