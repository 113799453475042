import React from 'react';

import { UIChip } from 'kit';

import { TableDateMultiField } from 'components/Table/TableDateField';
import { SelectColumnFilterArray } from 'components/Table/TableFilters';

import { ColDefs } from './tableColumnUtility';
import { roundWithDecimal } from 'utility';


type PartColId = 'name' | 'category' | 'description' | 'partNo' | 'qty' | 'uom' | 'min' | 'max' | 'updatedAt';

export const partCols: ColDefs<PartColId> = {
	name: {
		Header: 'Name',
		accessor: 'name',
		className: 'emphasis'
	},
	category: {
		Header: 'Category',
		accessor: 'tagNames',
		Filter: SelectColumnFilterArray,
		filter: 'includesAll',
		className: 'center-align',
		Cell: ({ cell: { value: tagNames } }) => tagNames.map(
			(tagName, index) => <UIChip key={`${tagName}-${index}`} outline noMarginBottom label={tagName} />
		)
	},
	description: {
		Header: 'Description',
		accessor: 'description'
	},
	partNo: {
		Header: 'Part No',
		accessor: 'part_number'
	},
	qty: {
		Header: 'Quantity',
		accessor: 'part_stock[0].part_total_qty',
		Cell: ({ cell: { value = 0 } }) => roundWithDecimal(value)
	},
	uom: {
		Header: 'Unit',
		accessor: 'uom'
	},
	min: {
		Header: 'Min',
		accessor: 'min'
	},
	max: {
		Header: 'Max',
		accessor: 'max'
	},
	updatedAt: {
		Header: 'Last Changed',
		accessor: 'updated_at',
		disableGlobalFilter: true,
		Cell: ({ cell: { value } }) => <TableDateMultiField ISOdateString={value} dateDisplayFormatTop="dateShortWithYear" dateDisplayFormatBottom="time" />
	}
}