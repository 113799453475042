
import { gql } from '@apollo/client';

import { workflowAllColumns } from '@constants';

export const GET_WORKFLOWS = gql`
	query GET_WORKFLOWS (
		$id: Int
	) {
		workflow (
			where: { 
				id: { _eq: $id }
			}
			order_by: {name: asc}
		) {
			${workflowAllColumns}
		}
	}
`;

