import React from 'react';
import { Route, Routes } from 'react-router-dom';

import {
	Contacts,
	Jobs, JobDetail, StepDetailPage, JobReview, JobDraft, JobHistory,
	SavedWorkflowsPage, FormDefinitionPage,	
	InventoryUniqueItemPage, AddInventoryUniquePage,
	InventoryUniquePage, PartRawPage,
	SavedStepsList, SavedStepDetailPage,
	Orders, OrderDetail, FormInstancePage,
	UserSettings, ProductPage, OrderDraft,
	NotFound, StepPage,
	Tools, FormBuilderPage,
	Recipes
} from 'pages';

import { MainRouteDefinition, mainRouteKeys, fullRouteKeys, fullRoutes, URLParamVariables } from 'routing';

import { PackageIcon } from '@styled-icons/feather/PackageIcon';
import { Cogs } from '@styled-icons/fa-solid/Cogs';
import { BookOpen } from '@styled-icons/evaicons-solid/BookOpen';
import { Group } from '@styled-icons/boxicons-solid/Group';
import { Cog } from '@styled-icons/boxicons-solid/Cog';
import { File } from '@styled-icons/boxicons-regular/File';
import { List } from '@styled-icons/entypo/List';
import { PaperPlane } from '@styled-icons/entypo/PaperPlane';

import { AddNewItemButton } from 'root/components/AddNewItemButton';
import { getObjectFromArray } from 'utility';



export function MainContainerRoutes(){
	// Document title handled in breadcrumb
	return (
		<Routes>
			{mainRoutes.map( route => 
			/**Use routecontent.name (which is the name of the component) as the key instead of the id. 
			 * This is so the component will not unmount if two different routes are actually the same component.  
			 * This is important because the left and right panels use component mount/unmount lifecycles to affect visibility, we don't want double mounting because it messes up whether or not the panel should be shown in mobile.
			 * */
				<Route key={route.RouteContent.name} path={route.path} element={<route.RouteContent />} /> 
			)}
			<Route path="*" element={<NotFound />} />
		</Routes>
	);
}

// ------------------------------------------- Main Page Routes -------------------------------------------


//route order matters!
export const mainRoutes: MainRouteDefinition[] = [
	{
		id: 'home',
		path: '/',
		label: 'Active Jobs',
		RouteContent: Jobs,
		icon: <PaperPlane />,
		leftMenu: {
			isVisible: false,
			mainMenu: 'manufacturing'
		},
		NavbarComponent: <AddNewItemButton type="job" routeName="jobs" />
	},
	{
		id: 'jobById',
		path: '/jobs/:id',
		label: 'Jobs',
		documentTitle: 'Job: %1',
		RouteContent: JobDetail,
		icon: <BookOpen />,
		leftMenu: {
			isVisible: false,
			mainMenu: 'manufacturing'
		},
		backlink: {
			linkTo: 'jobs'
		},
		labelVar: {
			queryType: 'job',
			pathVariable: ':id',
			queryResultKey: 'name'
		}
	},
	{
		id: 'jobs',
		path: '/jobs',
		label: 'Active Jobs',
		RouteContent: Jobs,
		icon: <PaperPlane />,
		leftMenu: {
			isVisible: true,
			order: 1,
			mainMenu: 'manufacturing'
		},
		NavbarComponent: <AddNewItemButton type="job" routeName="jobNewDraft" />
	},
	{
		id: 'jobHistory',
		path: '/jobs/history',
		label: 'Job History',
		RouteContent: JobHistory,
		icon: <BookOpen />,
		leftMenu: {
			isVisible: true,
			order: 2,
			mainMenu: 'manufacturing'
		},
		NavbarComponent: <AddNewItemButton type="job" routeName="jobNewDraft" />
	},
	{
		id: 'jobNewDraft',
		path: '/jobs/draft',
		label: 'New Job',
		RouteContent: JobDraft,
		icon: <BookOpen />,
		leftMenu: {
			isVisible: false,
			mainMenu: 'manufacturing'
		},
		backlink: {
			linkTo: 'jobs'
		},
		labelVar: {
			crumbTitlePrependText: 'New Job'
		}
	},
	{
		id: 'jobDraftById',
		path: '/jobs/draft/:id',
		label: 'Draft Job',
		documentTitle: 'Draft Job: %1',
		RouteContent: JobDraft,
		icon: <BookOpen />,
		leftMenu: {
			isVisible: false,
			mainMenu: 'manufacturing'
		},
		backlink: {
			linkTo: 'jobs'
		},
		labelVar: {
			crumbTitlePrependText: '[Draft]',
			queryType: 'job',
			pathVariable: ':id',
			queryResultKey: 'name'
		}
	},
	{
		id: 'jobReviewById',
		path: '/jobs/:id/review',
		label: 'Job Review',
		documentTitle: 'Job Review: %1',
		RouteContent: JobReview,
		icon: <BookOpen />,
		leftMenu: {
			isVisible: false,
			mainMenu: 'manufacturing'
		},
		backlink: {
			queryType: 'job',
			pathVariable: ':id',
			queryResultKey: 'name',
			linkTo: 'jobDraftById'
		},
		labelVar: {
			crumbTitlePrependText: '[Review]',
			queryType: 'job',
			pathVariable: ':id',
			queryResultKey: 'name'
		}
	},
	{
		id: 'operateStep',
		path: '/jobs/:id/:index',
		label: 'Step',
		documentTitle: '%1 - %2',
		RouteContent: StepDetailPage,
		icon: <BookOpen />,
		leftMenu: {
			isVisible: false,
			mainMenu: 'manufacturing'
		},
		backlink: {
			queryType: 'job',
			pathVariable: ':id',
			queryResultKey: 'name',
			linkTo: 'jobById'
		},
		labelVar: {
			queryType: 'step',
			pathVariable: ':index',
			queryResultKey: 'name'
		}
	},
	{
		id: 'orders',
		path: '/orders',
		label: 'Orders',
		RouteContent: Orders,
		icon: <PackageIcon />,
		leftMenu: {
			isVisible: true,
			order: 3,
			mainMenu: 'orders'
		},
		NavbarComponent: <AddNewItemButton type="order" routeName="orderNewDraft" />
	},
	{
		id: 'orderNewDraft',
		path: '/orders/draft',
		label: 'New Order',
		RouteContent: OrderDraft,
		icon: <PackageIcon />,
		leftMenu: {
			isVisible: false,
			mainMenu: 'orders'
		},
		backlink: {
			linkTo: 'orders'
		},
		labelVar: {
			crumbTitlePrependText: 'New Order'
		}
	},
	{
		id: 'orderById',
		path: '/orders/:id',
		label: 'Order Detail',
		documentTitle: 'Order: %1',
		RouteContent: OrderDetail,
		icon: <PackageIcon />,
		leftMenu: {
			isVisible: false,
			mainMenu: 'orders'
		},
		backlink: {
			linkTo: 'orders'
		},
		labelVar: {
			queryType: 'order',
			pathVariable: ':id',
			queryResultKey: 'name'
		}
	},
	{
		id: 'contacts',
		path: '/contacts',
		label: 'Customers',
		RouteContent: Contacts,
		icon: <Group />,
		leftMenu: {
			isVisible: true,
			order: 4,
			mainMenu: 'orders'
		}
	},
	{
		id: 'products',
		path: '/products',
		label: 'Products',
		RouteContent: ProductPage,
		icon: <List />,
		leftMenu: {
			isVisible: true,
			order: 5,
			mainMenu: 'orders'
		}
	},
	{
		id: 'inventoryUnique',
		path: '/inventory',
		label: 'Wafer Inventory',
		RouteContent: InventoryUniquePage,
		icon: <BookOpen />,
		leftMenu: {
			isVisible: true,
			order: 6,
			mainMenu: 'inventory'
		}
	},
	{
		id: 'inventoryUniqueById',
		path: '/inventory/unique/:id',
		label: 'Unique Inventory',
		documentTitle: 'Inventory: %1',
		RouteContent: InventoryUniqueItemPage,
		icon: <BookOpen />,
		leftMenu: {
			isVisible: false,
			mainMenu: 'inventory'
		},
		backlink: {
			linkTo: 'inventoryUnique'
		},
		labelVar: {
			queryType: 'inventory_unique',
			pathVariable: ':id',
			queryResultKey: 'name'
		}
	},
	{
		id: 'inventoryUniqueSetup',
		path: '/inventory/setup',
		label: 'Setup Unique Inventory',
		RouteContent: AddInventoryUniquePage,
		icon: <BookOpen />,
		leftMenu: {
			isVisible: false,
			mainMenu: 'inventory'
		}
	},
	{
		id: 'partRaw',
		path: '/materials',
		label: 'Materials',
		RouteContent: PartRawPage,
		icon: <BookOpen />,
		leftMenu: {
			isVisible: true,
			order: 7,
			mainMenu: 'inventory'
		}
	},
	{
		id: 'workflows',
		path: '/workflows',
		label: 'Workflows',
		RouteContent: SavedWorkflowsPage,
		icon: <Cogs />,
		leftMenu: {
			isVisible: true,
			order: 8,
			mainMenu: 'setup'
		},
		NavbarComponent: <AddNewItemButton type="workflow" fullRouteName="newWorkflowEditor" />
	},
	{
		id: 'steps',
		path: '/steps',
		label: 'Step History',
		icon: <BookOpen />,
		RouteContent: StepPage,
		leftMenu: {
			isVisible: true,
			order: 2,
			mainMenu: 'manufacturing'
		}
	},
	{
		id: 'stepHistoryById',
		path: '/steps/:id',
		label: 'Step History',
		documentTitle: 'Step History: %1',
		RouteContent: StepPage,
		icon: <BookOpen />,
		leftMenu: {
			isVisible: false,
			mainMenu: 'manufacturing'
		},
		backlink: {
			linkTo: 'steps'
		},
		labelVar: {
			queryType: 'step',
			pathVariable: ':id',
			queryResultKey: 'name'
		}
	},
	{
		id: 'stepSetup',
		path: '/step-setup',
		label: 'Step Setup',
		icon: <BookOpen />,
		RouteContent: SavedStepsList,
		leftMenu: {
			isVisible: true,
			order: 9,
			mainMenu: 'setup'
		}
	},
	{
		id: 'stepSetupById',
		path: '/step-setup/:id',
		label: 'Step Setup',
		documentTitle: 'Step Setup: %1',
		icon: <BookOpen />,
		RouteContent: SavedStepDetailPage,
		leftMenu: {
			isVisible: false,
			mainMenu: 'manufacturing'
		},
		backlink: {
			linkTo: 'stepSetup'
		},
		labelVar: {
			queryType: 'step',
			pathVariable: ':id',
			queryResultKey: 'name'
		}
	},
	{
		id: 'formInstances',
		path: '/forms',
		label: 'Completed Forms',
		RouteContent: FormInstancePage,
		icon: <File />,
		leftMenu: {
			isVisible: true,
			order: 7.5,
			mainMenu: 'manufacturing'
		}
	},
	{
		id: 'formInstanceById',
		path: '/forms/:id',
		label: 'Forms',
		documentTitle: 'Form: %1',
		RouteContent: FormInstancePage,
		icon: <File />,
		leftMenu: {
			isVisible: false,
			mainMenu: 'manufacturing'
		},
		backlink: {
			linkTo: 'formInstances'
		},
		labelVar: {
			queryType: 'form',
			pathVariable: ':id',
			queryResultKey: 'name'
		}
	},
	{
		id: 'formSetup',
		path: '/form-setup',
		label: 'Form Setup',
		RouteContent: FormDefinitionPage,
		icon: <BookOpen />,
		leftMenu: {
			isVisible: true,
			order: 10,
			mainMenu: 'setup'
		}
	},
	{
		id: 'formBuilderDraft',
		path: '/form-setup/0',
		label: 'New Form',
		RouteContent: FormBuilderPage,
		icon: <BookOpen />,
		leftMenu: {
			isVisible: false,
			mainMenu: 'manufacturing'
		},
		backlink: {
			linkTo: 'formSetup'
		}
	},
	{
		id: 'formBuilderById',
		path: '/form-setup/:id',
		label: 'Form Builder',
		documentTitle: 'Form: %1',
		RouteContent: FormBuilderPage,
		icon: <BookOpen />,
		leftMenu: {
			isVisible: false,
			mainMenu: 'setup'
		},
		backlink: {
			linkTo: 'formSetup'
		},
		labelVar: {
			queryType: 'form',
			pathVariable: ':id',
			queryResultKey: 'name'
		}
	},
	{
		id: 'tools',
		path: '/tools',
		label: 'Tools',
		RouteContent: Tools,
		icon: <Group />,
		leftMenu: {
			isVisible: true,
			order: 11,
			mainMenu: 'setup'
		}
	},
	{
		id: 'recipes',
		path: '/recipes',
		label: 'Recipes',
		RouteContent: Recipes,
		icon: <Cog />,
		leftMenu: {
			isVisible: true,
			order: 12,
			mainMenu: 'setup'
		}
	},
	{
		id: 'userSettings',
		path: '/user-settings',
		label: 'User Settings',
		RouteContent: UserSettings,
		icon: <Cog />,
		leftMenu: {
			isVisible: true,
			mainMenu: 'user'
		}
	}
];

// pre-filter left menu routes
export const leftMenuRoutes = mainRoutes
	.filter( routeDef => routeDef.leftMenu.isVisible )
	.sort( (a, b) => (a.leftMenu.order - b.leftMenu.order) );




// ---------------------------------------- Dev only: Verify routes --------------------------------------
if (process.env.NODE_ENV === 'development') {
// Ensure no duplicates in the route names array
	if ((new Set(mainRouteKeys)).size !== mainRouteKeys.length) {
		console.error('There are duplicate values in the Main Route Names array.');
	}
	if ((new Set(fullRouteKeys)).size !== fullRouteKeys.length) {
		console.error('There are duplicate values in the Full Route Names array.');
	}

// Ensure no duplicate IDs in the route array
	const routeIDs = mainRoutes.map( route => route.id );
	if ((new Set(routeIDs)).size !== routeIDs.length) {
		console.error('There are duplicate ids in the main routes array.');
	}
	const fullRouteIDs = fullRoutes.map( route => route.id );
	if ((new Set(fullRouteIDs)).size !== fullRouteIDs.length) {
		console.error('There are duplicate ids in the full routes array.');
	}

// Ensure all route name are represented in the routes array
// Loop through the route keys array and check to see if it exists in the routes array
	for (let index = 0; index < mainRouteKeys.length; index++) {
		const routeName = mainRouteKeys[index];
		const route = getObjectFromArray(mainRoutes, 'id', routeName);
		if (!route.id) {
			console.error(`route ${routeName} is not represented in the routes array.`);
		}
	}
	for (let index = 0; index < fullRouteKeys.length; index++) {
		const routeName = fullRouteKeys[index];
		const route = getObjectFromArray(fullRoutes, 'id', routeName);
		if (!route.id) {
			console.error(`route ${routeName} is not represented in the routes array.`);
		}
	}
// Ensure only eligible variable substition names are used
	// Put the colon in front of the variable
	const eligiblePathVars = URLParamVariables.map( varname => (':' + varname) )
	for (let index = 0; index < mainRoutes.length; index++) {
		const routeDef = mainRoutes[index];
		if (routeDef.backlink?.pathVariable && !eligiblePathVars.includes(routeDef.backlink?.pathVariable)) {
			console.error(`Variable ${routeDef.backlink?.pathVariable} in ${routeDef.id} is not valid.`);
		} 
		if (routeDef.labelVar?.pathVariable && !eligiblePathVars.includes(routeDef.labelVar?.pathVariable)) {
			console.error(`Variable ${routeDef.labelVar?.pathVariable} in ${routeDef.id} is not valid.`);
		} 
		
	}
}