import React from 'react';
import styled from 'styled-components';

import { UIHeading, UIPage, UIFlexChild, UICard, UIFadedTitle, UIButton, UIFlexbox, useModal, UIChip } from 'kit';

import { JobStepList } from 'components/Jobs/JobStepList';

import { useParams } from 'react-router-dom';

import { ThreadsList, ThreadsContainer } from 'components/Messages/Thread';
import { Thread, PartWIP } from 'types';
import { TabMenu } from 'components/Navigation/TabMenu';
import { JobInfo } from 'components/Jobs/JobInfo';
import { RightPortal } from 'components/Navigation/RightPortal';
import { useJobDetails } from 'hooks';
import { RoutedButton, UIRoutedLink } from 'routing';
import { getObjectFromArray } from 'utility';
import { TagChips } from 'root/components/common/TagChips';
import { AbortWorkflowModal } from 'root/components/WorkflowEditor/AbortWorkflowModal';
import { TagWorkflowDeviationButtonModalFromJob } from 'root/components/WorkflowEditor/TagWorkflowDeviationModal';

export function JobDetail() {	
	const { id } = useParams();
	const jobId = parseInt(id);
	
	const abortModalProps = useModal();
	const devTagModalProps = useModal();
	

	const { job, parts, attachedItems, productWip, productReserve, orders, isJobActive } = useJobDetails(jobId);
	
	const threads: Thread[] = job.threads || [] as Thread[];	

	return (
		<>
			<RightPortal>
				<TabMenu
					comment={ 
						<ThreadsContainer flex="1">
							<UIHeading>Comments</UIHeading>
							<ThreadsList
								threads={threads} 
								referenceType="job" 
								referenceId={jobId} 
							/>
						</ThreadsContainer>
					}
				/>
			</RightPortal>

			<UIPage id="job-detail" contentContainerSize="medium" asFlexbox childrenGrow="50/50" flexWrap="wrap">	
				<StyledChild>
					<JobInfo job={job} isEditable />

					
					<UICard
						margin="largeVertical"
						width="100%"
					>
						<UIFlexbox flexJustify="space-between" flexAlign="center">
							<UIFadedTitle>Workflow</UIFadedTitle>

							{!isJobActive && <TagWorkflowDeviationButtonModalFromJob modalProps={devTagModalProps} job={job} />}
						</UIFlexbox>
						<p>{job?.workflow?.name ?? 'Custom'}</p>
						<div>
							{ job?.deviation_tags?.length > 0 ? <TagChips tagIds={job.deviation_tags} tagType="deviation_tag" /> : <UIChip label="No Deviations" disabled light /> }
						</div>
						{isJobActive && 
							<UIFlexbox flexJustify="flex-end" flexAlign="center">
								<RoutedButton label="Edit Workflow" quiet margin="none" linkToRoute="workflowEditorJob" linkToVars={{ id: job.id }} />
								<UIButton label="Abort" quiet margin="none" onClick={abortModalProps.openModal} />
							</UIFlexbox>
						}
					</UICard>

					{job.job_status && (job.job_status === 'complete' ?
					<>
						<UICard
							margin="largeVertical"
							title="Product Inventory Transactions"
							width="100%"
						>
							{job.part_txs.filter( tx => tx.type === 'job_release').length === 0 && 'No Products Generated'}
							{job.part_txs.filter( tx => tx.type === 'job_release').map( tx => {
								const part = getObjectFromArray(parts, 'id', tx.part_id);
								return <div key={tx.id}>{part.name}: {tx.qty_change} {part.uom}</div> 
							}
							)}
						</UICard>
					</>
					:
					<>
						<UICard
							margin="largeVertical"
							title="Unique Inventory WIP"
							width="100%"
						>
							{attachedItems.length === 0 && 'None in WIP'}
							{attachedItems.length > 0 && <div>{attachedItems.length} item(s)</div>}
							{attachedItems.map( item => <UIRoutedLink key={item.id} text={item.name} linkToRoute="inventoryUniqueById" linkToVars={{ id: item.id }} display="block" /> )}
						</UICard>

						<UICard
							margin="largeVertical"
							title="Product WIP"
							width="100%"
						>
							{productWip.length === 0 && 'None in WIP'}
							{productWip.map( item => <div key={item.id}>{item.part.name}: {item.qty} {item.part.uom}</div> )}
						</UICard>
					</> 
					)}

					<UICard
						margin="largeVertical"
						title="Associated Orders"
						width="100%"
					>
						{orders.length === 0 && 'None'}
						{orders.map( order => {
							return (
							<React.Fragment key={order.id}>
								<UIRoutedLink key={order.id} text={order.name} linkToRoute="orderById" linkToVars={{ id: order.id }} display="block" /> 
								{order.order_items.map( lineItem => {
									const reserveItem: PartWIP = getObjectFromArray(productReserve, 'order_item_id', lineItem.id);
									if (reserveItem.id) {
										return <div key={reserveItem.id}>{lineItem.part.name}: {reserveItem.qty} reserved</div>
									}
								})}
							</React.Fragment>)
							}
						)}
					</UICard>

				</StyledChild>

				<JobStepList
					jobId={jobId}
				/>
				
				<AbortWorkflowModal job={job} modalProps={abortModalProps} />
			</UIPage>
		</>
	)
}

const StyledChild = styled(UIFlexChild)`
	margin-right: 30px;
	@media ${props => props.theme.mediaQuery.mobile} {
		min-width: 100%;
		margin-right: 0;
	}
`;