import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import matchSorter from 'match-sorter'

import { FormLabel, FormPhrase, FormSubtitle, FormInputContainer, UIFlexbox, DefaultOption, CustomOptionWrapper, CustomSingleValueWrapper } from 'kit';
import { getObjectFromArray } from 'utility';

import { useFormContext, Controller, UseFormMethods } from 'react-hook-form';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { PartialBy } from 'types';
import { theme } from 'theme';
import get from 'lodash.get';


interface SelectProps {
	// Main Props
	name: string
	isMulti?: boolean
	options: any[]//UISelectOption[],
	onChange: (value) => void
	defaultValue

	
	// Behavior
	disabled?: boolean
	scrollOnOpen?: boolean
	defaultMenuIsOpen?: boolean
	/** True if the value of the option is an object. */
	isLoading?: boolean

	// Presentational props
	label?: string
	subtitle?: string
	isPhraseFormat?: boolean
	phrase?: string
	placeholder?: string
	className?: string

	// Custom formatting of select components
	customOption?: ({ value, label, data }) => JSX.Element
	customSingleValue?: ({ data }) => JSX.Element
	labelAccessor?: string
	valueAccessor?: string
	isJsonValue?: boolean

	// Custom global sort using match-sorter
	filterOptionKeys?: Array<string | { key: string, threshold }>

	// Creatable function
	handleCreate?: (newName: string) => Promise<number>
}


interface RegisteredFormProps {
	required?: boolean
	/** Custom validation scheme. An object with custom key, plus a function that returns if validation passes. Key should also match with validateMessage */
	validate?: { [type: string]: () => boolean }
	/** Custom validation message. Custom key should match with "validate" prop. */
	validateMessage?: { [type: string]: string }
	/** Whether or not other components will render based on the value to this input.
	 * This is used for validating in onBlur mode
	 */
	hasConditionalComponents?: boolean
	skipRegister?: boolean
}

type PassThroughRegisteredFormProps = Pick<RegisteredFormProps, 
	'required' | 'validateMessage' | 'skipRegister'
>;


export const UISelect = (
	{ required, validate, validateMessage, hasConditionalComponents, skipRegister, onChange = () => {}, ...restProps }
	: RegisteredFormProps & PartialBy<SelectProps, 'onChange'>
) => {
	const formContext = skipRegister ? {} as UseFormMethods : useFormContext(); // useFormContext passes control implicitly to the controller
	//@ts-ignore
	const autoSave = formContext.autoSave;
	//@ts-ignore
	const autoSaveSubmit = formContext.onAutoSave;

	const handleChange = (value) => {
		onChange(value);
		if (autoSave) {
			autoSaveSubmit();
		}
	}
	
	const SelectElement = (
		<UISelectControlled
			{...restProps}
			onChange={() => {}}
			onControllerChanged={handleChange}
			registeredFormOptions={{
				required, validateMessage, skipRegister
			}}
		/>
	)

	return skipRegister ? SelectElement : (
		<Controller 
			as={SelectElement} 
			name={restProps.name}
			rules={{ required: required }}
			defaultValue={restProps.defaultValue}
		/>
	)
}

// export const UISelect = React.memo<any>((
export const UISelectControlled = (
	{ 
		name, onChange, onControllerChanged = () => {}, isMulti = false, options = [], defaultValue,
		label, subtitle, isPhraseFormat, phrase, className,
		disabled, defaultMenuIsOpen = false, scrollOnOpen = false,
		isJsonValue, isLoading, 
		placeholder = 'Type to search...',
		customOption = DefaultOption, customSingleValue,
		labelAccessor = 'name', valueAccessor = 'id',
		filterOptionKeys, handleCreate,
		registeredFormOptions = {}
	}: {
		registeredFormOptions?: PassThroughRegisteredFormProps
		onControllerChanged? // Pass through on change for when this is routed through react hook form (which takes over the normal onChange prop)
	} & SelectProps
) => {
	// Select component State
	const [selectedOptions, setSelectedOptions] = useState(convertValuesToOptionObjects(defaultValue));
	
	const [isFirstLoadComplete, setIsFirstLoadComplete] = useState(false);
	useEffect(() => {
		// update current state if options change. This is needed for items where hook originally returns an empty array for the options list
		if (!isFirstLoadComplete) {
			const defaultOptionObjs = convertValuesToOptionObjects(defaultValue);
			// This bypasses the handle change event, so we want to make sure this only happens once with isFirstLoadComplete
			const aSelectedOption = (isMulti ? defaultOptionObjs?.[0] : defaultOptionObjs) || {};
			if (Object.keys(aSelectedOption).length > 0) {
				setSelectedOptions(defaultOptionObjs);
				setIsFirstLoadComplete(true);
			}
		}

	}, [isLoading, isMulti ? defaultValue?.length : defaultValue, options.length]);
	
	// functions for managing options and values

	function convertValuesToOptionObjects(values) {
		if (!(options.length > 0)) {
			return null;
		}
		if (isMulti) {
			return values && 
				values.map( value => { return getObjectFromArray(options, valueAccessor, value) } )
		}
		// else
		return getObjectFromArray(options, valueAccessor, isJsonValue ? JSON.stringify(values) : values);
	}

	function convertOptionsToValues(opts) {
		if (isMulti) {
			return opts?.map( option => get(option, valueAccessor) ) ?? [];
		}
		// else
		const value = opts?.[valueAccessor] ?? void 0;
		return isJsonValue ? JSON.parse(value || '{}') : value;
	}


	const handleChange = newSelectedOptionObjects => {
		const value = convertOptionsToValues(newSelectedOptionObjects); 
		// const optionWasCleared = newSelectedOptionObjects === null; // don't this this is needed anymore?

		setSelectedOptions(newSelectedOptionObjects);
		
		onChange(value);
		onControllerChanged(value);
	}

	function createOptionAndSelect(submittedText){
		handleCreate(submittedText).then( newId => {
			if (newId > 0) {
				const newOption = { [valueAccessor]: newId, [labelAccessor]: submittedText };
				if (isMulti) {
					const newOptions = (selectedOptions || []).concat([newOption]);
					handleChange(newOptions);
				}
				else {
					handleChange(newOption);
			}
			}
		})
	}

	const selectRef = React.useRef(null);
	function scrollSelectToTop() {
		if (scrollOnOpen) {
			setTimeout(() => {
				selectRef && selectRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' }) 
			}, 600) // Give it a little time for the menu to open
		}
	}

	//  This block is to implement a sorted global filter (using react-select's default filterOption doesn't allow for sorting based on the filter)
		let [filteredOptions, setFilteredOptions] = useState(options);

    useEffect(() => {
        updateOptionsWithCreatableInstructions(options);
	}, [options.length]);
	
	const updateOptionsWithCreatableInstructions = (newOptions) => {
		const newOptionsWithCreatable = isCreatable ? newOptions.concat([{ [valueAccessor]: 'creatable-dummy', [labelAccessor]: 'Start typing to add new...', isDisabled: true }]) : newOptions;
		setFilteredOptions(newOptionsWithCreatable);
	}

    const onInputChange = (searchedValue, { action }) => {
        if (action === 'input-change') {
			// const escapedText = searchedValue.replace(/[-\\^$*+?.()|[\]{}]/g, '\\$&');
			const filteredSet = matchSorter(options, searchedValue, { keys: filterOptionKeys || [labelAccessor] });
			// Don't add the creatable dummy here since you're already typing
			setFilteredOptions(filteredSet);
		} 
		else if (action === 'menu-close') {
            updateOptionsWithCreatableInstructions(options);
		}
    }
	//  ^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^

	const isCreatable = typeof handleCreate === 'function';
	const SelectComponent = isCreatable ? CreatableSelect : Select;
	
	const CustomOptionWrapped = React.useMemo(
		() => CustomOptionWrapper.bind(this, selectedOptions, valueAccessor, customOption)
	, [selectedOptions?.length]);
	let customComponents: any = {
		Option: CustomOptionWrapped
	}
	if (customSingleValue) {
		const CustomSingleValueWrapped = React.useMemo(
			() => CustomSingleValueWrapper.bind(this, selectedOptions, valueAccessor, customSingleValue)
		, [selectedOptions?.length]);
		customComponents.SingleValue = CustomSingleValueWrapped
	}
	
	const SelectElement = (
	<SelectComponent
		isMulti={isMulti}
		isSearchable
		isClearable={isMulti || !registeredFormOptions?.required}
		filterOption={false}
		hideSelectedOptions={false}

		isDisabled={disabled}
		isLoading={isLoading}
		options={filteredOptions}
		value={selectedOptions}
		defaultValue={convertValuesToOptionObjects(defaultValue)}
		
		onChange={handleChange}
		onInputChange={onInputChange}
		placeholder={placeholder}
		
		menuPlacement="auto"
		menuShouldBlockScroll={false}
		menuShouldScrollIntoView={true}
		onMenuOpen={scrollSelectToTop}
		
		styles={isPhraseFormat ? phraseStyles : defaultStyle}
		classNamePrefix="select"
		components={customComponents}
		getOptionLabel={ option => get(option, labelAccessor) }
		getOptionValue={ option => get(option, valueAccessor) }
		// menuIsOpen={true} // Handy for style debugging
		defaultMenuIsOpen={defaultMenuIsOpen}
		autoFocus={defaultMenuIsOpen} //puts the focus on the search box
		
		onCreateOption={createOptionAndSelect}
		createOptionPosition="last"
	/>);

	return (
		<SelectWrapper
			SelectComponent={<CursorAndRefContainer disabled={disabled} ref={selectRef}>{SelectElement}</CursorAndRefContainer>}
			name={name}
			label={label}
			subtitle={subtitle}
			isPhraseFormat={isPhraseFormat}
			phrase={phrase}
			showLabel={label?.length > 0}
			className={className}
			registeredFormOptions={registeredFormOptions}
		/>
	);
}
/*,
	(prevProps, nextProps) => {
		const { options: prevOptions = [],  handleCreate: prevHC, customOption: prevCO, ...prevRest } = prevProps;
		const { options: nextOptions = [],  handleCreate: nextHC, customOption: nextCO, ...nextRest } = nextProps;
	
		if (!areEqualShallow(prevRest, nextRest)) {
			return false;
		}
		if (prevOptions.length !== nextOptions.length) {
			return false;
		}
		return true
	}
)


function areEqualShallow(a, b) {
    for(var key in a) {
        if(a[key] !== b[key]) {
            return false;
        }
    }
    return true;
}
*/

const SelectWrapper = (
	{ 
		SelectComponent, showLabel, 
		name, label, subtitle, phrase, isPhraseFormat, className,
		registeredFormOptions
	}:
	{
		SelectComponent
		showLabel
		name
		label
		subtitle
		isPhraseFormat
		phrase
		className
		registeredFormOptions: PassThroughRegisteredFormProps
	}
) => {
	// const { name, label, subtitle, isPhraseFormat, phrase, showLabel } = presentationlProps;
	if (isPhraseFormat) {
		return (
			<FormInputContainer className={className}>
				<UIFlexbox flexAlign={'center'} width="100%">
					<FormPhrase name={name} label={label} showLabel={showLabel} customError={registeredFormOptions.validateMessage} phrase={phrase}/>
					{SelectComponent}
				</UIFlexbox>
				<FormSubtitle>{subtitle}</FormSubtitle>
			</FormInputContainer>
		)
	}
	return (
		<FormInputContainer className={className}>
			<FormLabel name={name} label={label} required={registeredFormOptions.required} showLabel={showLabel} customError={registeredFormOptions.validateMessage} skipRegister={Object.keys(registeredFormOptions).length > 0 || registeredFormOptions?.skipRegister} />
			<FormSubtitle>{subtitle}</FormSubtitle>
			{SelectComponent}
		</FormInputContainer>
	)
}

/**
 * https://github.com/JedWatson/react-select/issues/404#issuecomment-433608100
	 Allowed the dropdown to show outside of the parent container
	 Doesn't work well in a Drop component because the drop sees the 'click' on an option as being outside of the drop, thereby closing it before selection can register
 *
 */

 
const CursorAndRefContainer = styled.div<{ disabled: boolean }>`
	/** Cursor applies to the outermost container */
	${props => props.disabled && props.theme.presets.inputDisabled}
	max-width: 30rem; /** contrain the width of the select component with this dummy container */
	
	/** background color applies to the control component */
	.select__control--is-disabled {
		${props => props.theme.presets.inputDisabled}
		
		/**text color applies to the single value component */
		.select__single-value {
			${props => props.theme.presets.inputDisabled}
		}
	}
`;


const defaultStyle = {
	container: (provided) => ({
		...provided
	}),
	input: (provided) => ({
		...provided,
		input: {
			fontFamily: theme.font.body
		}
	}),
	option: (provided, state) => ({
		...provided,
		padding: 0,
		color: theme.colors.baseText,
		backgroundColor: 'none',
		'&:hover': {
			backgroundColor: theme.colors.primaryLightAlpha
		}
	}),
	control: (provided, state) => ({
		...provided,
		// minWidth: 300,
		border: '1px solid lightgrey',
		boxShadow: 'none',
		backgroundColor: 'white',
		'&:hover': {
			borderColor: theme.colors.primary
		}
	}),
	menu: (provided) => ({
		...provided,
		border: '1px solid lightgrey',
		boxShadow: 'inset 0 1px 0 rgba(0, 0, 0, 0.1)'
	}),
	menuPortal: base => ({ ...base, zIndex: 9999 }),
	singleValue: (provided) => ({
		...provided
	})
};

//style for phrase format
const phraseStyles = {
	container: (provided) => ({
		...provided
	}),
	input: (provided) => ({
		...provided,
		input: {
			fontFamily: theme.font.body
		}
	}),
	option: (provided, state) => ({
		...provided,
		backgroundColor: 'none',
		'.select__option--is-selected': {
			backgroundColor: theme.colors.primary
		},
		'&:hover': {
			backgroundColor: theme.colors.primaryLightAlpha,
			color: theme.colors.baseText
		}
	}),
	control: (provided, state) => ({
		...provided,
		minWidth: 250,
		// fontWeight: '600',
		border: 'none',
		borderRadius: 'none',
		borderBottom: '2px solid lightgrey',
		boxShadow: 'none',
		'&:hover': {
			borderBottom: `2px solid ${theme.colors.primary}`
		}
	}),
	menu: (provided) => ({
		...provided,
		border: '1px solid grey',
		boxShadow: 'inset 0 1px 0 rgba(0, 0, 0, 0.1)'
	}),
	singleValue: (provided) => ({
		...provided,
		fontSize: 'medium'
	}),
	menuPortal: base => ({ ...base, zIndex: 9999 })
};