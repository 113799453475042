import React, { useMemo } from 'react';

import { UIPage, useModal } from 'kit';

import { ContactForm } from 'components/Contacts/ContactForm';

import { useContacts } from 'hooks';

import { NavbarPortal } from 'components/Navigation/NavbarPortal';
import { AddNewItemButton } from 'components/AddNewItemButton';

import { Table } from 'components/Table/Table';
import { contactCols } from 'components/Table/tableRef';
import { TableActionButton } from 'components/Table/TableActionButton';

import { Contact } from 'types';



export function Contacts() {
	const modalProps = useModal<Contact>();
	
	function toggleEdit(contact: Contact){
		modalProps.setModalData(contact);
		modalProps.toggleModal(true);
	}

	return (
		<UIPage id="contacts" contentContainerSize="large">
			<NavbarPortal>
				<AddNewItemButton type="contact" onClick={toggleEdit.bind(this, {})} />		
			</NavbarPortal>

			<ContactTable toggleEdit={toggleEdit} />

			<ContactForm 
				modalProps={modalProps} 
			/>
		</UIPage>
	)
}


function ContactTable({ toggleEdit }: 
	{ 
		toggleEdit(contact): void
	}
) {
	const { contacts } = useContacts();
	

	const columns = useMemo(() => [	{
			Header: '',
			id: 'actions',
			className: 'center-align show-on-hover',
			Cell: table => {
				const item = table.row.original;
				return (
					<TableActionButton type="edit" onClick={toggleEdit.bind(this, item)} />
				);
			}
		}].concat(contactColumnDefs)
	, [contacts.length]);
	
	let data = useMemo(() => contacts, [contacts]);

	return (
		<Table 
			columns={columns} 
			data={data} 
			hasSortableColumns 
			hasGlobalFilter 
			hasPagination 
			hasSelectablePageSize 
			hasFilterableColumns 
		/>
	);
}


const contactColumnDefs: any = [
	contactCols.firstName,
	contactCols.lastName,
	contactCols.company,
	contactCols.email,
	contactCols.phone,
	contactCols.address,
	contactCols.note,
	contactCols.updatedAt
	];