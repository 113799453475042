import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import { UIButton, UIChip } from 'kit';
import { OperateTaskBanners } from 'components/OperateStep/OperateTaskCard';

import { useStepDetails } from 'hooks';
import { startStep } from 'services/stepStart';
import { stopStep } from 'services/stepStop';

import { useNavMain } from 'routing';
import { PartRawEffect, StepPlan, StepHistoryRaw, InventoryUniqueItem, FormInstance, WorkflowOptions } from 'types';
import { getNextAvailableStep } from 'components/WorkflowEditor';

export type StepTask = 'recipe' | 'tool' | 'attach' | 'form' | 'inventory_unique_form' | 'partRaw';

export type TaskData = {
	recipe?: number
	tool?: number
	attach?: number[]
	partRaw?: PartRawEffect[]
}

const allowedTasks: {[stepState: string]: StepTask[] }  = {
	queue: [],
	startable: ['recipe', 'tool', 'attach'],
	processing: ['form', 'inventory_unique_form', 'partRaw'],
	complete: [],
	skip: []
}

export const useOperateStep = (jobId: number, stepIndex1: number) => {
	const navigateToJobs = useNavMain('jobs');
	const navigateToStep = useNavMain('operateStep');
	
	const{
		isDataReady, 
		job,
		stepInfo, stepState,
		stepPlan, supp, 
		stepHistory, stepHistoryHelper,
		uniqueItems
	} = useStepDetails(jobId, stepIndex1);
	
	useEffect(() => {
		if (stepState) {
			let tasks = [];
			let data = {}
			for (let index = 0; index < allowedTasks[stepState].length; index++) {
				const stepTask = allowedTasks[stepState][index];
				if (isTaskNeeded(stepTask, stepIndex1, stepPlan, supp, job.workflow_options)) { 
					// add the task to task array
					tasks.push(stepTask); 

					// set the initial value
					data[stepTask] = getTaskDefault(stepTask, stepPlan);
				}
			}
			setNeededTasks(tasks);
			setTaskData(data);
		}
	}, [isDataReady, jobId.toString() + '-' + stepIndex1.toString(), stepState])
	
	const startStepWithSubmit = () => {
		setisMutationRunning(true);
		const finalRecipe = allTaskData.recipe || supp.recipe_value || stepPlan?.recipe?.value;
		const finalTool = allTaskData.tool || supp.tool_value || stepPlan?.tool?.value;
		startStep(job, stepIndex1, finalRecipe, finalTool, allTaskData.attach).then( () => {
			setisMutationRunning(false);
		});
	}

	const stopStepWithSubmit = () => {
		setisMutationRunning(true);			
		stopStep(stepHistory, stepPlan, job, allTaskData.partRaw).then( success => {
			setisMutationRunning(false);
			if (success) { 
				const nextStepIndex = getNextAvailableStep(job.steps, job.current_step_index - 1, job.current_step_index - 1, true)
				if (nextStepIndex > 0 && nextStepIndex <= job.steps.length) {
					navigateToStep(job.id, nextStepIndex);
				}
				else {
					navigateToJobs(); 
				}
			}
		});
	}
	
	function isTaskComplete(stepTask: StepTask) {
		let isComplete = false;
		switch (stepTask) {
			case 'recipe':
			case 'tool':
				isComplete = typeof allTaskData[stepTask] === 'number';
				break;
			case 'attach':
			case 'partRaw':
				isComplete = allTaskData[stepTask].length > 0;
				break;
			case 'form':
				isComplete = (stepPlan.forms || []).filter( defId => !isFormComplete(defId, stepHistory) ).length === 0;
				break;
			case 'inventory_unique_form':
				isComplete = uniqueItems.filter( item => {
					// check if the form is incomplete
					const existingForm = getExistingForm(item, stepPlan.inventory_unique_form.form_definition_id);
					const fieldsForThisStep = stepPlan.inventory_unique_form?.fields || [];
					if (fieldsForThisStep.length === 0) {
						return !existingForm.values //if it exists, consider it complete
					}
					//else 
					return fieldsForThisStep.map( field => {
						// loop through each requested field and check to see if it exists in the form
						const isFieldIncomplete = !existingForm.values?.[field];
						return isFieldIncomplete;
					}).filter( isIncomplete => isIncomplete ).length > 0
				}).length === 0
				break;
			default:
				break;
		}
		return isComplete;
	}

	

	const [neededTasks, setNeededTasks] = useState([]);	
	const [allTaskData, setTaskData] = useState({} as TaskData);
	const [isMutationRunning, setisMutationRunning] = useState(false);	
	
	const allTasksComplete = neededTasks
		.map(taskName => isTaskComplete(taskName))
		.filter( isComplete => !isComplete)
		.length === 0;

	const stepsAway = stepIndex1 - job.current_step_index;
	const StateChip = (
		<UIChip 
			size="medium"
			light
			capitalize
			label={stepState + (stepState === 'queue' ? ` - ${stepsAway} step${stepsAway > 1 ? 's' : ''} away` : '')} 
			success={stepState === 'complete'} 
			muted={stepState === 'startable' || stepState === 'queue'} 
		/>
	);

	const ActionButton = stepState === 'startable' || stepState === 'processing' ?
		(<ActionContainer>
			<StartStopButton
				label={stepState === 'startable' ? 'Start' : 'Stop'}					
				onClick={stepState === 'startable' ? startStepWithSubmit : stopStepWithSubmit}					
				disabled={!allTasksComplete || isMutationRunning}
			/>
			{StateChip}
			{stepState === 'processing' && job.steps?.length === stepIndex1 && <div>Job will close when this step is stopped.</div>}
		</ActionContainer>)
		: <ActionContainer>{StateChip}</ActionContainer>;

		
	const TaskCards = isDataReady && neededTasks.map( taskName => (
		<OperateTaskBanners
			key={taskName}
			taskType={taskName}
			allTaskData={allTaskData}
			setTaskData={setTaskData}
			isTaskComplete={isTaskComplete}
			job={job}
			stepId={stepInfo.id}
			stepPlan={stepPlan}
			supp={supp}
			stepHistory={stepHistory}
			stepHistoryHelper={stepHistoryHelper}
			uniqueItems={uniqueItems}
		/>
	));

	return {
		ActionButton,
		TaskCards,
		uniqueItems
	};
}

function isTaskNeeded(stepTask, stepIndex, stepPlan, supp, workflowOptions: WorkflowOptions){
	let isNeeded = false;
	switch (stepTask) {
		case 'recipe':
			isNeeded = stepPlan?.recipe?.method === 'flex_operator';
			break;
		case 'tool':
			isNeeded = stepPlan?.tool?.method === 'flex_operator';
			break;
		case 'attach':
			isNeeded = workflowOptions.inventory_unique?.attach?.step_index === stepIndex;
			break;
		case 'form':
			isNeeded = stepPlan?.forms?.length > 0;
			break;
		case 'inventory_unique_form':
			isNeeded = typeof stepPlan?.inventory_unique_form !== 'undefined';
			break;
		case 'partRaw':
			isNeeded = stepPlan?.inventory_effect?.items?.length > 0;
			break;
		default:
			break;
	}
	return isNeeded;
}

function getTaskDefault(stepTask: StepTask, stepPlan: StepPlan){
	let data;
	switch (stepTask) {
		case 'recipe':
			data = stepPlan?.recipe?.default_value;
			break;
		case 'tool':
			data = stepPlan?.tool?.default_value;
			break;
		case 'attach':
			data = [];
			break;
		case 'partRaw':
			data = stepPlan?.inventory_effect?.items ?? [];
			break;
		default:
			break;
	}
	return data;
}

function getExistingForm(uniqueInvItem: InventoryUniqueItem, formDefId: number): FormInstance {
	return uniqueInvItem?.forms?.filter( 
		(formInstance: FormInstance) => formInstance.form_definition_id === formDefId
	)?.[0] ?? {} as FormInstance;
}

export function isFormComplete(formDefId, stepHistory: StepHistoryRaw) {
	const completedFormDefIds = stepHistory.forms.map( linkedForm => linkedForm.form_definition_id );
	return completedFormDefIds.indexOf(formDefId) > -1;
}

const ActionContainer = styled.div`
	width: min-content;
	text-align: center;
	margin: 0 auto 20px;
`;

const StartStopButton = styled(UIButton).attrs( props => ({
	margin: 'none'
}))`
	font-size: 1.7em;
	font-weight: ${props =>props.theme.font.boldWeight};
	padding: 0.5em 1.5em;
	border-radius: 32px;
	@media ${props => props.theme.mediaQuery.mobile} {
		width: 80vw;
	}
	${props => props.theme.shadow.depth4}
	margin: 0 auto 20px;
`;

