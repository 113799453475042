
import { apolloClient, updateCacheAfterInsert } from 'services/apollo';

import { thingLookup, ThingName, ThingTypes, ThingUpdateObjTypes, ThingInsertObjTypes, thingInsertLookup, thingUpdateLookup, thingQueryLookupById } from '@constants';

export interface ThingMutateResponse<K extends ThingName> {
	success: boolean
	item: ThingTypes[K]
}

export function addThing<K extends ThingName>(
	thingName: K, insertObj: ThingInsertObjTypes[K]
): Promise<ThingMutateResponse<K>> {
	const { tableName } = thingLookup[thingName];
	const MUTATION = thingInsertLookup[thingName];
	const GET_QUERY = thingQueryLookupById[thingName];

	return apolloClient.mutate({ 
		mutation: MUTATION, 
		variables: { 
			insertingObjs: [insertObj]
		},
		update(cache, result) {
			updateCacheAfterInsert(cache, result, tableName, GET_QUERY);
		}
	}).then( response => {
		return {
			success: true,
			item: response.data[`insert_${tableName}`].returning[0]
		}
	}).catch(() => {
		return {
			success: false,
			item: {} as ThingTypes[K]
		};
	});
}



export function updateThing<K extends ThingName>(
	thingName: K, 
	id: number, 
	changes: ThingUpdateObjTypes[K],
	options?: {
		refetchQueries?
	}
): Promise<ThingMutateResponse<K>> {
	const { refetchQueries = [] } = options || {};

	const { tableName } = thingLookup[thingName];
	const MUTATION = thingUpdateLookup[thingName];

	return apolloClient.mutate({ 
		mutation: MUTATION, 
		variables: { 
			id,
			changes
		},
		refetchQueries
	}).then( response => {
		return {
			success: true,
			item: response.data[`update_${tableName}`].returning[0]
		}
	}).catch(() => {
		return {
			success: false,
			item: {} as ThingTypes[K]
		};
	});
}

