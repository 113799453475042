import { useEffect } from 'react';
import { useAuthStore } from 'store';
import { useAuth0, Auth0ContextInterface } from '@auth0/auth0-react';
import { useQuery } from '@apollo/client';
import { GET_USER_BY_ID } from 'pages/UserSettings'
import { User } from 'types';

export interface AuthUser {
	email: string;
	nickname: string
	'https://hasura.io/jwt/claims': {
		'x-hasura-allowed-roles': ['user'];
		'x-hasura-default-role': 'user';
		'x-hasura-org-id': string; //hasura wants this to be a string
		'x-hasura-user-id': string; //hasura wants this to be a string
	};
	picture: string; //url
}

interface Auth0Context extends Auth0ContextInterface {
	user?: AuthUser;
}

export const useAuth = () => {
	const {
		loginWithRedirect,
		getIdTokenClaims,
		isLoading,
		isAuthenticated,
		user, 
		error, 
		logout
	}: Auth0Context = useAuth0();

	const storedToken = useAuthStore(state => state.token);
	// const storedAuthUser = useAuthStore(state => state.authUser);
	const setValue = useAuthStore(state => state.setValue);

	useEffect(() => {
		if (!isLoading && !isAuthenticated && !error) {
			loginWithRedirect();
		}
		if (!isLoading && !storedToken && !error) {
			getIdTokenClaims().then(resp => {
				setValue('token', resp.__raw);
			});
		}
		// if (isAuthenticated && !storedAuthUser.email) {
		// 	setValue('authUser', user);
		// }
	}, [isLoading, isAuthenticated, user?.email]);

	const { 
		// loading,
		// error,
		data: { user: [internalUser = {} as User] } = { user: [] }
	} = useQuery(GET_USER_BY_ID, { 
		variables: { id: user?.nickname },
		skip: !user?.nickname || !storedToken
	});

	useEffect(() => {
		setValue('internalUser', internalUser);
	}, [internalUser.id]);


	return { isLoading, isAuthenticated: isAuthenticated && storedToken, errMsg: error?.message, logout };
};
