import React from 'react';
import styled from 'styled-components';
import { Label } from '@rebass/forms/styled-components';
import { useFormContext, UseFormMethods } from 'react-hook-form';
import get from 'lodash.get';

export const FormInputContainer = styled.div`
	margin: 0 0 30px;
	width: 100%;
`;

export const FormSubtitle = styled.p`
	margin-bottom: 0; /* Default to not have a margin (& height)*/
	label ~ & {
		margin-bottom: 8px; /* Have a margin (& height) if the subtitle is after a label*/
	}
	font-size: 0.85em;
	color: ${props => props.theme.colors.baseTextLight};
	text-align: left; /** Specify left align since this property can be inherited from parents */
`;

export const RequiredSymbol = () => {
	return (
		<RequiredSpan>*</RequiredSpan>
	)
}

const RequiredSpan = styled.span`
	color: ${props => props.theme.colors.error};
	margin: 0 4px;
`;


export const FormLabel = (
	props: {
		name: string;
		label: string;
		required: boolean;
		showLabel?: boolean;
		customError?;
		skipRegister?: boolean;
		className?;
	}
) => {
	if (props.showLabel) {
		return (
			<StyledLabel htmlFor={props.name} className={props.className}>
				{props.label}
				{props.required && <RequiredSymbol />}
				{!props.skipRegister && <ErrorMessage name={props.name} label={props.label} customError={props.customError} />}
			</StyledLabel>
		);
	}
	return !props.skipRegister && <ErrorMessage name={props.name} label={props.label} customError={props.customError} />;
};

const StyledLabel = styled(Label)`
	margin-bottom: 6px;
	color: ${props => props.theme.colors.baseText};
	font-size: 1em;
	font-weight: ${props =>props.theme.font.boldWeight};
	font-family: ${props => props.theme.font.title};
	text-align: left; /** Specify left align since this property can be inherited from parents */
`;
FormLabel.defaultProps = {
	showLabel: true,
	skipRegister: false
};


const ErrorMessage = (
	props: {
		name: string;
		label: string;
		customError?;
	}
) => {
	const formContext = useFormContext() || {} as UseFormMethods;
	const errorForThisComponent = get(formContext.errors, props.name); // Use get since react-hook-form v4 doesn't support flattened structure anymore
	const customErrorMessage = errorForThisComponent && props.customError ? props.customError[errorForThisComponent.type] : '';

	return (<ErrorSpan>
		{errorForThisComponent && (customErrorMessage !== '' ? customErrorMessage : props.label + ' is required')}
	</ErrorSpan>);
};
const ErrorSpan = styled.span`
	color: ${props => props.theme.colors.error};
	${StyledLabel} & {
		margin-left: 10px;
	}
`;

export const FormPhrase = (
	props: {
		name: string;
		label: string;
		phrase: string;
		customError?;
		skipRegister?: boolean;
	}
) => {
	return (
		<React.Fragment>
			<StyledPhraseLabel>{props.phrase}</StyledPhraseLabel>
			{!props.skipRegister && <ErrorMessage name={props.name} label={props.label} customError={props.customError} />}
		</React.Fragment>
	);
};
const StyledPhraseLabel = styled(Label)`
	width: fit-content;
	color: ${props => props.theme.colors.baseText};
	font-size: 1em;
	font-weight: ${props =>props.theme.font.boldWeight};
	font-family: ${props => props.theme.font.title};
`;
FormPhrase.defaultProps = {
	showLabel: true
};
