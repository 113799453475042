import React from 'react';
import styled from 'styled-components';

import { UIChip } from 'kit';
import { Meter } from 'grommet';
import { UIRoutedLink } from 'routing';

import { SelectColumnFilter } from 'components/Table/TableFilters';
import { TableDateField } from 'components/Table/TableDateField';

import { capitalizeFirstLetter, elapseTime, deepCopy } from 'utility';

import { Job } from 'types';
import { ColDefs } from './tableColumnUtility';

import { calcJobAndStepStatus } from '@constants/gql';

const ProgressBar = styled(Meter)`
	width: 140px;
`;

interface JobWithTableHelpers extends Job {
	workflow_name?: string
	combined_status?: string
}

export function transformJobData(jobs: Job[]): JobWithTableHelpers[] {
	//create workflow_name accessor for missing custom name. Doing it here instead of in the cell helps with filtering.
	let updatedJobsList = deepCopy(jobs);
	for (let index = 0; index < jobs.length; index++) {
		let dJob: JobWithTableHelpers = deepCopy(jobs[index]);
		
		dJob.workflow_name = updatedJobsList[index].workflow === null ? 'Custom' : dJob.workflow.name;
		
		dJob.combined_status = calcJobAndStepStatus(dJob);

		updatedJobsList[index] = dJob;
	}
	return updatedJobsList;
}

type JobColId = 'name' | 'nameNoLink' | 'currentStep' | 'jobStepStatus' | 'workflow' 
	| 'createdAt' | 'updatedAt'
	| 'startAt' | 'stopAt' | 'duration' | 'progressText' | 'progressMeter' | 'stepCount';

export const jobCols: ColDefs<JobColId> = {
	name: {
		Header: 'Job',
		accessor: 'name',
		filter: 'fuzzyText',
		className: 'emphasis',
		Cell: (table) => {
			const item: JobWithTableHelpers = table.row.original;
			return (
				<UIRoutedLink
					text={item.name}
					linkToRoute={item.job_status === 'draft' ? 'jobDraftById' : 'jobById'}
					linkToVars={{ id: item.id }}
				/> 
			)
		}
	},
	nameNoLink: {
		Header: 'Job',
		accessor: 'name',
		className: 'emphasis'
	},
	currentStep: {
		Header: 'Current Step',
		accessor: 'current_step.step_definition.name',
		Filter: SelectColumnFilter,
		filter: 'includes',
		Cell: (table) => {
			const item: JobWithTableHelpers = table.row.original;
			return (
				<UIRoutedLink
					text={item.current_step.step?.name}
					linkToRoute="operateStep" 
					linkToVars={{ id: item.id, index: item.current_step_index }}
				/> 
			)
		}
	},
	jobStepStatus: {
		Header: 'Status',
		accessor: 'combined_status',
		Filter: SelectColumnFilter,
		filter: 'includes',
		className: 'center-align',
		Cell: (table) => {
			const item: JobWithTableHelpers = table.row.original;
			const status = item.combined_status;
			return (
				<UIChip
					outline={status !== 'complete'}
					noMarginBottom
					size="xsmall"
					label={capitalizeFirstLetter(status)}
					success={status === 'complete' || status === 'processing'}
				/> 
			)
		}
	},
	workflow: {
		Header: 'Workflow',
		accessor: 'workflow_name',
		Filter: SelectColumnFilter,
		filter: 'includes'
	},
	startAt: {
		Header: 'Started',
		accessor: 'start_at',
		disableGlobalFilter: true,
		Cell: ({ cell: { value } }) => <TableDateField ISOdateString={value} dateDisplayFormat="timeTodayDateOther" />
	},
	stopAt: 	{
		Header: 'Completed',
		accessor: 'stop_at',
		disableGlobalFilter: true,
		Cell: ({ cell: { value } }) => <TableDateField ISOdateString={value} dateDisplayFormat="timeTodayDateWithYearOther" />
	},
	createdAt: 	{
		Header: 'Created At',
		accessor: 'created_at',
		disableGlobalFilter: true,
		Cell: ({ cell: { value } }) => <TableDateField ISOdateString={value} dateDisplayFormat="timeTodayDateWithYearOther" />
	},
	updatedAt: 	{
		Header: 'Last Updated',
		accessor: 'updated_at',
		disableGlobalFilter: true,
		Cell: ({ cell: { value } }) => <TableDateField ISOdateString={value} dateDisplayFormat="timeTodayDateWithYearOther" />
	},
	duration: {
		Header: 'Duration',
		accessor: 'duration',
		disableGlobalFilter: true,
		Cell: (table) => {
			const job: Job = table.row.original;

			return (elapseTime(job.start_at, job.stop_at)
		)}
	},
	progressText: {
		Header: 'Step',
		accessor: 'current_step_index',
		id: 'current_step_index_count',
		Cell: (table) => {				
			const job: JobWithTableHelpers = table.row.original;
			const status = job.combined_status;
			return job.current_step_index + (status === 'active' ? 1 : 0) + '/' + job.steps.length
		}
	},
	progressMeter: {
		Header: 'Progress',
		accessor: 'current_step_index',
		disableGlobalFilter: true,
		id: 'current_step_index_bar', //need to set a unique id since the accessor is used twice
		Cell: (table) => {				
			const job: JobWithTableHelpers = table.row.original;
			const status = job.combined_status;

			let progress = (job.current_step_index + (status === 'active' ? 0.5 : 0)) / job.steps.length * 100
			if (job.job_status === 'draft') { progress = 0; }

			return (
				<ProgressBar
					type="bar"
					round
					values={[
						{
							value: progress,
							color: progress > 100 ? 'lightgrey' : 'success'
						}
					]}
				/>			
			)
		}
	},	
	stepCount: {
		Header: 'Step Count',
		accessor: 'steps.length'
	}
}