import React, { useState } from 'react';
import { UIPage, UIFlexbox, UIRadioSegment } from 'kit';


import { OrdersTable } from 'components/Orders/OrdersTable';

export function Orders() {
	const [statusFilter, setStatusFilter] = useState('active');

	return (
		<UIPage id="orders" contentContainerSize="xlarge">
			<UIFlexbox flexJustify="space-between" flexAlign="center">
				<UIRadioSegment
					options={[
						{ name: 'Active', id: 'active' },
						{ name: 'All', id: 'all' }
					]}
					name="displayType"
					label=""
					default="active"
					onChange={setStatusFilter}
				/>
			</UIFlexbox>

			<OrdersTable
				statusFilter={statusFilter}
			/>

			{/* <UIDrawer {...drawerContext}>
				<UIFlexbox flexDirection="column" margin="large">
					<OrderInfo order={order} />
					<OrderLineItems orderId={selectedOrderId} />
				</UIFlexbox>
			</UIDrawer> */}

		</UIPage>

	)
}