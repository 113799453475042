import React from 'react';
import ReactDOM from 'react-dom';

import styled from 'styled-components';

import { UIFlexbox } from 'kit';
import { FlexboxProps } from '@constants';


const StyledBar = styled(UIFlexbox).attrs( props => ({
	flexAlign: 'center'
}))`
	height: 70px;
	width: calc(100% - 60px);
	padding: 0 30px;
`;

const StyledTopBar = styled(StyledBar)<any>`
	border-bottom: 1px solid ${props => props.theme.colors.colorDefinitions.grey3};
`;

const StyledBottomBar = styled(StyledBar)<any>`
	position: absolute;
	bottom: 0;
	border-top: 1px solid ${props => props.theme.colors.colorDefinitions.grey3};
`;

type BarProps = {
	children?
	className?: string
} & FlexboxProps

export const TopBar = ({ children, className, ...restProps }: BarProps) => {

	return (
		<StyledTopBar id="top-bar-portal" className={className} {...restProps}>
			{children}
		</StyledTopBar>
	)
}

// ---------------------------------------------------------------------------------------------

export const BottomBar = ({ children, className, ...restProps }: BarProps) => {

	return (
		<StyledBottomBar id="bottom-bar-portal" className={className} {...restProps}>
			{children}
		</StyledBottomBar>
	)
}


// ---------------------------------------------------------------------------------------------


export const BalanceBar = ({ 
	hasBottomBar = false,
	hasTopBar = false,
	children,
	className 
}: { 
	hasBottomBar?: boolean
	hasTopBar?: boolean
	children
	className?: string
}) => {

	return (
		<HeightAdjustedDiv 
			hasTopBar={hasTopBar}
			hasBottomBar={hasBottomBar}
			className={className}
		>
			{children}
		</HeightAdjustedDiv>
	)
}

const HeightAdjustedDiv = styled.div<{ hasBottomBar: boolean, hasTopBar: boolean }>`
	height: ${props => `calc(100% - ${(props.hasBottomBar ? 70 : 0) + (props.hasTopBar ? 70 : 0) }px)`};
`;


// ---------------------------------------------------------------------------------------------


export class TopBarPortal extends React.Component {
	constructor(props) {
		super(props);
		// Create a div that we'll render the modal into. Because each
		// Modal component has its own element, we can render multiple
		// modal components into the modal container.
		//@ts-ignore
		this.el = document.createElement('div');
	}

	componentDidMount() {
		// Append the element into the DOM on mount. We'll render
		// into the modal container element (see the HTML tab).
		const navbarPortal = document.getElementById('top-bar-portal');
		//@ts-ignore
		navbarPortal.appendChild(this.el);
	}

	componentWillUnmount() {
		// Remove the element from the DOM when we unmount
		const navbarPortal = document.getElementById('top-bar-portal');
		//@ts-ignore
		navbarPortal.removeChild(this.el);
	}

	render() {
		// Use a portal to render the children into the element
		return ReactDOM.createPortal(
			// Any valid React child: JSX, strings, arrays, etc.
			this.props.children,
			// A DOM element
			//@ts-ignore
			this.el
		);
	}
}

// ---------------------------------------------------------------------------------------------

export class BottomBarPortal extends React.Component {
	constructor(props) {
		super(props);
		// Create a div that we'll render the modal into. Because each
		// Modal component has its own element, we can render multiple
		// modal components into the modal container.
		//@ts-ignore
		this.el = document.createElement('div');
	}

	componentDidMount() {
		// Append the element into the DOM on mount. We'll render
		// into the modal container element (see the HTML tab).
		const navbarPortal = document.getElementById('bottom-bar-portal');
		//@ts-ignore
		navbarPortal.appendChild(this.el);
	}

	componentWillUnmount() {
		// Remove the element from the DOM when we unmount
		const navbarPortal = document.getElementById('bottom-bar-portal');
		//@ts-ignore
		navbarPortal.removeChild(this.el);
	}

	render() {
		// Use a portal to render the children into the element
		return ReactDOM.createPortal(
			// Any valid React child: JSX, strings, arrays, etc.
			this.props.children,
			// A DOM element
			//@ts-ignore
			this.el
		);
	}
}
