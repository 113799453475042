import React from 'react';
import { UIForm, UITitle, UIButton, UIIconButton, UILine, useModal, UIModalWithHeader } from 'kit';

import { useLazyQuery } from '@apollo/client';
import { GET_INVENTORY_UNIQUE } from 'services/inventoryUniqueFetch';
import { UniqueInventoryTable } from 'components/InventoryUnique/InventoryUniqueTables';

import { getObjectFromArray } from 'utility';
import { InventoryUniqueItem, Job } from 'types';
import { Box } from 'grommet';
import { OperateTaskButton } from './TaskBannerPresentational';

import { LibraryAdd } from '@styled-icons/material-outlined/LibraryAdd';
import { UploadToCloud } from '@styled-icons/entypo/UploadToCloud';
import { Close } from '@styled-icons/material/Close';
import { MinusCircle } from '@styled-icons/boxicons-regular/MinusCircle'

//display attach inventory form
export const OperateAttachInventory = (
	props: {
		stagedIds
		updateStagedIds
		job: Job
	}
) => {
	const modalProps = useModal();	

	const typeId = props.job?.workflow_options?.inventory_unique?.type_id;
	
	const [fetchInventory, { 
		loading, 
		data: { inventory_unique: inventoryList } = { inventory_unique: [] as Array<InventoryUniqueItem> }
	}] = useLazyQuery(GET_INVENTORY_UNIQUE, { 
		variables: { 
			type_id: typeId, 
			stage_ids: props.job.workflow_options?.inventory_unique?.attach?.stage_ids?.length > 0 ? props.job.workflow_options?.inventory_unique?.attach?.stage_ids : void 0,
			status: 'stock'
		},
		fetchPolicy: 'cache-and-network'
	});
	
	const submit = () => {
		/*
		stageUniqueInventoryAttach(inventoryToAttach, job, jobStepIndex);		
		*/
		modalProps.toggleModal();
	}
	const addItem = (selectedItem: InventoryUniqueItem, index) => {
		if (props.stagedIds.indexOf(selectedItem.id) === -1) {
			props.updateStagedIds(props.stagedIds.concat([ selectedItem.id ]));
		}
	}

	const removeItem = clickedId => {
		let updatedItems = props.stagedIds.slice(); //duplicate
		const clickedIndex = updatedItems.indexOf(clickedId);
		if (clickedIndex !== -1) { updatedItems.splice(clickedIndex, 1); }
		props.updateStagedIds(updatedItems);
	}

	const showModal = () => {
		fetchInventory();
		modalProps.toggleModal();
	}
	
	return (
		<React.Fragment>
			<OperateTaskButton
				label={props.stagedIds.length > 0 ? (props.stagedIds.length + ' item(s) selected.') : 'Select items'}
				onClick={showModal}
			/>
										
			<UIModalWithHeader 
				HeaderLeft={ <UIButton icon={<UploadToCloud  />} label="Save" onClick={submit} />}
				HeaderRight={<UIIconButton muted icon={<Close />} onClick={modalProps.toggleModal} />}
				{...modalProps}
			>
				<UIForm onSubmit={submit}>					
				
					{props.stagedIds.length < 1 ? 
					<Box fill="horizontal" align="center" >
						<LibraryAdd size="60" />
						<UITitle>Select Inventory from the table</UITitle>
					</Box>
					: null
					}

					{ !loading &&
						props.stagedIds.map( id => (						
							<UniqueInventoryBox						
								key={'item_' + id} onClickCLose={removeItem.bind(this, id)}
								inventoryItem={getObjectFromArray(inventoryList, 'id', id)}
							/>						
						) )
					}
					<div>{props.stagedIds.length} items are selected to attach.</div>
				</UIForm>
					<UILine margin="30px" />
					<UniqueInventoryTable 
						typeId={props.job?.workflow_options?.inventory_unique?.type_id}
						stageIds={props.job?.workflow_options?.inventory_unique?.attach?.stage_ids}
						status="stock"
						selectedIds={props.stagedIds}
						actionType="add"
						actionFunction={addItem}
					/>
					
				
			</UIModalWithHeader>			
		</React.Fragment>
	);
}


OperateAttachInventory.defaultProps = {
	stagedIds: []
}

//enter attributes
export const UniqueInventoryBox = (
	props: {
		onClickCLose
		inventoryItem: InventoryUniqueItem
	}
) => {
	return (
		<Box direction="row" margin={{ top: 'small', bottom: 'small' }} 
			align="center" height="30px" background="ghostwhite" 
			round="small" gap="large"
		>	
			
			<UIIconButton muted icon={<MinusCircle />} onClick={props.onClickCLose} size="small" />
			<UITitle>ID: {props.inventoryItem.name}</UITitle>
			<UITitle>Type: {props.inventoryItem?.type?.name}</UITitle>
			<UITitle>Stage: {props.inventoryItem?.stage?.name}</UITitle>
		</Box>
	)
}