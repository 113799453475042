import React from 'react';
import styled from 'styled-components';

import { UIPage, UIHeading } from 'kit';

import { Surprise } from '@styled-icons/fa-regular/Surprise';

export function NotFound() {
	return (
		<UIPage id="not-found" asFlexbox fullHeight flexDirection="column" flexAlign="center" flexJustify="center">
			<Surprise size={160} />
			<StyledHeading level="1"> Uh Oh! </StyledHeading>
			<StyledDiv> The page you requested does not exist or has been removed. </StyledDiv>
		</UIPage>
	)
}

const StyledHeading = styled(UIHeading)`
	margin-top: 30px;
	font-size: 3rem;
	line-height: 4rem;
`;

const StyledDiv = styled.div`
	margin-bottom: 20%;
	text-align: center;
`;