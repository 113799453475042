import React, { useState } from 'react';
import styled from 'styled-components';

import { UISection, UIFlexChild, UIButton, UIIconButton, UIForm, UIHeading } from 'kit';
import { PaddingProps, paddingStyles } from '@constants';

import { useLeaveDialog } from 'services/hooks/useLeavePage';  //DON'T import this from the hooks module, because that will cause index.ts to load, which will cause a circular reference

import { Save } from '@styled-icons/material/Save';
import { Close } from '@styled-icons/material/Close';

import Modal from 'react-modal';

import { baseAppStyles } from 'root/theme';

// ------------------------------------ Modal Hook ----------------------------------------------------------------
export interface ModalHookProps<T = any> {
	isModalOpen: boolean
	setIsModalOpen: React.Dispatch<boolean>
	toggleModal: (newState?: boolean) => void
	openModal: () => void
	closeModal: () => void
	modalData: T
	setModalData: React.Dispatch<T>
}

export const useModal = <T, >(
    isOpenInitial: boolean = false,
    dataInitial: T = {} as T
): ModalHookProps<T> => {
	const [isModalOpen, setIsModalOpen] = useState(isOpenInitial);
	const [modalData, setModalData] = useState(dataInitial)

	function toggleModal(forceState?: boolean){ 
		setIsModalOpen(typeof forceState === 'boolean' ? forceState : !isModalOpen); 
	}

	function closeModal() { // some components have problems with toggle (ie, maintaining state properly.) they can use open/close functions explicity
		setIsModalOpen(false);
	}

	function openModal() { // some components have problems with toggle (ie, maintaining state properly.) they can use open/close functions explicity
		setIsModalOpen(true);
	}

    return { isModalOpen, setIsModalOpen, toggleModal, closeModal, openModal, modalData, setModalData };
}



// ------------------------------------ Modal Component ----------------------------------------------------------------
// NOTE: Modal styles are in index.css

// Make sure to bind modal to your appElement (http://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement('#root');


export type UIModalBaseProps = { 
	children?: any
	clickOutsideToClose?: boolean
	/**Defaults to medium width*/ 
	width?: 'small' | 'medium' | 'large' 
	isFormDirty?: boolean
} & PaddingProps


export const UIModal = (	
	props: UIModalBaseProps & ModalHookProps
) => {
	const { confirmLeave } = useLeaveDialog();

	function closeModalViaOutsideClick(){
		if (props.isFormDirty) {
			if (confirmLeave()) { 
				props.toggleModal(false);
			}
			else {
				// leave the modal open
			}
		}
		else {
			props.toggleModal(false);
		}
	}

	return (
		<StyledModal
			isOpen={props.isModalOpen}
			//onAfterOpen={afterOpenModal}
			onRequestClose={props.clickOutsideToClose ? closeModalViaOutsideClick : void 0}
			className="ReactModal__Content"
			overlayClassName="ReactModal__Overlay"
			{...props}
		/>
	);
}

const StyledModal = styled(Modal)<UIModalBaseProps>`
	${baseAppStyles}
	${props => props.theme.scrollbar.light};
	border-radius: 10px;
	
	${paddingStyles}
	min-width: 300px;
	@media ${props => props.theme.mediaQuery.moreThanMobile} {
		min-width: ${props => props.width === 'small' && '400px'};
		min-width: ${props => props.width === 'medium' && '650px'};
		min-width: ${props => props.width === 'large' && '1000px'};
	}
	max-height: 90%;
	min-height: 200px;
	max-width: 95%;
	overflow: auto;
	:focus {		
		outline: none;
	}
`;

UIModal.defaultProps = {
	width: 'small',
	padding: 'large',
	clickOutsideToClose: true
}



// ------------------------------------ Modal Component, Extended ----------------------------------------------------------------


export const UIModalWithHeader = (
	props: {
		HeaderLeft?
		HeaderCenter?
		HeaderRight?
		bottomBorder
	} & UIModalBaseProps & ModalHookProps
) => {
	return (
		<UIModal
			padding="none"
			{...props}
		>
			<UISection bottomBorder={props.bottomBorder} asFlexbox flexJustify="space-evenly" padding="small">
				<HeaderSubsection align="left">{props.HeaderLeft}</HeaderSubsection>
				<HeaderSubsection align="center">{props.HeaderCenter}</HeaderSubsection>
				<HeaderSubsection align="right">{props.HeaderRight}</HeaderSubsection>
			</UISection>
			<UISection padding="large">
				{props.children}
			</UISection>
		</UIModal>
	);
}

UIModalWithHeader.defaultProps = {
	width: 'medium',
	bottomBorder: true
}

export const HeaderSubsection = styled(UIFlexChild)<{ align: 'left' | 'center' | 'right' }>`
	margin: 0 10px;
	&:first-of-type {
		margin-left: 1rem;
	}
	&:last-of-type {
		margin-right: 0;
	}
	text-align: ${props => props.align};
`;


export const UIModalForm = (
	props: {
		HeaderCenter?
		defaultValues?
		onSubmit
		/** formKey: used to refresh the form so that default values can be reloaded */
		formKey?
	} & UIModalBaseProps & ModalHookProps
) => {
	return (
		<UIModal
			padding="none"
			{...props}
		>
			<UIForm padding="none" margin="none" onSubmit={props.onSubmit} defaultValues={props.defaultValues} key={props.formKey}>
				<UISection bottomBorder asFlexbox flexJustify="space-evenly" padding="medium" flexAlign="center">
					<HeaderSubsection align="left"><UIButton icon={<Save />} label="Save" type="submit"/></HeaderSubsection>
					<HeaderSubsection align="center">{props.HeaderCenter}</HeaderSubsection>
					<HeaderSubsection align="right"><UIIconButton icon={<Close />} onClick={props.toggleModal} /></HeaderSubsection>
				</UISection>
				<UISection padding="large">
					{props.children}
				</UISection>
			</UIForm>
		</UIModal>
	)
}


export const UIModalSimpleAction = (
	{ 
		Icon, title, description, 
		supplementalContent, 
		buttonText = 'OK', 
		onSubmit = () => {},
		showCancel = false,
		showSubmit = true,
		autoCloseModalOnSubmit = true,
		clickOutsideToClose = false, 
		...restProps 
	}: {
		Icon?: JSX.Element
		title?: string
		description?
		buttonText?: string
		supplementalContent?
		onSubmit?: () => void
		showCancel?: boolean
		showSubmit?: boolean
		autoCloseModalOnSubmit?: boolean
		clickOutsideToClose?: boolean
	} & UIModalBaseProps & ModalHookProps
) => {

	function confirmAction(){
		onSubmit();
		if (autoCloseModalOnSubmit) {
			restProps.toggleModal();
		}
	}

	
	return (
		<StyledModalSimpleAction
			{...restProps}
			clickOutsideToClose={clickOutsideToClose}
		>
			{Icon}
			<UIHeading>{title}</UIHeading>
			<p className="simple-modal-description">{description}</p>
			
			{supplementalContent}
			
			{showSubmit && 
				<UIButton full label={buttonText} onClick={confirmAction} margin="mediumVertical" />
			}
			{showCancel && 
				<UIButton full quiet label="Cancel" onClick={restProps.toggleModal} margin="mediumVertical" />
			}
		</StyledModalSimpleAction>
	)
}

const StyledModalSimpleAction = styled(UIModal)`
	text-align: center;
	& > svg:first-child {
		height: 60px;
		width: 60px;
		margin-bottom: 30px;
	}
	.simple-modal-description {
		color: ${props => props.theme.colors.baseTextLight};
		font-size: 0.95em;
		margin-bottom: 2rem;
	}
`;