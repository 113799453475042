import React from 'react';
import styled from 'styled-components';
import { PaddingProps, paddingStyles, FlexboxProps, flexboxStyles, ContainerProps, containerStyles } from '@constants';


const StyledUIPage = styled.div<PageProps>`
	${containerStyles}
	${flexboxStyles}
	${paddingStyles}
	position: relative;
	${props => props.fullHeight && 'height: 100%'};
`;

type PageProps = {
	id
	children
	asFlexbox?: boolean
	fullHeight?: boolean
} & ContainerProps & FlexboxProps & PaddingProps

export const UIPage = (
	props: PageProps
) => {
	return (<StyledUIPage  {...props} />)
}

UIPage.defaultProps = {
	asFlexbox: false
}