import React, { useContext } from 'react';
import styled from 'styled-components';


import { leftMenuRoutes, MainRouteDefinition } from 'routing';
import { VerticalMenu, VerticalMenuButton } from './VerticalMenu';
import { PanelContext } from './PanelState';

import { isMobileScreen, capitalizeFirstLetter } from 'utility';
import { useAuth0 } from '@auth0/auth0-react';
import { useAuthStore } from 'store';
import { userInitials } from 'root/services/userHelper';


export const LeftMenu = (
) => {
	const { closeLeftMenu } = useContext(PanelContext);

	// const currentRoute = useCurrentMainRoute();
	// const currentMenuSection = currentRoute.leftMenu.mainMenu;
	const userProfile = useAuthStore(state => state.internalUser);
	const { logout } = useAuth0();

	let buttons;
	// if (!isMobileScreen()) {
	// 	buttons = genButtonsForSection(currentMenuSection, closeLeftMenu);
	// }
	// else {
		buttons = genButtonsForSection('manufacturing', closeLeftMenu);
		buttons.push(genButtonsForSection('inventory', closeLeftMenu));
		buttons.push(genButtonsForSection('setup', closeLeftMenu));
		buttons.push(genButtonsForSection('orders', closeLeftMenu));
		// buttons.push(genButtonsForSection('settings', closeLeftMenu));
		buttons.push(genButtonsForSection('user', closeLeftMenu));

		buttons.push( <CustomMenuButton key="logout" label={`Logout (${userInitials(userProfile)})`} onClick={logout} /> );
	// }

	return (
		<>

			<LeftMenuWide 
				data-cy="nav-container"
				menuButtons={buttons}
				footerContent={
					<>
						{process.env.REACT_APP_VERSION}
						<br />
						{process.env.NODE_ENV === 'development' ? <small>{process.env.REACT_APP_GQL_ENDPOINT}</small> : null}
					</>
				}
			/>
		</>
		
	)
}

function genButtonsForSection(section: MainRouteDefinition['leftMenu']['mainMenu'], closeLeftMenu) {
	let elements = [];
	elements.push(<LeftMenuHeading key={`sec-${section}`}>{capitalizeFirstLetter(section)}</LeftMenuHeading>)

	elements.push(leftMenuRoutes.filter( route => route.leftMenu.mainMenu === section)
		.map(({ id, label, path }) => {
			return (					
				<CustomMenuButton
					key={path}
					label={label}
					linkToRoute={id}
					isExactLinkMatch={id === 'jobHistory'}
					onClick={isMobileScreen() ? closeLeftMenu : void 0}
				/>					
			)
		})
	);
	return elements;
}

const LeftMenuWide = styled(VerticalMenu)`
	padding: 0 28px;
	background-color: #484848;
		${props => props.theme.scrollbar.dark}
`;

const LeftMenuHeading = styled.div`
	text-transform: uppercase;
	text-align: center;
	padding: 16px 0 16px 0;
	color: ${props => props.theme.colors.colorDefinitions.grey2};
`;

const CustomMenuButton = styled(VerticalMenuButton)`
	color: white;
	transition: none;

	&.link-active {
		background-color: ${props => props.theme.colors.primaryLight2};
		color: white;
	}
	
	:hover {
		background-color: #777777;
		/* background-color: ${props => props.theme.colors.primaryLight1}; */
	}	
	
`;