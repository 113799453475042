import { gql } from '@apollo/client';
import { inventoryUniqueTypeAllColumns, inventoryUniqueAllColumns } from '@constants';


export const GET_INVENTORY_UNIQUE_TYPES = gql`
	query GET_INVENTORY_UNIQUE_TYPES (
		$type_id: Int
	) {
		inventory_unique_type (
			where: { id: { _eq: $type_id } }
		){
			${inventoryUniqueTypeAllColumns}
		}
	}
	
`;

export const GET_INVENTORY_UNIQUE = gql`
	query GET_INVENTORY_UNIQUE (
		$id: Int
		$type_id: Int
		$stage_id: Int
		$stage_ids: [Int!]
		$status: String
	){
		inventory_unique (
			where: { 
				id: { _eq: $id }
				type_id: { _eq: $type_id }
				stage_id: { _eq: $stage_id, _in: $stage_ids }
				status: { _eq: $status }
			}
		) {
			${inventoryUniqueAllColumns}
		}
	}
`;


export const GET_INVENTORY_UNIQUE_BY_IDS = gql`
	query GET_INVENTORY_UNIQUE_BY_IDS (
		$ids: [Int!]
		$type_id: Int
		$stage_id: Int
		$stage_ids: [Int!]
		$status: String
	){
		inventory_unique (
			where: { 
				id: { _in: $ids }
				type_id: { _eq: $type_id }
				stage_id: { _eq: $stage_id, _in: $stage_ids }
				status: { _eq: $status }
			}
		) {
			${inventoryUniqueAllColumns}
		}
	}
`;