import React from 'react';

import { UIPage, useModal, UIModal } from 'kit';

import { PartTable } from 'components/Parts/PartTable';
import { PartTxTable } from 'components/Parts/PartTransactionTable';
import { EditPartForm } from 'components/Parts/PartForm';

import { NavbarPortal } from 'components/Navigation/NavbarPortal';
import { AddNewItemButton } from 'components/AddNewItemButton';

import { PartRaw } from 'types';

export function PartRawPage() {
	const modalProps = useModal<PartRaw>();
	const txModalProps = useModal<PartRaw>();

	function toggleEdit(obj: PartRaw) {
		modalProps.setModalData(obj);
		modalProps.toggleModal();
	}

	function toggleAdjust(item) {
		txModalProps.setModalData(item);
		txModalProps.toggleModal();
	}

	return (
		<UIPage id="part-raw" contentContainerSize="xlarge">
			<NavbarPortal>
				<AddNewItemButton type="partRaw" onClick={toggleEdit.bind(this, {} as PartRaw)} />
			</NavbarPortal>

			<PartTable
				actionType="edit"
				actionFunction={toggleEdit}
				adjustActionFunction={toggleAdjust}
				isRaw
			/>

			<EditPartForm
				modalProps={modalProps}
				originatingPartType="raw"
			/>

			<UIModal {...txModalProps}>				
				<PartTxTable
					part={txModalProps.modalData}
					partType="raw"
				/>
			</UIModal>
		</UIPage>
	)
}

