import React from 'react';
import { UIPage } from 'kit';
import { JobsTable } from 'components/Jobs/JobsTable';

// import { Job } from 'types';
// import { JobInfo, CurrentStepInfo } from 'components/Jobs/JobInfo';

export function Jobs() {
	// const drawerContext = useDrawer();

	return (
		<UIPage id="jobs" contentContainerSize="large">
			<JobsTable />

			{/* <UIDrawer {...drawerContext} >
				<UIFlexbox flexDirection="column" margin="large">
					<CurrentStepInfo job={job} />
					<JobInfo job={job} />
				</UIFlexbox>
			</UIDrawer> */}

		</UIPage>
	)
}