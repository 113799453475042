import React from 'react';
import { useUIForm, UIForm2, SaveButtonBlock, UIInput, UIButton, UISelect, useModal, UIModal, UIHeading } from 'kit';


import { capitalizeFirstLetter } from 'utility';
import { addInventoryUniqueItem, addInventoryUniqueType, addInventoryUniqueTypeStage } from 'services/inventoryUniqueMutate';
import { AddCircle } from '@styled-icons/remix-line/AddCircle';
import { InventoryUniqueType } from 'types';


export const AddInventoryUniqueItemButtonModal = (	
	props: {
		typeData: InventoryUniqueType
		queryVariables: any
	}
) => {
	const modalProps = useModal();
	const { formMethods, isDirty } = useUIForm();

	const submit = formData => {		
		addInventoryUniqueItem(props.typeData.id, formData.stageId, formData.name, 'stock', props.queryVariables);
		modalProps.toggleModal();
	}

	return (
		<>
			<UIButton icon={<AddCircle />} onClick={modalProps.toggleModal} label="Add New Item" />

			<UIModal {...modalProps} isFormDirty={isDirty} >
				<UIHeading> {`Add a new ${capitalizeFirstLetter(props.typeData.name)}`} </UIHeading>
				<UIForm2 formMethods={formMethods} onSubmit={submit}>

					<UIInput name="name" label="ID" required placeholder="enter a unique id" />
					
					<UISelect
						options={props.typeData.stages}
						name="stageId"
						label="Stage"
						defaultValue=""
						required
						hasConditionalComponents={false}
					/>
					
					<SaveButtonBlock onCancel={modalProps.toggleModal} />

				</UIForm2>
			</UIModal>
		</>
	)
}

//add new type
export const AddInventoryUniqueTypeButtonModal = (	
	props: {
	}
) => {
	const modalProps = useModal();
	const { formMethods, isDirty } = useUIForm();

	const submit = formData => {
		addInventoryUniqueType(formData.name);
		modalProps.toggleModal();
	}
	
	return (
		<>
			<UIButton icon={<AddCircle />} onClick={modalProps.toggleModal} label="Add New Type" />

			<UIModal {...modalProps} isFormDirty={isDirty} >
				<UIHeading> Add a new inventory type </UIHeading>
				<UIForm2 formMethods={formMethods} onSubmit={submit}>
					
					<UIInput name="name" label="Name" required />			
					
					<SaveButtonBlock onCancel={modalProps.toggleModal} />

				</UIForm2>
			</UIModal>
		</>
	)
}

//add stages to type
export const AddInventoryUniqueStageButtonModal = (	
	props: {
		typeData: InventoryUniqueType
	}
) => {
	const modalProps = useModal();
	const { formMethods, isDirty } = useUIForm();

	const submit = formData => {
		addInventoryUniqueTypeStage(props.typeData.id, formData.name);
		modalProps.toggleModal();
	}

	return (
		<>
			<UIButton icon={<AddCircle />} onClick={modalProps.toggleModal} label="Add Stage" />

			<UIModal {...modalProps} isFormDirty={isDirty} >
				<UIHeading> Add a new inventory stage </UIHeading>
				<UIForm2 formMethods={formMethods} onSubmit={submit}>
					
					<UIInput name="name" label="Name" required />			
					
					<SaveButtonBlock onCancel={modalProps.toggleModal} />

				</UIForm2>
			</UIModal>
		</>
	)
}
