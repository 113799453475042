
import React from 'react';

import { UIForm2, UIInput, UITextArea, UISelect, DefaultOptionCheckbox, SaveButtonBlock, useUIForm, UIModal, UIHeading, ModalHookProps } from 'kit';

import { LinkStepsFromItem } from 'root/components/StepLink/LinkStepsFromItem'

import { useTags } from 'hooks';

import { addTag } from 'services/tagMutate';
import { ProcessParameterUpdateObject, ProcessParameterInsertObject, Step } from 'types';
import { updateThing, addThing } from 'root/services/thingMutate';
import { arrayString, capitalizeFirstLetter } from 'utility';


export const EditParameterForm = (
	{ parameterName, modalProps, addStepToPending }: {
		parameterName: 'recipe' | 'tool'
		modalProps: ModalHookProps
		addStepToPending?: Step
	}
) => {
	const savedObj = modalProps.modalData || {};
	const { formMethods, isDirty } = useUIForm(savedObj, { resetTrigger: modalProps.isModalOpen });

	const createTag = newtag => {
		return addTag( parameterName, newtag).then( queryResponse => {
			return queryResponse.id;
		})
	}

	const linkStepsRef = React.useRef<{ updateStepsFromItem(itemId: number): void }>();

	const submit = formData => {
		if (savedObj.id) {
			const updateObj: ProcessParameterUpdateObject = {
				...formData,
				tags: arrayString(formData.tags)
			}
			updateThing(parameterName, savedObj.id, updateObj);
			linkStepsRef.current.updateStepsFromItem(savedObj.id);
			modalProps.toggleModal();
		}
		else {
			const addObj: ProcessParameterInsertObject = {
				...formData,
				is_saved: true,
				tags: arrayString(formData.tags)
			}
			addThing(parameterName, addObj).then(thingMutateResp => {
				linkStepsRef.current.updateStepsFromItem(thingMutateResp.item.id);
				// Make sure to put toggle modal inside of the promise - if this was outside, the modal & the linked steps component ref get destroyed before we can access the function from the ref
				modalProps.toggleModal();
			});
		}
	}

	const { isTagsLoading, tags } = useTags(parameterName);

	return (
		<UIModal {...modalProps} isFormDirty={isDirty} >
			<UIHeading>{savedObj.id ? 'Update' : 'Add New' } {capitalizeFirstLetter(parameterName)}</UIHeading>
				<UIForm2 formMethods={formMethods} onSubmit={submit}>

					<UIInput name="name" label="Name" required />
					
					<LinkStepsFromItem
						ref={linkStepsRef}
						parameterName={parameterName}
						linkingId={savedObj.id}
						addStepToPending={addStepToPending}
					/>

					<UISelect
						isMulti
						name={`tags`}
						label={`Category`}
						defaultValue={savedObj.tags}
						options={tags}
						isLoading={isTagsLoading}
						customOption={DefaultOptionCheckbox}
						handleCreate={createTag}
						scrollOnOpen
					/>
					
					<UITextArea name="description" label="Description" />

					<SaveButtonBlock onCancel={modalProps.toggleModal} />
				</UIForm2>
		</UIModal>
	)
}
