import { thingLookup } from './things';
import { userReferenceColumns } from 'services/userHelper';


export const toolAllColumns = `
	id
	created_at
	updated_at
	name
	description
	tags
`;


export const recipeAllColumns = `
	id
	created_at
	updated_at
	name
	description
	tags
`;

export const workflowAllColumns = `
	id
	created_at
	updated_at
	name
	description
	steps
	is_active
	workflow_options
`;


// ------------------------------------------------- Parts -- -----------------------------------

export const partStockAllColumns = `
		id
		created_at
		updated_at
		part_id
		job_id
		inventory_unique_id
		qty
		part_total_qty( args:{ adjust_qty: 0 } )
`;
export const partStockAllColumnsAfterUpdate = (adjustQty) => (`
		id
		created_at
		updated_at
		part_id
		job_id
		inventory_unique_id
		qty
		part_total_qty( args:{ adjust_qty: ${adjustQty} } )
`);

export const partAllColumns = `
	id
	created_at
	updated_at
	name
	description
	part_number
	uom
	min
	max
	tags
	is_raw
	is_product
	part_stock {
		${partStockAllColumns}
	}
`;

export const partTransactionAllColumns = `
	id
	created_at
	created_user_id
	type
	part_id
	stock_job_id
	stock_inventory_unique_id
	qty_change
	new_qty
	new_part_total_qty
	job_id
	job {
		name
	}
	step_history_id
	order_item_id
	comment
`;

export const partWipAllColumns = `
	id
	created_at
	updated_at
	type
	job_id
	part_id
	qty
	order_item_id
	part {
		${partAllColumns}
	}
	job {
		name
		job_status
	}
`;

export const partWipTransactionAllColumns = `
	id
	created_at
	created_user_id
	wip_type
	type
	part_id
	job_id
	step_history_id
	order_item_id
	qty_change
	new_qty
	comment
`;



// ------------------------------------------------- Saved Step -----------------------------------

export const stepAllColumns = `
	id
	created_at
	updated_at
	name
	description
	is_saved
	tags
	recipes
	tools
	raw_parts
	forms
`;

export const stepRevisionAllColumns = `
	id
	step_definition_id
	created_at
	created_user
	updated_at
	plan
`;

export const stepInfoColumns = `
	id
	name
	description
	is_saved
	created_at
	updated_at
	current_revision_id
`;

export const stepDefinitionAllColumns = `
	${stepInfoColumns}
	current_revision {
		${stepRevisionAllColumns}
}
	step_revisions_aggregate {
		aggregate {
			count
		}
	}
`;



// ------------------------------------------------- Form -----------------------------------

export const formDefinitionAllColumns = `
	id
	created_at
	updated_at
	name
	foreign_table
	inventory_unique_type_id
	fields
`;

export const formInstanceAllColumns = `
	id
	created_at
	updated_at
	foreign_id	
	form_definition_id	
	values
`;

export const formInstanceDefinitionLink = `
	form_definition {
		${formDefinitionAllColumns}
	}
`;


export const formInstanceForeignObjects = `
	step_history {
		job_id
		job_step_index
		job {
			name
		}
		step_revision {
			step_info {
				name
			}
		}
	}
	inventory_unique {
		name
	}
	order {
		name
	}
`;




// ------------------------------------------------- Threads -----------------------------------



export const messageAllColumns = `
	id
	created_at
	updated_at
	user {
		first_name
		last_name
	}
	text
`;

export const threadAllColumns = `
	id
	messages {
		${messageAllColumns}
	}
`;


// ------------------------------------------------- Contacts -----------------------------------


export const contactAllColumns = `
	id
	created_at
	updated_at
	first_name
	last_name
	company_name
	email
	phone
	addresses
	note
`;

// ------------------------------------------------- Orders -----------------------------------


export const orderItemsAllColumns = `	
	id
	line_item
	qty	
	unit_cost
	status	
	part_id
	part {
		${partAllColumns}
	}
	part_txs (order_by: { created_at: desc }) {
		${partTransactionAllColumns}
	}
`;

export const orderAllColumns = `
	id
	created_at
	updated_at
	name
	status
	fulfillment_status
	start_at
	stop_at
	due_date
	po
	shipping_address
	billing_address
	contact_id
	contact {
		${contactAllColumns}
	}
	order_items {
		${orderItemsAllColumns}
	}
	threads {
		${threadAllColumns}
	}
	forms (
		order_by: { id: desc }
		where: { 
			form_definition: {
				foreign_table: { _eq: ${thingLookup.order.sharedTableId} }
			} 
		}
	) {
		${formInstanceAllColumns}
		${formInstanceDefinitionLink}
	}
`;

// ------------------------------------------------- Inventory Unique -----------------------------------


export const inventoryUniqueTypeAllColumns = `
	id
	name
	status
	stages: inventory_unique_type_stage {
		id
		type_id
		name
		status
	}
	form_definitions {
		${formDefinitionAllColumns}
	}
`;


export const inventoryUniqueAllColumns = `
	id
	created_at
	updated_at
	name
	job_id
	completed_job_ids
	status
	created_user_id
	created_user {
		${userReferenceColumns}
	}
	created_method
	type: inventory_unique_type {
		id
		name
	}
	stage_id
	stage: inventory_unique_type_stage {
		id
		name
	}
	threads {
		${threadAllColumns}
	}
	forms (
		order_by: { id: desc }
		where: { 
			form_definition: {
				foreign_table: { _eq: ${thingLookup.inventory_unique.sharedTableId} }
			} 
		}
	) {
		${formInstanceAllColumns}
		${formInstanceDefinitionLink}
	}
`;




// ------------------------------------------------- Step History -----------------------------------




export const stepHistoryYieldAllColumns = `
		id
		created_at
		step_history_id
		part_id
		qty_in
		qty_out
`;

export const stepHistoryAllColumns = `
	id
	job_id
	job {
		name
	}
	job_step_index
	step_id
	step {
		${stepAllColumns}
	}
	created_at
	start_at
	start_user {
		${userReferenceColumns}
	}
	stop_at
	stop_user {
		${userReferenceColumns}
	}
	tool_id
	tool {
		id
		name
		description
	}
	recipe_id
	recipe {
		id
		name
		description
	}
	inventory_unique_items
	threads {
		${threadAllColumns}
	}
	forms (
		order_by: { id: desc }
		where: { 
			form_definition: {
				foreign_table: { _eq: ${thingLookup.step_history.sharedTableId} }
			} 
		}
	) {
		${formInstanceAllColumns}
		${formInstanceDefinitionLink}
	}
	part_txs {
		${partTransactionAllColumns}
		
	}
	step_history_yields {
		${stepHistoryYieldAllColumns}
	}
`;


	// ------------------------------------------------- Job -----------------------------------

	
export const jobAllColumns = `
	id
	created_at
	updated_at
	start_at
	stop_at
	created_user_id
	created_user {
		${userReferenceColumns}
	}
	workflow_id
	workflow_options
	workflow {
		id
		created_at
		updated_at
		name
		description
	}
	deviation_tags
	name
	job_status
	steps
	current_step_index
	current_step {
		current_step_id
		step {
			${stepAllColumns}
		}
	}
	expense_category	
	step_histories_recent: step_histories (
		limit: 2, 
		order_by: { job_step_index: desc }
	) {
		${stepHistoryAllColumns}
	}
	threads {
		${threadAllColumns}
	}
	part_txs {
		${partTransactionAllColumns}
	}
	part_wips {
		${partWipAllColumns}
	}
`;