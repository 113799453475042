import React from 'react';

import { UITooltipIcon } from 'kit';

import { useQuery } from '@apollo/client';
import { GET_WORKFLOWS } from '../workflowFetch';

import { Workflow, StepPlan } from 'types';
import { useThingById } from './useThing';

/**
 * Checks and returns if a part is able to change whether it is raw or finished.
 * The preventing factor is if the part is used as raw or finished in a saved workflow or step.
 * Any existing jobs will be allowed to continue as-is, even if the part type is changed.
 * 
 * We'll only need to check this if the 
 * 
 * TODO: When we allow workflows to be archived, this should only check active workflows.
 * TODO (in a separate place): Workflows should check all parts to make sure proper types apply
 * TODO (in a separate place): Steps should check all parts to make sure proper types apply
 */
export function usePartTypeValidation(partId: number, originatingPartType: 'raw' | 'product'){
	
	const { item: part } = useThingById(originatingPartType === 'raw' ? 'partRaw' : 'partProduct', partId);
	
	const partTypeToValidate = originatingPartType === 'raw' ? 'product' : 'raw';
	const isValidatingPartTypeEnabled = part[`is_${partTypeToValidate}`];

	const { 
		data: { workflow: workflows } = { workflow: [] as Workflow[] }
	} = useQuery(GET_WORKFLOWS, { 
		skip: !(partTypeToValidate === 'product' && isValidatingPartTypeEnabled)
	});


	let canToggle = true;
	if (isValidatingPartTypeEnabled){
		if (partTypeToValidate === 'product') {
			for (let index = 0; index < workflows.length; index++) {
				const workflow = workflows[index];
			
				// check existence of products in workflows
				if (doesWorkflowContainProduct(workflow, partId)) {
					canToggle = false;
					break; // if any workflow fails, stop looping
				}
			}
		}
		else if (partTypeToValidate === 'raw') {
			// check existence of raw in steps
			//TODO:  just check if part.steps is empty or not
		}

	}

	
	const TogglePartTypeInfoTip = ({}: {} ) => {
		if (canToggle) { return null; }
		const infoText = partTypeToValidate === 'product' ? 
		'Disabled because there are workflows that utilize this product. To edit this setting, first remove this product from all saved workflows.'
		:
		'Disabled because there are steps that utilize this material. To edit this setting, first remove this material from all saved steps.'
		;
		return <UITooltipIcon tooltipContent={infoText} />
	}

	return {
		canToggleRaw: partTypeToValidate === 'raw' && canToggle,
		canToggleProduct: partTypeToValidate === 'product' && canToggle,
		TogglePartTypeInfoTip
	}
}

function doesWorkflowContainProduct(workflow: Workflow, productId: number) {
	if (workflow.workflow_options?.product_enabled) {
		const generatedProductIds = workflow.workflow_options.product?.fixed_part_ids ?? [];
		if (generatedProductIds.includes(productId)) {
			return true;
		}
	}
	return false;
}

function doesStepContainRawPart(plan: StepPlan, rawPartId: number) {
	return plan?.inventory_effect?.items?.filter( rawEffect => rawEffect.id === rawPartId ).length > 0;
}
