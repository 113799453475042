import React, { useMemo } from 'react';

import { Table } from 'components/Table/Table';
import { jobCols, transformJobData } from 'root/components/Table/tableRef';

import { useQuery } from '@apollo/client';
import { GET_JOBS } from '@constants/gql';

import { Job } from 'types';

const jobTableColumnDef = [
	jobCols.name, 
	jobCols.currentStep,
	jobCols.jobStepStatus,
	jobCols.workflow,
	jobCols.startAt,
	jobCols.progressText,
	jobCols.progressMeter
];


export function JobsTable(
) {
	const {
		loading,
		error: queryJobError,
		data: { job: jobs } = { job: [] as Array<Job> }
	} = useQuery(GET_JOBS, { 
		variables: { status: 'active' },
		fetchPolicy: 'cache-and-network'
	});

	const columns = useMemo(() => jobTableColumnDef, [loading, jobs.length]);

	let data = useMemo(() => transformJobData(jobs), [loading, jobs.length]);
	
	if (loading) { return <p>Loading...</p>; }
	else if (queryJobError) { return <p>Error :(</p>; }
	
		
	return (
		<Table
			columns={columns}
			data={data}
			hasSortableColumns
		/>
	)
}

