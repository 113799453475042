import React from 'react';
import styled from 'styled-components';

import { Link } from 'react-router-dom';
import { MainRouteName, FullRouteName, getRoutePath, LinkVars } from 'routing';

export const UIRoutedLink = (	
	{ text, linkToRoute, linkToVars, ...props }: {
		text: string
		linkToRoute: MainRouteName | FullRouteName
		linkToVars?: LinkVars
	} & UIRoutedLinkStyleProps
) => {
	const path = getRoutePath(linkToRoute, linkToVars);
	return (
		<StyledLink 
			to={path}
			{...props}
		>
			{text}
		</StyledLink>
	);
}


type UIRoutedLinkStyleProps = {
	display?: 'block'
	className?: string
}

const StyledLink = styled(Link)<UIRoutedLinkStyleProps>`
	text-decoration: none;
	color: ${props => props.theme.colors.primary};
	display: ${props => props.display};
	margin: 4px 0;
	cursor: pointer;

	/* mouse over link */
	&:hover {
		color: ${props => props.theme.colors.primaryDark};
		text-decoration: underline;
	}

	/* selected link */
	&:active {
		color: red;
	}
`;