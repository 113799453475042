import React, { useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { useTable, useFilters, useGlobalFilter, useSortBy, usePagination } from 'react-table'
import { UIFlexbox } from 'kit';
import { DropButton } from 'grommet';

import { Filter as FilterOutline } from '@styled-icons/heroicons-outline/Filter';
import { Filter as FilterSolid } from '@styled-icons/heroicons-solid/Filter';

import { SortIcons, convertSortStateToURL, convertURLToSortState } from './TableSort';
import { fuzzyTextFilterFn, DefaultColumnFilter, GlobalFilter, useURLFilter } from './TableFilters';
import { TablePagination } from './TablePagination';

import isEqual from 'lodash.isequal';

export function Table(
	props: {
		columns
		data
		/** hasSortableColumns:  Enables sorting by column */
		hasSortableColumns?: boolean
		/** hasFilterableColumns:  Enables input box where user can filter by individual column */
		hasFilterableColumns?: boolean
		/** hasGlobalFilter:  Enables input box where user can filter entire table */
		hasGlobalFilter?: boolean
		onRowSelect?: (rowObj: object, index: number) => void
		selectedIndex?: number
		/** hasPagination:  Enables more than first page */
		hasPagination?: boolean
		/** hasSelectablePageSize:  Shows a dropdown where user can choose how many rows per page. Only works if hasPagination is true 
		 * 		Currently disabled.
		*/
		hasSelectablePageSize?: boolean
		/** Highlights rows where id is in the supplied array. Only works with data with an id key. */
		selectedIds?: Array<number>
		defaultPageSize?: number
		/** Add some columns in addition to those provided by "columns" def. This may be useful for columns which need to reference other functions. */
		addColumnsStart?: Array<any>
	}
) {
	const filterTypes = React.useMemo( () => ({
		// Add a new fuzzyTextFilterFn filter type.
		fuzzyText: fuzzyTextFilterFn,
		// Or, override the default text filter to use
		// "startWith"
		text: (rows, id, filterValue) => {
			return rows.filter(row => {
				const rowValue = row.values[id]
				return rowValue !== void 0
				? String(rowValue)
					.toLowerCase()
					.startsWith(String(filterValue).toLowerCase())
				: true
			})
		}
	}), [] );

	const defaultColumn = React.useMemo( () => ({
		// Let's set up our default Filter UI
			Filter: DefaultColumnFilter
	}), [] );

	
	const { globalFilterText, searchParams, updateURLParam } = useURLFilter();
	const urlFilters = props.columns.filter(col => typeof searchParams.get(col.accessor) === 'string').map( col => {
		return {
			id: col.accessor,
			value: searchParams.get(col.accessor)
		}
	})
	const urlSortArray = convertURLToSortState(searchParams.get('sort'));

	const tableInstance = useTable(
		{
			columns: props.columns,
			data: props.data,
			//@ts-ignore
			defaultColumn, // Be sure to pass the defaultColumn option
			filterTypes,
			globalFilter: fuzzyTextFilterFn,
			initialState: { 
				//@ts-ignore
				pageIndex: 0, 
				pageSize: props.defaultPageSize, 
				globalFilter: useMemo(() => globalFilterText, [globalFilterText]),
				filters: useMemo(() => urlFilters, [searchParams.toString(), props.data.length /*Needed this to get custom form columns to pre-filter */]),
				sortBy: useMemo(() => urlSortArray, [searchParams.toString(), props.data.length /*Needed this to get custom form columns to pre-filter */])
			},
			manualPagination: !props.hasPagination
		},		
		useGlobalFilter, 
		useFilters,
		useSortBy,
		usePagination,
		hooks => {
			if (props.addColumnsStart) {
				hooks.visibleColumns.push(columns => {
					return columns.length === 0 ? columns :
					props.addColumnsStart.concat(columns)
				})
			}
		}
	)

	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		//rows,
		//@ts-ignore
		page, // Instead of using 'rows', we'll use page,
		// which has only the rows for the active page

		prepareRow,
		state,
		//@ts-ignore
		filteredRows,
		//@ts-ignore
		preGlobalFilteredRows,
		//@ts-ignore
		setGlobalFilter,
		//@ts-ignore
		setAllFilters,
		//@ts-ignore
		toggleSortBy
	} = tableInstance;


	function onRowClick(row, newRowIndex){
		typeof props.onRowSelect === 'function' && props.onRowSelect(row.original, newRowIndex);
	}

	useEffect(() => {
		// useMemo on the initialState object didn't seem to update filters or sorting when query strings were appended, so do it here
		// Need this for when the query string gets added to the path from the store, so it actually renders the table without filters and sorting once, then they need to be applied once they are interpreted from the url
		//@ts-ignore
		if (!isEqual(urlFilters, state.filters)) {
			setAllFilters(urlFilters)
		}
		//@ts-ignore
		if (!isEqual(globalFilterText, state.globalFilter)) {
			setGlobalFilter(globalFilterText)
		}
		//@ts-ignore
		if (!isEqual(urlSortArray, state.sortBy)) {
			urlSortArray.forEach( sortObj => toggleSortBy(sortObj.id, sortObj.desc, true))
		}
	}, [searchParams.toString(), globalFilterText])

	useEffect(() => {
		//@ts-ignore
		if (urlSortArray.length === 0 && state?.sortBy?.length === 0) {
			// do nothing, this avoids setting an empty query string on first render
		}
		else {
			updateURLParam('sort', convertSortStateToURL(state));
		}
		//@ts-ignore
	}, [JSON.stringify(state?.sortBy)])

	return ( <>
		<TableContainer>
			<TableHeaderContainer flexAlign="center">
				{props.hasGlobalFilter &&
					<GlobalFilter
						preGlobalFilteredRows={preGlobalFilteredRows}
						//@ts-ignore
						globalFilter={state.globalFilter}
						setGlobalFilter={setGlobalFilter}
					/>
				}
				
				{//@ts-ignore
				(state.filters.length > 0 || state.globalFilter) &&
					//@ts-ignore
					<div>{preGlobalFilteredRows.length} rows have been filtered down to <strong>{filteredRows.length}</strong> using {state.filters.length + (state.globalFilter ? 1 : 0)} filter(s)</div>
				}
			</TableHeaderContainer>

			<StyledTable {...getTableProps()}>
				<thead>
					{headerGroups.map(headerGroup => ( <tr {...headerGroup.getHeaderGroupProps()}>
						{headerGroup.headers.map((column: any) => {
							const columnName = column.render('Header');
							return ( 
								<th 
									className={column.className}
									{...column.getHeaderProps()} 
								>
									{columnName}
									
									
									{props.hasSortableColumns && columnName.length > 0 &&
										<span className="sort-icon">
											{/*
											//@ts-ignore */}
											<SortIcons column={column} isSortAlreadyAppliedAnywhere={state?.sortBy?.length > 0} />
										</span>
									}
									
									{props.hasFilterableColumns && columnName.length > 0 &&
										<DropButton
											dropContent={
												<FilterMenu>
													<div className="filter-title">Filter {column.render('Header')}</div>
													{column.render('Filter')}
												</FilterMenu>
											}
											dropAlign={{ top: 'bottom' }}
											dropProps={{ elevation: 'large', overflow: 'unset' }}
										>
											{column.filterValue ? <FilterSolid className="active" /> : <FilterOutline />}
										</DropButton>
									}
									
								</th>
							)
						})}
					</tr> ))}

				</thead>


				<tbody {...getTableBodyProps()}>
					{page.map(
						(row, i) => {
						//@ts-ignore
						return prepareRow(row) || (
							<tr 
								{...row.getRowProps()} 
								onClick={ onRowClick.bind(this, row, i) } 
								className={(props.selectedIds && props.selectedIds.indexOf(row.original.id) > -1) || (props.selectedIndex === i) ? 'selected' : ''}
							>
								{row.cells.map(cell => {
									return <td {...cell.getCellProps({ className: cell.column.className })}>{cell.render('Cell')}</td>
								})}
							</tr>
						)
					}
					)}
				</tbody>
			</StyledTable>
		</TableContainer>
		
		{props.hasPagination && 
			<TablePagination 
				tableInstance={tableInstance}
				hasSelectablePageSize={props.hasSelectablePageSize}
			/>
		}
	</> )
}


Table.defaultProps = {
	hasPagination: true,
	defaultPageSize: 50
}


const TableContainer = styled.div`
	margin: 20px 0 30px;
	:first-child {
		margin-top: 10px;
	}
	width: fit-content;
	min-width: 100%;
	box-sizing: border-box;
	padding-right: 15px;
`;

const TableHeaderContainer = styled(UIFlexbox)`
	
`;

const StyledTable = styled.table`
	text-align: left;
	border-spacing: 0;
	font-family: ${props => props.theme.font.table};
	table-layout: fixed;
	min-width: 100%;
	font-size: 14px;
	/** vv Needed to get the border radius to show up properly */
	border-collapse: separate; 
	/** ^^ Needed to get the border radius to show up properly */
	

	@media (hover: hover) and (pointer: fine) { /*Desktops with a mouse*/
		.show-on-hover svg {
			opacity: 0.4;
		}
	}

	.fit-width {
		width: 1px;
	}

/** Table border ---------------------------------------------------------- */
/** This needs to be done piecemeal instead of applying a border to the parent table 
	because the border radius wouldn't apply properly with sticky position
	(overflow hidden was needed for the radius, but that caused sticky to not work) */
	th {
		/* Apply top border to the <th> */
		border-top: 1px solid ${props => props.theme.colors.colorDefinitions.grey3};
		border-right: 1px solid ${props => props.theme.colors.colorDefinitions.grey3};
		&:first-child {
			border-top-left-radius: 12px;
		}
		&:last-child {
			border-top-right-radius: 12px;
		}
	}
	tr:last-child { /**bottom border */
		td {
			border-bottom: 1px solid ${props => props.theme.colors.colorDefinitions.grey3};
			&:first-child {
				border-bottom-left-radius: 12px;
			}
			&:last-child {
				border-bottom-right-radius: 12px;
			}
		}
	}

	th:last-child, td:last-child {
		/* Right border */
		border-right: 1px solid ${props => props.theme.colors.colorDefinitions.grey3};
		padding-right: 20px;
	}

	th:first-child, td:first-child {
		/* left border */
		border-left: 1px solid ${props => props.theme.colors.colorDefinitions.grey3};
		padding-left: 20px;
	}
/** ^^ Table border ---------------------------------------------------------- */



	tr {
		td {
			background-color: white; /* This seems redundant, but specify this for sticky columns*/
		}
		&.selected td {
			background-color: ${props => props.theme.colors.primaryLight2};
		}
		&:hover td {
			background-color: ${props => props.theme.colors.primaryLight1};
		}
		&:hover td.show-on-hover svg {
			opacity: 1;
		}
		:not(:last-child) td {
			border-bottom: 1px solid ${props => props.theme.colors.colorDefinitions.grey2};
		}
	}


	th, td {
		font-size: 1em;
		padding: 14px 14px;
		
		&.emphasis {
			/* vvv Needed for sticky */
			position: sticky;
			${props => props.theme.positionMainContainerCounter.left}
			.ReactModalPortal & { /* Tables within modal don't need the main container offset */
				left: 0;
			}
			z-index: 1;
			/* ^^^ Needed for sticky */
			${props => props.theme.shadow.rightOnly2}
		}
		&.left-align {
			text-align: left;
		}
		&.center-align {
			text-align: center;
		}
	}

	.emphasis {
		min-width: 80px; /*True min width*/
		width: 200px; /*Expand to this width if there is room on the screen*/
	}
	th.emphasis {
		z-index: 2;
		${props => props.theme.shadow.bottomOnly2};
	}

	th {
		white-space: nowrap; /* force onto one line */
		border-bottom: 0;
		font-weight: ${props => props.theme.font.boldWeight};
		/* vvv Needed for sticky */
		position: sticky;
		${props => props.theme.positionMainContainerCounter.top}
		.ReactModalPortal & { /* Tables within modal don't need the main container offset */
			/* top: 0; */
		}
		z-index: 1;
		background: white;
		/* ^^^ Needed for sticky */
	
		svg {
			height: 1em;
			width: 1em;
			margin-left: 4px; 
			color: ${props => props.theme.colors.colorDefinitions.grey4};
			&.active {
				color: ${props => props.theme.colors.error};
			}
		}
		
		${props => props.theme.shadow.bottomOnly2};
	}

	.sort-icon {
		margin-left: 5px;
	}

	.one-line {
		white-space: nowrap; /* force onto one line */
	}
`;

const FilterMenu = styled.div`
	padding: 30px 30px 40px;
	min-width: 300px;
	border: 1px solid ${props => props.theme.colors.colorDefinitions.grey3};
	box-sizing: border-box;
	border-radius: 10px;
	.filter-title {
		margin-bottom: 10px;
	}
`;
