import React from 'react';
import { UIInput, UIDateTimePicker, UISelect, UITextArea, UIRadioList } from 'kit';
import { FormFieldSpec, UISelectOption } from 'types';

export const RenderedFormField = (
	{ 
		formFieldDef, value, 
		disabled = false, 
		skipRegister = false, 
		isLastAndScrollSelect = false,
		spoofSelectValueIfNotFound = true,
		autoFocus = false
	}: {
		formFieldDef: FormFieldSpec
		value: 'string' | 'number' | 'boolean'
		disabled?: boolean
		skipRegister?: boolean
		isLastAndScrollSelect?: boolean
		spoofSelectValueIfNotFound?: boolean // You would pass in false to not apply an invalid value. This makes sense for options that are Things from our database
		autoFocus?: boolean
	}
) => {
	let formattedOptions: UISelectOption[] = formFieldDef.options?.map(option => { 
		if (typeof option === 'string') { // option is coming from a custom, user-generated form - need to transform into an object
			return { id: option, name: option }; 
		}
		return option; // object has already been provided, no need to transform.
	}) ?? [];

	if (spoofSelectValueIfNotFound) {
		const currentValueExistsInOptions = formattedOptions.map(option => option.id).includes(value);
		if (!currentValueExistsInOptions && value /** should only do this if value exists (ie, is not null or undefined) */) {
			formattedOptions.push({ id: value, name: value, isDisabled: true });
		}
	}

	return (
		<React.Fragment>
			{formFieldDef.input_type === 'input' &&
				<UIInput
					name={formFieldDef.key}
					label={formFieldDef.name}
					defaultValue={value}
					required={formFieldDef.is_required} 
					disabled={disabled}
					skipRegister={skipRegister}
					autoFocus={autoFocus}
				/>
			}
			{formFieldDef.input_type === 'datetime' &&
				<UIDateTimePicker
					name={formFieldDef.key}
					label={formFieldDef.name}
					defaultValue={value}
					required={formFieldDef.is_required} 
					disabled={disabled}
					skipRegister={skipRegister}
					isPastOnly
				/>
			}
			{formFieldDef.input_type === 'date' &&
				<UIDateTimePicker
					name={formFieldDef.key}
					label={formFieldDef.name}
					defaultValue={value}
					required={formFieldDef.is_required} 
					disabled={disabled}
					skipRegister={skipRegister}
					isDateOnly
				/>
			}
			{formFieldDef.input_type === 'select' &&
				<UISelect
					name={formFieldDef.key}
					label={formFieldDef.name}
					defaultValue={value}
					required={formFieldDef.is_required}
					disabled={disabled}
					skipRegister={skipRegister} 

					options={formattedOptions}
					scrollOnOpen={isLastAndScrollSelect}
				/>
			}
			{formFieldDef.input_type === 'textarea' &&
				<UITextArea
					name={formFieldDef.key}
					label={formFieldDef.name} 
					default={value}
					required={formFieldDef.is_required} 
					disabled={disabled}
					skipRegister={skipRegister}
				/>
			}
			{formFieldDef.input_type === 'radio' &&
				<UIRadioList
					name={formFieldDef.key}
					label={formFieldDef.name}
					defaultValue={value} 
					required={formFieldDef.is_required}
					disabled={disabled}
					skipRegister={skipRegister}

					options={formattedOptions}
				/>
			}
		</React.Fragment>
	);
};
