import React from 'react';

import styled, { css } from 'styled-components';
import { UIButton } from 'kit';
import { FormInputContainer } from './FormLabel'; // Had to import this directly from the file due to index order
import { StyledDateWrapper } from './DateTimePicker'; // Had to import this directly from the file due to index order


export const SaveButtonBlock = (	
	{ 
		layout = 'horizontal',
		isTypeSubmit = true,
		onSave, overrideSaveText, onCancel, className
	}: {
		layout?: 'horizontal' | 'vertical'
		isTypeSubmit?: boolean
		overrideSaveText?: string
		onSave?
		onCancel
		className?: string
	}
) => {
	
	return (
		<ButtonContainer layout={layout} className={className}>
			<UIButton 
				type={isTypeSubmit ? 'submit' : 'button'}
				label={overrideSaveText && overrideSaveText || 'Save'}
				onClick={onSave}
			/>
			<UIButton 
				type={isTypeSubmit ? 'submit' : 'button'}
				label="Cancel"
				onClick={onCancel}
				quiet
			/>
		</ButtonContainer>
	);
}


const ButtonContainer = styled.div<{ layout: 'horizontal' | 'vertical' }>`
	${FormInputContainer} + & {
		margin-top: 3rem; /**Collapsing margin */
	}
	${StyledDateWrapper} + & {
		margin-top: 1rem; /**Non Collapsing margin */
	}
	${props => props.layout === 'horizontal' && css`
		white-space: nowrap;
		button {
			margin: 0;
			&:not(:last-of-type) {
				margin-right: 1rem;
			}
		}
	`};
`