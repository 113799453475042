import React from 'react';
import styled from 'styled-components';

import { UIPage, UIFlexbox, UIFlexChild, UIHeading } from 'kit';

import { NewLinkThingToStep } from 'root/components/StepLink/NewLinkChip';

import { useParams } from 'react-router';
import { useThingById, useThingsIn } from 'hooks';
import { LinkChip } from 'root/components/StepLink/LinkChip';
import { StepLinkableThing } from 'root/components/StepLink/LinkStepsFromItem';
import { getStepColName } from 'root/services/stepLink';

export function SavedStepDetailPage() {
	const { id } = useParams();
	const stepId = parseInt(id);

	return (
		<UIPage id="steps" contentContainerSize="large">
			<UIFlexbox>
				<UIFlexChild>
					<UIHeading level="4">Recipes</UIHeading>
					<LinkedAssets stepId={stepId} linkedThing="recipe" />
				</UIFlexChild>
				<UIFlexChild>
					<UIHeading level="4">Tools</UIHeading>
					<LinkedAssets stepId={stepId} linkedThing="tool" />
				</UIFlexChild>
				<UIFlexChild>
					<UIHeading level="4">Materials</UIHeading>
					<LinkedAssets stepId={stepId} linkedThing="partRaw" />
				</UIFlexChild>
				<UIFlexChild>
					<UIHeading level="4">Forms</UIHeading>
					<LinkedAssets stepId={stepId} linkedThing="form" />
				</UIFlexChild>
			</UIFlexbox>
		</UIPage>
	)
}

export function LinkedAssets (
	{ stepId, linkedThing }: {
		stepId: number
		linkedThing: StepLinkableThing
	}
) {
	const { item: step } = useThingById('step', stepId);

	const stepColName = getStepColName(linkedThing);
	
	const { items } = useThingsIn(linkedThing, step[stepColName], { skip: !step.id });

	return (
		<ChipContainer>
			<NewLinkThingToStep stepId={stepId} linkingThing={linkedThing} />
			{items.map(item => <LinkChip key={`r${item.id}`} linkedThingName={linkedThing} linkedId={item.id} step={step} originatingType="step" />)}
		</ChipContainer>
	)
}


const ChipContainer = styled.div`
	.MuiChip-root {
		display: flex;
		width: fit-content;
	}
`;
