import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';

import { UIInputControlled, FormInputContainer } from 'kit';

import { StepCard } from 'components/WorkflowEditor/StepCard';

import { UIDraggable, UIDroppable, useThingsAll } from 'hooks';


import matchSorter from 'match-sorter'
import { StepPlan, Step } from 'types';
import { AddStepShortcutModal } from 'pages';
import { WorkflowEditorContext } from '.';
import { convertStepToDragDropSourceObj } from './useWorkflowEdit';

export const StepSourceList = (
	{  }: {
	}
) => {
	const { setDragDropSourceList, isAmendment } = useContext(WorkflowEditorContext);

	const { items: steps } = useThingsAll('step');

	const [filterText, setFilterText] = useState('');
	const [filteredSteps, setFilteredSteps] = useState([]);
	
	useEffect(() => {
		handleFilterChange(filterText) // this handles both the initial load of all the steps, and when a new step is added.
	}, [steps.length])

	function handleFilterChange(newFilter){
		setFilterText(newFilter);

		const keysToFilterAgainst: Array<keyof Step> = ['name', 'description'];

		const filteredSet = matchSorter(steps, newFilter, { keys: keysToFilterAgainst });
		setDragDropSourceList(filteredSet.map( step => convertStepToDragDropSourceObj(step, isAmendment) )  )
		// Don't add the creatable dummy here since you're already typing
		setFilteredSteps(filteredSet);
	}

	const filterExactMatchFound = steps.map(step => step.name.toLowerCase()).includes(filterText.toLowerCase());

	if (steps.length === 0) { return null; }
	return ( 
	<>
		<PaddedDiv>
			<UIInputControlled 
				name="step-filter" 
				placeholder="Type to filter or add a new step..." 
				type="search" 
				value={filterText} 
				onChange={handleFilterChange} 
				disableAutocomplete 
			/>
			{ filteredSteps.length < steps.length && <p>Showing {filteredSteps.length} steps (out of a possible {steps.length})</p> }
		</PaddedDiv>

		<SmallerHandleSpacingDroppable 
			name="source" 
			readonly={true} 
		>
			{filteredSteps.map(
				(savedStep, index) => {
					return (
						<UIDraggable
							index={index}
							name={ 'src_' + (savedStep.id || 0).toString() + '_' + index}
							key={ 'src_' + (savedStep.id || 0).toString() + '_' + index}
							clone={true}
							content={
								<EqualMarginStepCard
									stepInfo={savedStep}
									stepPlan={{} as StepPlan}
									isCompact
								/>
							}
						/>
					)
				}
			)}
		</SmallerHandleSpacingDroppable>

		{/* {filterText.length > 0 && !filterExactMatchFound && <StyledButton icon={<AddCircle />} label={`Create new step "${filterText}"`} />} */}
		{filterText.length > 0 && !filterExactMatchFound && 
			<AddStepShortcutModal 
				overrideLabel={`Create new step "${filterText}"`} 
				savedObj={{ name: filterText }} 
			/>
		}
	</>
	)
}



const PaddedDiv = styled.div`
	padding: 1rem;
	${FormInputContainer} {
		margin-bottom: 1rem;
	}
`;

const EqualMarginStepCard = styled(StepCard)`
	margin: 8px 0;
`;

const SmallerHandleSpacingDroppable = styled(UIDroppable)`
	.drag-handle {
		margin: 0 6px 0 0;
	}
`;
