import React from 'react';
import styled, { css } from 'styled-components';
import { flexboxStyles, FlexboxProps, containerStyles, ContainerProps, MarginProps, PaddingProps, paddingStyles, marginStyles } from '@constants';

type UIFlexboxProps = FlexboxProps & ContainerProps & PaddingProps & MarginProps & {
	children
}

const Flexbox = styled.div<UIFlexboxProps>`
	${flexboxStyles}
	${containerStyles}
	${paddingStyles}
	${marginStyles}
`;


export const UIFlexbox = (
	props: UIFlexboxProps
) => {
	return (<Flexbox {...props} asFlexbox={true} />)
};

// -------------------------------------------------------------------------------------

type FlexChildProps = FlexboxProps & ContainerProps & PaddingProps & MarginProps & {
	flex?: '1' | '2' | '3'
	height?
}

const FlexChild = styled.div<FlexChildProps>`
	flex: ${props => props.flex || '1'};
	/*width: 100%;*/ /*Not sure if this does anything*/
	${flexboxStyles}
	${containerStyles}
	${paddingStyles}
	${marginStyles}
	${props => props.height && css`height: {props.height};`};
`;

export const UIFlexChild = (
	props: 
		FlexChildProps 
	& { 
		children
	} 
) => (
	<FlexChild {...props} />
);

UIFlexChild.defaultProps = {
	asFlexbox: false
}