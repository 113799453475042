import React, { useState } from 'react';
import { UIHeading, UIPage, UIFlexbox, UIButton, UIMenu, useModal } from 'kit';

import { TabMenu } from 'components/Navigation/TabMenu';
import { RightPortal } from 'components/Navigation/RightPortal';

import { InventoryUniqueStepHistory, InventoryUniqueJobHistory } from 'components/InventoryUnique/InventoryUniqueStepHistory';
import { ThreadsList, ThreadsContainer } from 'components/Messages/Thread';
import { FormInstanceAll } from 'components/Form/FormInstance';
import { FormInstanceModal_NewForm } from 'components/Form/FormInstanceModal';

import { CardViewChange } from 'components/common/CardViewChange';
import { ChangeSpec } from 'components/common/useChange';

import { useQuery } from '@apollo/client';
import { GET_INVENTORY_UNIQUE } from 'services/inventoryUniqueFetch';
import { changeInventoryUniqueItem } from 'services/inventoryUniqueMutate';


import { useInvUniqueType } from 'hooks';
import { useParams } from 'react-router-dom';
import { Thread, InventoryUniqueItem, InventoryUniqueChanges, FormDefinition } from 'types';
import { invUniqueStatusOptions } from 'root/components/WorkflowEditor/WorkflowOptionsInventoryUnique';

export function InventoryUniqueItemPage() {	
	const { id } = useParams();
	const itemId = parseInt(id);
	
	const { 
		loading,
		error,
		data: { inventory_unique: [item] } = { inventory_unique: [{} as InventoryUniqueItem] }
	} = useQuery(GET_INVENTORY_UNIQUE, { 
		variables: { id: itemId }
	});
	
	const threads: Thread[] = item.threads || [];

	if (loading) { return <div>Loading...</div> }
	if (error) { return <div>Error</div> }

	let jobIds = item.completed_job_ids;
	if (item.job_id > 0) { jobIds = jobIds.concat([item.job_id]) }

	
	return (
		<UIPage id="unique-inventory-detail">
			<UIFlexbox width="100%" flexAlign="stretch" flexWrap="wrap" childrenGrow="67/33">
				<InvUniqueDetailEditView item={item} />
				<InventoryUniqueJobHistory jobIds={jobIds} />
			</UIFlexbox>

			<FormInstanceAll
				forms={item.forms}
				isEditable
				formType="inventory_unique"
			/>

			<InvUniqueAddForm item={item} />

			<InventoryUniqueStepHistory jobIds={jobIds} itemId={item.id} />

			<RightPortal>
				<TabMenu					
					comment={
						<ThreadsContainer flex="1">
							<UIHeading>Comments</UIHeading>
							<ThreadsList 
								threads={threads} 
								referenceType="inventory_unique" 
								referenceId={itemId}
							/>
						</ThreadsContainer>
					}
				/>
			</RightPortal>
			
		</UIPage>
	)
}


const InvUniqueDetailEditView = (
	{ item }
	: {
		item: InventoryUniqueItem
	}
) => {
	const [hasEditWindowBeenOpened, setHasEditWindowBeenOpened] = useState(false);
	const { invUnType } = useInvUniqueType(item.type.id, !hasEditWindowBeenOpened);

	const specList: ChangeSpec<InventoryUniqueItem, InventoryUniqueChanges>[] = [
		{
			key: 'name'
		},
		{
			key: 'stage_id',
			name: 'Stage',
			valueDisplayAccessor: 'stage.name',
			input_type: 'select',
			options: invUnType.stages || []
		},
		{
			key: 'status',
			input_type: 'select',
			isEditDisabled: item.status === 'processing',
			options: invUniqueStatusOptions
		},
		{
			key: 'created_at',
			isEditDisabled: true,
			formatValue: 'datetime'
		},
		{
			key: 'updated_at',
			isEditDisabled: true,
			formatValue: 'datetime'
		}
	]



	return (
		<CardViewChange
			title={`${item?.type?.name} ${item.name} Details`} 
			specList={specList} 
			item={item} 
			onEditOpen={setHasEditWindowBeenOpened.bind(this, true)}
			saveFunction={changeInventoryUniqueItem}
		/>
	)
}

const InvUniqueAddForm = (
	{ item }
	: {
		item: InventoryUniqueItem
	}
) => {
	const { invUnType } = useInvUniqueType(item.type.id);
	const modalProps = useModal<FormDefinition>();	

	const unusedFormDefs = invUnType.form_definitions?.filter( formDef => {
		return !item.forms?.map( formInstance => formInstance.form_definition_id ).includes(formDef.id)
	}) ?? [];

	function showForm(formDef: FormDefinition){
		modalProps.setModalData(formDef);
		modalProps.toggleModal();
	}
	
	if (unusedFormDefs.length === 0) { return null; }
	return (
		<>
			{ unusedFormDefs.length === 1 
				?
				<UIButton 
					label={`Populate Form "${unusedFormDefs[0].name}"`} 
					onClick={showForm.bind(this, unusedFormDefs[0])}
					outline 
					margin="mediumVertical"
				/>
				:
				<UIMenu
					options={unusedFormDefs.map( formDef => 
						(
							{ label: `Populate Form "${formDef.name}"`, onClick: showForm.bind(this, formDef) }
						)
					)}
					overrideButton={<UIButton label="Populate New Form" outline margin="mediumVertical" />}
				/>
			}

			<FormInstanceModal_NewForm 
				modalProps={modalProps}
				formDefinition={modalProps.modalData}
				referenceId={item.id}
			/>
		</>
	)
}