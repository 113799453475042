import React, { useContext } from 'react';

import { UITabs } from 'kit';

import { CheckCircle } from '@styled-icons/boxicons-solid/CheckCircle';
import { Circle } from '@styled-icons/boxicons-regular/Circle';
import { ErrorWarning } from '@styled-icons/remix-line/ErrorWarning';

import { stepFormCategoryLookup, StepFormCategory } from '@constants';
import { theme } from 'theme';
import { StepFormContext } from './StepFormContext';


export const StepFormTabs = () => {
	const { 
		tabContext, sectionIsTracked, sectionIsFormErrored, validationErrors
	} = useContext(StepFormContext);

	let tabDefs = [];
	let catName: StepFormCategory; // Need to cast the type explicitly here because typescript won't automatically identify it in the for in syntax
	for (catName in stepFormCategoryLookup) {
		if (!stepFormCategoryLookup.hasOwnProperty(catName)) { return; }
		
		const lookup = stepFormCategoryLookup[catName];
		const isTracked = sectionIsTracked[catName];
		const isErrored = sectionIsFormErrored[catName] || validationErrors?.linkErrors?.hasOwnProperty(catName);
		let icon = <Circle size={18} />
		
		if (isTracked) { icon = <CheckCircle size={18}  color={theme.colors.primaryLight2} /> }
		if (isErrored) { icon = <ErrorWarning size={18} color={theme.colors.error} /> }
		
		tabDefs.push({
			label: <>{icon} {lookup.display} </>,
			key: `${catName}_${isTracked}_${isErrored}`
		});
	}

	return (
		<UITabs
			vertical
			activeTabBackground="primaryLight"
			tabContext={tabContext}
			tabDefs={tabDefs}
		/>
	)
}