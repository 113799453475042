import React, { useContext } from 'react';
import styled from 'styled-components';

import { UIFlexbox, UIFlexChild, UIIconButton, UIHeading } from 'kit';

import { SideBar } from '@styled-icons/remix-line/SideBar';
import { Menu } from '@styled-icons/boxicons-regular/Menu';

import logo from 'root/img/logo192.png';
import { PanelContext } from './PanelState';

const StyledFlexbox = styled(UIFlexbox)`
	position: sticky;
	left: 0;
	background-color: white;
	margin-bottom: 24px;
`;

const StyledFlexChild = styled(UIFlexChild)`
	padding-left: 16px;
`;

export const MobileNavbar = (
) => {
	const { openLeftMenu, isRightPanelUsed, openRightPanel } = useContext(PanelContext);

	return (
		<>
			<StyledFlexbox flexAlign="center">
				<img src={logo} height="24" />
				<StyledFlexChild>
					<UIHeading level="6" noMarginBottom>MES</UIHeading>
				</StyledFlexChild>

				{isRightPanelUsed && <UIIconButton icon={<SideBar/>} onClick={openRightPanel} />}
				<UIIconButton icon={<Menu/>} onClick={openLeftMenu}/>
			</StyledFlexbox>
		</>
	);
}