import React from 'react';
import styled from 'styled-components';

import { FormInputContainer, FormLabel } from 'kit';

import { NewLinkStepToThing } from './NewLinkChip';
import { LinkChip } from './LinkChip';

import { updateStepsFromItem } from 'root/services/stepLink';

import { useQuery } from '@apollo/client';
import { GET_STEPS_FOR_RECIPE, GET_STEPS_FOR_TOOL, GET_STEPS_FOR_PART, GET_STEPS_FOR_FORM } from '@constants/gql';


import { Step } from 'types';
import { ThingName } from '@constants';
import { useDynamicList } from 'hooks';


export type StepLinkableThing = Extract<ThingName, 'recipe' | 'tool' | 'partRaw' | 'form'>


export const LinkStepsFromItem = React.forwardRef((
	{ parameterName, linkingId, addStepToPending }: {
		parameterName: StepLinkableThing
		linkingId: number
		addStepToPending?: Step //Use this to automatically add a specific step to the list of linked steps. For example, when creating a new recipe from a step, you would want the recipe to link the originating step by default.
	}
, ref) => {
	let FETCHQUERY;
	switch (parameterName) {
		case 'recipe':
			FETCHQUERY = GET_STEPS_FOR_RECIPE;
			break;
		case 'tool':
			FETCHQUERY = GET_STEPS_FOR_TOOL;
			break;
		case 'partRaw':
			FETCHQUERY = GET_STEPS_FOR_PART;
			break;
		case 'form':
			FETCHQUERY = GET_STEPS_FOR_FORM;
			break;
		default:
			break;
	}
	const {
		data: { step: linkedSteps } = { step: [] as Step[] }
	} = useQuery(FETCHQUERY, {
		variables: {
			id: linkingId
		},
		skip: !(linkingId > 0)
	});

	// The parent can use this function via ref
	/**
	 	Usage: 
		1. From the parent component, useRef with
		const linkStepsRef = React.useRef<{ updateStepsFromItem(itemId: number): void }>();
		
		2.  Make sure to attach a ref to the instance
		<LinkStepsFromItem
			ref={linkStepsRef} // <---
			parameterName={parameterName}
			linkingId={savedObj.id}
		/>

		3.  Then, when you want to call the function:
		linkStepsRef.current.updateStepsFromItem();

		3a. Make sure you call that function before this component gets unmounted. (ie, close the modal after the function gets called).
			Because the function lives inside of this component, this component must still be mounted in order for the function to run
	 */ 
	React.useImperativeHandle(ref, () => ({
		updateStepsFromItem(itemId: number) {
			updateStepsFromItem(parameterName, itemId, linkedSteps.map(step => step.id), pendingSteps.map(step => step.id));
		}
	}));

	
	// const dynamicListContext =  useDynamicList<ThingTypes[K]>(linkedSteps/*, { onUpdate: updateFormValue }*/);
	const dynamicListContext =  useDynamicList<Step>(linkedSteps.concat(addStepToPending ? [addStepToPending] : []));
	const { rows: pendingSteps, addRow, deleteRow } = dynamicListContext;

	
	return (
		<FormInputContainer>
			{/* 
			//@ts-ignore */}
			<FormLabel label="Linked Steps"/>
			<ChipContainer>
				{pendingSteps.map( (step, index) => 
					(<LinkChip 
						key={`t${step.id}`} 
						step={step} 
						linkedThingName={parameterName} 
						linkedId={linkingId} 
						originatingType={parameterName} 
						onDelete={deleteRow.bind(this, index)}
					/>)
				)}
				<NewLinkStepToThing
					linkedSteps={pendingSteps}
					thingId={linkingId}
					linkingThing={parameterName}
					onSelect={addRow}
				/>
			</ChipContainer>
		</FormInputContainer>
	)
});


const ChipContainer = styled.div`
	width: 400px;
	max-width: 100%;
	.MuiChip-root {
	}
`;
