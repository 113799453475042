import React from 'react';
import styled from 'styled-components';

interface HeadingProps {
	level?: '1' | '2' | '3' | '4' | '5' | '6'
	children?
	className?
	noMarginBottom?: boolean
}


export const UIHeading = (
	props: HeadingProps
) => {
	// @ts-ignore
	return <StyledHTag as={`h${props.level}`} {...props} />
}

UIHeading.defaultProps = {
	level: 2
}

export const StyledHTag = styled.div<HeadingProps>`
	font-family: ${props => props.theme.font.title};
	font-weight: ${props =>props.theme.font.boldWeight};
	margin-bottom: ${props => props.noMarginBottom && 0};
`;