export function getObjectFromArray<ObjectType, KeyName extends keyof ObjectType>(
	arrayOfObjects: Array<ObjectType> = [], 
	keyName: KeyName, 
	keyValue: ObjectType[KeyName]
) {
	const filteredArray: ObjectType[] = arrayOfObjects.filter( item => item?.[keyName] === keyValue );
	return filteredArray[0] || {} as ObjectType;
}

export function deepCopy<ObjectType>(object: ObjectType = {} as ObjectType){
	return JSON.parse(JSON.stringify(object)) as ObjectType;
} 

export function stringifyArrayByKey<ObjectType, KeyName extends keyof ObjectType>(
	arrayOfObjects: ObjectType[], 
	keyName: KeyName
) {
	return arrayOfObjects.map( obj => obj[keyName]).toString();
}

export function removeFromArrayByValue(arr: any[] = [], valueToRemove: string | number) {
	return arr.filter(item => item !== valueToRemove);
}

export function removeDuplicatesFromArray(values: any[]) {
	const uniqueValues = [...new Set(values)];
	return uniqueValues;
}