import React, { useState } from 'react';

import { UIButton, UIFlexbox, UIInputControlled } from 'kit';

import { readString } from 'react-papaparse'
import { FormFieldSpec } from 'types';


export const useParse = (formFields: FormFieldSpec[]) => {
	
	const [parsedObject, setParsedObject] = useState({});
	const [pastedText, setPastedText] = useState('');
	const [parseKey, setParseKey] = useState(0);
	const [isInputShown, setIsInputShown] = useState(true);


	// from order draft
	function parseOnFormDefinition(textToParse: string) {
		const result = readString(textToParse).data[0];
		let newValues = {};
		formFields.filter( def => def.status !== 'archive' )
			.forEach( (fieldDef, index) => {
				newValues[fieldDef.key] = result[index];
			});
		setParsedObject(newValues)
		setParseKey(parseKey + 1);
		setIsInputShown(false);
	}

	function onInputChange(inputValue: string) {
		setPastedText(inputValue)
		parseOnFormDefinition(inputValue)
	}

	function reset() {
		setIsInputShown(true);
		setPastedText('');
	}

	const Input = (
		<>
			{isInputShown &&
				<UIInputControlled 
					key={parseKey}
					name="parse" 
					placeholder="Paste text" 
					value={pastedText}
					onChange={onInputChange} 
				/>
			}
			{!isInputShown && <UIFlexbox flexAlign="center" childrenGrow="67/33">
				<div>Data has been parsed. <br/><small>{pastedText}</small></div>
				<UIButton outline label="Start Over" onClick={reset} />
			</UIFlexbox>
			}
		</>
	);
	
	return {
		parsedObject,
		parseKey,
		parseOnFormDefinition,
		Input,
		isInputShown
	};
};