import React from 'react';

import { useUIForm, UIForm2, UIInput, UITextArea, UISection, UISelect, DefaultOptionCheckbox, UIKeyValueHorizontal, SaveButtonBlock, UIHeading, UINumberInput, UISwitch, UIFlexbox, ModalHookProps, UIModal } from 'kit';

import { useTags, usePartTypeValidation } from 'hooks';

import { adjustPartQty } from 'services/partTxMutate';
import { addTag } from 'services/tagMutate';

import { arrayString } from 'utility';
import { PartInsertObj, PartUpdateObj, Part, Step } from 'types';
import { addThing, updateThing } from 'root/services/thingMutate';
import { LinkStepsFromItem } from '../StepLink/LinkStepsFromItem';

interface EditPartFormValues extends Part {
	qty?: number
}

export const EditPartForm = (
	{ modalProps, originatingPartType, addStepToPending }: {
		modalProps: ModalHookProps<any>
		originatingPartType: 'raw' | 'product'
		addStepToPending?: Step
	}
) => {
	const existingPart = modalProps.modalData;
	const partId = existingPart?.id;
	const isNewPart = typeof partId !== 'number';
	const { formMethods, isDirty } = useUIForm<EditPartFormValues>(existingPart, { resetTrigger: modalProps.isModalOpen });
	const { isTagsLoading, tags } = useTags('partRaw');

	const { canToggleRaw, canToggleProduct, TogglePartTypeInfoTip } = usePartTypeValidation(existingPart.id, originatingPartType);

	const createTag = newtag => {
		const part = originatingPartType === 'raw' ? 'partRaw' : 'partProduct';
		return addTag(part, newtag).then( queryResponse => {
			return queryResponse.id;
		})
	}

	const linkStepsRef = React.useRef<{ updateStepsFromItem(itemId: number): void }>();

	const submit = (formData: EditPartFormValues) => {
		if (isNewPart){
			const newPart: PartInsertObj = {
				name: formData.name,
				description: formData.description,
				part_number: formData.part_number,
				tags: arrayString(formData.tags),
				uom: formData.uom,
				min: formData.min,
				max: formData.max,
				is_raw: originatingPartType === 'raw' ? true : formData.is_raw,
				is_product: originatingPartType === 'product' ? true : formData.is_product,
				part_txs: {
					data: [
						{
							qty_change: formData.qty,
							type: 'initialize'
						}
					]
				}
			};
			addThing(originatingPartType === 'raw' ? 'partRaw' : 'partProduct', newPart).then(thingMutateResp => {
				linkStepsRef.current.updateStepsFromItem(thingMutateResp.item.id);
				if (typeof modalProps.toggleModal === 'function') { modalProps.toggleModal(); }
			});
		}
		else {
			let updatedPart: PartUpdateObj = {
				name: formData.name,
				description: formData.description,
				part_number: formData.part_number,
				tags: arrayString(formData.tags),
				uom: formData.uom,
				min: formData.min,
				max: formData.max
			};
			if (originatingPartType === 'raw') {
				updatedPart.is_product = formData.is_product;
			}
			else if (originatingPartType === 'product') {
				updatedPart.is_raw = formData.is_raw;
			}
			updateThing(originatingPartType === 'raw' ? 'partRaw' : 'partProduct', partId, updatedPart);
			linkStepsRef.current.updateStepsFromItem(partId);
			if (typeof modalProps.toggleModal === 'function') { modalProps.toggleModal(); }
		}

	}

	return (
		<UIModal {...modalProps} isFormDirty={isDirty}>
			<UIHeading>{!modalProps.modalData || !modalProps.modalData.id ? 'Add New' : 'Update' } Material</UIHeading>
	
			<UIForm2 onSubmit={submit} formMethods={formMethods} >
				<UIInput name="name" label="Name" required />

				{originatingPartType === 'raw' && 
					<LinkStepsFromItem
						ref={linkStepsRef}
						parameterName="partRaw"
						linkingId={partId}
						addStepToPending={addStepToPending}
					/>
				}

				<UITextArea name="description" label="Description" />
				<UIInput name="part_number" label="Part Number" />
				<UISelect
					isMulti
					name={`tags`}
					label={`Category`}
					defaultValue={existingPart.tags}
					options={tags}
					isLoading={isTagsLoading}
					handleCreate={createTag}
					customOption={DefaultOptionCheckbox}
				/>
				{isNewPart && <UIInput name="qty" label="Initial Quantity" required />}
				<UIInput name="uom" label="Unit" required />
				<UIFlexbox flexAlign="flex-start">
					{originatingPartType === 'product' && <UISwitch name="is_raw" label="This Product can also be used as a Material" default={existingPart.is_raw} disabled={!canToggleRaw} /> }
					{originatingPartType === 'raw' && <UISwitch name="is_product" label="This Material can also be used as a Product" default={existingPart.is_product} disabled={!canToggleProduct} />}
					<TogglePartTypeInfoTip />
				</UIFlexbox>

				<SaveButtonBlock onCancel={modalProps.toggleModal} />

			</UIForm2>
		</UIModal>
	)
}

export const AdjustPartForm = (
	{ modalProps, part, partType }: {
		modalProps
		part: Part
		partType: 'raw' | 'product'
	}
) => {
	const { formMethods, isDirty } = useUIForm<PartFormValues>( { adjust_qty: 0 } );

	const part_stock = part.part_stock[0];
	const currentStockQty = part_stock.qty;

	const adjustQty = formMethods.watch('adjust_qty') * 1;
	
	const submit = (formData: PartFormValues) => {
		const isRaw = partType === 'raw' ? true : false;
		const isProduct = partType === 'product' ? true : false;
		adjustPartQty(isRaw, isProduct, formData.adjust_qty, part.id, part_stock.job_id, part_stock.inventory_unique_id, formData.comment);

		modalProps.toggleModal();
	};

	return (
		<UIModal {...modalProps} isFormDirty={isDirty}>
			<UIForm2 onSubmit={submit} formMethods={formMethods}>
				<UIHeading>Adjust Inventory for {part.name}</UIHeading>
				
				<UINumberInput
					name="adjust_qty"
					label="Add or Subtract Qty"
					min={currentStockQty * -1}
				/>

				<UITextArea name="comment" label="Comment" />

				<UISection>
					<UIKeyValueHorizontal title="Current Qty" text={currentStockQty + ' ' + part.uom}/>
					<UIKeyValueHorizontal title="New Qty" text={(currentStockQty + adjustQty) + ' ' + part.uom}/>
				</UISection>


				<SaveButtonBlock onCancel={modalProps.toggleModal} />

			</UIForm2>
		</UIModal>
	)
}

interface PartFormValues {
	adjust_qty: number
	comment?: string
}