import React, { useState } from 'react';
import styled from 'styled-components';

import { UISection, GridContainer, GridRow, UISelect, UINumberInputControlled, SaveButtonBlock } from 'kit';

import { useDynamicList, DynamicListContext, calcFulfilledQty, useThingsAll, useThingById } from 'hooks';

import { addOrderItems, updateOrderItems, PendingLineItem, deleteOrderItems } from 'services/orderItemMutate';

import { getObjectFromArray } from 'utility';
import { OrderItemInsertObject, OrderItem } from 'types';
import isEqual from 'lodash.isequal';


export const EditOrderLineItems = ({ 
	isNewOrder, orderId, 
	existingLineItems = [] as OrderItem[], 
	commitLineItems, onSave
}: {
	isNewOrder: boolean
	existingLineItems: OrderItem[]
	orderId?: number
	commitLineItems?
	onSave?
}) => {
	if (!isNewOrder && typeof orderId !== 'number') {
		console.error('Order Id is required for existing order');
	}
	else if (isNewOrder && typeof commitLineItems !== 'function') {
		console.error('commitLineItems is required for new order');
	}
	
	const [pendingLineItems, setPendingLineItems] = useState<PendingLineItem[]>([]);

	const dynamicListContext =  useDynamicList<PendingLineItem>(existingLineItems, { onUpdate: updateState });
	const { isAdding, AddButton } = dynamicListContext;
	
	function updateState(newRows: PendingLineItem[]) {
		setPendingLineItems(newRows);
		if (isNewOrder) { // run it through the save function so that objects get converted to proper type for insert
			save(newRows);
		}
	}

	function convertPendingToInsertObject(item: PendingLineItem): OrderItemInsertObject {
		let insertObj: OrderItemInsertObject = { ...item, status: 'unfulfilled' };
		if (!isNewOrder) {
			insertObj.order_id = orderId;
		}
		return insertObj;
	}

	const save = (rows: PendingLineItem[]) => {
		let addedItems = [] as OrderItemInsertObject[];
		let editedItems = [] as PendingLineItem[];

		// determine added, and edited line items
		rows.forEach( pendingItem => {
			const matchingExistingItem: OrderItem = getObjectFromArray(existingLineItems, 'part_id', pendingItem.part_id);
			const alreadyFulfilledQty = calcFulfilledQty(matchingExistingItem);
			if (!matchingExistingItem.id) {
				addedItems.push(convertPendingToInsertObject(pendingItem));
			}
			else {
				if (!isEqual(pendingItem, matchingExistingItem)) {
					if (matchingExistingItem.status === 'fulfilled' && pendingItem.qty > matchingExistingItem.qty) {
						pendingItem.status = 'unfulfilled';
					}
					else if (matchingExistingItem.status === 'unfulfilled' && pendingItem.qty === alreadyFulfilledQty) {
						pendingItem.status = 'fulfilled';
					}
					editedItems.push(pendingItem);
				}
			}
		});

		
		if (isNewOrder) {
			commitLineItems(addedItems);
		}
		else {
			// determined deleted line items
			const pendingPartIds = rows.map( item => item.part_id );
			const deletedItemIds = existingLineItems.filter( item => !pendingPartIds.includes(item.part_id) ).map( item => item.id );
			
			addOrderItems(addedItems, orderId);
			updateOrderItems(editedItems);
			deleteOrderItems(deletedItemIds, orderId);
		}

		if (typeof onSave === 'function') {
			onSave();
		}
	};

	return (
		<UISection>
			<GridContainer columns={6} disableHover gridTemplateColumns="1fr 100px 250px 100px 100px 20px">
				<GridRow isTitleRow>
					<div>Product Name</div>
					<div>Description</div>
					<div>Qty</div>
					<div>Unit Cost</div>
					<div>Line Item</div>
					<div> </div>
				</GridRow>

				{pendingLineItems.map( (lineItem, index) => {
					return (
						<ProductRow 
							key={lineItem.part_id} 
							index={index} 
							dynamicListContext={dynamicListContext}
						/>
					)
				})}

			</GridContainer>
			<>
				<AddButton />
				{isAdding && 
				<AddProductSelection 
					alreadySelectedProducts={pendingLineItems.map( sel => sel.part_id )} 
					dynamicListContext={dynamicListContext}
				/>}
			</>

			{!isNewOrder && 
				<SaveButtonBlock onSave={save.bind(this, pendingLineItems)} onCancel={onSave} />
			}
		</UISection>
	)
};


const AddProductSelection = ( { alreadySelectedProducts = [], dynamicListContext: { addRow } }: {
	alreadySelectedProducts: number[]
	dynamicListContext: DynamicListContext<PendingLineItem>
}) => {
	const { isLoading, items: parts } = useThingsAll('partProduct');

	const allowedOptions = parts.filter( item => !alreadySelectedProducts.includes(item.id) )
	
	function onSelect(partId) {
		if (typeof partId === 'number') {
			addRow({ part_id: partId, qty: 1 });
		}
	}

	//if (allowedOptions.length === 0) { return null; }

	return (
		<SelectContainer>
			<UISelect 
				name="part-select" label="Product"
				skipRegister
				options={allowedOptions}
				defaultValue={null}
				hasConditionalComponents={false}
				isLoading={isLoading}
				onChange={onSelect}
			/>
		</SelectContainer>
	);
}

const SelectContainer = styled.div`
	max-width: 400px;
`;


const ProductRow = ( { index, dynamicListContext }: 
	{
		index: number
		dynamicListContext: DynamicListContext<PendingLineItem>
	}
) => {
	const { rows, editSingleValueInRow, DeleteButton } = dynamicListContext;
	const lineItem = rows[index] || {} as PendingLineItem;
	const { part_id: partId, qty, unit_cost } = lineItem;

	const alreadyFulfilledQty = calcFulfilledQty(lineItem as OrderItem);

	
	const { item: part } = useThingById('partProduct', partId);


	function updateQty(newQty) {
		editSingleValueInRow(index, 'qty', newQty);
	}

	function updateUnitCost(newValue) {
		editSingleValueInRow(index, 'unit_cost', newValue);
	}
	
	return (
		<GridRow>
			<div>{part.name}</div>
			<div>{part.description}</div>
			<UINumberInputControlled 
				defaultValue={qty}
				onChange={updateQty}
				min={alreadyFulfilledQty}
				endLabel={part.uom}
			/>
			<UINumberInputControlled 
				defaultValue={unit_cost || void 0}
				onChange={updateUnitCost}
			/>
			<div> </div>
			{alreadyFulfilledQty > 0 ? <div> </div> : <DeleteButton index={index} />}
		</GridRow>
	)
}