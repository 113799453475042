import React from 'react';
import { UIAlert } from 'kit';
import { useThingById } from 'hooks';
import { ValidationErrors } from './StepForm';
import { StepFormCategory, stepFormCategoryLookup } from '@constants';


export const BrokenLinkAlertBanner = (
	{ err, removeFunc, categoryName }: {
		err: ValidationErrors['linkErrors'][StepFormCategory][0]
		categoryName: StepFormCategory
		removeFunc?: () => void
	}
) => {
	const thingName = stepFormCategoryLookup[categoryName].thingName;
	const { item } = useThingById(thingName, err.invalidLinkId);
	
	return (
		<UIAlert
			key={err.invalidLinkId}
			title={err.message.replace('__name__', item.name)}
			variant="error"
			actionOnClick={err.action === 'remove' ? removeFunc : void 0}
			actionLabel="Remove"
		/>
	);
};
