import React from 'react';

import { useModal, UIModal, UIButton, GridRow, GridContainer } from 'kit';


import { PartTransaction, StepHistoryYield } from 'types';
import { getObjectFromArray } from 'utility';
import { useThingsAll } from 'hooks';

export const PartList = (
	{ label, parts }:{ label: string, parts: Array<PartTransaction> }
) => {
	const modalProps = useModal();

	const { items: partList } = useThingsAll('partRaw');

	return (
		<React.Fragment>
			<UIButton quiet label={label} onClick={modalProps.toggleModal}/>

			<UIModal {...modalProps}>

				<GridContainer columns={3} gridRowGap="20px" fullWidth>
					<GridRow isTitleRow hasBottomBorder>
						<div>Material</div>
						<div>Quantity Change</div>
						<div>Stock Quantity</div>
					</GridRow>

					{parts.map( ( partTx ) => {
						return (
							<GridRow key={partTx.part_id}>
								<div>{getObjectFromArray(partList, 'id', partTx.part_id).name}</div>
								<div>{partTx.qty_change}</div>
								<div>{partTx.new_qty}</div>
							</GridRow>
							)
						})
					}

				</GridContainer>

			</UIModal>

		</React.Fragment>
	)
}

export const ProductList = (
	{ label, products }:{ label: string, products: Array<StepHistoryYield> }
) => {
	const modalProps = useModal();

	
	const { items: partList } = useThingsAll('partProduct');

	return (
		<React.Fragment>
			<UIButton quiet label={label} onClick={modalProps.toggleModal}/>

			<UIModal {...modalProps}>

				<GridContainer columns={3} gridRowGap="20px" fullWidth>
					<GridRow isTitleRow hasBottomBorder>
						<div>Product</div>
						<div>Quantity In</div>
						<div>Quantity Out</div>
					</GridRow>

					{products.map( ( productTx ) => {
						return (
							<GridRow key={productTx.part_id}>
								<div>{getObjectFromArray(partList, 'id', productTx.part_id).name}</div>
								<div>{productTx.qty_in}</div>
								<div>{productTx.qty_out}</div>
							</GridRow>
							)
						})
					}

				</GridContainer>

			</UIModal>

		</React.Fragment>
	)
}