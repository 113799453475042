import React from 'react';
import styled, { css } from 'styled-components';

import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import { NavLink } from 'react-router-dom';

import { marginStyles, MarginProps } from '@constants';
import { convertHexToRGBA } from 'root/theme';


export type UIButtonProps = {
	label
	onClick?: any

	icon?: JSX.Element
	iconPosition?: 'start' | 'end'

	linkToPath?: string
	isExactLinkMatch?: boolean

	// size / container modifiers
	size?: 'small' | 'medium' | 'large'
	full?: boolean

	// fill modifiers
	quiet?: boolean
	linkish?: boolean

	outline?: boolean
	ghost?: boolean

	// color modifiers
	muted?: boolean
	success?: boolean
	error?: boolean
	white?: boolean
	
	//html button props
	disabled?: boolean
	type?: 'button' | 'submit' | 'reset' //match standard button types
	className?: string
} & MarginProps

export const UIButton = (	
	{ size = 'medium', iconPosition = 'start', isExactLinkMatch = true, ...props }: UIButtonProps
) => {
	// Separate out props that need to manipulated vs direct material ui props
	const { quiet, outline, ghost, icon, linkToPath, ...directPassthruProps } = props;

	const color = 'primary';

	let variant: 'text' | 'contained' | 'outlined' = quiet || props.linkish ? 'text' : 'contained';
	if (outline || ghost ) { variant = 'outlined'; }

	const CustomLink = React.useMemo(
		() =>
			React.forwardRef((linkProps, ref) => (
				<NavLink to={linkToPath} {...linkProps} end={isExactLinkMatch} activeClassName="link-active" />
			)),
		[linkToPath]
	);


	return (
		<StyledMaterialButton 
			size={size} 
			color={color} 
			variant={variant}
			startIcon={iconPosition === 'start' && icon ? icon : void 0}
			endIcon={iconPosition === 'end' && icon ? icon : void 0}

			to={linkToPath}
			component={linkToPath && CustomLink}
			
			ghost={ghost}
			linkish={props.linkish}

			{...directPassthruProps} 
		>
			{props.label}
		</StyledMaterialButton>
	);
}



const StyledMaterialButton = styled(Button).withConfig({
	// Material UI emits a console error with props that are not native to it. Filter out the props that we are using for our style overrides
	shouldForwardProp: (prop) => !['full', 'ghost', 'linkish', 'error', 'success', 'muted', 'white'].includes(prop)
})<UIButtonProps & { to: string, component: any }>`
	
	border-radius: 3px;
	min-width: 48px;
	font-weight: 400;

	${marginStyles} 

	${props => !props.full && css`
		margin-right: 1rem;
		${!props.margin && css`
			& + & {
				${typeof props.margin === 'string' && 'margin-right: unset;'}
			}
		`}
	`};

	text-transform: none;
	line-height: 1.2;
	svg {
		height: 1em;
		width: 1em;
		margin: 0 !important;
	}
	${props => props.size === 'small' && css`
		font-size: 0.9rem;
		padding: 5px 10px;
	`};
	${props => props.size === 'medium' && css`
		font-size: 1rem;
		padding: 8px 12px;
	`};
	${props => props.size === 'large' && css`
		font-size: 1.2rem;
		padding: 9px 14px;
	`};

	${props => props.full && css`
		width: -webkit-fill-available;
		justify-content: center;
		max-width: 400px;
		margin-bottom: 1rem;
		& + & {
			${typeof props.margin === 'string' && 'margin-bottom: unset;'}
		}
	`};


	${props => props.variant === 'outlined' && css`
		&:hover, & { 
			border-color: ${props.white && 'white'};
			color: ${props.white && 'white'};
		}
		&:hover {
			background-color: ${props.white && 'rgba(255, 255, 255, 0.15)'};
		}
	`};


	${props => props.variant === 'contained' && css`
		background-color: ${props.theme.colors.primary};
		background-color: ${props.muted && props.theme.colors.muted};
		background-color: ${props.success && props.theme.colors.success};
		background-color: ${props.error && props.theme.colors.error};
		&:hover {
			background-color: ${props.theme.colors.primaryDark};
		}
	`};

	${props => props.variant === 'text' && css`
	
		color: ${props.error && `${props.theme.colors.errorDark}`};

		&:hover {
			background-color: rgba(0, 0, 0, 0.05);
		}
	`};

	${props => props.linkish && css`
		text-decoration: underline;
		font-weight: ${props.theme.font.boldWeight};
		&:hover {
			text-decoration: underline;
		}
	`};

	${props => props.ghost && css`
		&, &:hover {
			border-style: dashed;
		}

		${typeof props.onClick === 'function' || props.type === 'submit' ?
			css`
				:hover {
					color: white;
					background-color: ${convertHexToRGBA(props.theme.colors.primary, 0.5)};
					background-color: ${props.success && convertHexToRGBA(props.theme.colors.success, 0.5)};
					background-color: ${props.muted && convertHexToRGBA(props.theme.colors.muted, 0.5)};
					background-color: ${props.error && convertHexToRGBA(props.theme.colors.error, 0.5)};
				}
			`
		: ``
		};
	`}

	${props => typeof props.onClick !== 'function' && props.type !== 'submit' && !props.to && css`
		pointer-events: none;
	`}
`;







export type UIIconButtonProps = {
	onClick?: any
	icon?: any
	className?: string

	linkToPath?: string
	isExactLinkMatch?: boolean

	// size / container modifiers
	size?: 'small' | 'medium'

	solid?: boolean

	// color modifiers
	muted?: boolean
	success?: boolean
	error?: boolean

	//html button props
	disabled?: boolean
	type?: 'button' | 'submit' | 'reset' //match standard button types

 } 

export function UIIconButton (
	{ size = 'medium', isExactLinkMatch = true, ...props }: UIIconButtonProps & MarginProps
) {
	const { icon, linkToPath, ...restProps } = props;
	const color = 'primary';

	const CustomLink = React.useMemo(
		() =>
			React.forwardRef((linkProps, ref) => (
				<NavLink to={linkToPath} {...linkProps} end={isExactLinkMatch} activeClassName="link-active" />
			)),
		[linkToPath]
	);

	return 	(
		<StyledIconButton 
			size={size} 
			color={color}

			to={linkToPath}
			component={linkToPath && CustomLink}

			{...restProps}
		>
			{icon}
		</StyledIconButton>
	)
}

const StyledIconButton = styled(IconButton).withConfig({
	// Material UI emits a console error with props that are not native to it. Filter out the props that we are using for our style overrides
	shouldForwardProp: (prop) => !['solid', 'error', 'muted', 'success', 'linkToRoute', 'linkToVars'].includes(prop)
})<UIIconButtonProps & { to: string, component: any }>`
	${marginStyles}

	&.MuiIconButton-root {
		padding: 9px;
	}
	&.MuiIconButton-sizeSmall {
		padding: 6px;
	}
	svg {
		margin: 0 !important;
		${props => props.size === 'small' && css`
			height: 14px;
			width: 14px;
		`};
		${props => props.size === 'medium' && css`
			height: 20px;
			width: 20px;
		`};
	}

	&:hover {
		background-color: ${props => props.theme.colors.primaryLightAlpha};
	}

	color: ${props => props.theme.colors.primary};
	color: ${props => props.muted && props.theme.colors.muted};
	color: ${props => props.success && props.theme.colors.success};
	color: ${props => props.error && props.theme.colors.error};

	${props => props.solid && css`
		background-color: ${props.theme.colors.primary};
		background-color: ${props.muted && props.theme.colors.muted};
		background-color: ${props.success && props.theme.colors.success};
		background-color: ${props.error && props.theme.colors.error};
		&:hover {
			background-color: ${props.theme.colors.primaryDark};
			background-color: ${props.muted && props.theme.colors.mutedDark};
			background-color: ${props.success && props.theme.colors.successDark};
			background-color: ${props.error && props.theme.colors.errorDark};
		}
		color: #ffffff;
	`}

	${props => typeof props.onClick !== 'function' && props.type !== 'submit' && css`
		pointer-events: none;
	`}
`;





// ---------------------------------------------------------------------------------------

export const UICircleButton = styled(UIButton)`
	border-radius: 50%;
	height: 36px;
	width: 36px;
	min-width: unset;
`;