
export const userReferenceColumns = `
		id
		first_name
		last_name
`;

export const userInitials = (userProfile) => {
	return (userProfile?.first_name ?? '_').substr(0, 1) + 
			(userProfile?.last_name ?? '_').substr(0, 1);
}