
import { useQuery, gql } from '@apollo/client';
import { GET_ORDER_ITEM } from 'services/orderFetch';
import { hydratePartStock } from 'services/partHydrate';

import { OrderItem, PartWIP } from 'types';
import { partWipAllColumns } from '@constants';


export function calcFulfilledQty(orderItem: OrderItem){
	return orderItem.part_txs?.
	filter( tx => tx.type === 'fulfill' && tx.order_item_id === orderItem.id) /**This checks are redundant with what the query provides, but double check anyway */
	.map( tx => tx.qty_change * -1 ) // Qtys are getting deducted from stock, so the deltas are reported as negative - make them positive here
	.reduce( (qty1, qty2) => qty1 + qty2, 0 ) ?? 0; 
}

export const useFulfill = (orderItemId: number, refreshCache = false) => {
	const { 
		loading: orderItemLoading,
		data: { order_item: [orderItem = {} as OrderItem] } = { order_item: [] }
	} = useQuery(GET_ORDER_ITEM, { 
		variables: { id: orderItemId },
		skip: !(orderItemId > 0),
		fetchPolicy: refreshCache ? 'cache-and-network' : 'cache-first'
	});
	
	const lineItemQty = orderItem.qty ?? 0;
	

	// Determine quantity already fulfilled
	const fulfilledQty: number = calcFulfilledQty(orderItem); 
	

	// Determine quantity reserved
	//    This means both the total reservations for the part, and the reservation for this line item
	const { 
		loading: wipLoading,
		data: { part_wip: wip } = { part_wip: [] as PartWIP[] }
	} = useQuery(GET_FULFILLMENT_WIP, { 
		variables: { 
			part_id: orderItem.part?.id,
			order_item_id: orderItem.id
		},
		skip: typeof orderItem.part?.id !== 'number',
		fetchPolicy: refreshCache ? 'cache-and-network' : 'cache-first'
	});
	
	const partReservedQty: number = wip
		.filter( dWip => dWip.type === 'reserve' &&
			(typeof dWip.job_id !== 'number' || dWip.job?.job_status === 'complete') // job status chechk: catches reservations that used to be wip, but have since finished are are now in stock. For rows with job_id null to begin with, these were reserved rom stock, 
		)
		.map( dWip => dWip.qty )
		.reduce( (qty1, qty2) => qty1 + qty2, 0 );

	const stockReservations =  wip.slice() // because we want to sort (ie, mutate) the array, we need to duplicate it first
		.sort( (wip1, wip2) => (wip1.job_id ?? 0) - (wip2.job_id ?? 0) )
		.filter( dWip => dWip.type === 'reserve' && dWip.order_item_id === orderItemId &&
			(typeof dWip.job_id !== 'number' || dWip.job?.job_status === 'complete') 
	);

	const reservedFromStockQty: number = stockReservations
		.map( dWip => dWip.qty )
		.reduce( (wip1, wip2) => wip1 + wip2, 0 );

		


	const wipReservations = wip
		.filter( dWip => dWip.type === 'reserve' && dWip.order_item_id === orderItemId && 
			(typeof dWip.job_id === 'number' && dWip.job?.job_status !== 'complete') 
		);

	const reservedFromWipQty: number = wipReservations
		.map( dWip => dWip.qty )
		.reduce( (qty1, qty2) => qty1 + qty2, 0 );

	// Determine quantity in stock
	const partStock = hydratePartStock(orderItem.part);
	const partStockMinusReservedQty = (partStock?.totalQty ?? 0) - partReservedQty;
	
		
	const unfulfilledActualQty = lineItemQty - fulfilledQty;
	const unfulfilledAndNotReservedQty = unfulfilledActualQty - reservedFromStockQty - reservedFromWipQty;
	const fulfillableQtyInclStockReserves = Math.min(Math.max(0, partStockMinusReservedQty), unfulfilledAndNotReservedQty);
	const reservableQtyInclStockReserves = Math.min(Math.max(0, partStockMinusReservedQty + reservedFromStockQty), unfulfilledAndNotReservedQty + reservedFromStockQty);


	// List associated jobs
	const reservationsWithJobs = wip.filter(dWip => dWip.type === 'reserve' && (typeof dWip.job_id === 'number'));
	const fulfillmentsWithJobs = orderItem.part_txs?.filter( tx => typeof tx.job_id === 'number');
	
	const returnValues =  {
		isLoading: orderItemLoading || wipLoading,
		orderItem,

		/** Qty requested for this line item */
		lineItemQty,

		/** Qty already fulfilled for the line item */
		fulfilledQty,

		/** Qty reserved from stock for the line item */
		reservedFromStockQty,

		/** Reservation (stock) objects associated with this line item */
		stockReservations,

		/** Reservation (wip) objects associated with this line item */
		wipReservations,

		/** Qty reserved from wip for the line item */
		reservedFromWipQty,

		/** Qty still needed to fulfill the line item */
		unfulfilledActualQty,

		/** Qty unaccounted for from the line item.  This is the unfulfilled quantity that has not been addressed via reservations. */
		unfulfilledAndNotReservedQty,

		/** The quantity that you could feasibly fulfill right now. 
		 * If reserves exist for this line item, the reserves are subtracted from the balance (since you can't over-reserve)
		 * If stock is insufficient, this will be the qty that you could fulffil from stock.
		 */
		fulfillableQtyInclStockReserves,


		/** The quantity that you could feasibly reserve right now. 
		 * It includes the reserves that already exist for this line item. (only the real reserves in stock not wip.)
		 * If stock is insufficient, this will be the qty that you could fulffil from stock.
		 */
		reservableQtyInclStockReserves,

		/** Current stock total for the part. Does not include reserves. */
		partStockActualQty: partStock.totalQty,
		
		/** Current reserved total for the part */
		partReservedQty,

		/** Current stock total for the part, minus reserved qtys. */
		partStockMinusReservedQty,
		
		/** Fulfillments with Job associations */
		fulfillmentsWithJobs,
		
		/** Reservations with Job associations */
		reservationsWithJobs
	};

	// console.log('useFulfill: ', returnValues)

	return returnValues;
};

export const GET_FULFILLMENT_WIP = gql`
	query GET_FULFILLMENT_WIP(
		$part_id: Int!
		$order_item_id: Int!
	) {
		part_wip (
			where: { 
				_or: {
					_and: { #total reservations for the part
						part_id: { _eq: $part_id } 
						type: { _eq: "reserve" } 
					}
					order_item_id: { _eq: $order_item_id }  # wip for this line item, which isn't fetched by the above conidtion because of the reserve flag
				}
			}
		) {
			${partWipAllColumns}
		}
	}
`;
