import React, { useState } from 'react';
import styled from 'styled-components';

import { Address } from 'types';
import { useUIForm, UIForm2, UIInput, UICard, useModal, UIModal, UIHeading, UIFlexbox, useFormCustomRegister, SaveButtonBlock } from 'kit';

import { useDynamicList } from 'hooks';

export const ShowAndEditAddresses = ( { addresses = [] as Address[] }: 
	{
		addresses: Address[]
	}
) => {
	const modalProps = useModal<{ submitAction, index, address }>(false);

	const { updateFormValue } = useFormCustomRegister('addresses');

	const dynamicListContext =  useDynamicList<Address>(addresses, { onUpdate: updateFormValue });
	const { rows: pendingAddresses, addRow, AddButton, editRow } = dynamicListContext;


	function toggleEdit(address: Address, index){
		modalProps.setModalData({ address, index, submitAction: submitEdit.bind(this, index) });
		modalProps.toggleModal();
	}

	function toggleAdd(){
		modalProps.setModalData({ address: {}, index: null, submitAction: submitAdd });
		modalProps.toggleModal();
	}

	function submitEdit(index, address: Address) {
		editRow(index, address);
		modalProps.toggleModal(false);
	}

	function submitAdd(address: Address) {
		addRow(address);
		modalProps.toggleModal(false);
	}

	const [isDirty, setIsDirty] = useState();
	return (
		<>
			{pendingAddresses?.map( (address, index) => (
				<AddressCard 
					key={address.address1 + index}
					address={address} 
					onClickEdit={toggleEdit.bind(this, address, index)} 
				/>
			))}
			

			<AddButton label="Add New Address" onClick={toggleAdd} />
			<UIModal {...modalProps} isFormDirty={isDirty} > 
				<AddressForm 
					onSubmit={modalProps.modalData?.submitAction} 
					address={modalProps.modalData?.address} 
					onDirtyChange={setIsDirty}
					modalProps={modalProps}
				/>
			</UIModal>
		</>
	)
}

export const AddressForm = ( 
{ 
	address: existingAddress = {} as Address, 
	onSubmit = () => {},
	onDirtyChange,
	modalProps
}: { 
	address: Address
	onSubmit?(formData: Address): void
	onDirtyChange,
	modalProps
} ) => {
	const isNewAddress = typeof existingAddress.country !== 'string';
	
	const { formMethods } = useUIForm<Address>(existingAddress, { onDirtyChange });

	/** Have to handle submit manually because this component appears as a child of another form*/
	const submit = formMethods.handleSubmit(() => {
		const formData = formMethods.getValues();

		onSubmit(formData);
	})
	
	return (
		<StyledForm formMethods={formMethods} >

			<UIHeading>{isNewAddress ? 'Add' : 'Edit'} Address</UIHeading>

			<UIFlexbox childrenGrow="50/50">
				<UIInput name="first_name" label="First Name" required />
				<UIInput name="last_name" label="Last Name" required />
			</UIFlexbox>
			<UIInput name="company_name" label="Company" />
			<UIInput name="address1" label="Street Address" required />
			<UIInput name="address2" label="Apt, Suite, etc" />
			<UIInput name="city" label="City" required/>
			<UIFlexbox childrenGrow="50/50">
				<UIInput name="state" label="State"/>
				<UIInput name="country" label="Country"/>
				<UIInput name="zip" label="Zip/Postal Code"/>
			</UIFlexbox>
			<UIFlexbox childrenGrow="50/50">
				<UIInput name="email" label="Email Address" />
				<UIInput name="phone" label="Phone Number" />
			</UIFlexbox>




			<SaveButtonBlock isTypeSubmit={false} onSave={submit} onCancel={modalProps.toggleModal} />
		</StyledForm>
	);
}

const StyledForm = styled(UIForm2)`
	input {
		max-width: 700px;
	} 
`;

export const AddressCard = ( 
	{ 
		address = {} as Address,
		onClickEdit,
		emptyMessage = ''
	}: 
	{
		address: Address
		onClickEdit?: () => void
		emptyMessage?: string
	}
) => {
	const isEditAllowed = typeof onClickEdit === 'function';

	const doesAddressExist = typeof address.address1 === 'string';

	if (!doesAddressExist) {
		return <div>{emptyMessage}</div>
	}

	return (
		<UICard>
			{isEditAllowed && <div onClick={onClickEdit}>Edit</div>}
			<div>{address.first_name} {address.last_name}</div>
			<div>{address.company_name}</div>
			<div>{address.address1}</div>
			<div>{address.address2}</div>
			<div>{address.city} {address.state} {address.zip}</div>
			<div>{address.country}</div>
			<div>{address.phone}</div>
		</UICard>
	)
} 
