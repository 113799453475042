import { StepPlan, currentStepVersion } from 'types';
import { deepCopy } from 'utility';



/**
 * The branching nature of the various decisions for each parameter 
 * results in the possibility that some values in the options object
 * are completely useless, potentially misleading, and therefore should not be saved. 
 * This function removes said branches of the tree.
 * @param   {object} stepDef Submitted form data object from form submit.
 * @returns {object} Shaken options object.
 *  */ 
export function stripStepDefinition(stepDef: StepPlan){
	// deep copy (json stringify and parse) to get rid of JSON keys that have undefined values (ie, form inputs that weren't filled out)
	let plan: StepPlan = deepCopy(stepDef) || {} as StepPlan;

	// always delete helper data from switches
	// @ts-ignore
	delete plan.dummy;

	// Remove empty nested objects
	const topLevelKeys = Object.keys(plan);
	for (let index = 0; index < topLevelKeys.length; index++) {
		const topLevelKey = topLevelKeys[index];
		const topLevelItem = plan[topLevelKey];
		
		if (typeof topLevelItem === 'object' && Object.keys(topLevelItem).length === 0) {
			// This is an empty object
			delete plan[topLevelKey]; //topLevelItem
		}
	}

	// Apply the version. Maybe this should go somewhere else later.
	plan.version = currentStepVersion;

	// set return object to the edited values
		return plan;
}