import Ajv from 'ajv';
import { logWorkflowValidationError } from 'services/errorHelper';
import { StepPlan, SupplementalStepInfo, WorkflowOptions } from 'types';

import stepPlanSchema from 'types/stepPlan.schema.json';
import stepSuppSchema from 'types/stepSupp.schema.json';
import workflowOptionsSchema from 'types/workflowOptions.schema.json';
import { WorkflowEditType } from 'components/WorkflowEditor';


export function validateStepDefinitionSchema(startingRevId, stepPlan: StepPlan): boolean {
	var ajv = new Ajv();
	const isValid = ajv.validate(stepPlanSchema, stepPlan);
	if (!isValid) {
		logWorkflowValidationError('Step Rev ' + startingRevId, stepPlan, ajv.errors);
		return false;
	}
	return true;
}

export function validateStepSuppSchema(type: WorkflowEditType, id: number, stepIndex: number, supp: SupplementalStepInfo): boolean {
	var ajv = new Ajv();
	const isValid = ajv.validate(stepSuppSchema, supp);
	if (!isValid) {
		logWorkflowValidationError(`${type} ${id}: step ${stepIndex}`, supp, ajv.errors);
		return false;
	}
	return true;
}

export function validateWorkflowOptionsSchema(type: WorkflowEditType, id: number | 'draft', workflowOptions: WorkflowOptions): boolean {
	var ajv = new Ajv();
	const isValid = ajv.validate(workflowOptionsSchema, workflowOptions);
	if (!isValid) {
		logWorkflowValidationError(`${type} ${id}`, workflowOptions, ajv.errors);
		return false;
	}
	// check that the plan IDs match between fixed_part_ids, and the part IDs defined in the plan.
	if (workflowOptions.product_enabled && workflowOptions.product?.method === 'fixed') {
		let planIdsMatch = true;
		workflowOptions.product?.plans?.forEach( (plan, index) => {
			if (workflowOptions.product.fixed_part_ids?.[index] !== plan.part_id) {
				planIdsMatch = false;
			}
		});
		if (!planIdsMatch) { 
			console.error('Ids do not match');
			return false; 
		}
	}
	return true;
}
