import { gql } from '@apollo/client';
import { apolloClient, updateCacheAfterInsert } from 'services/apollo';

import { GET_ORDERS } from './orderFetch';
import { orderAllColumns } from '@constants';
import { Order, OrderInsertObject, OrderChanges } from 'types';

export const ADD_ORDER = gql`
	mutation ADD_ORDER ( 
		$newObjs: [order_insert_input!]!
	) {
		insert_order (
			objects: $newObjs
		) {
			returning {
				${orderAllColumns}
			}
		}
	}
`;



export const addNewOrder = (newOrder: OrderInsertObject): Promise<Order> => {
	return apolloClient.mutate({ 
		mutation: ADD_ORDER, 
		variables: {
			newObjs: [newOrder]
		},	
		update(cache, result) {
			updateCacheAfterInsert(cache, result, 'order', GET_ORDERS, { status: 'active' });
		}
	}).then( (queryResponse) => {
		return queryResponse?.data?.insert_order?.returning?.[0] ?? {};
	});
};


const UPDATE_ORDER = gql`
	mutation UPDATE_ORDER ( 
		$id: Int!
		$changes: order_set_input!
	) {
		update_order (
			where: { id: { _eq: $id }}
			_set: $changes
		) {
			returning {
				${orderAllColumns}
			}
		}
	}
`;

export function changeOrder(id: number, changes: OrderChanges) {
	apolloClient.mutate({ 
		mutation: UPDATE_ORDER,
		variables: {
			id,
			changes: changes
		}
	});
}


const UPDATE_ORDER_STATUS = gql`
	mutation UPDATE_ORDER_STATUS ( 
		$id: Int!		
		$status: String
		$stop_at: timestamptz
	) {
		update_order (
			where: { 
				id: { _eq: $id }
			},
			_set: {				
				status: $status				
				stop_at: $stop_at
			}
		) {
			returning {
				${orderAllColumns}
			}
		}
	}
`;

export const updateOrderStatus = (status: string, itemId: number) => {
	
	if (itemId) {
		return apolloClient.mutate({
			mutation: UPDATE_ORDER_STATUS,
			variables: {
				id: itemId,				
				status: status,
				stop_at: status === 'complete' ? new Date() : null
			},
			refetchQueries: [
				{ query: GET_ORDERS, variables: { status: 'active' } }
			]
		}).then( (queryResponse) => {
			return queryResponse?.data?.update_order?.[0] ?? {}
		});
	}
};
