import React, { useCallback } from 'react';
import styled from 'styled-components';
import { UIPage, UIHeading, useDrawer, UIFlexbox, UITabs, UITabBody, UIForm2, UIInput, UITextArea, UIAlert } from 'kit';
import { DragDropContext } from 'react-beautiful-dnd';

import { WorkflowDragDrop } from 'root/components/WorkflowEditor/WorkflowEditorMainPanel';
import { StepFormInDrawer } from 'components/StepSetup/StepForm';
import { ImportWorkflowButtonModal } from './ImportWorkflowButtonModal';
import { DuplicateWorkflowButtonModal } from './DuplicateWorkflowButtonModal';
import { WorkflowOptionsCardModal } from './WorkflowOptions';
import { StepSourceList } from './WorkflowEditorSources';

import { useWorkflowEdit } from './useWorkflowEdit';
import { WorkflowEditorContext } from './WorkflowEditorContext';
import { TagWorkflowDeviationModal } from './TagWorkflowDeviationModal';

export const WorkflowEditor = (
) => {	
	const drawerContextForm = useDrawer();

	const wfContext = useWorkflowEdit(); // the draft route doesn't use the id parameter, so fill this in if id is undefined
		const { 
			dynamicListContext, formMethods, leftPanelTabContext, tagModalProps, 
			type, item, workflowValidationErrors
		} = wfContext;

	const description = type === 'job' ? 'Custom workflow' : item.description;

	const hasBrokenLink = workflowValidationErrors?.filter( err => err.hasBrokenLink )?.length > 0;

	const showStepsList = useCallback( () => {
		leftPanelTabContext.setTabIndex(1);
	}, [])


	return (
		<WorkflowEditorContext.Provider value={wfContext}>
		<UIPage id="workflow-edit" fullHeight asFlexbox>
			<StepFormInDrawer
				drawerContextForm={drawerContextForm}
			/>

			<DragDropContext onDragEnd={dynamicListContext.onDragEnd}>	
				<WFEditorLeftPanel flexDirection="column">
					<WFOverview>
						<UIHeading level="2">{item.name ?? <i>Unnamed workflow</i>}</UIHeading>
						<p>{description ?? <i>No description.</i>}</p>
						<p>{item.steps?.length ? item.steps?.length + ' step' + (item.steps?.length > 1 ? 's' : '') : 'No steps.'} </p>
						{hasBrokenLink && <UIAlert title="Broken step links" variant="error" />}
					</WFOverview>
					<UITabs
						tabContext={leftPanelTabContext}
						tabDefs={[{ label: 'General' }, { label: 'Steps' }]}
					/>
					<TabBodyContainer data-cy="workflow-editor-left-content">
						<UITabBody bodyIndex={0} padding="medium" tabContext={leftPanelTabContext} keepMounted>
					
							<UIForm2 formMethods={formMethods}>
								{type === 'workflow' && 
									<>
										<UIInput name="name" label="Name" required />
										<UITextArea name="description" label="Description" />
									</>
								}
							</UIForm2>

							<WorkflowOptionsCardModal />
							<UIFlexbox flexJustify="space-evenly">
								<ImportWorkflowButtonModal />
								{type === 'workflow' &&
									<DuplicateWorkflowButtonModal 
										workflow={item} 
									/>
								}
							</UIFlexbox>
						</UITabBody>
						<UITabBody bodyIndex={1} padding="small" tabContext={leftPanelTabContext}>
							<StepSourceList />
						</UITabBody>
					</TabBodyContainer>
				</WFEditorLeftPanel>

				<WFEditorMainContainer>
					<WorkflowDragDrop 
						showStepsList={showStepsList}
						drawerContextForm={drawerContextForm}
					/>
				</WFEditorMainContainer>
			
			
			</DragDropContext>	

			{type === 'job' && 
				<TagWorkflowDeviationModal modalProps={tagModalProps} />
			}
		</UIPage>
		</WorkflowEditorContext.Provider>
	)
}

const WFEditorLeftPanel = styled(UIFlexbox)`
	box-sizing: border-box;
	background-color: white;
	width: 350px;
	${props => props.theme.shadow.depth2}
	z-index: 20;
`;

const WFEditorMainContainer = styled(UIFlexbox)`
	background: ${props => props.theme.colors.colorDefinitions.grey1};
	width: calc(100vw - 350px);
	height: 100%;
	position: relative;
	overflow: auto;
	${props => props.theme.scrollbar.light} 
`;



const WFOverview = styled.div`
	box-sizing: border-box;
	padding: 2rem;
	background-color: #626769;
	color: white;
	*:last-child {
		margin-bottom: 0;
	}
`;

const TabBodyContainer = styled.div`
	height: 100%;
	overflow: auto;
	${props => props.theme.scrollbar.light} 
`;
