export function roundWithDecimal(value: number, decimal_places: number = 0): number{
	if (decimal_places === 0){
		return Math.round(value);
	}

	const factor = Math.floor(10 * decimal_places);
	if (decimal_places < 0){
		return Math.round(value / factor) * factor;
	}
	return parseFloat((Math.round(value * factor) / factor).toFixed(decimal_places));
}