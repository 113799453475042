import create from 'zustand';
import { useAuthStore } from 'store';
import { User } from 'types';

type GhostStore = {
	orgId: number
	setOrgId
	role: string
	setRole
	userId: string
	setUserId
}

export const useGhostStore = create<GhostStore>(set => ({
	orgId: process.env.REACT_APP_HASURA_ORG_ID,
	setOrgId: newVal => set({ orgId: newVal }),
	role: process.env.REACT_APP_HASURA_ROLE,
	setRole: newVal => set({ role: newVal }),
	userId: process.env.REACT_APP_HASURA_USER_ID,
	setUserId: newVal => {
		useAuthStore.setState({ internalUser: { id: newVal } as User });
		set({ userId: newVal });
	}
}))


if (process.env.NODE_ENV === 'development') {
	// send user id over to user object for potential database operations
	useGhostStore.getState().setUserId(process.env.REACT_APP_HASURA_USER_ID);
}