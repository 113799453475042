import React, { useState, useMemo } from 'react';

import { Table } from 'components/Table/Table';
import { TableActionButton } from 'components/Table/TableActionButton';
import { partCols } from 'components/Table/tableRef';

import { useTags, useThingsAll } from 'hooks';

import { Part } from 'types';


export function PartTable({ actionType, actionFunction, adjustActionFunction, selectedItems = [], isRaw = false, isProduct = false }: {
	actionType: 'edit' | 'add'
	actionFunction(item?: Part): void
	adjustActionFunction?(item: Part): void
	selectedItems?
	isRaw?: boolean
	isProduct?: boolean
}) {
	const [selectedIndex, setSelectedIndex] = useState(null);

	const { tags, tableHelperMemoToTagNames } = useTags('partRaw');

	const { items: parts } = useThingsAll(isRaw ? 'partRaw' : 'partProduct');
	
	function onRowSelect(obj = {}, index = null) {
		if (actionType === 'edit') {
			setSelectedIndex(index);
		}
	}

	const columns = useMemo(() => [ {
			Header: '',
			id: 'actions',
			className: 'center-align show-on-hover one-line',
			Cell: table => {
				const item = table.row.original;
				return (
					<>
						<TableActionButton type={actionType} onClick={actionFunction.bind(this, item)} />
						{typeof adjustActionFunction === 'function' && <TableActionButton type="adjustQty" onClick={adjustActionFunction.bind(this, item)} />}
					</>
				);
			}
			//@ts-ignore
		} ].concat(partColumnDefs)
	, [parts.length, selectedItems.length]); // The action fuction uses current state, need to refresh the function whenever state changes
	
	let data = useMemo(() => tableHelperMemoToTagNames(parts), [parts, tags.length]);

	return (
		<Table 
			columns={columns} 
			data={data} 
			hasSortableColumns 
			hasGlobalFilter 
			hasPagination 
			hasSelectablePageSize 
			selectedIndex={selectedIndex} 
			onRowSelect={onRowSelect} 
			hasFilterableColumns 
		/>
	);
}


const partColumnDefs = [
	partCols.name,
	partCols.category,
	partCols.description,
	partCols.partNo,
	partCols.qty,
	partCols.min,
	partCols.max,
	partCols.updatedAt
];