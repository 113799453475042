import React from 'react';
import { useState } from 'react';

import { useQuery } from '@apollo/client';
import { GET_TAGS_BY_TABLE } from '../tagFetch';

import { Tag } from 'types';
import { ThingName, thingLookup } from '@constants';
import { deepCopy, getObjectFromArray, capitalizeFirstLetter } from 'utility';
import { useModal, UIButton, UIModal, UIInput, UISelect, useUIForm, UIForm2, UIHeading, SaveButtonBlock } from 'kit';

import { Cogs } from '@styled-icons/fa-solid/Cogs';
import { AddCircle } from '@styled-icons/remix-line/AddCircle';
import { addTag } from '../tagMutate';


export const useTags = (tagType: ThingName, shouldSkip?: boolean) => {

	const {
		loading: isTagsLoading,
		data: { tag: tags } = { tag: [] as Array<Tag> }
	} = useQuery(GET_TAGS_BY_TABLE, {
		variables: {
			table_id: thingLookup[tagType].sharedTableId
		},
		skip: shouldSkip
	});


	function replaceTagIdsWithNames(tagIdArray: number[] = []) {
		if (tags.length === 0) { return [] }
		return tagIdArray.map( tagId => {
			const tag: Tag = getObjectFromArray(tags, 'id', tagId );
			return tag.name;
		});
	}

	/** Assumes that "tags" key is in first level of object and consists of array of IDs */
	function tableHelperMemoToTagNames(itemList: Array<any> = []) {
		let itemListWithTagNames = deepCopy(itemList);
		itemListWithTagNames.forEach( (item, index) => {
			const tagNameArray = replaceTagIdsWithNames(item.tags);
			itemListWithTagNames[index].tagNames = tagNameArray;
		});
		return itemListWithTagNames;
	}

	return {
		isTagsLoading,
		tags,
		replaceTagIdsWithNames,
		tableHelperMemoToTagNames
	};
};

export function EditTags(
	{ tagType }:{ tagType: ThingName }
) {
	const modalProps = useModal();
	const [ newItem, addNewItem ] = useState(false)
	const [ displayName, setDisplayName ] = useState(false)
	const [ selectedTag, setSelectedTag ] = useState({} as Tag)
	const { formMethods, isDirty } = useUIForm(selectedTag, { resetTrigger: selectedTag.id })
	
	const {
		loading: isTagsLoading,
		data: { tag: tags } = { tag: [] as Array<Tag> }
	} = useQuery(GET_TAGS_BY_TABLE, {
		variables: {
			table_id: thingLookup[tagType].sharedTableId
		}
	});
	
	//set to default
	const toggle = event => {		
		addNewItem(false)
		setDisplayName(false)
		setSelectedTag({} as Tag)
		modalProps.toggleModal()
	}
	
	const addNew = boolean => {
		setDisplayName(true)
		addNewItem(true)
	}

	const selectTag = tagId => {		
		setDisplayName(tagId)
		setSelectedTag(getObjectFromArray(tags, 'id', tagId))
	}

	const submit = formData => {		
		let tagId = formData.tag
		if (newItem) {tagId = null}
		addTag( tagType, formData.name, tagId)
		modalProps.toggleModal();
	}

	return (
		<React.Fragment>
			<UIButton outline muted label="Category" icon={<Cogs/>} onClick={toggle.bind(this)}/>

			<UIModal {...modalProps} isFormDirty={isDirty} >
				<UIHeading>{capitalizeFirstLetter(tagType)} Categories</UIHeading>
				{!displayName && <UIButton outline label="Add New Category" icon={<AddCircle />} onClick={addNew.bind(this)} margin="mediumVertical" />}
				
				<UIForm2 formMethods={formMethods} key={'tag_' + selectedTag.id}>

					{!newItem &&
					<UISelect
						name="tag"
						label="Select a Category to edit"
						defaultValue={selectedTag.id || 0}
						options={tags}
						isLoading={isTagsLoading}
						onChange={selectTag.bind(this)}
						hasConditionalComponents={false}
						scrollOnOpen
					/>}

					{displayName && <UIInput name="name" label={newItem ? 'New Category' : 'Update Category'} required />}

					<SaveButtonBlock onSave={formMethods.handleSubmit(submit)} onCancel={modalProps.toggleModal} />

				</UIForm2>
			</UIModal>

		</React.Fragment>
	)
}