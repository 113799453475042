
import React from 'react';

import { UIButton } from 'kit';
import { AddCircle } from '@styled-icons/remix-line/AddCircle';

import { MainRouteName, FullRouteName, useNavMain, useNavFull } from 'routing';
import { ThingName, thingLookup } from '@constants';

export const AddNewItemButton = (	
	{ type, routeName, fullRouteName, onClick, overrideLabel, className }: {
		type: ThingName
		routeName?: MainRouteName
		fullRouteName?: FullRouteName
		onClick?
		overrideLabel?: string
		className?: string
	}
) => {
	let handleClick;
	if (routeName) {
		handleClick = useNavMain(routeName);
	}
	else if (fullRouteName) {
		handleClick = useNavFull(fullRouteName);
	}
	else if (typeof onClick === 'function') {
		handleClick = onClick;
	}
	
	return (
		<UIButton 
			icon={<AddCircle />} 
			onClick={handleClick} 
			label={overrideLabel ? overrideLabel : `Add New ${thingLookup[type].nameSingle}`} 
			className={className}
		/>		
	)
}
