
import { gql } from '@apollo/client';
import { apolloClient } from 'services/apollo';

import { GET_JOBS } from '@constants/gql';
import { stepHistoryAllColumns, stepHistoryYieldAllColumns, partTransactionAllColumns, partWipTransactionAllColumns } from '@constants';
import { detachInventoryFragment, convertInventoryFragment } from 'services/stepInventoryUniqueEffects';
import { isLastStepInJob } from 'components/WorkflowEditor';

import { Job, StepPlan, PartRawEffect, InventoryUniqueItem, PartWIP, PartTransactionInsertObject_ReleaseToFGI, PartWipTxInsertObject, PartTransactionInsertObject_RawStep, StepHistoryRaw } from 'types';
import { useAuthStore } from 'store';
import { GET_JOB_WIP } from './stepStart';
import { getObjectFromArray } from 'utility';
import { jobAllColumns } from '@constants';



export async function stopStep (
	stepHistory: StepHistoryRaw, 
	stepPlan: StepPlan, 
	currentJob: Job, 
	overridePartRawEffect: PartRawEffect[] = []
): Promise<boolean> 
{
	const isLastStep = currentJob.steps.length === currentJob.current_step_index || isLastStepInJob(currentJob.steps, currentJob.current_step_index);
	const inventoryUniqueDetachPlanned = currentJob.workflow_options?.inventory_unique?.detach.step_index === currentJob.current_step_index;
	const convertPlan = getObjectFromArray(currentJob.workflow_options?.inventory_unique?.converts ?? [], 'step_index', currentJob.current_step_index);
	const inventoryUniqueConvertNeeded = typeof convertPlan?.step_index === 'number';
	const isYieldCompletionNeeded = stepHistory.step_history_yields?.length > 0;
	const isTransactingToFGI = isLastStep && currentJob.workflow_options.product_enabled;
	
	const inventoryEffects = overridePartRawEffect || stepPlan?.inventory_effect?.items || [] as PartRawEffect[];
	const hasPartRawTx = inventoryEffects.length > 0;
	const hasPartTx = isTransactingToFGI || hasPartRawTx;
	const hasPartWipTx = isTransactingToFGI;

	// fetch wip info
	let uniqueItems = [] as InventoryUniqueItem[];
	let partsInWip = [] as PartWIP[];
	if (isYieldCompletionNeeded || isTransactingToFGI || inventoryUniqueDetachPlanned 
		|| isLastStep /**Double check regardless of the situation if it's the last step, so we can remove any wip when the job closes */
	) {
		const response = await apolloClient.query({ 
			query: GET_JOB_WIP, 
			variables: { 
				jobId: stepHistory.job_id,
				wipType: 'wip'
			},
			fetchPolicy: 'network-only'
		})
		uniqueItems = response.data?.inventory_unique ?? [];
		partsInWip = response.data?.part_wip ?? [];
	}
	
	// Generate the stop step mutations
	
		const STOP_STEP = gql`
			mutation STOP_STEP( 
				$id: Int!
				$jobId: Int!
				$nextStepIndex: Int!
				$stopUserId: String!
				${hasPartTx 	? '$txs: [part_tx_insert_input!]!' : ''}
				${hasPartWipTx 	? '$wipTxs: [part_wip_tx_insert_input!]!' : ''}
			) {
				update_step_history(
					where: { 
						id: { _eq: $id }
					},
					_set: {
						stop_user_id: $stopUserId
						stop_at: "now()"
					}
				){
					affected_rows
					returning {
						${stepHistoryAllColumns}
					}
				}
				update_job (
					where: { 
						id: { _eq: $jobId }
					},
					_set: {
						current_step_index: $nextStepIndex
					}
				) {
					returning {
						${jobAllColumns}
					}
				}
				# TODO...should this force detach be recorded?
				${inventoryUniqueDetachPlanned || (isLastStep && uniqueItems.length > 0) ? detachInventoryFragment(currentJob.workflow_options.inventory_unique?.detach?.status, uniqueItems) : ''}
				${inventoryUniqueConvertNeeded ? convertInventoryFragment(convertPlan, stepHistory.inventory_unique_items) : ''}
				${isYieldCompletionNeeded ? genYieldUpdateFragment(stepHistory, partsInWip) : ''}
				${hasPartRawTx || isTransactingToFGI ? `
				insert_part_tx (
					objects: $txs
				) {
					returning {
						${partTransactionAllColumns}
					}
				}` : ''}
				
				${hasPartWipTx ? `
				insert_part_wip_tx (
					objects: $wipTxs
				) {
					returning {
						${partWipTransactionAllColumns}
					}
				}` : ''}
			}
		`;
	
	// Assign Variables
		const currentUser = useAuthStore.getState().internalUser.id;

		let variables: any = {
			id: stepHistory.id, 
			stopUserId: currentUser, 
			nextStepIndex: stepHistory.job_step_index + 1,
			jobId: currentJob.id
		};

		if (isTransactingToFGI) {
			const fgiTxs: PartTransactionInsertObject_ReleaseToFGI[] = partsInWip.map( partWip => (
				{
					type: 'job_release',
					part_id: partWip.part_id, 
					qty_change: partWip.qty,
					job_id: currentJob.id,
					order_item_id: partWip.order_item_id
				}
			));
			
			const wipTxs: PartWipTxInsertObject[] = partsInWip.map( partWip => (
				{
					wip_type: 'wip',
					type: 'job_release',
					part_id: partWip.part_id, 
					qty_change: (partWip.qty * -1),
					job_id: currentJob.id,
					order_item_id: partWip.order_item_id
				}
			));

			variables.txs = fgiTxs;
			variables.wipTxs = wipTxs;
		}

		if (hasPartRawTx) {
			const partRawTxs: PartTransactionInsertObject_RawStep[] = inventoryEffects.map( effect => (
				{
					type: 'step_raw',
					part_id: effect.id, 
					qty_change: (effect.qty_change),
					job_id: currentJob.id,
					step_history_id: stepHistory.id
				}
			));

			variables.txs = variables.txs ? variables.txs.concat(partRawTxs) : partRawTxs;
		}

	// Execute the mutations

	return apolloClient.mutate({ 
		mutation: STOP_STEP, 
		variables,
		refetchQueries: isLastStep ? [
			{ query: GET_JOBS, variables: { id: currentJob.id } } //refetch the job so that product transactions show up
		] : [], 
		awaitRefetchQueries: false
	}).then( mutationData => {
		return true;
	}).catch( error => {
		return false
	});
}


function genYieldUpdateFragment(stepHistory: StepHistoryRaw, partsInWip: PartWIP[]){
	let updateYieldGQL = ``;

	stepHistory.step_history_yields?.forEach( yieldItem => {
		const wipItem: PartWIP = getObjectFromArray(partsInWip, 'part_id', yieldItem.part_id);
		updateYieldGQL += `
			update_step_history_yield_${yieldItem.part_id}: update_step_history_yield (
				where: { 
					id: { _eq: ${yieldItem.id} }
				},
				_set: {
					qty_out: ${wipItem.qty}
				}
			) {
				returning {
					${stepHistoryYieldAllColumns}
				}
			}
		`
	});
	return updateYieldGQL;
}
