import React, { useContext } from 'react';
import { UIRadioList, UIAlertContainer } from 'kit';


import { useFormContext } from 'react-hook-form';
import { StepPlan, StepParameterMethod } from 'types';
import { ParameterSelectSingle } from './ParameterComponentsSelectSingle';
import { StepFormContext } from './StepFormContext';
import { BrokenLinkAlertBanner } from './BrokenLinkAlertBanner';


export const ParameterSetup = (	
	props: {
		parameterName: 'recipe' | 'tool'
		stepPlan: StepPlan
		stepId: number
	}
) => {
	const { validationErrors } = useContext(StepFormContext);
	const linkErrorMessages = validationErrors?.linkErrors?.[props.parameterName] || [];

	return (
		<React.Fragment>
			<UIAlertContainer>
				{linkErrorMessages.map( err => {
					return (
						<BrokenLinkAlertBanner
							key={err.invalidLinkId} 
							err={err}
							categoryName={props.parameterName}
						/> 
					)
				})
				}
			</UIAlertContainer>
			<UIRadioList 
				name={`${props.parameterName}.method`}
				label="Selection Mode"
				subtitle={`Choose how the ${props.parameterName} will be selected in this step.`}
				options={[
					{ 
						name: 'Fixed', id: 'fixed', 
						subtitle: `Set the ${props.parameterName} now. When this step is placed in a workflow, the ${props.parameterName} will be fixed to the selected value.` 
					},
					{ 
						name: 'Select at run time', id: 'flex_operator', 
						subtitle: `The user selects the ${props.parameterName} during the job, when the step is about to start. You can provide a default ${props.parameterName} for the user, as well as filter down the list of eligible ${props.parameterName}s.` 
					},
					{ 
						name: 'Select at job creation', id: 'flex_engineer', 
						subtitle: `The user selects the ${props.parameterName} when the job is created, during the job review process. You can provide a default ${props.parameterName} for the user, as well as filter down the list of eligible ${props.parameterName}s.` 
					}
				]}
				required
			/>

			
			<MethodConditional parameterName={props.parameterName} stepPlan={props.stepPlan} stepId={props.stepId} />
			
		</React.Fragment>  
	);
}



const MethodConditional = (	
	props: {
		parameterName: 'recipe' | 'tool'
		stepPlan: StepPlan
		stepId: number
	}
) => {
	const { watch } = useFormContext();
	const method = watch(`${props.parameterName}.method`) as StepParameterMethod;


	if (method === 'fixed') {
		return (
			<ParameterSelectSingle
				key="fixed"
				parameterName={props.parameterName}
				nameForForm={props.parameterName + '.value'}
				defaultValue={props.stepPlan?.[props.parameterName]?.value}
				label={'Set ' +  props.parameterName + ': '}
				subtitle={`Set ${props.parameterName} to be fixed for this step`}
				stepId={props.stepId}
				required
				scrollOnOpen
			/>
		)
	} 
	else if (method === 'flex_operator') {
		return (
			<ParameterSelectSingle
				key="flex_operator"
				parameterName={props.parameterName}
				nameForForm={props.parameterName + '.default_value'}
				label={'Default ' +  props.parameterName }
				subtitle={`The default value will be pre-selected, but can be changed by the user at the beginning of the step.`}
				defaultValue={props.stepPlan?.[props.parameterName]?.default_value}
				stepId={props.stepId}
				required={false}
				scrollOnOpen
			/>
		)
	}
	else if (method === 'flex_engineer') {
		return (	
			<ParameterSelectSingle
				key="flex_operator"
				parameterName={props.parameterName}
				nameForForm={props.parameterName + '.default_value'}
				label={'Default ' +  props.parameterName}
				subtitle={`The default value will be pre-selected, but can be changed by the user at job creation.`}
				defaultValue={props.stepPlan?.[props.parameterName]?.default_value}
				stepId={props.stepId}
				required={false}
			/>
		)
	}
	return null;
}