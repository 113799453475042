import React, { useState } from 'react';
import styled from 'styled-components';

import { UICard, UIKeyValue, UIInputControlled, UIFlexChild } from 'kit';

import { DragDropContext } from 'react-beautiful-dnd';

import { UIDroppable, UIDraggable, DynamicListContext } from 'hooks';
import debounce from 'lodash.debounce';


export const FormFieldSelectOptionsDragDrop = (
	{ dynamicListContext }: {
		dynamicListContext: DynamicListContext<string>;
	}
) => {

	const { rows: selectOptionList, addRow, AddButton, onDragEnd, selectedIndex, setSelectedIndex } = dynamicListContext;

	//add a new option field
	const addSelectOption = () => {
		addRow('');
		setSelectedIndex(selectOptionList.length);
	};

	return (
		<DragDropContext onDragEnd={onDragEnd}>
			<UICard width="100%">
				<UIKeyValue title="Select Options" text="Enter options for select" />

				<UIDroppable
					name="destination"
					readonly={false}
					containerDragOverStyle={{ backgroundColor: '#b0daea', borderRadius: '10px' }}
				>
					{selectOptionList.map((optionString, index) => {
						return (
							<UIDraggable
								index={index}
								name={'dest_' + index}
								clone={false}
								key={'drag_' + index}
								content={<SelectOptionItem
									key={index + '_' + selectedIndex}
									index={index}
									dynamicListContext={dynamicListContext}
								/>} 
							/>
						);
					})}
				</UIDroppable>

				<AddButton label="Add Select Option" onClick={addSelectOption} />
			</UICard>
		</DragDropContext>
	);
};



export const SelectOptionItem = (
	{ index, dynamicListContext }: {
		index: number
		dynamicListContext: DynamicListContext<string>
	}
) => {
	const { rows: selectOptionsList, DeleteButton, editRow } = dynamicListContext;
	
	const [ inputText, setInputText ] = useState(selectOptionsList[index]);


	function commitValue(newValue) {
		editRow(index, newValue);
	}

	const commitValueDebounced = debounce(commitValue, 400);
	
	const handleChange = newText => {
		setInputText(newText);
		commitValueDebounced(newText);
	}

	return (
		<StyledUIFlexChild asFlexbox flexDirection="row" flexAlign="center" padding="small">
			<StyledUIInput
				name={'Option ' + index}
				value={inputText}
				onChange={handleChange}
			/>
			<DeleteButton index={index}/>
		</StyledUIFlexChild>
	)
}

const StyledUIFlexChild = styled(UIFlexChild)`
	margin: 0px;
	padding: 0px;
`

const StyledUIInput = styled(UIInputControlled)`
	margin: 0px;
	padding: 10px 0px;
`
