import { format, isToday } from 'date-fns';
import humanizeDuration from 'humanize-duration';


interface DateFormatLookup {
	/** Tuesday, March 18, 2020 7:51 AM GMT-08:00 */
	dateTimeFullTZ: string

	/** Tuesday, March 18, 2020 7:51 AM */
	dateTimeFull: string

	/** Mar 18, 2020 7:51 AM */
	dateTimeShort: string

	/** 3/18/20 7:51 AM */
	dateTimeSlash: string

	/** 3/18/20 */
	dateSlash: string

	/** Mar 18 */
	dateShortNoYear: string

	/** Mar 18, 2020 */
	dateShortWithYear: string

	/** March 18, 2020 */
	dateWithYear: string

	/** 7:51 AM if today, Mar 18 if otherwise */
	timeTodayDateOther: string

	/** 7:51 AM if today, or Mar 18, 2020 */
	timeTodayDateWithYearOther: string

	/** 7:51 AM*/
	time: string
  }


export type DateFormat = keyof DateFormatLookup;

// https://date-fns.org/docs/format
const dateFnsFormat: DateFormatLookup = {
	dateTimeFullTZ: 'EEEE, MMMM d, yyyy hh:mm a zzzz',
	dateTimeFull: 'EEEE, MMMM d, yyyy hh:mm a',
	dateTimeShort: 'MMM d, yyyy hh:mm a',
	dateTimeSlash: 'MM/dd/yy hh:mm a',
	dateSlash: 'MM/dd/yy',
	dateShortNoYear: 'MMM d',
	dateShortWithYear: 'MMM d, yyyy',
	dateWithYear: 'MMMM d, yyyy',
	timeTodayDateOther: 'hh:mm a',
	timeTodayDateWithYearOther: 'hh:mm a',
	time: 'hh:mm a'
}

/**
 * 
 * @param ISOdateString dateTimeShort
 * @param formatType 
 */

export function dateFormat(ISOdateString: string | number | Date, formatType: DateFormat): string {
	if (!ISOdateString) {
		return '';
	}
	const dateObj = new Date(ISOdateString);

	let formatString = dateFnsFormat[formatType];
	if (formatType === 'timeTodayDateOther' && !isToday(dateObj)){
		formatString = dateFnsFormat.dateShortNoYear;
	}
	else if (formatType === 'timeTodayDateWithYearOther' && !isToday(dateObj)){
		formatString = dateFnsFormat.dateShortWithYear;
	}

	return format(dateObj, formatString);
}

export function elapseTime(startISOdateString, finishISOdateString, isFullWord = false, returnNumber = false) {
	if (typeof startISOdateString === 'undefined' || startISOdateString === null) { return ''; }
	if (typeof finishISOdateString === 'undefined' || finishISOdateString === null) { return ''; }
	
	var start = new Date(startISOdateString);	
	var finish = new Date(finishISOdateString);
	var duration = finish.getTime() - start.getTime();

	//return as milliseconds
	if (returnNumber) {return duration;}
	
	//humanize duration
	const languagesDef = {		
		shortHMS: {				
			h: function() { return 'hr' },
			m: function() { return 'mins' },
			s: function() { return 'sec' }
		}
	}
	let l = isFullWord ? 'en' : 'shortHMS'	

	const hour = 60 * 60 * 1000;
	var u = (duration > hour) ? ['h', 'm'] : ['h', 'm', 's'];
	var d = humanizeDuration(duration, { language: l, languages: languagesDef, units: u, round: true });
	return d;
}

export function durationString(startISOdateString, isFullWord = false, returnNumber = false) {
	if (typeof startISOdateString === 'undefined' || startISOdateString === null) { return ''; }	
	
	var duration = new Date(startISOdateString).getTime();
	
	//return as milliseconds
	if (returnNumber) {return duration;}
	
	//humanize duration
	const languagesDef = {		
		shortHMS: {				
			h: function() { return 'hr' },
			m: function() { return 'mins' },
			s: function() { return 'sec' }
		}
	}
	let l = isFullWord ? 'en' : 'shortHMS'	

	const hour = 60 * 60 * 1000;
	var u = (duration > hour) ? ['h', 'm'] : ['h', 'm', 's'];
	var d = humanizeDuration(duration, { language: l, languages: languagesDef, units: u, round: true });
	return d;
}

/** 
 * @return {integer} seconds
 */
export function currentUnixTime(){
	return Math.floor(Date.now() / 1000);
}