import { useState, useEffect } from 'react';

import { useQuery } from '@apollo/client';
import { GET_JOBS, GET_PARTS_IN } from '@constants/gql';
import { GET_JOB_WIP } from '../stepStart';
import { queryPartFromCache } from '../partStockFromCache';

import { Job, InventoryUniqueItem, PartWIP, Part, Order } from 'types';
import { GET_ORDERS_BY_LINE_ITEMS } from '../orderFetch';


export const useJobDetails = (jobId: number) => {
	
	const { 
		data: { job: [job = {} as Job] } = { job: [] }
	} = useQuery(GET_JOBS, { 
		variables: { id: jobId }
	});

	const isJobFetched = typeof job.id === 'number';

	const { 
		data: { 
			inventory_unique: attachedItems, 
			part_wip: productWipResult 
		} = { 
			inventory_unique: [] as InventoryUniqueItem[], 
			part_wip: [] as PartWIP[]
		}
	} = useQuery( GET_JOB_WIP, {
		variables: { jobId },
		skip: !isJobFetched // can't skip if job is complete because reserve inventory remains reserved even after job complete
	} );
	
	const { parts } = usePartsInJob(job);

	const productWip = productWipResult.filter( wip => wip.type === 'wip' );
	const productReserve = productWipResult.filter( wip => wip.type === 'reserve' );

	//  Determine order association
	let orderItemIds = [];
	if (isJobFetched) {
		// Fulfilled
		orderItemIds = job.part_txs 
			.filter( tx => tx.type === 'fulfill' && tx.order_item_id)
			.map( tx => tx.order_item_id );

		// reserved
		orderItemIds = orderItemIds.concat(
			productReserve.map( wip => wip.order_item_id )
		)
	}
	
	const { 
		data: { order: orders } = { order: [] as Order[] }
	} = useQuery( GET_ORDERS_BY_LINE_ITEMS, {
		variables: { lineItemIds: orderItemIds },
		skip: orderItemIds.length === 0
	} );
	
	return {
		job,
		attachedItems,
		productWip,
		productReserve,
		parts,
		orders,
		isJobActive: job.job_status === 'active'
	};
};

/**
 * Use this sparingly if possible. It will fetch parts from cache, but won't subscribe to cache updates.
 * Best for things like part name.
 * @param partIds 
 */
export const usePartsInJob = (job: Job) => {
	const partIds = job.workflow_options?.product?.selections?.map( sel => sel.part_id ) ?? [];

	const [areAllInCache, setAreAllInCache] = useState(true);
	const [parts, setParts] = useState<Part[]>([]);

	useQuery( GET_PARTS_IN, {
		variables: { ids: partIds },
		skip: areAllInCache || partIds.length === 0,
		onCompleted: data => {
			setParts(data?.part || []);
			setAreAllInCache(true);
		}
	} );

	useEffect(() => {
		// set state from cache
		let allInTemp = true
		let partsTemp = [];
		partIds.forEach( partId => {
			const cachedPart = queryPartFromCache(partId);
			if (typeof cachedPart?.id !== 'number') {
				allInTemp = false;
			}
			else {
				partsTemp.push(cachedPart);
			}
		});
		setAreAllInCache(allInTemp);
		setParts(partsTemp);
	}, [partIds.length])

	return {
		parts
	}
}