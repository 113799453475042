import { GET_JOBS, GET_JOBS_IN, UPDATE_JOB,
		GET_STEPS, GET_STEPS_IN, ADD_STEP, UPDATE_STEP, 
		GET_TOOLS, GET_TOOLS_IN, ADD_TOOL, UPDATE_TOOL, 
		GET_RECIPES, GET_RECIPES_IN, ADD_RECIPE, UPDATE_RECIPE,
		GET_RAW_PARTS, GET_PRODUCTS, GET_PARTS_IN, ADD_PART, UPDATE_PART
} from '@constants/gql';
import { GET_WORKFLOWS } from 'root/services/workflowFetch';
import { GET_ORDERS, GET_ORDERS_BY_IDS } from 'root/services/orderFetch';
import { GET_FORM_DEFINITIONS, GET_FORM_DEFINITIONS_BY_IDS } from 'root/services/formFetch';
import { GET_INVENTORY_UNIQUE, GET_INVENTORY_UNIQUE_BY_IDS } from 'root/services/inventoryUniqueFetch';
import { GET_CONTACTS } from 'root/services/contactFetch';
import { ThingName } from '../things';
import { DocumentNode } from 'graphql';
import { jobAllColumns, stepAllColumns, stepHistoryAllColumns, workflowAllColumns, toolAllColumns, recipeAllColumns, partAllColumns, orderAllColumns, contactAllColumns, formDefinitionAllColumns, inventoryUniqueAllColumns } from '../columns';


export const thingColsLookup: { [key in ThingName]: string } = {
	job: jobAllColumns,
	step: stepAllColumns,
	step_history: stepHistoryAllColumns,
	workflow: workflowAllColumns,
	tool: toolAllColumns,
	recipe: recipeAllColumns,
	partRaw: partAllColumns,
	partProduct: partAllColumns,
	order: orderAllColumns,
	contact: contactAllColumns,
	form: formDefinitionAllColumns,
	inventory_unique: inventoryUniqueAllColumns,
	deviation_tag: null
}

export const thingQueryLookupById: { [key in ThingName]: DocumentNode } = {
	job: GET_JOBS,
	step: GET_STEPS,
	step_history: null,
	workflow: GET_WORKFLOWS,
	tool: GET_TOOLS,
	recipe: GET_RECIPES,
	partRaw: GET_RAW_PARTS,
	partProduct: GET_PRODUCTS,
	order: GET_ORDERS,
	contact: GET_CONTACTS,
	form: GET_FORM_DEFINITIONS,
	inventory_unique: GET_INVENTORY_UNIQUE,
	deviation_tag: null
}

export const thingQueryLookupByIds: { [key in ThingName]: DocumentNode } = {
	job: GET_JOBS_IN,
	step: GET_STEPS_IN,
	step_history: null,
	workflow: null,
	tool: GET_TOOLS_IN,
	recipe: GET_RECIPES_IN,
	partRaw: GET_PARTS_IN,
	partProduct: GET_PARTS_IN,
	order: GET_ORDERS_BY_IDS,
	contact: null,
	form: GET_FORM_DEFINITIONS_BY_IDS,
	inventory_unique: GET_INVENTORY_UNIQUE_BY_IDS,
	deviation_tag: null
}

export const thingInsertLookup: { [key in ThingName]: DocumentNode } = {
	job: null,
	step: ADD_STEP,
	step_history: null,
	workflow: null,
	tool: ADD_TOOL,
	recipe: ADD_RECIPE,
	partRaw: ADD_PART,
	partProduct: ADD_PART,
	order: null,
	contact: null,
	form: null,
	inventory_unique: null,
	deviation_tag: null
}

export const thingUpdateLookup: { [key in ThingName]: DocumentNode } = {
	job: UPDATE_JOB,
	step: UPDATE_STEP,
	step_history: null,
	workflow: null,
	tool: UPDATE_TOOL,
	recipe: UPDATE_RECIPE,
	partRaw: UPDATE_PART,
	partProduct: UPDATE_PART,
	order: null,
	contact: null,
	form: null,
	inventory_unique: null,
	deviation_tag: null
}