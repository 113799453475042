import { StepPlan } from './stepInterfaces';

export interface Workflow extends WorkflowInfo {
	steps: Array<WorkflowDatabaseStep>
	workflow_options: WorkflowOptions
}

export interface WorkflowInfo {
	id: number
	created_at: string
	updated_at: string
	name: string
	description: string
	is_active: boolean
}

// ------------------------------------- Mutation Interfaces ---------------------------------

export interface WorkflowInsertUpdateObj {
	name: string;
	description?: string
	is_active?: boolean
	steps: Array<WorkflowDatabaseStep>
	workflow_options: WorkflowOptions
}

// ------------------------------------- Workflow Step ---------------------------------

export interface WorkflowDatabaseStep {
	step_id: number // Used in Saved Workflows
	plan?: StepPlan
	supp?: SupplementalStepInfo
}

export const currentSuppVersion = '2';
// schema.json should match the interface
export interface SupplementalStepInfo {
	version: '2' // Make sure this matches the above
	tool_value?: number
	recipe_value?: number
	workflow_mod?: {
		skip?: boolean
		add?: boolean
		rev_change?: {
			original_rev: number
		}
		reorder?: {
			prev_step_id: number
			next_step_id: number
		}
		user_id: string
		time: Date
	}
}

export type WorkflowStepModType = keyof SupplementalStepInfo['workflow_mod'];

export interface SupplementalStepInfoHistory {
	version: '1' // Make sure this matches the above
	tool_value?: number
	recipe_value?: number
}

// ------------------------------------- Workflow Options ---------------------------------

export const currentWorkflowOptionsVersion = '2';
// schema.json should match the interface
export type WorkflowOptions = WorkflowOptionsV2;

export interface WorkflowOptionsV2 {
	version: '2'; // Make sure this matches the above
	inventory_unique_enabled: boolean;
	inventory_unique?: {
		type_id: number;
		attach: {
			/**step at which to perform the attach operation */
			step_index: number;
			stage_ids?: number[];
			source: 'choose' | 'convert_part';
			/**If converting from a raw part, this is the part id */
			convert_from?: number;
			/**If converting from a raw part, this is the qty to consume */
			qty?: number;
			/** A helper value to store user input */
			staged_ids?: Array<number>;
		};
		detach: {
			/**step at which to perform the detach operation */
			step_index: number;
			/** Change the status to this value when the item detaches */
			status: 'stock' | 'consumed' | 'shipped' | 'scrapped';
		};
		converts?: ConvertPlan[];
	};
	product_enabled: boolean;
	product?: {
		method: 'fixed' | 'flex_job';
		// Required for method = fixed
		fixed_part_ids?: number[];
		// Required for method = fixed
		plans?: WorkflowProductPlan[];
		// Required for method = flex_job
		allowed_tags: number[];
		// Populated at job run time
		selections?: WorkflowProductSelection[];
	};
}

export type ConvertPlan = {
	/**step at which to perform the convert operation */
	step_index: number;
	/** Any item in the job will be converted to this stage id */
	dest_stage_id: number;
};

export type WorkflowProductPlan = {
	part_id: number;
	default_qty?: number;
	enforce_bom: boolean;
	transact_in_step: boolean;
};

export type WorkflowProductSelection = {
	part_id: number;
	/** processing qty that user selects at job create  */
	processing_qty: number;
	/** order that the job's product should be associated with, temp storage that user selects at job create  */
	order_item_id?: number;
	/** qty to reserve, temp storage that user selects at job create  */
	reserve_qty?: number;
};




export interface WorkflowOptionsV1 {
	version: '1'; 
	inventory_unique_enabled: boolean;
	inventory_unique?: {
		type_id: number;
	};
}





/**Revision?

export interface SavedStep {
	id: number
	name: string
	description?: string
	is_saved: boolean
	current_revision_id: number
	created_at: string
	updated_at: string
	current_revision: StepRevision
	step_revisions_aggregate: {
		aggregate: {
			count: number
		}
	}
}

export interface StepRevision {
	id: number
	step_definition_id: number
	plan: StepPlan
	created_at: string
	updated_at: string
	created_user: string
}

 * 
 */