
import { gql } from '@apollo/client';
import { contactAllColumns } from '@constants';


export const GET_CONTACTS = gql`
	query GET_CONTACTS {
		contact (
			order_by: { company_name: asc }
		) {
			${contactAllColumns}
		}
	}
`;