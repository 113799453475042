import React from 'react';
import styled from 'styled-components';

import { useModal, UIModal, UIIconButton, UIForm2, useUIForm, UIHeading, SaveButtonBlock } from 'kit';
import { EditOutline as Edit } from '@styled-icons/evaicons-outline/EditOutline';
import { EditAlt } from '@styled-icons/boxicons-regular/EditAlt';

import { RenderedFormField } from 'components/FormBuilder/RenderedFormField';

import { FormFieldSpec } from 'types';
import { prettifyKey, dateFormat } from 'utility';
import get from 'lodash.get';



export type ChangeSpec<ThingItem, ThingChanges> =
	/** Use the FormField Interface as a base for the actual input */
	Partial<FormFieldSpec> & 

	/** Additional info needed for the spec list */
	ChangeSpecKey<ThingItem, ThingChanges> & 
	
	{
		/** By default, the component will use the key as the value accessor. You can override it with the accessor prop. Dot notation can be used for nested values. */
		valueDisplayAccessor?: string
			
		/** Special formatting overrides */
		formatValue?: 'date' | 'datetime' | null
	}

// This enforces that isEditDisabled must be true if the key does not exist in the change object interface
type ChangeSpecKey<ThingItem, ThingChanges> = {
	key: keyof ThingItem
	isEditDisabled: true
} | {
	key: keyof ThingChanges
	isEditDisabled?: boolean
}


export interface UseChangeOptions<T, TChanges> {
	item: T
	specList: ChangeSpec<T, TChanges>[]
	saveFunction: (id: number, changes: TChanges) => void
	title: string

	idAccessor?: keyof T | 'id'
	onEditOpen?: () => void
}

export const useChange = <T, TChanges>( options: UseChangeOptions<T, TChanges>) => {
	const { 
		item, specList, saveFunction, title,
		idAccessor = 'id', 
		onEditOpen = () => {} 
	} = options;


	const modalProps = useModal();	
	const { formMethods, isDirty } = useUIForm();

	function openEditWindow() {
		modalProps.openModal();
		onEditOpen();
	}

	function save(formData) {
		//@ts-ignore
		saveFunction(item[idAccessor], formData);
		modalProps.toggleModal();
	}

	const OpenChangeButton = (
		<AbsoluteIconButton 
			icon={<Edit />} 
			onClick={openEditWindow} 
		/> 
	);

	const ChangeModal = (
		<UIModal {...modalProps} isFormDirty={isDirty} >
			<HeroIcon />
			<UIHeading level="1">{title}</UIHeading>
			<UIForm2 
				formMethods={formMethods}
				onSubmit={save}
			>
				{specList.map( (spec, index) => {
					const mergedSpec = mergeWithDefault(spec);

					let value = get(item, mergedSpec.key);
					if (mergedSpec.isEditDisabled && mergedSpec.formatValue === 'date') {
						value = dateFormat(value, 'dateWithYear');
					}
					else if (mergedSpec.isEditDisabled && mergedSpec.formatValue === 'datetime') {
						value = dateFormat(value, 'dateTimeShort');
					}
					else if (mergedSpec.isEditDisabled && mergedSpec.input_type === 'input' && mergedSpec.valueDisplayAccessor) {
						value = get(item, mergedSpec.valueDisplayAccessor);
					}

					return (
						<RenderedFormField 
							key={mergedSpec.key} 
							formFieldDef={mergedSpec}
							value={value}
							disabled={mergedSpec.isEditDisabled}
							skipRegister={mergedSpec.isEditDisabled}
							isLastAndScrollSelect={index === specList.length - 1}
							spoofSelectValueIfNotFound={false}
						/>
					)
				})}
				<SaveButtonBlock onCancel={modalProps.toggleModal} />
			</UIForm2>
		</UIModal>
	);

	return { openEditWindow, OpenChangeButton, ChangeModal }
}

const AbsoluteIconButton = styled(UIIconButton)`
	position: absolute;
	right: 1rem;
`;

const HeroIcon = styled(EditAlt)`
	height: 60px;
	width: 100%;
	color: ${props => props.theme.colors.primaryLight2};
	margin-top: -1rem !important;
	margin-bottom: 2rem !important;
`;

export function mergeWithDefault(providedSpec: ChangeSpec<any, any>) {
	const defaults: Required<Omit<ChangeSpec<any, any>, 'min' | 'max'>> = {
		key: providedSpec.key,
		name: prettifyKey(providedSpec.key),
		valueDisplayAccessor: providedSpec.key,
		input_type: 'input',
		value_type: 'string',
		is_required: providedSpec.isEditDisabled ? false : true,
		status: 'active',
		options: [],
		isEditDisabled: false,
		formatValue: null

	}
	return {
		...defaults,
		...providedSpec
	}
}