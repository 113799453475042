import { gql } from '@apollo/client';
import { apolloClient, updateCacheAfterInsert } from 'services/apollo';

import { GET_FORM_DEFINITIONS } from 'services/formFetch';

import { FormFieldSpec, FormDefinitionInsertObject, FormDefinition, FormDefinitionUpdateObject } from 'types';
import { thingLookup, formDefinitionAllColumns } from '@constants';


export const ADD_FORM_DEFINITION = gql`
	mutation ADD_FORM_DEFINITION ( 
		$newObjs: [form_definition_insert_input!]!
	) {
		insert_form_definition (
			objects: $newObjs
		) {
			returning {
				${formDefinitionAllColumns}
			}
		}
	}
`;

export const addFormDefinition = (
	name: string, 
	fields: Array<FormFieldSpec>, 
	foreignTableId: number, 
	inventoryUniqueTypeId?: number
): Promise<FormDefinition> => {
	const isInvUniqueForm = foreignTableId === thingLookup.inventory_unique.sharedTableId;

	if (isInvUniqueForm && typeof inventoryUniqueTypeId !== 'number') {
		console.error('inventoryUniqueTypeId is required for unique inventory forms. Form was not added.');
		return;
	}

	// have to referech this query separately since it uses a different query than the standard one. Make sure this files up right with the fetch query in the add inventory unique page.
	const refetchQueries = isInvUniqueForm ? [
		{ query: GET_FORM_DEFINITIONS, variables: { foreign_table: thingLookup.inventory_unique.sharedTableId } }
	] : [];

	const newFormDef: FormDefinitionInsertObject = {
		name,
		fields: fields,
		foreign_table: foreignTableId,
		inventory_unique_type_id: inventoryUniqueTypeId
	}

	return apolloClient.mutate({ 
		mutation: ADD_FORM_DEFINITION, 
		variables: {
			newObjs: [newFormDef]
		},	
		update(cache, result) {
			updateCacheAfterInsert(cache, result, 'form_definition', GET_FORM_DEFINITIONS);
		},
		refetchQueries: refetchQueries
	}).then( queryResponse => {
		return queryResponse?.data?.insert_form_definition?.returning?.[0] ?? {};
	});
};



const UPDATE_FORM_DEFINITION = gql`
	mutation UPDATE_FORM_DEFINITION ( 
		$id: Int!
		$changes: form_definition_set_input!
	) {
		update_form_definition (
			where: { id: { _eq: $id }}
			_set: $changes
		) {
			returning {
				${formDefinitionAllColumns}
			}
		}
	}
`;


export const updateFormDefinition = (id: number, name: string, formFields: Array<FormFieldSpec>) => {
	const updatedFormDef: FormDefinitionUpdateObject = {
		name,
		fields: formFields
	}
	return apolloClient.mutate({
		mutation: UPDATE_FORM_DEFINITION,
		variables: {
			id: id,
			changes: updatedFormDef
		}
	}).then( queryResponse => {
		return queryResponse?.data?.update_form_definition?.[0] ?? {};
	});
};