import React from 'react';

import { useUIForm, UIForm2, UIModal, ModalHookProps, UIInput, UITextArea, SaveButtonBlock, UIHeading, UIFlexbox } from 'kit';

import { ShowAndEditAddresses } from 'components/Contacts/Address';

import { addContact, updateContact } from 'services/contactMutate';

import { Contact } from 'types';


export const ContactForm = ( { modalProps  }  : 
	{ 
		modalProps: ModalHookProps<Contact>
	} 
) => {
	const existingContact = modalProps.modalData;
	const isNewContact = typeof existingContact.id !== 'number';
	
	const { formMethods, isDirty } = useUIForm(existingContact);

	const submit = (formData) => {
		if (isNewContact) {
			addContact(formData);
		}
		else {
			updateContact(existingContact.id, formData);
		}
		modalProps.toggleModal();
	}
	
	return (
		<UIModal {...modalProps} isFormDirty={isDirty} >
			<UIForm2 formMethods={formMethods} onSubmit={submit}>
				<UIHeading>{isNewContact ? 'Add' : 'Edit'} Contact</UIHeading>

				<UIFlexbox childrenGrow="50/50">
					<UIInput name="first_name" label="First Name" required autoFocus />
					<UIInput name="last_name" label="Last Name" required />
				</UIFlexbox>
				<UIInput name="company_name" label="Company" />
				<UIFlexbox childrenGrow="50/50">
					<UIInput name="email" label="Email Address" />
					<UIInput name="phone" label="Phone Number" />
				</UIFlexbox>

				<ShowAndEditAddresses addresses={existingContact.addresses} />

				<UITextArea name="note" label="Note"  />


				<SaveButtonBlock onCancel={modalProps.toggleModal} />
			</UIForm2>
		</UIModal>
	);
}