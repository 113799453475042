import { gql } from '@apollo/client';

import { toolAllColumns } from '@constants';

export const GET_TOOLS = gql`
	query GET_TOOLS (
		$id: Int
	) {
		tool (
			where: { 
				is_saved: { _eq: true } 
				id: { _eq: $id }
			}
		) {
			${toolAllColumns}
		}
	}
`;

export const GET_TOOLS_IN = gql`
	query GET_TOOLS_IN (
		$ids: [Int!]
	){
		tool (
			where: { id: { _in: $ids } } 
		) {
			${toolAllColumns}
		}
	}
`;


export const ADD_TOOL = gql`
	mutation ADD_TOOL (
		$insertingObjs: [tool_insert_input!]!
	) {
		insert_tool (
			objects: $insertingObjs
		) {
			returning {
				${toolAllColumns}
			}
		}
	}
`;

export const UPDATE_TOOL = gql`
	mutation UPDATE_TOOL ( 
		$id: Int!
		$changes: tool_set_input!
	) {
		update_tool (
			where: { id: { _eq: $id } },
			_set: $changes
		) {
			returning {
				${toolAllColumns}
			}
		}
	}
`;
