import React from 'react';
import styled from 'styled-components';

import { UIChip, UISelectControlled, FormInputContainer, useTooltipMenu, useModal } from 'kit';

import { StepLinkableThing } from './LinkStepsFromItem';
import { updateStepToAddRecipe, updateStepToAddTool, updateStepToAddRawPart, getStepColName, updateStepToAddForm } from 'root/services/stepLink';


import { EditPartForm } from 'components/Parts/PartForm';
import { EditParameterForm } from 'components/AddItem';

import { useThingsAll, useThingById } from 'hooks';

import { Step } from 'types';
import { thingLookup } from '@constants';
import { getObjectFromArray, emptyPromise } from 'utility';

import { toast } from 'react-toastify';


export const NewLinkThingToStep = ({ stepId, linkingThing, overrideLabel }: {
	stepId: number
	linkingThing: StepLinkableThing
	overrideLabel?: string
}) => {
	const { UITooltipMenu, ...tooltipMenuProps } = useTooltipMenu();

	const stepColName = getStepColName(linkingThing);
	
	const { item: step } = useThingById('step', stepId);
	const { items } = useThingsAll(linkingThing, { 	
		skip: !tooltipMenuProps.isTooltipMenuVisible, 
		vars: linkingThing === 'form' ? { foreign_table: thingLookup.step_history.sharedTableId } : {}
	});
	const notCurrentlyLinkedItems = items.filter(item => !step[stepColName]?.includes(item.id));

	function handleSelect(selectedId: number) {
		if (selectedId > 0) { // double check that the input is valid
			if (linkingThing === 'recipe') {
				updateStepToAddRecipe(stepId, selectedId);
			}
			else if (linkingThing === 'tool') {
				updateStepToAddTool(stepId, selectedId);
			}
			else if (linkingThing === 'partRaw') {
				updateStepToAddRawPart(stepId, selectedId);
			}
			else if (linkingThing === 'form') {
				updateStepToAddForm(stepId, selectedId);
			}
			tooltipMenuProps.closeTooltipMenu();
		}
	}

	const modalProps = useModal();
	function createItem(newItemName: string){
		if (linkingThing === 'form'){
			toast.error('Go to forms setup to add a new form.', {
				position: toast.POSITION.BOTTOM_CENTER
			});
		}
		else {
			modalProps.setModalData({ name: newItemName });
			modalProps.openModal();
		}
		return emptyPromise(-1);
	}

	return (
		<>
		<UITooltipMenu
			{...tooltipMenuProps}
			entryElement={<UIChip label={overrideLabel || `Link another...`} />}
			tooltipContent={<SelectContainer>
				<UISelectControlled
					key={tooltipMenuProps.forceTooltipUpdateKey} /**The select input needs to be cleared each time the popup is toggled, and this wasn't happening after the first select */
					name="new-link"
					options={notCurrentlyLinkedItems}
					onChange={handleSelect}
					defaultValue={null}
					defaultMenuIsOpen 
					handleCreate={createItem} // This didn't work from the WF editor on react-select 3.1.0, but did work on 3.0.8	https://github.com/JedWatson/react-select/issues/4244
					placeholder="Type to search or create new..."
				/>
			</SelectContainer>} 
		/>
			{(linkingThing === 'tool' || linkingThing === 'recipe') && 
				<EditParameterForm
					modalProps={modalProps}
					parameterName={linkingThing}
					addStepToPending={step}
				/>
			}
			{linkingThing === 'partRaw' && 
				<EditPartForm
					modalProps={modalProps}
					originatingPartType="raw"
					addStepToPending={step}
				/>
			}
		</>
	);
};



export const NewLinkStepToThing = ({ thingId, linkingThing, linkedSteps, onSelect }: {
	thingId: number;
	linkingThing: StepLinkableThing;
	linkedSteps: Step[];
	onSelect: (selectedStep: Step) => void
}) => {
	const { UITooltipMenu, ...tooltipMenuProps } = useTooltipMenu();

	const { items: steps } = useThingsAll('step', { skip: !tooltipMenuProps.isTooltipMenuVisible });
	const notCurrentlyLinkedSteps = steps.filter(eachStep => !linkedSteps.map(linkedStep => linkedStep.id).includes(eachStep.id));

	function handleSelect(selectedId: number) {
		if (selectedId > 0) { // double check that the input is valid
			onSelect(getObjectFromArray(steps, 'id', selectedId));
			/**
			 * This was the autosave functionality, just leaving it here in case it becomes useful later
			 if (linkingThing === 'recipe') {
				 updateStepToAddRecipe(selectedId, thingId, true);
			 }
			 else if (linkingThing === 'tool') {
				 updateStepToAddTool(selectedId, thingId, true);
			 }
			 else if (linkingThing === 'partRaw') {
				 updateStepToAddRawPart(selectedId, thingId, true);
			 }
			 else if (linkingThing === 'form') {
				 updateStepToAddForm(selectedId, thingId, true);
			 }
			 */
			tooltipMenuProps.closeTooltipMenu();
		}
	}

	return (
		<UITooltipMenu
			{...tooltipMenuProps}
			entryElement={<UIChip label={`Link another...`} />}
			tooltipContent={<SelectContainer>
				<UISelectControlled
					key={tooltipMenuProps.forceTooltipUpdateKey} /**The select input needs to be cleared each time the popup is toggled, and this wasn't happening after the first select */
					name="new-link"
					options={notCurrentlyLinkedSteps}
					onChange={handleSelect}
					defaultValue={void 0}
					defaultMenuIsOpen />
			</SelectContainer>} />
	);
};


const SelectContainer = styled.div`
	width: 300px;
	margin: 1rem;
	${FormInputContainer} {
		margin-bottom: 0;
	}
`;
