import React from 'react';
import styled from 'styled-components';

import { UICard, UIFlexChild, UIIconButton, UIButton, FormInputContainer } from 'kit';
import { Exclamation } from '@styled-icons/fa-solid/Exclamation';
import { Check } from '@styled-icons/fa-solid/Check';

import { StepTask } from './useOperateStep';


export const TaskBannerPresentational = (
	props: {
		taskType: StepTask
		isTaskCompleted: boolean
		ActionComponent
	}
) => {

	const taskStatus = props.isTaskCompleted ? 'complete' : 'info-needed';

	let message;
	switch (taskStatus) {
		case 'complete':
			message = 'Selection Complete';
			break;
		case 'info-needed':
			message = 'Selection Needed';
			break;
			
		default:
			break;
	}

	return (
		<TaskBannerCard
			asFlexbox
			flexDirection="row"
			flexWrap="wrap"
			flexAlign="center"
			className={taskStatus}
		>
			<UIIconButton 
				icon={taskStatus === 'complete' ? <Check /> : <Exclamation />} 
				success={taskStatus === 'complete'}
				error={taskStatus === 'info-needed'} 
				solid
				size="small"
			/>
			<UIFlexChild className="message">{props.taskType} {message}</UIFlexChild>
			<UIFlexChild className="action-container"> {props.ActionComponent} </UIFlexChild>

		</TaskBannerCard>	
	);
}

const TaskBannerCard = styled(UICard).attrs( props => ({
	width: '100%'
}))`
	border: none;
	${props => props.theme.shadow.depth1};

	/* make wrapped rows space in between, but don't have extra space at the bottom */
	padding: 1.5rem;
	& > * {
		margin-bottom: 1.5rem !important; /* This should match the padding */
	}
	padding-bottom: 0px;
	/* ^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^ */

	&.complete {
		background-color: ${props => props.theme.colors.successLight};
		.action-container {
			button {
				/**The right way to do this would be to passs the prop directly to OperateTaskButton, but it was pretty cumbersome to pass the isTaskComplete prop around */
				border-color: ${props => props.theme.colors.successDark};
				color: ${props => props.theme.colors.successDark};
				:hover {
					background-color: ${props => props.theme.colors.success};
					color: white;
				}
			}
		}
	}
	&.info-needed {
		background-color: ${props => props.theme.colors.errorLight};
		.action-container {
			button {
				/**The right way to do this would be to passs the prop directly to OperateTaskButton, but it was pretty cumbersome to pass the isTaskComplete prop around */
				border-color: ${props => props.theme.colors.errorDark};
				color: ${props => props.theme.colors.errorDark};
				:hover {
					background-color: ${props => props.theme.colors.error};
					color: white;
				}
			}
		}
	}
	button:first-of-type {
		margin-right: 20px;
		margin-top: 4px;
	}
	.message {
		font-family: ${props => props.theme.font.title};
		font-weight: ${props =>props.theme.font.boldWeight};
	}

	.action-container {
		display: flex;
		justify-content: flex-end;
		button {
			min-width: 140px;
			@media (max-width: 500px) {
				width: 100%;
			}
		}
		form {
			margin: 0;
			width: fill-available;
		}
		${FormInputContainer} {
			margin: 0;
		}
	}
`;

export const OperateTaskButton = styled(UIButton).attrs(props => ({
	// we can define static props
	outline: true
  
	// or we can define dynamic ones
	//size: props.size || "1em"
  }))`
	/* //border-radius: 3px; */
	/* here we use the dynamically computed prop */
  `;