import React from 'react';
import styled from 'styled-components';

import { UIInputControlled, UISelectControlled } from 'kit';

import { UISelectOption } from 'types';
import { useQueryString } from 'hooks';

import matchSorter from 'match-sorter';
import debounce from 'lodash.debounce';
import { removeDuplicatesFromArray } from 'utility';

export const useURLFilter = () => {
	let { searchParams, updateURLParam } = useQueryString();
	
	const globalKeyName = 'g';

	function updateGlobalURLFilter(newValue){
		updateURLParam(globalKeyName, newValue);
	}

	const globalFilterText = searchParams.get(globalKeyName);

	return { searchParams, globalFilterText, updateGlobalURLFilter, updateURLParam }
}


// Define a default UI for filtering
export function GlobalFilter({ preGlobalFilteredRows, globalFilter, setGlobalFilter }) {
	const count = preGlobalFilteredRows.length;

	
	const { updateGlobalURLFilter } = useURLFilter();
	function updateFilter(newText){
		setGlobalFilter(newText);
		updateGlobalURLFilter(newText);
	}
	const setFilterDebounced = debounce(updateFilter, 200);

	return (
		<GlobalInput 
			name="filter-global" label="Search entire table..."
			value={globalFilter || ''} 
			onChange={setFilterDebounced}  //undefined to remove the filter entirely
			placeholder={`${count} records...`}
			type="search"
		/>
	);
}

const GlobalInput = styled(UIInputControlled)`
	margin-left: 20px;
	margin-right: 20px;
	width: 80%;
	max-width: 400px;
`;


// Define a default UI for filtering
export function DefaultColumnFilter({ column: { id, filterValue, preFilteredRows, setFilter } }) {
	//const count = preFilteredRows.length

	const { updateURLParam } = useURLFilter();
	function updateFilter(newText){
		setFilter(newText);
		updateURLParam(id, newText);
	}

	return (
		<UIInputControlled 
			name={`filter-${id}`}
			value={filterValue || ''} 
			onChange={updateFilter} // undefined removes the filter entirely
			placeholder={`Search...`} 
			autoFocus
			type="search"
		/>
	);
}


//needs to be fixed to filter correctly
export function SliderProgressFilter({ column: { filterValue, setFilter, preFilteredRows, id } }) {
	// Calculate the min and max
	// using the preFilteredRows
	const [min, max] = React.useMemo(() => {
		let rowMin = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
		let rowMax = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
		preFilteredRows.forEach(row => {
			rowMin = Math.min(row.values[id], rowMin);
			rowMax = Math.max(row.values[id], rowMax);
		});
		return [rowMin, rowMax];
	}, [id, preFilteredRows]);
	return (<input type="range" min={min} max={max} step={1} value={filterValue || min} onChange={e => { setFilter(parseInt(e.target.value, 10)); }} />);
}


export function SliderColumnFilter({ column: { filterValue, setFilter, preFilteredRows, id } }) {
	// Calculate the min and max
	// using the preFilteredRows
	const [min, max] = React.useMemo(() => {
		let rowMin = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
		let rowMax = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
		preFilteredRows.forEach(row => {
			rowMin = Math.min(row.values[id], rowMin);
			rowMax = Math.max(row.values[id], rowMax);
		});
		return [rowMin, rowMax];
	}, [id, preFilteredRows]);
	return (<input type="range" min={min} max={max} step={1} value={filterValue || min} onChange={e => { setFilter(parseInt(e.target.value, 10)); }} />);
}


export function fuzzyTextFilterFn(rows, columnnames, filterValue) {
	//use disableGlobalFilter in date columns to improve number filtering
	const keys = columnnames.map( id => {
		return (item => item.values[id])
	})
	return matchSorter(rows, filterValue, { keys });
}


// Let the table remove the filter if the string is empty
fuzzyTextFilterFn.autoRemove = val => !val;


export function SelectColumnFilter({ column: { filterValue, setFilter, preFilteredRows, id } }) {	
	// Calculate the options for filtering using the preFilteredRows
	const options: UISelectOption[] = React.useMemo(() => {
		const values = preFilteredRows.map(row => row.values[id]).filter( value => typeof value === 'string');
		const uniqueValues = removeDuplicatesFromArray(values);

		const noFilterOption = [{ id: '', name: 'All' }] as UISelectOption[];
		const uniqueOptions: UISelectOption[] = uniqueValues.map( (value) => ({ id: value, name: value === '' ? '[None]' : value } as UISelectOption));
		return noFilterOption.concat(uniqueOptions)
	}, [id, preFilteredRows.length]);

	const { updateURLParam } = useURLFilter();
	function updateFilter(newText){
		setFilter(newText || void 0);
		updateURLParam(id, newText);
	}

	return (
		<UISelectControlled 
			name="column_select_filter"
			defaultValue={filterValue} 
			onChange={updateFilter}
			options={options} 
			defaultMenuIsOpen
		/>
	);
}

export function SelectColumnFilterNumber({ column: { filterValue, setFilter, preFilteredRows, id } }) {	
	// Calculate the options for filtering using the preFilteredRows
	const options: UISelectOption[] = React.useMemo(() => {
		const values = preFilteredRows.map(row => row.values[id]).filter( value => typeof value === 'number');		
		const uniqueValues = removeDuplicatesFromArray(values);

		const noFilterOption = [{ id: '', name: 'All' }] as UISelectOption[];
		const uniqueOptions: UISelectOption[] = uniqueValues.map( (value) => ({ id: value, name: value === '' ? '[None]' : value } as UISelectOption));
		return noFilterOption.concat(uniqueOptions)
	}, [id, preFilteredRows.length]);

	function updateFilter(value) {
		setFilter(value || void 0);
	}

	return (
		<UISelectControlled 
			name="column_select_filter"
			defaultValue={filterValue} 
			onChange={updateFilter}
			options={options} 
			defaultMenuIsOpen
		/>
	);
}

export function SelectColumnFilterArray({ column: { filterValue, setFilter, preFilteredRows, id } }) {
	// Calculate the options for filtering using the preFilteredRows
	const options: UISelectOption[] = React.useMemo(() => {
		// get an array of unique values, when the source are arrays
		const arrays = preFilteredRows.map( row => row.values[id] ).filter( arr => arr.length > 0 )
		const uniqueValues = [...new Set([].concat(...arrays))];

		const noFilterOption = [{ id: '', name: 'All' }] as UISelectOption[];
		const uniqueOptions: UISelectOption[] = uniqueValues.map( (value) => ({ id: value, name: value === '' ? '[None]' : value } as UISelectOption));
		return noFilterOption.concat(uniqueOptions)
	}, [id, preFilteredRows.length]);

	function updateFilter(value) {
		setFilter(value || void 0);
	}

	return (
		<UISelectControlled 
			isMulti
			name="column_select_filter"
			defaultValue={filterValue} 
			onChange={updateFilter}
			options={options} 
			defaultMenuIsOpen
		/>
	);
}