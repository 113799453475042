import { gql } from '@apollo/client';
import { Part, PartStock } from 'types';
import { partAllColumns, partStockAllColumns } from '@constants';
import { apolloClient } from './apollo';
import { hydratePartStock } from './partHydrate';


export function queryPartFromCache(partId: number): Part {
	const cachedPart = apolloClient.readFragment({
		id: `part:${partId}`,
		fragment: gql`
			fragment CACHED_PART on part {
				${partAllColumns}
			}`
	});
	return cachedPart as Part;
}

export function queryStockFromCache(partId: number) {
	const cachedPart: Part = apolloClient.readFragment({
		id: `part:${partId}`,
		fragment: gql`
			fragment CACHED_PART on part {
				${partAllColumns}
			}`
	});
	return hydratePartStock(cachedPart);
}


export function queryStockBucketFromCache(partId: number, jobId: number, inventoryUniqueId: number) {
	const cachedPartStock: PartStock = apolloClient.readFragment({
		id: `part_stock:{"part_id":${partId},"job_id":${jobId},"inventory_unique_id":${inventoryUniqueId}}`,
		fragment: gql`
			fragment CACHED_PART_STOCK on part_stock {
				${partStockAllColumns}
			}`
	});
	return cachedPartStock || {} as PartStock;
}
