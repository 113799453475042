import { gql } from '@apollo/client';
import { formDefinitionAllColumns, formInstanceAllColumns, formInstanceForeignObjects } from '@constants';



export const GET_FORM_DEFINITIONS = gql`
	query GET_FORM_DEFINITIONS ( 
		$id: Int
		$foreign_table: smallint
	){
		form_definition (
			where: { 
				id: { _eq: $id } 
				foreign_table: { _eq: $foreign_table } 
			}
		) {
			${formDefinitionAllColumns}
		}
	}
`;

export const GET_FORM_DEFINITIONS_BY_IDS = gql`
	query GET_FORM_DEFINITIONS_BY_IDS ( 
		$ids: [Int!]
	){
		form_definition (
			where: { id: { _in: $ids } }
		) {
			${formDefinitionAllColumns}
		}
	}
`;





export const GET_FORMS_BY_DEF_ID = gql`
	query GET_FORMS_BY_DEF_ID ( 
		$defId: Int!
	){
		form (
			where: { form_definition_id: { _eq: $defId } }
			order_by: { updated_at: desc }
		) {
			${formInstanceAllColumns}
			${formInstanceForeignObjects}
		}
	}
`;