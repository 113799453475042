import React from 'react';
import { UIButton, useModal, UIModalSimpleAction } from 'kit';
import { Duplicate } from '@styled-icons/boxicons-regular/Duplicate';

import { Workflow, WorkflowInsertUpdateObj } from 'types';


import { addWorkflow } from 'services/workflowMutate';
import { useNavFull } from 'routing';
import { toast } from 'react-toastify';

export const DuplicateWorkflowButtonModal = (
	{ workflow }: 
	{
		workflow: Workflow
	}
) => {
	const modalProps = useModal();
	const navigateToWorkflowById = useNavFull('workflowEditorWorkflow');

	function duplicate() {
		const workflowObj: WorkflowInsertUpdateObj = {
			name: workflow.name + ' - Copy',
			description: workflow.description,
			steps: workflow.steps,
			is_active: workflow.is_active,
			workflow_options: workflow.workflow_options

		}
		addWorkflow(workflowObj).then( newWorkflow => {
			toast.info(`${newWorkflow.name} was successfully created!`, {
				position: toast.POSITION.BOTTOM_CENTER,  autoClose: 4000
			});
			navigateToWorkflowById(newWorkflow.id);
		});
	}


	return (<React.Fragment>

		<UIButton quiet label="Duplicate" onClick={modalProps.toggleModal} />

		<UIModalSimpleAction 
			{...modalProps} 
			Icon={<Duplicate />}
			title="Duplicate Workflow?"
			description="Duplicating will create a new workflow, and copy over the current saved workflow."
			buttonText="Duplicate"
			onSubmit={duplicate}
			showCancel
		/>

	</React.Fragment>);
};
