import { Part } from 'types';

export function hydratePartStock(part: Part = {} as Part) {
	const { 
		part_stock = []
	} = part;

	/*
	Don't really need this anymore since we just refetch all buckets after a transaction.
	const bucketsFromCache = part_stock.map( item => {
		const bucket = queryStockBucketFromCache(item.part_id, item.job_id, item.inventory_unique_id);
		console.log(bucket)
		const bucketExists = typeof bucket?.part_id === 'number';
		return bucketExists ? bucket : item
	})
	

	const mostRecentStockBucket = bucketsFromCache.reduce(function(prev, current) {
		return (prev.updated_at > current.updated_at) ? prev : current
	}) //returns object
	*/

	return { 
		totalQty: part_stock[0]?.part_total_qty ?? 0,
		buckets: part_stock
	};		
}
