import { gql } from '@apollo/client';

import { recipeAllColumns } from '@constants';

export const GET_RECIPES = gql`
	query GET_RECIPES (
		$id: Int
	) {
		recipe (
			where: { 
				is_saved: { _eq: true } 
				id: { _eq: $id }
			}
		) {
			${recipeAllColumns}
		}
	}
`;


export const GET_RECIPES_IN = gql`
	query GET_RECIPES_IN (
		$ids: [Int!]
	){
		recipe (
			where: { id: { _in: $ids } } 
		) {
			${recipeAllColumns}
		}
	}
`;

export const ADD_RECIPE = gql`
	mutation ADD_RECIPE (
		$insertingObjs: [recipe_insert_input!]!
	) {
		insert_recipe (
			objects: $insertingObjs
		) {
			returning {
				${recipeAllColumns}
			}
		}
	}
`;

export const UPDATE_RECIPE = gql`
	mutation UPDATE_RECIPE ( 
		$id: Int!
		$changes: recipe_set_input!
	) {
		update_recipe (
			where: { id: { _eq: $id } },
			_set: $changes
		) {
			returning {
				${recipeAllColumns}
			}
		}
	}
`;
