import React, { useState } from 'react';
import styled from 'styled-components';

import { FormLabel, FormInputContainer } from 'kit';
import { UISelectOption } from 'types';
import { Input, Textarea } from '@rebass/forms/styled-components';
import { CheckBox } from 'grommet';

import { useFormContext, UseFormMethods } from 'react-hook-form';

import debounce from 'lodash.debounce';

interface SharedInputProps {
	name: string
	label?: string
	placeholder?: string
	type?: 'password' | 'search'
	disabled?: boolean
	className?: string
	autoFocus?: boolean
	disableAutocomplete?: boolean
}

export const UIInput = (	
	{ 
		name, label, placeholder, type, disabled = false, className, autoFocus = false, disableAutocomplete = false,
		skipRegister = false, required = false, validate, validateMessage,
		defaultValue
	}: { 
		/**In theory you shouldn't need this because the defaults come from react-hook-form, but there are some rare cases where this is still needed */
		defaultValue?: string | number

		required?: boolean
		/** Custom validation scheme. An object with custom key, plus a function that returns if validation passes. Key should also match with validateMessage */
		validate?: { [type: string]: (parameter: any) => boolean }
		/** Custom validation message. Custom key should match with "validate" prop. */
		validateMessage?: { [type: string]: string }
		skipRegister?: boolean

	} & SharedInputProps
) => {
	const formContext = skipRegister ? {} as UseFormMethods : useFormContext();
	const { register, trigger } = formContext;
	//@ts-ignore
	const autoSave = formContext.autoSave;
	//@ts-ignore
	const autoSaveSubmit = formContext.onAutoSave;

	if (autoSave && validate && process.env.NODE_ENV === 'development') {
		console.warn('%cAutosave can not be used with a custom validate function (it won\'t work)!', 'color: blue');
	}

	const performAutoSave = () => {
		trigger().then( validateSuccess => {
			if (validateSuccess) {
				autoSaveSubmit();
			}
		});
	}

	const showLabel = typeof label === 'string';

	return (
		<FormInputContainer className={className}>
			<FormLabel name={name} label={label} required={required} showLabel={showLabel} customError={validateMessage} skipRegister={skipRegister} />
			<StyledInput 
				name={name} 
				defaultValue={defaultValue}
				placeholder={placeholder}
				ref={skipRegister ? null : register({ required: required, validate: validate })} 
				onChange={autoSave ? debounce(performAutoSave, 400) : void 0}
				type={type}
				disabled={disabled}
				autoFocus={autoFocus}
				autoComplete={disableAutocomplete ? 'off' : 'on'}
			/>
		</FormInputContainer>		
	)
}

export const UIInputControlled = (	
	{ 
		name, label, placeholder, type, disabled = false, className, autoFocus = false, disableAutocomplete = false,
		value, onChange = () => {}
	}: { 
		value: string | number
		onChange: (value) => void
	} & SharedInputProps
) => {

	const handleChange = (e) => {
		const newVal = e.target?.value;
		onChange(newVal);
	}

	return (
		<FormInputContainer className={className}>
			{ label && <FormLabel name={name} label={label} required={false} /> }
			<StyledInput 
				name={name} 
				value={value}
				placeholder={placeholder}
				onChange={handleChange}
				type={type}
				disabled={disabled}
				autoFocus={autoFocus}
				autoComplete={disableAutocomplete ? 'off' : 'on'}
			/>
		</FormInputContainer>		
	)
}


const StyledInput = styled(Input)`
	outline: none; /**No blue glow */
	border-radius: 5px;
	border: 1px solid lightgrey;
	background-color: white;
	font-family: ${props => props.theme.font.body};
	font-size: 0.95em;
	padding: 8px 10px;
	:disabled {
		${props => props.theme.presets.inputDisabled};
	}
`;


export const UITextArea = (	
	props: { 
		name: string
		label: string
		default?: string | number
		required?: boolean
		showLabel?: boolean
		disabled?: boolean
		skipRegister?: boolean
		className?
	} 
) => {
	const formContext = props.skipRegister ? {} as UseFormMethods : useFormContext();
	const { register } = formContext;

	
	return (
		<FormInputContainer className={props.className}>
			<FormLabel name={props.name} label={props.label} required={props.required} showLabel={props.showLabel} skipRegister={props.skipRegister} />
			<StyledTextarea 
				name={props.name} 
				defaultValue={props.default} 
				ref={props.skipRegister ? null : register({ required: props.required })} 
				disabled={props.disabled} 
			/>
		</FormInputContainer>		
	)
}

UITextArea.defaultProps = {
	default: '',
	required: false
}


const StyledTextarea = styled(Textarea)`
	white-space: pre-line;
	outline: none; /**No blue glow */
	overflow: auto; /** Remove default scrollbars in Internet Explorer*/
	border-radius: 5px;
	border: 1px solid lightgrey;
	background-color: white;
	font-family: ${props => props.theme.font.body};
	font-size: 0.95em;
	padding: 8px 10px;
	:disabled {
		${props => props.theme.presets.inputDisabled};
	}
`;


export const UISwitch = (
	props: {
		default: boolean
		name: string
		label: string
		skipRegister?: boolean
		disabled?: boolean
		className?
	}
) => {
	const [isChecked, setChecked] = useState(props.default);
	const { register } = useFormContext() // retrieve all hook methods

	function toggleSwitch (event) {
		setChecked(event.target.checked)
	}

	return (
		<FormInputContainer className={props.className}>
			<CheckBox
				{...props}
				checked={isChecked}
				onChange={toggleSwitch}
				ref={props.skipRegister ? null : register} 
				toggle
				disabled={props.disabled}
			/>
		</FormInputContainer>
	);
}

UISwitch.defaultProps = {
	default: false,
	skipRegister: false
};

export const UIRadioSegment = (
	props: {
		options: UISelectOption[],
		name: string
		label: string
		default: string | number
		onChange?
	}
) => {
	// Note....this hasn't been tested within ui form yet. Was created for use in InventoryUniquePage
	//const { register  } = useFormContext() // retrieve all hook methods
	
	function onChange(value) {
		if (typeof props.onChange === 'function') {
			props.onChange(value);
		}
	}

	return (
		<RadioSegmentContainer>
			{props.options.map( option => {

				return (
					<React.Fragment key={option.id}>
						<input 
							type="radio" 
							id={props.name + option.id.toString()} 
							name={props.name} 
							value={option.id} 
							defaultChecked={option.id === props.default} 
							onChange={onChange.bind(this, option.id)} 
						/>
						<label htmlFor={props.name + option.id}>{option.name}</label>
					</React.Fragment>
				)
			})}
		</RadioSegmentContainer>
	);
}

UIRadioSegment.defaultProps = {
};

const RadioSegmentContainer = styled(FormInputContainer)`
	display: flex;
	margin: 20px 10px;
	overflow: hidden;
	user-select: none;
	padding: 0 1px; /** right border was getting clipped */
	input {
		position: absolute !important;
		clip: rect(0, 0, 0, 0);
		height: 1px;
		width: 1px;
		border: 0;
		overflow: hidden;
	}
	label {
		background-color: ${props => props.theme.colors.colorDefinitions.grey1};
		color: ${props => props.theme.colors.colorDefinitions.grey3};
		font-size: 14px;
		line-height: 1;
		text-align: center;
		padding: 8px 16px;
		margin-right: -1px;
		border: 1px solid ${props => props.theme.colors.colorDefinitions.grey2};
		box-shadow: inset 0 1px 4px rgba(0, 0, 0, 0.1), 0 1px rgba(255, 255, 255, 0.1);
		transition: all 0.1s ease-in-out;
	}
	label:hover {
		cursor: pointer;
	}
	input:checked + label {
		background-color: ${props => props.theme.colors.primary};
		color: white;
		box-shadow: none;
	}
	label:first-of-type {
		border-radius: 4px 0 0 4px;
	}
	label:last-of-type {
		border-radius: 0 4px 4px 0;
	}
`;