import React from 'react';

import { UIButton, UIButtonProps, UIIconButton, UIIconButtonProps } from 'kit';

import { MainRouteName, FullRouteName, getRoutePath } from 'routing';

export type LinkVars = {
	id?: number
	index?: number
}

export const RoutedButton = ( 
	{ linkToRoute, linkToVars = {}, ...buttonProps }: 
{ 	
	linkToRoute?: MainRouteName | FullRouteName
	linkToVars?: LinkVars
} & UIButtonProps 
) => {

	const path = linkToRoute && getRoutePath(linkToRoute, linkToVars);

	return (
		<UIButton 
			{...buttonProps}
			linkToPath={path}
		/>
	)
}

export const RoutedIconButton = ( 
	{ linkToRoute, linkToVars = {}, ...buttonProps }: 
{ 	
	linkToRoute?: MainRouteName | FullRouteName
	linkToVars?: LinkVars
} & UIIconButtonProps 
) => {
	const path = linkToRoute && getRoutePath(linkToRoute, linkToVars);

	return (
		<UIIconButton 
			{...buttonProps}
			linkToPath={path}
		/>
	)
}
