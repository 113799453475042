import React from 'react';

import { UIRoutedLink } from 'routing';

import { TableDateMultiField } from 'components/Table/TableDateField';
import { SelectColumnFilter } from 'components/Table/TableFilters';

import { ColDefs } from './tableColumnUtility';
import { InventoryUniqueItem } from 'types';


export function transformInventoryUniqueFormData(items: Array<InventoryUniqueItem>){
	return items.map( item => {
		const { forms, ...rest } = item;
		let itemWithFormData = { ...rest };
		forms.map( formInstance => {
			itemWithFormData['form_' + formInstance.form_definition_id] = formInstance.values;
		} )
		return itemWithFormData;
	});
}

type InvUniqueColId = 'name' | 'type' | 'status' | 'stage' | 'createdAt';

export const invUniqueCols: ColDefs<InvUniqueColId> = {
	name: {
		Header: 'ID',
		accessor: 'name',
		className: 'emphasis',
		Cell: (table) => {
			const item: InventoryUniqueItem = table.row.original;
			return (
				<UIRoutedLink
					text={item.name}
					linkToRoute={'inventoryUniqueById'}
					linkToVars={{ id: item.id }}
				/> 
			)
		}
	},
	type: {
		Header: 'Type',
		accessor: 'type.name'
	},
	status: {
		Header: 'Status',
		Filter: SelectColumnFilter,
		accessor: 'status'
	},
	stage: {
		Header: 'Stage',
		Filter: SelectColumnFilter,
		accessor: 'stage.name'
	},
	createdAt: {
		Header: 'Created',
		accessor: 'created_at',
		disableGlobalFilter: true,
		Cell: ({ cell: { value } }) => <TableDateMultiField ISOdateString={value} dateDisplayFormatTop="dateShortWithYear" dateDisplayFormatBottom="time" />
	}
}