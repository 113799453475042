import React, { useState } from 'react';
import styled from 'styled-components';

import { UIIconButton } from 'kit';


import Menu from '@material-ui/core/Menu';
import { MoreVerticalOutline } from '@styled-icons/evaicons-outline/MoreVerticalOutline';

interface UIMenuProps {
	options: {
		label: string
		onClick
	}[]
	overrideButton?
}

export const UIMenu = ({ 
	options = [{ label: 'dummy', onClick: null }],
	overrideButton 
}: UIMenuProps) => {
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const open = Boolean(anchorEl);

	const handleClick = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};
	
	const handleClose = () => {
		setAnchorEl(null);
	};
	const clickAndClose = (onClick) => {
		if (typeof onClick === 'function') {
			onClick();
		}
		handleClose();
	};

	const MenuButton = overrideButton ? 
		React.cloneElement(overrideButton, { onClick: handleClick })
	: (<UIIconButton 
		icon={<MoreVerticalOutline />}
		onClick={handleClick}
	/>)


	return (
		<>
			{MenuButton}
			<Menu
				anchorEl={anchorEl}
				anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
				transformOrigin={{ horizontal: 0, vertical: -20 }}
				keepMounted
				open={open}
				onClose={handleClose}
			>
				{options.map((option) => (
					<UIMenuItem key={option.label} onClick={clickAndClose.bind(this, option.onClick)}>
						{option.label}
					</UIMenuItem>
				))}
			</Menu>
		</>
	);
}

// Forward ref for Material UI
const UIMenuItem = React.forwardRef((props: {
	onClick?
	children?
}
, ref) => 
{
	return (
		//@ts-ignore
		<StyledDiv {...props} ref={ref} />
	)
} )

const StyledDiv = styled.div`
	line-height: 1.5;
	padding: 6px 16px;
	white-space: nowrap;
	padding-bottom: 6px;
	cursor: pointer;
	:hover {
		text-decoration: none;
		background-color: rgba(0, 0, 0, 0.04);
	}
`;
