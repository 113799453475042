import { gql } from '@apollo/client';

import { apolloClient } from 'services/apollo';
import { GET_ORDER_ITEMS_BY_ORDER } from './orderFetch';
import { orderItemsAllColumns } from '@constants';

import { OrderItemInsertObject, OrderItemUpdateObject, FulfillmentStatus } from 'types';


//modify order_items qty, add, remove


// NOTE: you are not allowed to update an order item's part id, since it is linked to wip, products, and workflows
const UPDATE_ORDER_ITEMS = gql`
	mutation UPDATE_ORDER_ITEM ( 
		$id: Int!
		$changes: order_item_set_input!
	) {
		update_order_item (
			where: { id: { _eq: $id }}
			_set: $changes
		) {
			returning {
				${orderItemsAllColumns}
			}
		}
	}
`;


export interface PendingLineItem extends OrderItemUpdateObject {
	id?: number
	part_id: number
	qty: number
	status?: FulfillmentStatus
}


export const updateOrderItems = (editedItems: PendingLineItem[]) => {
	editedItems.forEach( item => {
		const { id, qty, line_item, unit_cost, status } = item;
		let changes: OrderItemUpdateObject = {
			qty, 
			line_item, 
			unit_cost,
			status
		};
		apolloClient.mutate({
			mutation: UPDATE_ORDER_ITEMS,
			variables: {
				id: id,
				changes
			}
		})
	});
};




const ADD_ORDER_ITEMS = gql`
	mutation ADD_ORDER_ITEMS (		
		$order_items: [order_item_insert_input!]!
	) {
		insert_order_item (
			objects: $order_items
		) {
			returning {
				${orderItemsAllColumns}
			}
		}
	}
`;

export const addOrderItems = (orderItems: OrderItemInsertObject[] = [], orderId: number) => {
	if (orderItems.length === 0) { return; }
	return apolloClient.mutate({
		mutation: ADD_ORDER_ITEMS,
		variables: {
			order_items: orderItems
		},
		refetchQueries: [
			{ query: GET_ORDER_ITEMS_BY_ORDER, variables: { order_id: orderId } }
		]
	})
};




const DELETE_ORDER_ITEMS = gql`
	mutation DELETE_ORDER_ITEMS (
		$ids: [Int!]!
	) {
		delete_order_item (
			where: { id: { _in: $ids } }
		) {
			affected_rows
		}
	}
`;
export const deleteOrderItems = (itemIds: number[] = [], orderId: number) => {
	if (itemIds.length === 0) { return; }
	return apolloClient.mutate({
		mutation: DELETE_ORDER_ITEMS,
		variables: {
			ids: itemIds
		},
		refetchQueries: [
			{ query: GET_ORDER_ITEMS_BY_ORDER, variables: { order_id: orderId } }
		]
	});
};

