import React, { useState } from 'react';
import { UIForm, UIInput, UIButton, UIModal, useModal, UIHeading, useUIForm, UIForm2 } from 'kit';
import { readString } from 'react-papaparse';
import { Spreadsheet } from '@styled-icons/boxicons-regular/Spreadsheet'
import { Refresh } from '@styled-icons/heroicons-outline/Refresh'
import { FormFieldSpec } from 'types';

export const AddFormDefinitionFromPastePage = (
	props: {
		setFields?: any
	}
) => {
	const modalProps = useModal();	
	const [textResult, setTextResult] = useState([])
	const { formMethods } = useUIForm<any>(textResult);

	//parse data
	const parse = (parseText) => {
		//papa parse	
		var result = readString(parseText.parse).data[0]
		//sanitize periods
		result.forEach((item, index) => {
			result[index] = item.replace(/\./g, ' ')
		})

		setTextResult(result)
	}
	
	//form data
	const submit = formMethods.handleSubmit(() => {
		const formData = formMethods.getValues();
				
		let formDefinition = []
		for (const key in formData) {			
			if (key !== 'name' && key !== 'foreign_table') {
				formDefinition[key] = { name: formData[key], input_type: 'input', value_type: 'string', status: 'active', is_required: true }
			}
		}
		props.setFields(formDefinition)
		
		modalProps.toggleModal();
	})

	function toggleModal(){
		if (!modalProps.isModalOpen){setTextResult([])}
		modalProps.toggleModal();
	}

	return (
		<React.Fragment>
			<UIButton
				icon={<Spreadsheet />}
				label="Create Fields from Spreadsheet"
				onClick={toggleModal}
				quiet
			/>
		
			<UIModal {...modalProps} key={'key_' + textResult.length}>
				
				<UIForm onSubmit={parse} autoSave>
					<UIHeading>Create Form Fields from spreadsheet</UIHeading>					
					<UIInput name="parse" label="Copy and Paste 1 row from a spreadsheet, each cell will create a new field" required/>
				</UIForm>

				<UIForm2 onSubmit={submit} formMethods={formMethods}>
					<UIButton muted label="Cancel" onClick={modalProps.toggleModal} />
					<UIButton label="Save Fields to Form" onClick={submit} disabled={textResult.length < 1}/>

					{textResult.map( (value, index) => {
						return (
							<UIInput name={String(index)} label={'Field:' + index} key={'d_' + index} defaultValue={value}/>
						)
					})}
				</UIForm2>

			</UIModal>

		</React.Fragment>
	)
}



export function InputFormParse(
	props: {
		parseResult?
		formFields: Array<FormFieldSpec>
		autoSave?: boolean
	}
) {
	const modalProps = useModal();

	//parse data
	const parse = (parseText) => {		
		var result = readString(parseText.parse).data[0];
		var newValues = {};
		props.formFields.map( (key, value) => {			
			newValues[key.name] = result[value]
		});
		props.parseResult(newValues);
		
		modalProps.toggleModal();
	}

	return (
		<React.Fragment>
			<UIButton icon={<Refresh/>} label="Parse Text" onClick={modalProps.toggleModal} margin="mediumVertical" />		
			<UIModal {...modalProps}>
				<UIForm onSubmit={parse} autoSave={props.autoSave}>					
					<UIInput name="parse" label="Paste Text to Parse" required/>
					{props.autoSave ? null : <UIButton type="submit" icon={<Refresh/>} label="Parse Text" margin="mediumVertical" />}		
				</UIForm>
			</UIModal>
		</React.Fragment>	
	)
}
