import React from 'react';

import { UIChip } from 'kit';

import { TableDateMultiField } from 'components/Table/TableDateField';
import { SelectColumnFilterArray } from '../TableFilters';
import { ColDefs } from './tableColumnUtility';



type ToolRecipeColId = 'name' | 'category' | 'description' | 'createdAt' | 'updatedAt';

export const toolRecipeCols: ColDefs<ToolRecipeColId> = {
	name: 	{
		Header: 'Name',
		accessor: 'name',
		className: 'emphasis'	
	},
	category: {
		Header: 'Category',
		accessor: 'tagNames',
		Filter: SelectColumnFilterArray,
		filter: 'includesAll',
		className: 'center-align',
		Cell: ({ cell: { value: tagNames } }) => tagNames.map(
			(tagName, index) => <UIChip key={`${tagName}-${index}`} outline noMarginBottom label={tagName} />
		)
	},
	description: {
		Header: 'Description',
		accessor: 'description'
	},
	createdAt: {
		Header: 'Created',
		accessor: 'created_at',
		disableGlobalFilter: true,
		Cell: ({ cell: { value } }) => <TableDateMultiField ISOdateString={value} dateDisplayFormatTop="dateShortWithYear" dateDisplayFormatBottom="time" />
	},
	updatedAt: {
		Header: 'Last Changed',
		accessor: 'updated_at',
		disableGlobalFilter: true,
		Cell: ({ cell: { value } }) => <TableDateMultiField ISOdateString={value} dateDisplayFormatTop="dateShortWithYear" dateDisplayFormatBottom="time" />
	}
}