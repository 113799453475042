import React from 'react';
import styled from 'styled-components';

import { UIFlexbox, UIButton } from 'kit';

import { ChevronLeft } from '@styled-icons/fa-solid/ChevronLeft';
import { ChevronRight } from '@styled-icons/fa-solid/ChevronRight';


export function TablePagination(props: {
	tableInstance;
	hasSelectablePageSize: boolean;
}) {
	const { 
		canPreviousPage, canNextPage,
		pageOptions, 
		//pageCount, 
		//gotoPage, 
		nextPage, previousPage, 
		//setPageSize, 
		state: { pageIndex/*, pageSize*/ } 
	} = props.tableInstance;
	return (
		<PaginationContainer>
			<UIButton quiet onClick={previousPage} disabled={!canPreviousPage} icon={<ChevronLeft />} label="" />

			<div className="pagination-center">
				Page <strong> {pageOptions.length === 0 ? 0 : pageIndex + 1}</strong> of {pageOptions.length}
			</div>

			<UIButton quiet onClick={nextPage} disabled={!canNextPage} icon={<ChevronRight />} label="" />
			{/* <span>
				Go to page:{' '}
				<input type="number" defaultValue={pageIndex + 1} onChange={e => {
					const toPage = e.target.value ? Number(e.target.value) - 1 : 0;
					gotoPage(toPage);
				}} style={{ width: '100px' }} />
			</span>{' '} */}

			{/* {props.hasSelectablePageSize ?
				<select value={pageSize} onChange={e => {
					setPageSize(Number(e.target.value));
				}}>
					{[15, 20, 30, 40, 50].map(newPageSize => (<option key={newPageSize} value={newPageSize}>
						Show {newPageSize}
					</option>))}
				</select>
				: null} */}
		</PaginationContainer>
	);
}

export const PaginationContainer = styled(UIFlexbox)`
	align-content: center;
	width: fit-content;
	height: 40px;
	margin: 20px auto;
	border: 1px solid ${props => props.theme.colors.colorDefinitions.grey3};
	border-radius: 10px;
	button {
		margin: 0%;
		padding: 0;
		width: 40px;
		height: -webkit-fill-available;
		border-radius: 0;
		span {
			margin: 0;
		}
	}
	.pagination-center {
		border-left: 1px solid ${props => props.theme.colors.colorDefinitions.grey3};
		border-right: 1px solid ${props => props.theme.colors.colorDefinitions.grey3};
		padding: 10px;
		strong {
			color: ${props => props.theme.colors.primary};
			font-size: 1.1em;
			margin: 0 3px;
		}
	}
`;
