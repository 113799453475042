import React from 'react';

import { StepCard } from 'components/WorkflowEditor/StepCard';

import { useQuery } from '@apollo/client';
import { GET_JOBS } from '@constants/gql';

import { useNavMain } from 'routing';
import { useHydrateStepFromJob } from 'services/stepHydrate';
import { Job } from 'types';


export const JobStepList = (	
	{ jobId }: { 
		jobId: number,
	} 
) => {
	const navigateJobStep = useNavMain('operateStep');

	const { 
		loading, error,
		data: { job: [job = {} as Job] } = { job: [] }
	} = useQuery(GET_JOBS, { 
		variables: { id: jobId }
	});

	const { getWorkflowStepFull } = useHydrateStepFromJob(jobId);


	function navToStep(index?: any){
		navigateJobStep(jobId, index);
	}
	
	if (loading || error) { return null; }
	return (
		<div>
			
			{job.steps.map( (step, index0) => {
				const { stepInfo, stepPlan, supp } = getWorkflowStepFull(index0);
				let indicator: any = job.current_step_index > index0 + 1 ? 'complete' : index0 + 1
				if (supp?.workflow_mod?.skip) {
					indicator = 'skip';
				}
				return (			
					<StepCard
						key={'stepHistory_' + index0} 
						onClick={navToStep.bind(this, index0 + 1)}
						isSelected={ job.current_step_index === index0 + 1 && job.job_status !== 'complete'}
						indicator={indicator}
						stepInfo={stepInfo}
						stepPlan={stepPlan}
					/>
				)
			})}
			
		</div>
	)
}