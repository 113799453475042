import React, { useEffect } from 'react';
import styled from 'styled-components';

import { PaddingProps, paddingStyles, MarginProps, marginStyles } from '@constants';

import { useForm, FormProvider, UseFormMethods, UseFormOptions, useFormContext } from 'react-hook-form';
import { FormStateProxy } from 'react-hook-form/dist/types/form';

import debounce from 'lodash.debounce';
import get from 'lodash.get';


import { useLeavePage } from 'services/hooks/useLeavePage';  //DON'T import this from the hooks module, because that will cause index.ts to load, which will cause a circular reference

export const UIForm = (	
	props: { 
		onSubmit?(event: any, formState?: FormStateProxy): void
		onSubmitWithReset?(event: any, formState?: FormStateProxy): Promise<boolean>
		autoSave?: boolean
		autoSaveAdditionalActions?
		defaultValues?
		children?
		style?
		disableAutocomplete?: boolean
	} & PaddingProps & MarginProps
) => {
	const { defaultValues, autoSave, autoSaveAdditionalActions, disableAutocomplete, onSubmitWithReset, ...restProps } = props;
	const formMethods = useForm( {
		defaultValues
	} );

	const submit = (formData) => {
		if (typeof props.onSubmitWithReset === 'function'){
			props.onSubmitWithReset(formData, formMethods.formState).then( () => {
				formMethods.reset();
			});
		}
		else if (typeof props.onSubmit === 'function'){
			props.onSubmit(formData, formMethods.formState);
		}
	}

	const onAutoSave = () => {
		const values = formMethods.getValues();
		formMethods.trigger().then( validationSuccessful => {
			if (validationSuccessful) {
				props.onSubmit(values);
				if (typeof autoSaveAdditionalActions === 'function') {
					props.autoSaveAdditionalActions();
				}
			}
		})
	}

	const debounceAutoSave = debounce(onAutoSave, 400);

	return (
		<FormProvider {...{ ...formMethods, autoSave, onAutoSave: debounceAutoSave /*pass autoSave in through context for usage with unregisterable components (react-select) */ }}>
			{/* "handleSubmit" will validate your inputs before invoking "onSubmit" */}
			<StyledForm 
				{...restProps}
				onSubmit={formMethods.handleSubmit(submit)}
				onBlur={autoSave ? debounceAutoSave : () => {}}
				style={props.style}
				autoComplete={disableAutocomplete ? 'off' : 'on'}
			/>
		</FormProvider>
	)
}

UIForm.defaultProps = {
	margin: 'none'
}

const StyledForm = styled.form`
	${paddingStyles}
	${marginStyles}
	min-width: 200px;
`;


export const useUIForm = <FormData, >(
	defaultValues = {} as UseFormOptions<FormData>['defaultValues'],
	options?: {
		resetTrigger?: any,
		onDirtyChange?: (isDirty: boolean) => void
	}
)/*: ModalHookProps*/ => {

	//  Initialize the form.
	const formMethods = useForm<FormData>( {
		defaultValues
	} );

	// Reset and re-render the form on demand
	useEffect(() => {
		if (typeof options?.resetTrigger !== 'undefined'){
			//@ts-ignore might be able to get rid of this in typescript 4
			formMethods.reset(defaultValues);
		}
	}, [options?.resetTrigger])
	// ^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^
	

	// Show an alert if the user attempts to leave when the form has been changed without saving.
	// Form has to be submitted with handleSubmit in order for isSubmitted to update
	const isDirty = formMethods.formState.isDirty;
	const isSubmitted = formMethods.formState.isSubmitted;

	useEffect(() => {
		// If the form submission changes from false to true, reset isDirty also (which stays true as of r-h-f 6.0.8)
		if (isSubmitted){
			// resetting the form reverts isDirty back to false.
			// This is needed so that the useLeavePage hook can allow leave if the form was just saved
			//@ts-ignore
			formMethods.reset(formMethods.getValues());
		}
	}, [isSubmitted])

	// provide a callback if dirty state changes. This is handy for 
	// components where the dirty state is needed somewhere above the form itself, like modals or dialogs
	useEffect(() => {
		if (typeof options?.onDirtyChange === 'function'){
			options.onDirtyChange(isDirty)
		}
	}, [isDirty])

	useLeavePage(isDirty);
	// ^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^


    return { formMethods, isDirty };
}


export const UIForm2 = (
	{
		onSubmit, 
		onSubmitWithReset, 
		autoSave, 
		onAutoSave = () => {},
		onChange, // don't provide a default for this one because we'll use use undefined to determine if this needs to be triggered
		disableAutocomplete = false, 
		replaceEmptyWithNull = true,
		formMethods,
		style,
		children,
		...restProps
	}: { 
		onSubmit?(event: any): void
		onSubmitWithReset?(event: any): Promise<boolean>
		autoSave?: boolean
		onAutoSave?: () => void
		onChange?: (formData) => void
		disableAutocomplete?: boolean
		replaceEmptyWithNull?: boolean
		formMethods: UseFormMethods<any>
		style?

		children?
		className?: string
	} & PaddingProps & MarginProps
) => {
	const submit = (formData) => {
		if (replaceEmptyWithNull) {
			Object.keys(formData).forEach(key => {
				if (formData[key] === '') {
					formData[key] = null;
				}
			});
		}

		if (typeof onSubmitWithReset === 'function'){
			onSubmitWithReset(formData).then( () => {
				formMethods.reset();
			});
		}
		else if (typeof onSubmit === 'function'){
			onSubmit(formData);
		}
	}

	// NOTE: this only gets triggered on an interaction with an actual form element.
	// For example, changing a value via formMethods.setValue won't trigger this
	const handleAutoSave = () => { 
		const values = formMethods.getValues();
		if (typeof onChange === 'function') {
			onChange(values);
		}
		formMethods.trigger().then( validationSuccessful => {
			if (validationSuccessful && autoSave) {
				onSubmit(values);
				onAutoSave();
			}
		})
	}
	const shouldAutoSave = autoSave || onChange;
	const debounceAutoSave = debounce(handleAutoSave, 300);

	let autoFocusFound = false;
	
	const autoFocusedChildren = Array.isArray(children) ? children?.map( (child, index) => {
		if (child?.length > 0) {
			// The first child is actually an array of children...loop through the array
			const newChild = child.map( (arrChild, arrIndex) => {
				const [isConverted, resultChild] = convertChildAutoFocus(arrChild, arrIndex, autoFocusFound);
				if (isConverted) { autoFocusFound = true; }
				return resultChild;
			})
			return newChild;
		}
	
		// else { // Just a normal child
			const [isConverted, resultChild] = convertChildAutoFocus(child, index, autoFocusFound);
			if (isConverted) { autoFocusFound = true; }
			return resultChild;
	})
	: convertChildAutoFocus(children, 0, autoFocusFound);

	function convertChildAutoFocus(child, index, autoFocusAlreadyFound) {
		if (
			(index === 0 || index === 1) && 
			(child?.type?.name === 'UIInput' || child.props?.formFieldDef?.input_type === 'input') &&
			!autoFocusAlreadyFound
		) {
			autoFocusFound = true;
			return [true, React.cloneElement(child, { autoFocus: true, key: `autofocused_${index}` })]
		}
		return [false, child];
	}

	return (
		<FormProvider {...{ ...formMethods, autoSave: shouldAutoSave, onAutoSave: debounceAutoSave /*pass autoSave in through context for usage with unregisterable components (react-select) */ }}>
			{/* "handleSubmit" will validate your inputs before invoking "onSubmit" */}
			<StyledForm
				{...restProps}
				onSubmit={formMethods.handleSubmit(submit)}
				onChange={shouldAutoSave ? debounceAutoSave : () => {}}
				style={style}
				autoComplete={disableAutocomplete ? 'off' : 'on'}
				children={autoFocusedChildren}
			/>
		</FormProvider>
	)
}

UIForm2.defaultProps = {
	margin: 'none'
}


export function useFormCustomRegister(name: string, options?: { 
	required?
	valueOnRegister? 
	validate?
	shouldDoubleTriggerOnAutoSave?: boolean,
	formMethods?
}) {
	const { required = true, valueOnRegister, validate, shouldDoubleTriggerOnAutoSave = false, formMethods } = options || {};

	const formContext = formMethods || useFormContext() || {};
	//@ts-ignore
	const { register, unregister, setValue, trigger, errors } = formContext;
	//@ts-ignore
	const autoSave = formContext.autoSave;
	//@ts-ignore
	const autoSaveSubmit = formContext.onAutoSave;
	
	if (autoSave && validate && process.env.NODE_ENV === 'development') {
		console.warn('%cAutosave can not be used with a custom validate function (it won\'t work)!', 'color: blue');
	}

	const errorsForThisField = get(errors, name);
	const isRequiredValidationError = errorsForThisField?.type === 'required';

	useEffect(() => {
		register(name, { required: required, validate: validate });
		if (valueOnRegister) {
			updateFormValue(valueOnRegister);
		}
		return () => unregister(name);
	}, [register, unregister]);

	function updateFormValue(newValue) {
		setValue(name, newValue, {
			shouldDirty: true,
			shouldValidate: true
		});

		if (autoSave) {
			trigger().then( validateSuccess => {
				if (shouldDoubleTriggerOnAutoSave) {
					//trigger validation again, since the validation will not capture the newly rendered components
					trigger().then( validateSuccess2 => {
						if (validateSuccess2 && autoSave) {
							autoSaveSubmit();
						}
					})
				}
				else if (validateSuccess && autoSave) {
					autoSaveSubmit();
				}
			})
		}
	}
	
	return { updateFormValue, isRequiredValidationError }
  }