import { gql } from '@apollo/client';
import { apolloClient } from 'services/apollo';

import { GET_INVENTORY_UNIQUE_TYPES, GET_INVENTORY_UNIQUE } from 'services/inventoryUniqueFetch';
import { inventoryUniqueAllColumns } from '@constants';
import { InventoryUniqueChanges } from 'types';

export const ADD_INVENTORY_UNIQUE = gql`
	mutation ADD_INVENTORY_UNIQUE ( 
		$type_id: Int!
		$stage_id: Int
		$name: citext!
		$status: String
	) {
		insert_inventory_unique (
			objects: { 
				type_id: $type_id
				stage_id: $stage_id
				name: $name
				status: $status
			}
		) {			
			affected_rows			
		}
	}
`;


export const addInventoryUniqueItem = (typeId: number, stageId: number, name: string, status: string, queryVariables) => {
	return apolloClient.mutate({ 
		mutation: ADD_INVENTORY_UNIQUE, 
		variables: { 
			type_id: typeId,
			stage_id: stageId,
			name: name,
			status: status
		},
		
		refetchQueries: [
			{ query: GET_INVENTORY_UNIQUE, variables: queryVariables }
		]
	}).then( (queryResponse) => {
		return queryResponse.data?.insert_inventory_unique?.[0] || {}
	});
};

export const ADD_INVENTORY_UNIQUE_TYPE = gql`
	mutation ADD_INVENTORY_UNIQUE_TYPE ( 
		$name: citext!
	) {
		insert_inventory_unique_type (
			objects: { 
				name: $name
			}
		) {			
			affected_rows			
		}
	}
`;

export const addInventoryUniqueType = (name: string) => {
	return apolloClient.mutate({ 
		mutation: ADD_INVENTORY_UNIQUE_TYPE, 
		variables: { 
			name: name
		},
		refetchQueries: [
			{ query: GET_INVENTORY_UNIQUE_TYPES }
		]
	}).then( (queryResponse) => {
		return queryResponse.data?.insert_inventory_unique_type?.[0] || {}
	});
};

export const ADD_INVENTORY_UNIQUE_TYPE_STAGE = gql`
	mutation ADD_INVENTORY_UNIQUE_TYPE_STAGE ( 
		$type_id: Int!
		$name: citext!
	) {
		insert_inventory_unique_type_stage (
			objects: {
				type_id: $type_id
				name: $name
			}
		) {			
			affected_rows			
		}
	}
`;

export const addInventoryUniqueTypeStage = (typeId: number, name: string) => {
	return apolloClient.mutate({ 
		mutation: ADD_INVENTORY_UNIQUE_TYPE_STAGE,
		variables: {
			type_id: typeId,
			name: name
		},
		refetchQueries: [
			{ query: GET_INVENTORY_UNIQUE_TYPES }
		]
	}).then( (queryResponse) => {
		return queryResponse.data?.insert_inventory_unique_type_stage?.[0] || {}
	});
};

const UPDATE_INVENTORY_UNIQUE = gql`
	mutation UPDATE_INVENTORY_UNIQUE ( 
		$id: Int!
		$changes: inventory_unique_set_input!
	) {
		update_inventory_unique (
			where: { id: { _eq: $id }}
			_set: $changes
		) {
			returning {
				${inventoryUniqueAllColumns}
			}
		}
	}
`;

export function changeInventoryUniqueItem(id: number, changes: InventoryUniqueChanges) {
	apolloClient.mutate({ 
		mutation: UPDATE_INVENTORY_UNIQUE,
		variables: {
			id,
			changes: changes
		}
	});
}