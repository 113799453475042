import { currentUnixTime } from 'utility';

type LocalStorageKeys = 'tableParams' | 'token' | 'expirySec';

const checkLocalStorageExpired = () => {
	const isExpired = !window.localStorage.getItem('expirySec') || parseInt(window.localStorage.getItem('expirySec')) < currentUnixTime();
	if (isExpired) {
		window.localStorage.clear();
	}
	return isExpired;
}

export const getLocalStorage = (key: LocalStorageKeys) => {
	checkLocalStorageExpired();
	return JSON.parse(window.localStorage.getItem(key));
}

export const setLocalStorage = (key: LocalStorageKeys, value, expirySec?: number ) => {
	const isExpired = checkLocalStorageExpired();
	window.localStorage.setItem(key, JSON.stringify(value));
	if (isExpired) {
		extendLocalStorageSession(isExpired ? expirySec || currentUnixTime() + 3600 : expirySec);
	}
	else if (typeof expirySec === 'number') {
		extendLocalStorageSession(expirySec);
	}
}

export const extendLocalStorageSession = (newExpirySec: number) => {
	if (newExpirySec > currentUnixTime()) {
		window.localStorage.setItem('expirySec', newExpirySec.toString());
	}
}

export const clearLocalStorage = () => {
	window.localStorage.clear();
}