
import { gql } from '@apollo/client';
import { apolloClient, updateCacheAfterInsert } from 'services/apollo';

import { stepHistoryAllColumns, partWipAllColumns, inventoryUniqueAllColumns } from '@constants';
import { GET_STEP_HISTORY_BY_JOB_INDEX } from 'services/stepHistoryFetch';
import { attachInventory } from 'services/stepInventoryUniqueEffects';

import { arrayString, emptyPromise } from 'utility';
import { Job, StepHistoryInsert, PartWIP } from 'types';



export async function startStep(job: Job, stepIndex: number, recipe: number = null, tool: number = null, idsToAttach: number[] = []): Promise<boolean> {
	if (idsToAttach.length) {
		const attachSuccess = await attachInventory(job.workflow_options, idsToAttach, job.id);
		// toasts are handled in attachInventory function
		if (!attachSuccess) {
			return emptyPromise().then( () => false	);
		}
	}

	const stepId = job.steps[stepIndex - 1].step_id;

	return apolloClient.query({ 
		query: GET_JOB_WIP, 
		variables: { 
			jobId: job.id,
			wipType: 'wip'
		},
		fetchPolicy: 'network-only'
	}).then( queryData => {
		const uniqueIdsInWip = queryData.data?.inventory_unique?.map( item => item.id ) || [];
		const partsInWip: PartWIP[] = queryData.data?.part_wip//?.map( item => item.id ) || [];

		let stepHistoryEntry: StepHistoryInsert = {
			step_id: stepId,
			job_id: job.id,
			job_step_index: stepIndex,
			inventory_unique_items: arrayString(uniqueIdsInWip),
			tool_id: tool,
			recipe_id: recipe,
			start_at: 'now()'
		}
		if (partsInWip.length > 0) {
			stepHistoryEntry.step_history_yields = {
				data: partsInWip.map( partWip => ({
					part_id: partWip.part_id,
					qty_in: partWip.qty
				}))
			};
		}

		return apolloClient.mutate({ 
			mutation: START_STEP, 
			variables: { stepHistObj: stepHistoryEntry },
			update(cache, result) {
				updateCacheAfterInsert(cache, result, 'step_history', GET_STEP_HISTORY_BY_JOB_INDEX, { 
					// these both need to be integers to update the cache properly
					jobId: job.id,
					index1: stepIndex
				});
			}
		}).then( () => { 
			return true; 
		}).catch(error => { 
			console.log(error); 
			return false;
		});
	}).catch(error => { 
		console.log(error); 
		return false;
	});
}


export const GET_JOB_WIP = gql`
	query GET_JOB_WIP ( 
		$jobId: Int!
		$wipType: String
	) {
		inventory_unique ( 
			where: { job_id: { _eq: $jobId } }  
		) {
			${inventoryUniqueAllColumns}
		}
		part_wip (
			where: { 
				job_id: { _eq: $jobId } 
				type: { _eq: $wipType }
			}  
		){
			${partWipAllColumns}
		}
	}
`;

export const START_STEP = gql`
	mutation START_STEP ( 
		$stepHistObj: [step_history_insert_input!]!
	) {
		insert_step_history (
			objects: $stepHistObj
		) {
			returning {
				${stepHistoryAllColumns}
			}
		}
	}
`;