import React from 'react';
import styled from 'styled-components';

import { UIHeading, UIButton, useModal, UIModal, UICard,
	UIFlexbox, UIFlexChild, UIKeyValue, UIIconButton,
	Placeholder, useDrawer } from 'kit';
	
import { Cogs } from '@styled-icons/fa-solid/Cogs';
import { Edit } from '@styled-icons/fa-regular/Edit';

import { RenderedFormField } from 'components/FormBuilder/RenderedFormField';
import { EditFieldForm } from './EditFieldForm';
import { AddFormDefinitionFromPastePage } from './AddFormDefinitionFromPastePage';

import { DragDropContext } from 'react-beautiful-dnd';
import { useDynamicList, UIDroppable, UIDraggable } from 'hooks';
import { FormDefinition, FormFieldSpec } from 'types';



export const FormBuilder = (
	{
		formDefinition, onUpdate
	}:
	{
		formDefinition: FormDefinition
		onUpdate:(rows) => void
	}
) => {
	const modalProps = useModal(false, []);
	const isNewForm = !formDefinition.id;

	const dynamicListContext =  useDynamicList<FormFieldSpec>(formDefinition.fields, { 
		hideAddButtonWhileAdding: false, 
		onUpdate: onUpdate,
		onDelete: onDelete 
	});
	const { rows: fieldList, setSelectedIndex, setRows, AddButton, DeleteButton, editRow, onDragEnd } = dynamicListContext;

	const drawerContext = useDrawer();


	const editField = (fieldIndex: number) => {
		setSelectedIndex(fieldIndex);
		drawerContext.openDrawer();
	}


	const restoreField = (formData, index) => {		
		const newFieldData: FormFieldSpec = {
			...formData,
			status: 'active'
		}		
		editRow(index, newFieldData);
		modalProps.toggleModal();
	}


	function onDelete(newRows: FormFieldSpec[], deletedRow: FormFieldSpec, deletedIndex0) {		
		const newFieldData: FormFieldSpec = {
			...deletedRow,
			status: 'archive'
		}
		//add new row and setRows
		const newFieldList = newRows.concat([newFieldData]);
		setRows(newFieldList)
	}

	const archiveModal = () => {		
		modalProps.setModalData(fieldList);
		modalProps.toggleModal()
	}

	return (
		<React.Fragment>

			<DragDropContext onDragEnd={onDragEnd}>


				<UIFlexChild asFlexbox flexDirection="row" flexJustify="space-between" flexAlign="baseline">
					<UIHeading>Form Preview</UIHeading>
					<UIButton quiet icon={<Cogs size={20}/>} label="Archived Fields" onClick={archiveModal.bind(this)}/>
				</UIFlexChild>

				{fieldList.length === 0 ? <div>No fields.</div> :
					<PreviewContainer>
						<UIDroppable
							name="destination"
							readonly={false}
						>
							{ fieldList.filter( item => { return item.status === 'active' }).map( (definition, defIndex) => {							
								return ( <UIDraggable
									index={defIndex}
									name={'dest_' + defIndex}
									clone={false}
									key={'drag_' + defIndex + definition.name}
									content={ 
									<UIFlexbox flexAlign="center">
										<InputOffsetContainer>
											<RenderedFormField 
												formFieldDef={definition}
												value={null}
												skipRegister
											/>
										</InputOffsetContainer>
										<UIIconButton
											icon={<Edit />}
											onClick={editField.bind(this, defIndex)}
										/>
										<DeleteButton index={defIndex} margin="medium" />
									</UIFlexbox> 
									}
								/> )
							}) }

						</UIDroppable>

					</PreviewContainer>
				}

				<AddButton label="Add New Field" onClick={editField.bind(this, -1)} margin="mediumVertical" />
				

				{isNewForm && <AddFormDefinitionFromPastePage setFields={setRows}/>}
		
			</DragDropContext>

			<EditFieldForm
				drawerContext={drawerContext}
				dynamicListContext={dynamicListContext}
			/>

			<UIModal {...modalProps}>

				<UIKeyValue title="Select an archived field to restore" text="Restored field will be the last in list"/>
				{modalProps.modalData.filter( item => { return item.status === 'archive' }).length < 1 &&
					<Placeholder text="No Archived Fields"/>
				}
				{
					modalProps.modalData.map( (definition, defIndex) => {						
						if (definition.status === 'archive'){
						return (
							<UIFlexbox width="100%" className="steprow" flexAlign="center" key={'input_' + defIndex}>
								<UICard									
									onClick={restoreField.bind(this, definition, defIndex)}
									flexJustify="flex-start"
									width="100%"
									padding="small"
								>
									<UIKeyValue title={definition.name} text={definition.input_type}/>

								</UICard>

							</UIFlexbox>
						)}
					})
				}
			</UIModal>

		</React.Fragment>
	)
}

const PreviewContainer = styled.div`

	background-color: white;
	border: 1px solid lightgrey;
	border-radius: 10px;
	margin: 0 auto 2rem;
	.draggable {
		transition: all 0.2s;
		border-radius: 5px;
	}
	.draggable:hover:not(.draggable-dragging) {
		/* background-color: #b0daea; */
		background-color: ${props => props.theme.colors.primaryLight1};
		svg {
			opacity: 1;
		}
	}
	svg {
		opacity: 0;
		/* color: white; */
	}
`;

const InputOffsetContainer = styled.div`
	margin-right: 20px;
	margin-bottom: 10px;
	padding-top: 18px;
	width: 100%;
`;