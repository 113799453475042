import React, { useState, useEffect } from 'react';

import { UIButton, UIHeading, UINumberInputControlled, UIKeyValueHorizontal, UISection, UIRadioSegment } from 'kit';

import { fulfillFromInventory, fulfillFromReservation, reserveFromInventory } from 'root/services/partTxMutate';

import { Order, PartWIP } from 'types';
import { useFulfill } from 'hooks';
import { getObjectFromArray } from 'utility';
import { useQuery } from '@apollo/client';
import { GET_JOB_WIP } from 'root/services/stepStart';
import { GET_ORDERS } from 'root/services/orderFetch';

const FulFillReserveHeader = ( { orderItemId, orderId }: {
	orderItemId: number
	orderId: number
}) => {

	const { 
		data: { order: [order = {} as Order] } = { order: [] }
	} = useQuery(GET_ORDERS, { variables: { id: orderId } });
	
	const { 
		orderItem,
		lineItemQty, 
		fulfilledQty,
		reservedFromStockQty,
		reservedFromWipQty,
		partStockMinusReservedQty

	} = useFulfill(orderItemId);

	return (
		<UISection>
			<UIKeyValueHorizontal title="Order Name" text={order.name} justify />
			<UIKeyValueHorizontal title="Product Name" text={orderItem.part?.name} justify />
			<UIKeyValueHorizontal title="Qty Ordered" text={lineItemQty} justify />
			<UIKeyValueHorizontal title="Qty Fulfilled" text={fulfilledQty} justify />
			<UIKeyValueHorizontal title="Qty In Stock" text={partStockMinusReservedQty + (reservedFromStockQty > 0 ? ` (+${reservedFromStockQty} reserved)` : '')} justify />
			<UIKeyValueHorizontal title="Qty Reserved From Stock" text={reservedFromStockQty} justify />
			<UIKeyValueHorizontal title="Qty Reserved From WIP" text={reservedFromWipQty} justify />
		</UISection>
	)
}

export const FulfillLineItem = (
	{
		orderItemId, orderId,
		onAction = () => {}
	}:
	{
		orderId: number
		orderItemId: number
		onAction?
	}
) => {
	
	const { 
		orderItem,
		stockReservations,
		reservedFromStockQty,
		unfulfilledAndNotReservedQty,
		fulfillableQtyInclStockReserves

	} = useFulfill(orderItemId, true);

	const [qty, setQty] = useState(fulfillableQtyInclStockReserves);
	const [radioValue, setRadioValue] = useState< 0 | 1 >(0);

	useEffect(() => {
		setQty(fulfillableQtyInclStockReserves);
	}, [fulfillableQtyInclStockReserves, reservedFromStockQty])


	function fulfillInv() {
		fulfillFromInventory(orderId, qty, orderItemId, orderItem.part.id, null, null);
		onAction();
	}
	function fulfillReservation(reservationId) {
		const reservation: PartWIP = getObjectFromArray(stockReservations, 'id', reservationId)
		const { qty: reservedQty, order_item_id: reserveOrderItemId, job_id: reservedJobId  } = reservation;
		fulfillFromReservation(orderId, reservedQty, reserveOrderItemId, orderItem.part.id, reservedJobId);
		onAction();
	}


	return (
		<>
			<UIHeading>Fulfill Line Item</UIHeading>
			<FulFillReserveHeader orderItemId={orderItemId} orderId={orderId} />
			{stockReservations.length > 0 &&
				<UIRadioSegment 
					options={[
						{ name: 'Fulfill From Inventory', id: 0 },
						{ name: 'Fulfill From Reservation', id: 1 }
					]} 
					onChange={setRadioValue}
					default={radioValue}
					name="fulfill-type" label=""
				/>
			}

			{radioValue === 0 ?
				<>
					<UISection asFlexbox flexDirection="column" flexAlign="center">
						<UINumberInputControlled 
							defaultValue={qty}
							onChange={setQty}
							min={0}
							max={fulfillableQtyInclStockReserves}
							endLabel={`of ${unfulfilledAndNotReservedQty} ${orderItem.part?.uom}`}
						/>
					</UISection>
					<UIButton full label={'Fulfill From Inventory'} onClick={fulfillInv} disabled={qty === 0} />
				</>
				:
				<>
					<UISection asFlexbox flexDirection="column" flexAlign="center">
						{stockReservations.map( reservation => {
							return (
								<UIButton 
									key={reservation.id}
									full 
									label={`Fulfill ${reservation.qty} From Reservation (${reservation.job?.name ?? 'Stock'})`} 
									onClick={fulfillReservation.bind(this, reservation.id)} 
								/>
							)
						}) }
					</UISection>
				</>
			}
		</>
	)
}



export const ReserveLineItem = (
	{ 
		orderItemId, orderId,
		onAction = () => {}
	}:
	{
		orderId: number
		orderItemId: number
		onAction?
	}
) => {
	
	const { 
		orderItem,
		reservedFromStockQty,
		reservedFromWipQty,
		wipReservations,
		stockReservations,
		unfulfilledAndNotReservedQty,
		reservableQtyInclStockReserves

	} = useFulfill(orderItemId, true);

	const [activeJobId, setActiveJobId] = useState(null);
	const [activeExistingQty, setActiveExistingQty] = useState(0);
	const [activeLabel, setActiveLabel] = useState('');
	const [isEditing, setIsEditing] = useState(false);

	function chooseActiveBucket(jobId, existingQty, label) {
		setActiveJobId(jobId);
		setActiveExistingQty(existingQty);
		setActiveLabel(label);
		setIsEditing(true);
	}

	function changeReservation(qtyChange, jobId = null) {
		reserveFromInventory(orderId, qtyChange, orderItemId, orderItem.part.id, jobId, null);
		onAction();
	}

	const BucketButton = ({ type, reservation = {} as PartWIP }: { type: 'stock' | 'wip', reservation?: PartWIP }) => {
		let buttonLabel;// = existingQty === 0 && isStock ? 'Reserve From Stock' :
		if (type === 'stock' && !reservation.id) {
			buttonLabel = 'Reserve From Stock';
		}
		else if (type === 'stock' && !reservation.job_id) {
			buttonLabel = `${reservation.qty} reserved from Stock`
		}
		else {
			buttonLabel = `${reservation.qty} reserved from Job: ${reservation.job?.name} (In ${type === 'stock' ? 'Stock' : 'Progress'})`
		}
		//	`${existingQty} reserved from ${isStock ? 'Stock' : 'Job'} `
		return <UIButton full label={buttonLabel} onClick={chooseActiveBucket.bind(this, reservation.job_id, reservation.qty, buttonLabel)} />
	}

	return (
		<>
			<UIHeading>Manage Reservations</UIHeading>
			<FulFillReserveHeader orderItemId={orderItemId} orderId={orderId} />

			{!isEditing && <>
				{reservedFromStockQty === 0 && reservedFromWipQty === 0 && 
					<div>No current reservations.</div>
				}

				{stockReservations.length > 0 && 
					//<BucketButton label={`${reservedFromStockQty} Reserved from Stock`} jobId={null} existingQty={reservedFromStockQty} />
					stockReservations.map( res => 
						<BucketButton key={res.id} type="stock" reservation={res} />
					)
				}
				{wipReservations.length > 0 && 
					wipReservations.map( res => 
						<BucketButton key={res.id} type="wip" reservation={res} />
					)
				}

				{reservedFromStockQty === 0 && 
					<BucketButton type="stock" />
				}
			</>}

			{isEditing &&
				<>
					<UIHeading level="4">{activeLabel}</UIHeading>
					<UIButton quiet label="Edit a different Reservation" onClick={setIsEditing.bind(this, false)} />
					<ReservationSelect 
						key={activeJobId}
						startingReserveQty={activeExistingQty}
						uom={orderItem.part?.uom}
						changeReservation={changeReservation}
						unfulfilledAndNotReservedQty={unfulfilledAndNotReservedQty}
						reservableQtyInclStockReserves={reservableQtyInclStockReserves}
						jobId={activeJobId}
						partId={orderItem.part?.id}
					/>
				</>
			}
		</>
	)
}



const ReservationSelect = ({
	startingReserveQty = 0, 
	uom,
	changeReservation, 
	unfulfilledAndNotReservedQty, 
	reservableQtyInclStockReserves,
	jobId,
	partId
}: {
	startingReserveQty: number
	uom: string
	changeReservation
	unfulfilledAndNotReservedQty: number
	reservableQtyInclStockReserves: number
	jobId: number | null // If this is null, reserve from stock
	partId: number
}) => {
	const isStock = typeof jobId !== 'number';

	const { 
		data: { 
			part_wip: productWipResult 
		} = { 
			part_wip: [] as PartWIP[]
		}
	} = useQuery( GET_JOB_WIP, {
		variables: { jobId },
		skip: isStock
	} );
	const wipForJob = productWipResult.filter( wip => wip.part_id === partId && wip.type === 'wip');
	const resForJob = productWipResult.filter( wip => wip.part_id === partId && wip.type === 'reserve' ); // Includes reserves from any order
	
	// free (available) reserve spots for the job
	const maxReservableForJob: number = resForJob[0]?.job?.job_status === 'complete' ? 0 // If the job is complete, make this 0. this means that you can't reserve any additional from stock and say there were part of this job.
		: wipForJob.map( wip => wip.qty ).reduce( (q1, q2) => q1 + q2, 0) - resForJob.map( res => res.qty ).reduce( (q1, q2) => q1 + q2, 0);

	const maxReservableForLineItem = unfulfilledAndNotReservedQty + startingReserveQty;
	const maxReservableForBucket = (isStock ? reservableQtyInclStockReserves : maxReservableForJob) + startingReserveQty;
	const max = Math.min(maxReservableForLineItem, maxReservableForBucket);

	const [qty, setQty] = useState(startingReserveQty === 0 ? reservableQtyInclStockReserves : startingReserveQty);

	function updateReservation() {
		const qtyChange = qty - startingReserveQty;
		changeReservation(qtyChange, jobId)
	}

	return (
		<UISection asFlexbox flexDirection="column" flexAlign="center">
			<UINumberInputControlled 
				defaultValue={qty}
				onChange={setQty}
				min={0}
				max={max}
				endLabel={uom}
			/>
			<UIButton full label={`${startingReserveQty === 0 ? 'Make' : 'Update'} Reservation`} onClick={updateReservation} disabled={qty === startingReserveQty} />
				
		</UISection>
	)
}