import { PartRawEffect, UserReference, Tool, Recipe, Thread, FormInstanceLinked, PartTransaction, Job } from 'types';

export interface Step {
	id: number
	name: string
	description?: string
	is_saved: boolean
	recipes: number[]
	tools: number[]
	raw_parts: number[]
	forms: number[]
	created_at: string
	updated_at: string
}

export type StepInsertObject = Pick<Step,
	'name' |
	'description' |
	'is_saved'
>

export type StepUpdateObject = Partial<Pick<Step,
	'name' |
	'description' |
	'is_saved' |
	'recipes' |
	'tools' |
	'raw_parts' |
	'forms'
>>


export type StepParameterMethod = 'fixed' | 'flex_operator' | 'flex_engineer';

export interface WorkflowStepProcessOptions {
	method: StepParameterMethod
	default_value?: number
	value?: number
}

export interface InventoryUniqueFormEffect {
	form_definition_id: number
	fields: Array<string>
}

export const currentStepVersion = '1';
// schema.json should match the interface
export interface StepPlanV1 {
	version: '1' // Make sure this matches the above
	tool?: WorkflowStepProcessOptions
	recipe?: WorkflowStepProcessOptions
	inventory_effect?:  {
		items: PartRawEffect[]
	}
	inventory_unique_form?: InventoryUniqueFormEffect
	forms?: Array<number>
}

export type StepPlan = StepPlanV1;


export interface StepHistoryRaw {
	id: number
	job_id: number
	job: Job
	job_step_index: number

	step_id
	step: Step
	
	created_at: string
	start_at: string
	start_user: UserReference
	stop_at: string
	stop_user: UserReference
	
	tool_id?: number
	recipe_id?: number
	tool?: Tool
	recipe?: Recipe
	inventory_effect: Array<PartRawEffect>
	inventory_unique_effect?
	
	inventory_unique_items?: number[]

	threads?: Thread[]
	forms?: FormInstanceLinked[]
	step_history_yields?: StepHistoryYield[]
	part_txs: PartTransaction[]
}


export type StepHistoryInsert = Pick<StepHistoryRaw, 
	'step_id' |
	'job_id' |
	'job_step_index' |
	'tool_id' |
	'recipe_id'
> & {
	start_at?: 'now()'
	inventory_unique_items?: string // array literal
	step_history_yields?: {
		data: StepHistoryYieldInsert[]
	}
}

export type StepHistoryChanges = Partial<Pick<StepHistoryRaw, 
	'start_at' |
	'stop_at' |
	'tool_id' |
	'recipe_id'
>> 

export type JobAndStepStatus = 'active' | 'processing' | 'queue' | 'draft' | 'complete';




export interface StepHistoryYield {
	id: number
	created_at: string
	step_history_id: number
	part_id: number
	qty_in: number
	qty_out: number
}

export interface StepHistoryYieldInsert {
	part_id: number
	qty_in: number
	qty_out?: number
}



