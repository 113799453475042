import React, { useContext } from 'react';
import { UISwitch, UIRadioList, UISelect, DefaultOptionCheckbox } from 'kit';

import { useTags } from 'hooks';

import { useFormContext } from 'react-hook-form';
import { EditProductPlans } from './WorkflowOptionsProductPlansEdit';
import { WorkflowEditorContext } from './WorkflowEditorContext';


export const WorkflowOptionsProduct = ( props: {
	isHidden: boolean
} ) => {
	const { workflowOptions, isAmendment } = useContext(WorkflowEditorContext);
	const { watch } = useFormContext();
	
	const hasProduct = watch(`product_enabled`);
	const method = watch(`product.method`);

	return (
		<div style={{ display: props.isHidden ? 'none' : 'block' }}>
			<UISwitch 
				label="Generate Product" 
				name="product_enabled" 
				default={workflowOptions.product_enabled} 
				disabled={isAmendment}
			/>
			{ hasProduct &&
				<UIRadioList 
					name={`product.method`}
					label="Product Selection Method"
					subtitle={`When the inventory leaves a job, what state the items will change to.`}
					options={[
						{ 
							name: 'Fixed', id: 'fixed', 
							subtitle: `Select the product(s) now. Quantities will be determined when the job is created.`
						},
						{ 
							name: 'Flexible', id: 'flex_job', 
							subtitle: `Select the product(s) and quantities when the job is created.` 
						}
					]}
					required
					disabled={isAmendment}
				/>
			}

			{ hasProduct && method === 'fixed' && 
				<EditProductPlans existingPlans={workflowOptions.product?.plans} />
			}

			{ hasProduct && method === 'flex_job' && 
				<SelectProductTags existingTags={workflowOptions.product?.allowed_tags} />
			}

			
		</div>
	)
}

export const SelectProductTags = ({ existingTags = [] }: {
	existingTags: number[];
}) => {
	
	const { isAmendment } = useContext(WorkflowEditorContext);
	const { isTagsLoading, tags } = useTags('partProduct');

	return (
		<UISelect 
			isMulti
			label="Allowed Categories" 
			name="product.allowed_tags" 
			defaultValue={existingTags} 
			options={tags}
			isLoading={isTagsLoading}
			customOption={DefaultOptionCheckbox}
			scrollOnOpen
			disabled={isAmendment}
		/>
	)
}