import React, { useMemo } from 'react';
import { UIHeading, UIFlexbox, UISection } from 'kit';

import { Table } from 'components/Table/Table';
import { invUniqueCols, transformInventoryUniqueFormData } from 'components/Table/tableRef';

import { AddInventoryUniqueItemButtonModal } from 'components/InventoryUnique/InventoryUniqueForms';

import { useQuery } from '@apollo/client';
import { GET_INVENTORY_UNIQUE, GET_INVENTORY_UNIQUE_TYPES } from 'services/inventoryUniqueFetch';

import { capitalizeFirstLetter } from 'utility';
import { InventoryUniqueType, InventoryUniqueItem, FormDefinition } from 'types';
import { useInvUniqueType } from 'hooks';
import { TableActionButton } from '../Table/TableActionButton';


function getUniqueInventoryColumns(formDefinitions: Array<FormDefinition>) {
	let inventoryUniqueColumnDefs = [
		invUniqueCols.name,
		invUniqueCols.type,
		invUniqueCols.status,
		invUniqueCols.stage,
		invUniqueCols.createdAt
	];

	formDefinitions.forEach( formDefinition => {
		formDefinition.fields.forEach( fieldDef => {
			if (fieldDef.status !== 'archive') {
				inventoryUniqueColumnDefs.push({
					Header: fieldDef.name,
					accessor: `form_${formDefinition.id}.${fieldDef.key}`
				})
			}
		})
	})
	
	return inventoryUniqueColumnDefs
}

export type UniqueInventoryTableViewMode = 'single' | 'stage';

export function UniqueInventoryAllTables(
	props: {
		mode: UniqueInventoryTableViewMode
	}
) {	
	
	const {	
		data: { inventory_unique_type: typeData } = { inventory_unique_type: [] as Array<InventoryUniqueType> }
	} = useQuery(GET_INVENTORY_UNIQUE_TYPES);

	//loop through and render a table for each type
	return (
		<>
			{
				typeData.map( (type: InventoryUniqueType) => {
					if (props.mode === 'single') {
						return (<UniqueInventoryTable key={type.id} typeId={type.id} />) 
					}
					if (props.mode === 'stage') {
						return (<UniqueInventoryTablesByStage key={type.id} typeId={type.id} />)
					}
				})
			}
		</>)
}


export function UniqueInventoryTable (
	props: {
		typeId: number
		stageId?: number
		stageIds?: number[]
		status?: string
		selectedIndex?
		selectedIds?
		actionType?: 'view' | 'add'
		actionFunction?
	}
) {	
	const queryVariables = { 
		type_id: props.typeId,
		stage_id: props.stageId,
		//TODO: put the below in a function or hook to encapsulate the void 0 logic. As of 6/12/20, there were 3 places in the code that need this
		stage_ids: props.stageIds?.length > 0 ? props.stageIds : void 0, // an empty array should be interpreted as "all", which requires that this where condition is ignored (do this by setting it to undefined)
		status: props.status
	};
	const { 
		loading, 
		data: { inventory_unique: items } = { inventory_unique: [] as Array<InventoryUniqueItem> }
	} = useQuery(GET_INVENTORY_UNIQUE, { 
		variables: queryVariables
	});	


	const { isLoading: typeLoading, invUnType } = useInvUniqueType(props.typeId);
	
	const formDefinitions = invUnType.form_definitions || [];

	const columns = useMemo(() => {
		const firstCol =  props.actionType !== 'add' ? [] : [{
			Header: '',
			id: 'actions',
			className: 'center-align show-on-hover',
			Cell: table => {
				const item: InventoryUniqueItem = table.row.original;
				return (
					<TableActionButton 
						type={props.actionType}
						onClick={props.actionFunction && props.actionFunction.bind(this, item)}
						routeName={props.actionType === 'view' ? 'inventoryUniqueById' : void 0}
						routeVars={{ id: item.id }}
					/> 
				)
			}
		}]
		//@ts-ignore
		return firstCol.concat(getUniqueInventoryColumns( formDefinitions ) );
	}, [typeLoading, formDefinitions.length, props.selectedIds.length/**Need this one because props.actionFunction references state from orig component, needs to be udpated if state updates */]); 
	const data = useMemo(() => transformInventoryUniqueFormData(items), [loading, items.length]);
	
	
	return (
		<React.Fragment>		
			<UIFlexbox flexAlign="flex-start" flexJustify="space-between">
				<UIHeading>{capitalizeFirstLetter(invUnType.name)} Inventory</UIHeading>				
				<AddInventoryUniqueItemButtonModal typeData={invUnType} queryVariables={queryVariables} />
			</UIFlexbox>
			<Table 
				columns={columns}
				data={data}
				hasSortableColumns
				hasGlobalFilter
				selectedIndex={props.selectedIndex}
				selectedIds={props.selectedIds}
				hasPagination
				hasSelectablePageSize
				hasFilterableColumns
			/>
		</React.Fragment>
	)
}

UniqueInventoryTable.defaultProps = {
	selectedIds: [],
	actionType: 'view'
}


export function UniqueInventoryTablesByStage(
	props: {
		typeId: number
	}
) {
	const { 
		loading, 
		data: { inventory_unique: items } = { inventory_unique: [] as Array<InventoryUniqueItem> }
	} = useQuery(GET_INVENTORY_UNIQUE, { 
		variables: { 
			type_id: props.typeId
		} 
	});	


	const { invUnType } = useInvUniqueType(props.typeId);
	const formDefinitions = invUnType.form_definitions || [];

	const columns = useMemo(() => getUniqueInventoryColumns(formDefinitions), [loading]);

	const data = useMemo(() => {
		if (!invUnType.stages) { return {}; }

		const itemsWithFormData = transformInventoryUniqueFormData(items);

		let dataByStage = {};
		for (let index = 0; index < invUnType.stages.length; index++) {
			const stageObj = invUnType.stages[index];
			dataByStage[stageObj.id] = itemsWithFormData.filter( item => item?.stage?.id === stageObj.id );
		}
		return dataByStage;
	}, [loading, items.length]);

	return invUnType.stages ?
		(<> 
			{invUnType.stages.map( (stage) => {
				return (
					<UISection key={stage.id}>
						<UIHeading>{invUnType.name} / {stage.name}</UIHeading>
						<Table
							key={stage.id}
							columns={columns}
							data={data[stage.id]}
							hasSortableColumns
							hasGlobalFilter
							hasPagination
							hasSelectablePageSize
							hasFilterableColumns
						/>
					</UISection>
				)
			})}
		</>)
	: null
}