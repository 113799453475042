import React, { useState } from 'react';
import styled from 'styled-components';

import { UIPage, UIHeading, UISubtitle, UIButton, UIForm2, useUIForm, UIInput, UISelect, UICard, UIFlexbox, useModal, UIModal } from 'kit';


import { addJobDraft, updateJobFormOnly, updateJobFormAndWorkflow } from 'services/jobCreate';
import { useFormContext } from 'react-hook-form';
import { Job } from 'types';

import { useParams } from 'react-router-dom';
import { useNavMain, useNavFull } from 'routing';
import { useQuery } from '@apollo/client';
import { GET_JOBS } from '@constants/gql';
import { useWorkflows } from 'services/getWorkflowById';
import { getObjectFromArray } from 'utility';
import { JobDraftTable } from 'components/Jobs/JobDraftTable';
import { NavbarPortal } from 'root/components/Navigation/NavbarPortal';


export const JobDraft = (	
) => {
	const modalProps = useModal(false);
	const navigateToJobDraftById = useNavMain('jobDraftById');
	const navigateToJobReview = useNavMain('jobReviewById');
	const urlParams = useParams();	
	const jobId = parseInt(urlParams.id) || 0; //make the job id 0 so that the query below doesn't just fetch all jobs	
	const isNewDraft = jobId === 0;
	
	const [selectedWorkflowId, setSelectedWorkflowId] = useState(null); //only used to sync up with form in a new job

	const { 
		loading: queryJobLoading, 
		error: queryJobError, 
		data: { job: [job = {} as Job] } = { job: [] }
	} = useQuery(GET_JOBS, { 
		variables: { id: jobId }, 
		skip: isNewDraft,
		fetchPolicy: 'no-cache' 
	});

	
	const numStepsInJob = job?.steps?.length ?? 0;
	
	const { workflows } = useWorkflows();
	const currentlySelectedWorkflow = getObjectFromArray(workflows, 'id', selectedWorkflowId || job.workflow_id);

	const modeIsSaved = (job?.workflow?.id || selectedWorkflowId) > 0;
	const modeIsCustom = !job?.workflow?.id && numStepsInJob > 0;
	const modeIsSet = modeIsSaved || modeIsCustom;

	
	const { formMethods } = useUIForm(job, { resetTrigger: job.id });


	if (jobId !== 0 && queryJobLoading) {
		return <div>Loading...</div>
	}
	else if (jobId !== 0 && queryJobError) {
		return <div>Error...</div>
	}
	else if (jobId !== 0 && job.job_status && job.job_status !== 'draft') {
		return <div>This job is no longer a draft</div>
	}

	const goReview = (formData) => {
		if (!isNewDraft) {
			updateJobFormAndWorkflow(job, formData).then( thingMutateResp => {
				if (thingMutateResp.success) { navigateToJobReview(jobId); }
			});
		}
		else {
			addJobDraft(formData, formData.workflow_id).then( addedJob => {
				navigateToJobDraftById(addedJob.id, { replace: true });
				navigateToJobReview(addedJob.id);
			});
		}
	}

	return (
		<UIPage id="add-job" contentContainerSize="medium">
			<NavbarPortal>
				<UIButton outline onClick={modalProps.toggleModal} label={'Open Saved Draft'} />
			</NavbarPortal>
			
			<UIForm2 formMethods={formMethods} onSubmit={goReview}>				
				
				<UIInput name="name" label="Name" required placeholder="enter a unique job name" />
				<UIInput name="expense_category" label="Expense Category" />

				<UIHeading level="3">Workflow</UIHeading>
				{!modeIsSet && <UISubtitle>Assign a workflow via one of the methods below</UISubtitle>}

				<UIFlexbox width="100%" flexWrap="wrap" childrenGrow="50/50">
					<WorkflowCard disabled={modeIsSet && !modeIsSaved} margin="mediumVertical">
						<UIHeading level="4">Use a Saved Workflow</UIHeading>
						<WorkflowSelect 
							isRequired={modeIsSet && !modeIsCustom}  // This needs to be set this way (instead of just modeIsSaved, for example, because the state needs to stay consistent when the value is changed. Otherwise the component will re=render and reset the value in the formData.)
							customLabel=" "
							defaultValue={job?.workflow_id}
							setSelectedWorkflowId={setSelectedWorkflowId} 
							disabled={modeIsSet && !modeIsSaved}
						/>
						<div style={{ marginTop: 15 }}>{modeIsSaved ? (currentlySelectedWorkflow?.steps?.length ?? 0) + ' steps configured' : '' } </div>
					</WorkflowCard>

					<WorkflowCard disabled={modeIsSet && !modeIsCustom} margin="mediumVertical">
						<UIHeading level="4">Create a Custom Workflow</UIHeading>
						<EditWorkflowButton isNewDraft={isNewDraft} jobId={jobId} disabled={modeIsSet && !modeIsCustom} />
						<div style={{ marginTop: 15 }}>{modeIsCustom ? (job?.steps?.length || 0) + ' steps configured' : '' } </div>
					</WorkflowCard>
				</UIFlexbox>
				
				<UIButton type="submit" label="Review" disabled={!modeIsSet} />
				
			</UIForm2>

			<UIModal {...modalProps}>
				<UIHeading>Draft Jobs</UIHeading>
				<UISubtitle>Select a draft to configure</UISubtitle>
				
				<JobDraftTable onSelect={modalProps.toggleModal} />
			</UIModal>
		</UIPage>
	)
}

const WorkflowCard = styled(UICard)<{ disabled: boolean }>`
	background: ${props => props.disabled && props.theme.colors.colorDefinitions.grey3};
	text-align: center;
	button {
		margin-top: 6px;
	}
`;

const EditWorkflowButton = (
	props: {
		isNewDraft: boolean
		jobId: number
		disabled: boolean
	}
) => {
	const navigateToJobDraftById = useNavMain('jobDraftById');
	const navigateToWorkflowEditor = useNavFull('workflowEditorJob');
	const { getValues, trigger, handleSubmit } = useFormContext();
		
	const clickEditWorkflow = () => {
		const formData = getValues() as Job;
		trigger().then( validateSuccess => {
			if (validateSuccess) {
				if (props.isNewDraft) {
					addJobDraft(formData).then( addedJob => {
						navigateToJobDraftById(addedJob.id, { replace: true });
						navigateToWorkflowEditor(addedJob.id);
					});
				}
				else {
					updateJobFormOnly(props.jobId, formData).then( () => {
						navigateToWorkflowEditor(props.jobId);
					});
				}
			}
		})
		
	}

	return (
		<UIButton
			label={props.isNewDraft ? 'Create a custom workflow' : 'Edit custom workflow'}
			onClick={handleSubmit(clickEditWorkflow)} 
			disabled={props.disabled} 
		/>		
	)

}

/**
 * A Select component that registers to "workflow_id" in react-hook-form
 * Needs to be a separate component (child of form) so that form context can be used.
 * @param {object} props see props typings
 * @return {function} UISelect Component
 */
export const WorkflowSelect = (
	props: {
		isRequired: boolean
		customLabel?: string
		setSelectedWorkflowId?
		disabled?: boolean
		defaultValue?: number
	}
) => {
	const { loading, workflows } = useWorkflows();

	const { getValues } = useFormContext();
	const savedFormValues = getValues();

	return (<UISelect 
		name="workflow_id" label={props.customLabel || 'Workflow'}
		defaultValue={props.defaultValue || savedFormValues.workflow_id}
		options={workflows}
		isLoading={loading}
		hasConditionalComponents={false}
		required={props.isRequired}
		onChange={props.setSelectedWorkflowId}
		disabled={props.disabled}
		scrollOnOpen
	/>)
}

WorkflowSelect.defaultProps = {
	isRequired: true
}