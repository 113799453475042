import React from 'react';
import styled from 'styled-components';

import { UIButton, UICard, UIChip, UIFlexbox, UIFlexChild } from 'kit';

import { Block } from '@styled-icons/boxicons-regular/Block';
import { LogOutCircle } from '@styled-icons/boxicons-regular/LogOutCircle';
import { LogInCircle } from '@styled-icons/boxicons-regular/LogInCircle';
import { AlertCircleOutline } from '@styled-icons/evaicons-outline/AlertCircleOutline';


import { useInvUniqueType, useTags, useThingsIn } from 'hooks';
import { getObjectFromArray } from 'utility';
import { WorkflowOptions } from 'types';



const WorkflowOptionDisplayCard = (
	{ title, titleChipText, isEnabled, contentsWhenEnabled, openModal }: {
		title: string
		titleChipText?: string
		isEnabled: boolean
		contentsWhenEnabled: React.ReactNode
		openModal
	}
) => {
	return (
		<WorkflowOptionCard
			title={
				<UIFlexbox flexJustify="space-between" width="100%">
					<span>{title}</span>
					{isEnabled ? <UIChip success noMarginBottom light label={titleChipText} /> : <Block />}
				</UIFlexbox>
			}
			isEnabled={isEnabled}
		>
			{isEnabled && contentsWhenEnabled }

			<UIFlexbox className="action-container" flexJustify="space-between" flexAlign="center">
				<div>{!isEnabled && 'Not Tracked.'}</div>
				<UIButton quiet label="Manage" margin="none" onClick={openModal} />
			</UIFlexbox>
		</WorkflowOptionCard>
	);
};


const WorkflowOptionCard = styled(UICard)<{ isEnabled: boolean }>`
	width: 100%;
	font-size: 0.85rem;
	.action-container {
		padding-top: ${props => props.isEnabled && '1em'};
	}
	.chip-inline {
		margin-right: 6px;
	}
	.chip-container {
		margin-bottom: -8px; 
	}
	.line-item-container:not(:last-child) {
		margin-bottom: 4px; 
	}
	.red {
		color: red;
	}
`;


export const InventoryUniqueDisplayCard = (
	{ workflowOptions = {} as WorkflowOptions, openModal }: {
		workflowOptions: WorkflowOptions;
		openModal: () => void;
	}
) => {
	const { invUnType } = useInvUniqueType(workflowOptions.inventory_unique?.type_id);
	const attachStages = workflowOptions.inventory_unique?.attach?.stage_ids ?? [];
	const attachStageText = attachStages.length === 0 ? 'Any Stage' : 'Stages: ';
	const attachChips = invUnType.stages?.filter(stage => attachStages.includes(stage.id))
		.map(stage => <UIChip key={stage.id} size="xsmall" outline muted label={stage.name} />);

	return (
		<WorkflowOptionDisplayCard
			title="Tracked Inventory"
			titleChipText={invUnType.name}
			isEnabled={workflowOptions.inventory_unique_enabled}
			openModal={openModal}
			contentsWhenEnabled={
				<>
					<UIFlexbox className="line-item-container">
						<LogInCircle size={16} />
						<UIFlexChild asFlexbox flexAlign="flex-start" flexWrap="wrap">
							<span className={`chip-inline ${attachStages.length === 0 ? 'red' : ''}`}>{attachStageText}</span>
							{attachChips?.length > 0 && <div className="chip-container">{attachChips}</div>}
						</UIFlexChild>
					</UIFlexbox>
					<div className="line-item-container">
						<LogOutCircle size={16} />
						Detaching to {workflowOptions.inventory_unique?.detach?.status}
					</div>
					{ !(workflowOptions.inventory_unique?.converts?.length > 0) &&
					<div className="line-item-container">
						<AlertCircleOutline size={16} className="warning" />
						There is no convert step in this workflow.
					</div>
					}
				</>
			}
		/>
	);
};

export const ProductDisplayCard = (
	{ workflowOptions = {} as WorkflowOptions, openModal }: {
		workflowOptions: WorkflowOptions;
		openModal: () => void;
	}
) => {
	const { items: parts } = useThingsIn('partProduct', workflowOptions.product?.plans?.map( plan => plan.part_id ));
	const { replaceTagIdsWithNames } = useTags('partProduct', !(workflowOptions.product?.allowed_tags?.length > 0));
	
	let chipText, allowedCatText;
	switch (workflowOptions.product?.method) {
		case 'flex_job':
			chipText = 'Flexible';
			allowedCatText = workflowOptions.product?.allowed_tags?.length > 0 ? 'Allowed categories: ' : 'All products allowed.';
			break;
		case 'fixed':
			chipText = 'Fixed';
			break;
		default:
			break;
	}


	return (
		<WorkflowOptionDisplayCard
			title="Generated Products"
			titleChipText={chipText}
			isEnabled={workflowOptions.product_enabled}
			openModal={openModal}
			contentsWhenEnabled={
				<>
					{workflowOptions.product?.method === 'fixed' &&
						<ul>
							{workflowOptions.product?.plans?.map(plan => {
								const plannedPart = getObjectFromArray(parts, 'id', plan.part_id);
								return <li key={plan.part_id}>{plannedPart.name}, default qty {plan.default_qty}</li>;
							})}
						</ul>}
					{workflowOptions.product?.method === 'flex_job' &&
						<>
							<div className="line-item-container">Product selection will be made at job creation.</div>
							<UIFlexbox className="line-item-container" flexAlign="flex-start">
								<span className="chip-inline">{allowedCatText}</span>
								{replaceTagIdsWithNames(workflowOptions.product?.allowed_tags).map(
									tagName => <UIChip key={tagName} size="xsmall" outline muted label={tagName} />
								)}
							</UIFlexbox>
						</>}
				</>
			}
		/>
	);
};
