import { deepCopy } from 'utility';
import { shiftIndexWithDrag } from 'hooks';
import { WorkflowOptions, WorkflowDatabaseStep } from 'types';
import { getNextAvailableStep } from 'components/WorkflowEditor';

export function updateIndexesOnReorder(workflowOptions: WorkflowOptions, steps: WorkflowDatabaseStep[], originalIndex0, newIndex0, firstAmendableStep0){
	let newWorkflowOptions = deepCopy(workflowOptions);

	// Update unique inventory indicies (which start at 1)
	if (workflowOptions.inventory_unique_enabled) {
		const oi = originalIndex0 + 1;
		const ni = newIndex0 + 1;
		const lastIndex = steps.length;

		
		const attachIndex = workflowOptions.inventory_unique?.attach?.step_index;
		const isAttachStillEditable = attachIndex - 1 >= firstAmendableStep0;
		if (!(attachIndex === 1) && isAttachStillEditable) { // if attach is first step, "pin" it there
			newWorkflowOptions.inventory_unique.attach.step_index = shiftIndexWithDrag(attachIndex, oi, ni);
		}

		const detachIndex = workflowOptions.inventory_unique?.detach?.step_index;
		const isDetachStillEditable = detachIndex - 1 >= firstAmendableStep0;
		if (!(detachIndex === lastIndex) && isDetachStillEditable) { // if detach is last step, "pin" it there
			newWorkflowOptions.inventory_unique.detach.step_index = shiftIndexWithDrag(detachIndex, oi, ni);
		}

		workflowOptions.inventory_unique?.converts.forEach( (convert, index) => {
			const isConvertStillEditable = convert.step_index - 1 >= firstAmendableStep0;
			if (isConvertStillEditable) {
				newWorkflowOptions.inventory_unique.converts[index].step_index = shiftIndexWithDrag(convert.step_index, oi, ni);
			}
		})
	}
	return newWorkflowOptions;
}

export function updateIndexesOnDelete(workflowOptions: WorkflowOptions, newSteps: WorkflowDatabaseStep[], deletedRow: WorkflowDatabaseStep, deletedIndex0: number, firstAmendableStep0: number) {
	let newWorkflowOptions = deepCopy(workflowOptions);
// Need to make sure next one isnt skipped
	// Update unique inventory indicies (which start at 1)
	if (workflowOptions.inventory_unique_enabled) {
		const di = deletedIndex0 + 1;

		const attachIndex = workflowOptions.inventory_unique?.attach?.step_index;
		if (di < attachIndex) { // the deleted item was "below" the attach step, shift attach down one
			newWorkflowOptions.inventory_unique.attach.step_index = attachIndex - 1;
		}

		const detachIndex = workflowOptions.inventory_unique?.detach?.step_index;
		if (di < detachIndex) {
			//  the deleted item was "below" the detach step, shift detach down one
			newWorkflowOptions.inventory_unique.detach.step_index = detachIndex - 1;
		}
		else if (detachIndex === di) { 
			//  the deleted item was the last step in the workflow, and it was also where the detach step was
			let nextAvailStep = getNextAvailableStep(newSteps, firstAmendableStep0, di /**Include the same index in the search */, true);
			if (nextAvailStep === -1) {
				nextAvailStep = getNextAvailableStep(newSteps, firstAmendableStep0, di /**Include the same index in the search */, false);
			}
			newWorkflowOptions.inventory_unique.detach.step_index = nextAvailStep;
		}

		workflowOptions.inventory_unique?.converts.forEach( (convert, index) => {
			if (di === convert.step_index) { // this step is getting deleted, remove the convert too
				newWorkflowOptions.inventory_unique.converts.splice(index, 1);
			}
			if (di < convert.step_index) { //  the deleted item was "below" the convert step, shift convert down one
				newWorkflowOptions.inventory_unique.converts[index].step_index = convert.step_index - 1;
			}
		})
	}
	return newWorkflowOptions;
}

export function updateIndexesOnSkip(workflowOptions: WorkflowOptions, steps: WorkflowDatabaseStep[], skippedIndex0: number, firstAmendableStep0: number) {
	let newWorkflowOptions = deepCopy(workflowOptions);

	// Update unique inventory indicies (which start at 1)
	if (workflowOptions.inventory_unique_enabled) {
		const si = skippedIndex0 + 1;
		const attachIndex = workflowOptions.inventory_unique?.attach?.step_index;
		const detachIndex = workflowOptions.inventory_unique?.detach?.step_index;

		let nextAvailStep = getNextAvailableStep(steps, firstAmendableStep0, si, true);
		if (nextAvailStep === -1) {
			nextAvailStep = getNextAvailableStep(steps, firstAmendableStep0, si, false);
		}
		
		if (si === attachIndex) { // the skipped step had detach in it
			newWorkflowOptions.inventory_unique.attach.step_index = nextAvailStep;
		}
		if (si === detachIndex) { // the skipped step had detach in it
			newWorkflowOptions.inventory_unique.detach.step_index = nextAvailStep;
		}
	}
	return newWorkflowOptions;
}


export function updateIndexesOnAdd(workflowOptions: WorkflowOptions, newSteps, addeddRow, addedIndex0, firstAmendableStep0){
	let newWorkflowOptions = deepCopy(workflowOptions);
	
	// Update unique inventory indicies (which start at 1)
	if (workflowOptions.inventory_unique_enabled) {

		if (newSteps.length === 1) { //this is the only step, put the attach and detach in it
			newWorkflowOptions.inventory_unique.attach.step_index = 1;
			newWorkflowOptions.inventory_unique.detach.step_index = 1;
		}
		else {
			const ai = addedIndex0 + 1;
			const lastIndexBeforeAdd = newSteps.length - 1; // -1 because we need the count before adding

			const attachIndex = workflowOptions.inventory_unique?.attach?.step_index;
			const isAttachStillEditable = attachIndex - 1 >= firstAmendableStep0;
			if (ai <= attachIndex && isAttachStillEditable) { // the added item was "below" the attach step, shift attach up one
			newWorkflowOptions.inventory_unique.attach.step_index = attachIndex + 1;
		}
		
		const detachIndex = workflowOptions.inventory_unique?.detach?.step_index;
		const isDetachStillEditable = detachIndex - 1 >= firstAmendableStep0;
			if ((
					ai <= detachIndex || 
					(detachIndex === lastIndexBeforeAdd && ai === lastIndexBeforeAdd)
				)
				&& isDetachStillEditable
			) { //  the deleted item was "below" the detach step, shift detach down one
				newWorkflowOptions.inventory_unique.detach.step_index = detachIndex + 1;
			}

			workflowOptions.inventory_unique?.converts?.forEach( (convert, index) => {
				const isConvertStillEditable = convert.step_index - 1 >= firstAmendableStep0;
				if (ai < convert.step_index && isConvertStillEditable) { //  the deleted item was "below" the convert step, shift convert down one
					newWorkflowOptions.inventory_unique.converts[index].step_index = convert.step_index + 1;
				}
			})
		}

		
	}
	return newWorkflowOptions;
}

export function updateAttachIndex(workflowOptions: WorkflowOptions, newAttachIndex1: number) {
	let newWorkflowOptions = deepCopy(workflowOptions);
	if (typeof newWorkflowOptions.inventory_unique?.attach?.step_index === 'number') {
		newWorkflowOptions.inventory_unique.attach.step_index = newAttachIndex1;
	}
	return newWorkflowOptions;
}

export function updateDetachIndex(workflowOptions: WorkflowOptions, newDetachIndex1: number) {
	let newWorkflowOptions = deepCopy(workflowOptions);
	if (typeof newWorkflowOptions.inventory_unique?.detach?.step_index === 'number') {
		newWorkflowOptions.inventory_unique.detach.step_index = newDetachIndex1;
	}
	return newWorkflowOptions;
}

export function updateConvertStage(workflowOptions: WorkflowOptions, index1: number, newStageId: number | 'delete') {
	let newWorkflowOptions = deepCopy(workflowOptions);
	let converts = newWorkflowOptions.inventory_unique?.converts ?? []

	const existingIndex = converts.map( convert => convert.step_index).indexOf(index1);
	if (existingIndex === -1 && typeof newStageId === 'number') { //adding a convert
		converts.push({ dest_stage_id: newStageId, step_index: index1 });
	}
	else if (existingIndex > -1 && typeof newStageId === 'number') { //update  convert
		converts[existingIndex].dest_stage_id = newStageId;
	}
	else if (existingIndex > -1 && newStageId === 'delete') { //deleting  convert
		converts.splice(existingIndex, 1);
	}

	newWorkflowOptions.inventory_unique.converts = converts;
	return newWorkflowOptions;
}