import { PartProduct, FormDefinition, Job, Step, Workflow, StepHistoryRaw, Tool, Recipe, PartRaw, Order, Contact, InventoryUniqueItem, WorkflowInsertUpdateObj, StepInsertObject, ProcessParameterInsertObject, OrderInsertObject, ContactInsertObj, FormDefinitionInsertObject, JobUpdateObj, StepUpdateObject, ProcessParameterUpdateObject, ContactUpdateObj, FormDefinitionUpdateObject, PartInsertObj, PartUpdateObj } from 'types';


interface ThingLookupItem {
	nameSingle: string
	namePlural: string
	sharedTableId?: number
	isFormAllowed: boolean
	tableName: string
	isUseThingAllowed: boolean
}

const thingNames = [
	'job', 'workflow', 'step_history', 
	'tool', 'recipe', 'step',
	'partRaw', 'partProduct', 'inventory_unique',
	'order', 'contact', 'form',

	'deviation_tag'
] as const;
// Take the routeKeys array and make a type out of it
export type ThingName = typeof thingNames[number];
export type ThingDef = { [key in ThingName] : ThingLookupItem };

export type ThingTypes = {
	job: Job
	workflow: Workflow
	step_history: StepHistoryRaw
	step: Step
	tool: Tool
	recipe: Recipe
	partRaw: PartRaw
	partProduct: PartProduct
	order: Order
	contact: Contact
	form: FormDefinition
	inventory_unique: InventoryUniqueItem
	deviation_tag: null
}

export type ThingInsertObjTypes = {
	job: never
	workflow: WorkflowInsertUpdateObj
	step_history: never
	step_definition: never
	step: StepInsertObject
	tool: ProcessParameterInsertObject
	recipe: ProcessParameterInsertObject
	partRaw: PartInsertObj
	partProduct: PartInsertObj
	order: OrderInsertObject
	contact: ContactInsertObj
	form: FormDefinitionInsertObject
	inventory_unique: never
	deviation_tag: never
}

export type ThingUpdateObjTypes = {
	job: JobUpdateObj
	workflow: WorkflowInsertUpdateObj
	step_history: never
	step: StepUpdateObject
	tool: ProcessParameterUpdateObject
	recipe: ProcessParameterUpdateObject
	partRaw: PartUpdateObj
	partProduct: PartUpdateObj
	order: never
	contact: ContactUpdateObj
	form: FormDefinitionUpdateObject
	inventory_unique: never
	deviation_tag: never
}

export const thingLookup: ThingDef = {
	job: {
		nameSingle: 'Job',
		namePlural: 'Jobs',
		sharedTableId: 2,
		isFormAllowed: false,
		tableName: 'job',
		isUseThingAllowed: true
	},
	workflow: {
		nameSingle: 'Workflow',
		namePlural: 'Workflows',
		isFormAllowed: false,
		tableName: 'workflow',
		isUseThingAllowed: true
	},
	step_history: {
		nameSingle: 'Step',
		namePlural: 'Steps',
		sharedTableId: 1,
		isFormAllowed: true,
		tableName: 'step_history',
		isUseThingAllowed: false
	},
	step: {
		nameSingle: 'Step',
		namePlural: 'Steps',
		isFormAllowed: false,
		tableName: 'step',
		isUseThingAllowed: true
	},
	tool: {
		nameSingle: 'Tool',
		namePlural: 'Tools',
		sharedTableId: 4,
		isFormAllowed: false,
		tableName: 'tool',
		isUseThingAllowed: true
	},
	recipe: {
		nameSingle: 'Recipe',
		namePlural: 'Recipes',
		sharedTableId: 5,
		isFormAllowed: false,
		tableName: 'recipe',
		isUseThingAllowed: true
	},
	partRaw: {
		nameSingle: 'Material',
		namePlural: 'Materials',
		sharedTableId: 7,
		isFormAllowed: false,
		tableName: 'part',
		isUseThingAllowed: true
	},
	partProduct: {
		nameSingle: 'Product',
		namePlural: 'Products',
		sharedTableId: 7,
		isFormAllowed: false,
		tableName: 'part',
		isUseThingAllowed: true
	},
	order: {
		nameSingle: 'Order',
		namePlural: 'Orders',
		sharedTableId: 6,
		isFormAllowed: true,
		tableName: 'order',
		isUseThingAllowed: true
	},
	contact: {
		nameSingle: 'Customer',
		namePlural: 'Customers',
		isFormAllowed: false,
		tableName: 'contact',
		isUseThingAllowed: true
	},
	form: {
		nameSingle: 'Form',
		namePlural: 'Forms',
		isFormAllowed: false,
		tableName: 'form_definition',
		isUseThingAllowed: true
	},
	inventory_unique: {
		nameSingle: 'Inventory',
		namePlural: 'Inventory',
		sharedTableId: 3,
		isFormAllowed: true,
		tableName: 'inventory_unique',
		isUseThingAllowed: true
	},
	deviation_tag: {
		nameSingle: 'Deviation Tag',
		namePlural: 'Deviation Tags',
		sharedTableId: 101,
		isFormAllowed: false,
		tableName: 'pseudo',
		isUseThingAllowed: false
	}
}



export const formDefinitionTableOptions = Object.keys(thingLookup).map((keyName, i) => {
	const ref: ThingLookupItem = thingLookup[keyName];
	return ref.isFormAllowed && { name: ref.nameSingle, id: ref.sharedTableId }
}).filter( option => option /**filter out undefined from the map */ );








// ---------------------------------------- Dev only: Verify routes --------------------------------------
if (process.env.NODE_ENV === 'development') {
// Ensure no duplicates in the route names array
	if ((new Set(thingNames)).size !== thingNames.length) {
		console.error('There are duplicate values in the Thing Names array.');
	}

// Ensure all things are represented in the lookup
// Loop through the thing name array and check to see if it exists in the name lookup
	for (let index = 0; index < thingNames.length; index++) {
		const thingName = thingNames[index];
		if (!thingLookup[thingName]) {
			console.error(`thing ${thingName} exists in the name array but is not represented in the thingLookup object.`);
		}
		
	}

// Ensure all object keys are represented in the name array
	for (const thingName in thingLookup) {
		if (thingLookup.hasOwnProperty(thingName)) {
			//@ts-ignore
			if (!thingNames.includes(thingName)) {
				console.error(`thing ${thingName} exists in the lookup object but is not represented in the thingLookup array.`);
			}
			
		}
	}
}