
import { useQuery } from '@apollo/client';
import { GET_CONTACTS } from 'services/contactFetch';

import { Contact } from 'types';


export const useContacts = () => {
	const {
		loading, error,
		data: { contact: contacts } = { contact: [] as Contact[] }
	} = useQuery(GET_CONTACTS, { 
		variables: {  }
	});
	
	
	const returnValues =  {
		isLoading: loading,
		isErrored: error,
		contacts
	};

	return returnValues;
};
