
import { gql } from '@apollo/client';
import { orderAllColumns, orderItemsAllColumns } from '@constants';


export const GET_ORDERS = gql`
	query GET_ORDERS ( 
		$status: String
		$id: Int
	){
		order (
			where: { 
				status: { _eq: $status } 
				id: { _eq: $id } 
			}
		) {
			${orderAllColumns}
		}
	}
`;

export const GET_ORDERS_BY_IDS = gql`
	query GET_ORDERS_BY_IDS ( 
		$ids: [Int!]
	){
		order (
			where: { id: { _in: $ids } }
		) {
			${orderAllColumns}
		}
	}
`;


export const GET_ORDER_ITEMS_BY_PART = gql`
	query GET_ORDER_ITEMS_BY_PART (
		$partId: Int = -1
	) {
  		order_item (
			where: {
				order: { status: { _eq: "active"} }, 
				part: { id: { _eq: $partId } }
			}
		) {
			${orderItemsAllColumns}
			order {
				name
			}
		}
	}
`;

export const GET_ORDER_ITEMS_BY_ORDER = gql`
	query GET_ORDER_ITEMS_BY_ORDER (
		$order_id: Int!
	) {
  		order_item (
			where: {
				order_id: { _eq: $order_id }
			}
		) {
			${orderItemsAllColumns}
		}
	}
`;

export const GET_ORDER_ITEM = gql`
	query GET_ORDER_ITEM (
		$id: Int!
	) {
  		order_item (
			where: {
				id: { _eq: $id }
			}
		) {
			${orderItemsAllColumns}
		}
	}
`;


export const GET_ORDERS_BY_LINE_ITEMS = gql`
	query GET_ORDERS_BY_LINE_ITEMS (
		$lineItemIds: [Int!]
	) {
		
  		order (
			where: {
				order_items: { 
					id: { _in: $lineItemIds } 
				}
			}
		) {
			${orderAllColumns}
		}
	}
`;

