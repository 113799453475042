import React, { useContext } from 'react';
import styled from 'styled-components';

import { UIIconButton } from 'kit';


import { Save } from '@styled-icons/material/Save';
import { Close } from '@styled-icons/material/Close';
import { ViewList } from '@styled-icons/heroicons-outline/ViewList';
import { ViewBoards } from '@styled-icons/heroicons-solid/ViewBoards';
import { Tag } from '@styled-icons/bootstrap/Tag';


import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';

import { WorkflowEditorContext } from './WorkflowEditorContext';
import { ViewMode } from './WorkflowEditorMainPanel';

import { useNavMain } from 'routing';



export const WorkflowEditorSaveBlock = (
	{ viewMode, setViewMode }: {
		viewMode: ViewMode
		setViewMode: React.Dispatch<ViewMode>
	}
) => {	
	const navigateToJobById = useNavMain('jobById');
	const navigateToJobDraft = useNavMain('jobDraftById');
	const navigateToWorkflowList = useNavMain('workflows');


	const wfContext = useContext(WorkflowEditorContext);
		const { 
			type, id, isJobDraft, isValid, saveToDB, tagModalProps, isAmendment
		} = wfContext;


	function navigateBack() {
		if (type === 'job') {
			if (isJobDraft){ navigateToJobDraft(id); }
			else { navigateToJobById(id); }
		}
		else if (type === 'workflow') {
			navigateToWorkflowList();
		}
	}


	const handleChange = (event: React.MouseEvent<HTMLElement>, nextView: ViewMode) => {
		setViewMode(nextView);
	};


	return (
		<>
			<UIIconButton 
				solid 
				disabled={!isValid} 
				icon={<Save />} 
				type="submit" 
				onClick={saveToDB} 
				margin="mediumVertical" 
				data-cy="workflow-save-button"
			/>
			
			<StyledToggleButtonGroup orientation="vertical" size="small" value={viewMode} exclusive onChange={handleChange}>
				<ToggleButton value="setupMinimum" aria-label="streamlined-view">
					<ViewList size={16} />
				</ToggleButton>
				<ToggleButton value="detailed" aria-label="detailed-view">
					<ViewBoards size={16} />
				</ToggleButton>
			</StyledToggleButtonGroup>


			{type === 'job' && isAmendment && <UIIconButton icon={<Tag />} onClick={tagModalProps.openModal} margin="mediumVertical" />}
			<UIIconButton icon={<Close />} onClick={navigateBack} margin="mediumVertical" />
		</>
	)
}

const StyledToggleButtonGroup = styled(ToggleButtonGroup)`
	margin-bottom: 1rem;
`;