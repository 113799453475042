import { useQuery } from '@apollo/client';
import { GET_INVENTORY_UNIQUE_TYPES } from 'services/inventoryUniqueFetch';

import { getObjectFromArray } from 'utility';
import { InventoryUniqueType } from 'types';


export const useInvUniqueType = (id: number, shouldSkip?: boolean) => {
	const { 
		loading,
		data: { inventory_unique_type: [invUnType = {} as InventoryUniqueType] } = { inventory_unique_type: [] }
	} = useQuery(GET_INVENTORY_UNIQUE_TYPES, { 
		variables: { type_id: id },
		skip: !(id > 0) || shouldSkip
	});

	function getStageNameFromId(stageId: number) {
		const stage = getObjectFromArray(invUnType.stages, 'id', stageId);
		return stage.name;
	}

	return {
		isLoading: loading,
		invUnType,
		getStageNameFromId
	}
}


export const useInvUniqueTypes = (shouldSkip?: boolean) => {
	const { 
		loading,
		data: { inventory_unique_type: invUniqueTypes } = { inventory_unique_type: [] as InventoryUniqueType[] }
	} = useQuery(GET_INVENTORY_UNIQUE_TYPES, {
		skip: shouldSkip	
	});

	return {
		isLoading: loading,
		invUniqueTypes
	}
}