import { useQuery } from '@apollo/client';
import { GET_JOBS } from '@constants/gql';
import { GET_STEP_HISTORY_BY_JOB_INDEX } from '../stepHistoryFetch';
import { GET_INVENTORY_UNIQUE_BY_IDS } from '../inventoryUniqueFetch';
import { genStepHistoryHelpers, useHydrateStepFromJob, StepHistoryHelper } from '../stepHydrate';

import { Job, StepHistoryRaw, InventoryUniqueItem, SupplementalStepInfo } from 'types';



export type StepState = 'queue' | 'startable' | 'processing' | 'complete' | 'skip' | '';


function calcStepState(stepHistoryHelper: StepHistoryHelper, job: Job, currentIndex: number, supp: SupplementalStepInfo): StepState {
	if (supp?.workflow_mod?.skip) {
		return 'skip';
	}
	if (stepHistoryHelper.isCompleted) {
		return 'complete';
	}
	if (stepHistoryHelper.isStarted) {
		return 'processing';
	}
	if (job.current_step_index === currentIndex) {
		return 'startable'
	}
	if (job.current_step_index < currentIndex) {
		return 'queue'
	}
	return '';
}

export const useStepDetails = (jobId: number, stepIndex1: number) => {
	
	const { 
		data: { job: [job = {} as Job] } = { job: [] }
	} = useQuery(GET_JOBS, { 
		variables: { id: jobId }
	});

	const { 
		data: { step_history: [stepHistory = {} as StepHistoryRaw]  } = { step_history: [] }
	} = useQuery(GET_STEP_HISTORY_BY_JOB_INDEX, { 
		variables: { 
			jobId: jobId,
			index1: stepIndex1
		}
	});
	
	let { isDataReady, getWorkflowStepFull } = useHydrateStepFromJob(jobId);
	let { stepInfo, stepPlan, supp } = getWorkflowStepFull(stepIndex1 - 1);

	const stepHistoryHelper = genStepHistoryHelpers(stepHistory);

	const { 
		data: { inventory_unique: uniqueItems } = { inventory_unique: [] as InventoryUniqueItem[] }
	} = useQuery( GET_INVENTORY_UNIQUE_BY_IDS, {
		variables: { ids: stepHistory.inventory_unique_items },
		skip: !stepHistoryHelper.stepHistoryExists || stepHistory.inventory_unique_items.length === 0
	} );

	const stepState = calcStepState(stepHistoryHelper, job, stepIndex1, supp);

	const isStepStartable = stepState === 'startable';
	const hasStepStarted = stepState === 'processing' || stepState === 'complete';
	const hasStepFinished = stepState === 'complete';

	return {
		isDataReady,
		job,
		stepState,
		isStepStartable,
		hasStepStarted,
		hasStepFinished,
		stepInfo, 
		stepPlan, 
		supp, 
		stepHistory, stepHistoryHelper,
		uniqueItems
	};
};
