import React from 'react';

import { UIChip } from 'kit';

import { ThingName } from '@constants';
import { useTags } from 'hooks';


export const TagChips = (
	{ 
		tagIds = [],
		tagType
	}: 
	{ 
		tagIds: number[]
		tagType: ThingName
	}
) => {
	const { replaceTagIdsWithNames } = useTags(tagType);	

	const stringTags = replaceTagIdsWithNames(tagIds);

	return (
		<>
			{stringTags.map( tagName => {
				return (
					<UIChip key={tagName} label={tagName} outline size="xsmall" error />
				)
			} )}
		</>
	);
}