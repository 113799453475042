import React, { memo } from 'react';
import styled from 'styled-components';

import { UITooltipWrapper } from 'kit';

import { dateFormat, DateFormat } from 'utility';


export const TableDateField = (props: {
	ISOdateString: string
	dateDisplayFormat: DateFormat
}) => {
	if (!props.ISOdateString) {
		return null;
	}
	const fullDate = dateFormat(props.ISOdateString, 'dateTimeFullTZ')
	return (
		<UITooltipWrapper tooltipContent={fullDate}>
			<FitDiv>{dateFormat(props.ISOdateString, props.dateDisplayFormat)}</FitDiv>
		</UITooltipWrapper>
	);
};

export const TableDateMultiField = (props: {
	ISOdateString: string
	dateDisplayFormatTop: DateFormat
	dateDisplayFormatBottom: DateFormat
}) => {
	if (!props.ISOdateString) {
		return null;
	}
	const fullDate = dateFormat(props.ISOdateString, 'dateTimeFullTZ')
	return (
		<UITooltipWrapper tooltipContent={fullDate}>
			<FitDiv>
				<div className="multi-top">{dateFormat(props.ISOdateString, props.dateDisplayFormatTop)}</div>
				<small>{dateFormat(props.ISOdateString, props.dateDisplayFormatBottom)}</small>
			</FitDiv>
		</UITooltipWrapper>
	);
};

const FitDiv = styled.div`
/** Center the tooltip arrow in case the column is wider than the content */
	width: fit-content;
	text-align: center;
	.multi-top {
		font-size: 0.95em;
	}
	small {
		font-size: 0.9em;
		opacity: 0.8;
	}
`;



export const sortDateString = (rowA, rowB, columnId: string, desc: boolean) =>{
	const a = new Date(rowA.values[columnId])
	const b = new Date(rowB.values[columnId])
	console.log(rowA, columnId, a, b)
	return a > b;
}

export const sortDateStringMemo = memo

