import create from 'zustand';

type TransientStore = {
	data: TransientData
	setValue: (key: TransientDataKey, newVal: TransientData[TransientDataKey] ) => void
	consumeValue: <K extends TransientDataKey>(key: K) => TransientData[K]
}

interface TransientData {
	formSetupInvUniqueTypeId?: number
	isRedirectedFromJobBrokenLink?: boolean
}
type TransientDataKey = keyof TransientData;

/**
 * Used for storing information that might be passed between different pages of the app
 */
export const useTransientStore = create<TransientStore>((set, get) => ({
	data: {},
	setValue: (key, newVal) => {
		set(state => {
			const newData = {
				...state.data,
				[key]: newVal
			}
			return { data: newData }
		} )
	},
	consumeValue: (key) => {
		const existingValue = get().data[key];
		set(state => {
			let newData = {
				...state.data
			}
			delete newData[key];
			return { data: newData }
		})
		return existingValue;
	}
}));