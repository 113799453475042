import React from 'react';

import { UIFlexbox } from './Layout';
import { Pageview } from '@styled-icons/material-outlined/Pageview';
import { UIHeading } from 'kit';


export const Placeholder = (
	props: {		
		text?: string
		isRow?: boolean
	}
) => {
	return (
		<UIFlexbox flexDirection={props.isRow ? 'row' : 'column'} flexAlign="center" flexJustify="center" height="50%">
			<Pageview size={50} />
			<UIHeading level="3">
				{props.text}
			</UIHeading>
		</UIFlexbox>
    );
}

export default Placeholder;