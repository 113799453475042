import React, { useState, useEffect } from 'react';
import { UIModalForm, ModalHookProps, UIHeading } from 'kit';


import { FormInstanceContents } from 'components/Form/FormInstance';

import { updateFormInstance, addFormInstance } from 'services/formSubmit';
import { FormInstance, FormInstanceLinked, FormDefinition } from 'types';
import { InputFormParse } from '../FormBuilder/AddFormDefinitionFromPastePage';

export const FormInstanceModal_NewForm = (props: {
	modalProps: ModalHookProps;
	formDefinition: FormDefinition;
	referenceId: number;
	hasParseButton?: boolean;
}) => {
	return (<FormInstanceModal 
		{...props}
		existingForm={{} as FormInstanceLinked} 
		isEditable 
	/>);
};

export const FormInstanceModal_EditExistingForm = (props: {
	modalProps: ModalHookProps;
	formDefinition: FormDefinition
	existingForm: FormInstance;
	hasParseButton?: boolean;
}) => {
	return (<FormInstanceModal 
		{...props}
		referenceId={props.existingForm.foreign_id} 
		existingForm={props.existingForm} 
		isEditable
	/>);
};

export const FormInstanceModal_ViewForm = (props: {
	modalProps: ModalHookProps
	formDefinition: FormDefinition
	existingForm: FormInstanceLinked
}) => {
	return (<FormInstanceModal 
		{...props}
	/>);
};



export const FormInstanceModal = ({ 
	modalProps, 
	formDefinition, 
	referenceId, 
	existingForm = {} as FormInstance, 
	isEditable = false, 
	hasParseButton = false
}: {
	modalProps: ModalHookProps
	formDefinition: FormDefinition
	referenceId?: number
	existingForm?: FormInstance
	isEditable?: boolean
	hasParseButton?: boolean
}) => {
	const [parseTextResult, setParseTextResult] = useState(existingForm.values);
	const [formKey, setFormKey] = useState(0);

	useEffect(() => {
		hasParseButton && setFormKey(formKey + 1);
	}, [parseTextResult]);

	function submit(formData) {
		if (existingForm.id) {
			updateFormInstance(existingForm.id, formData, formDefinition.foreign_table, referenceId);
		}
		else {
			addFormInstance(formDefinition.id, formData, formDefinition.foreign_table, referenceId);
		}
		setParseTextResult(formData);
		modalProps.toggleModal();
	}


	return (
		<UIModalForm 
			key={formKey} 
			onSubmit={submit} 
			defaultValues={parseTextResult} 
			HeaderCenter={<UIHeading noMarginBottom>{formDefinition.name}</UIHeading>} 
			{...modalProps}
		>

			{isEditable && hasParseButton ? 
				<InputFormParse 
					parseResult={setParseTextResult} 
					formFields={formDefinition.fields} 
					autoSave
					/> 
				: null
			}

			<FormInstanceContents 
				formFields={formDefinition.fields} 
				formValues={existingForm.values} 
				isEditable={isEditable} 
			/>

		</UIModalForm>
	);
};
