import React from 'react';
import { Route, Routes } from 'react-router-dom';

import { WorkflowEditor } from 'components/WorkflowEditor';

import { Main } from 'root/App'


import { useDocumentTitle, FullRouteDefinition } from 'routing';


export function FullPageRoutes(){
	useDocumentTitle('full', []);

	return (
		<Routes>
			{fullRoutes.map( route => 
				<Route key={route.id} path={route.path} element={<route.RouteContent />} /> 
			)}
			<Route path="/*" element={<Main />} />
		</Routes>
	)
}

// ------------------------------------------- Full Page Routes -------------------------------------------

//route order matters!
export const fullRoutes: FullRouteDefinition[] = [
	{
		id: 'workflowEditorWorkflow',
		path: '/workflows/:id',
		RouteContent: WorkflowEditor,
		documentTitle: 'Workflow Editor'
	},
	{
		id: 'newWorkflowEditor',
		path: '/workflows/draft',
		RouteContent: WorkflowEditor,
		documentTitle: 'Workflow Editor'
	},
	{
		id: 'workflowEditorJob',
		path: '/jobs/:id/workflow',
		RouteContent: WorkflowEditor,
		documentTitle: 'Workflow Editor'
	}
]

