import React from 'react';
import styled from 'styled-components';
import { currentUnixTime } from 'utility';

export function capitalizeFirstLetter(string = '') {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export function prettifyKey(string = '') {
    return string.split('_').map(word => capitalizeFirstLetter(word)).join(' ');
}

export function arrayToString(array = []) {
	const txt = JSON.stringify(array, null, 2)

	return (txt.replace(/\"|{|}|\[|\]|/g, ''))
}

export function newLineToBr(text: string = '') {
	return text.split('\n').map( (item, index) => {
		return (
			<React.Fragment key={index}>
				{index === 0 ? null : <PaddingSpan />}
				{item}
			</React.Fragment>
		)
	});
}

const PaddingSpan = styled.span`
	height: 6px;
	display: block;
`;

export function convertStringToKey(rawStr: string, existingKeys?: string[]) {
	let keyString = rawStr.replace(/-/g, '_').toLowerCase(); // first replace hyphens with underscores and change to lowercase
	keyString = keyString.replace(/[^a-zA-Z0-9_ ]/g, ''); // remove special characters, leaving only spaces and alphanumeric and underscores
	keyString = keyString.replace(/\s+/g, '_'); // have to use a regex for this because standard string replace only replaces the first occurrence


	// Double check for duplicate keys, if an array was provided
	if (existingKeys?.length > 0) {
		if (existingKeys.includes(keyString)) {
			// If a duplicate was found, append a number
			const maxCount = 99;
			for (let index = 1; index <= maxCount; index++) {
				const candidateKeyString = `${keyString}_${index}`;
				// double check that the keyString with the appended number is also not a duplicate
				if (!existingKeys.includes(candidateKeyString)) {
					keyString = candidateKeyString;
					break; // a non-duplicate was found, ok to exit
				}
				if (index === maxCount) {
					// You looped through a bunch of keys and still couldn't generate a unique key?!
					// Use the current unix timestamp
					keyString = `${keyString}_${currentUnixTime()}`;
				}
			}
		}
	}

	return keyString;
}