import React from 'react';
import styled from 'styled-components';

import { components } from 'react-select';

import { UIFlexbox } from 'kit';

import { CheckboxChecked } from '@styled-icons/boxicons-regular/CheckboxChecked';
import { Checkbox } from '@styled-icons/boxicons-regular/Checkbox';
import { AddCircle } from '@styled-icons/material/AddCircle';


// ---------------------------------------- Wrapper ------------------------------------------------

export const CustomOptionWrapper = (selectedOptions, valueAccessor, Child, optionProps) => {
	const { data, value, label } = optionProps;
	const selectedOptionsArray = Array.isArray(selectedOptions) ? selectedOptions : [selectedOptions];
	const selectedValues =  selectedOptionsArray?.map(opt => opt?.[valueAccessor]);
	return (
		<components.Option {...optionProps} >
			<Child data={data} value={value} label={label} isSelected={selectedValues?.includes(value)} />
		</components.Option>
	)
}

export const CustomSingleValueWrapper = (selectedOptions, valueAccessor, Child, valueProps) => {
	const { data = {}, hasValue, selectProps } = valueProps;
	
	return (
		<components.SingleValue {...valueProps}>
			{hasValue && Object.keys(data)?.length > 0
				? 
				<Child data={data} />
				:
				<div>{selectProps.placeholder}</div>
			}
		</components.SingleValue>
	)
}


// ---------------------------------------- Default Option ------------------------------------------------


export const DefaultOption = React.memo<any>(
	({ value, label, data, isSelected }) => {
		const optionDisplay = data.__isNew__ ? data.label : label;

		let icon = null;
		if (data.__isNew__) {
			icon = <AddCircle size={20}/>
		}

		return (
			<SelectCustomOptionFlexbox flexAlign="center" >
				{icon}{optionDisplay}
			</SelectCustomOptionFlexbox>
		)
	}
)


// ---------------------------------------- Checkbox (Multi Select) ------------------------------------------------
export const DefaultOptionCheckbox = React.memo<any>(
	({ value, label, data, isSelected }) => {
		const optionDisplay = data.__isNew__ ? data.label : label;
		return (
			<SelectOptionCheckbox
				title={optionDisplay}
				isSelected={isSelected}
				isDisabled={data.isDisabled}
				isCreating={data.__isNew__}
			/>
		);
	}
)


//used for multi-select menu items with checkbox
export const SelectOptionCheckbox = (
	{ title, isSelected, isDisabled, isCreating }: { title: string, isSelected: boolean, isDisabled: boolean, isCreating: boolean }
) => {
	let icon = null;
	if (isCreating) {
		icon = <AddCircle size={20}/>
	}
	else if (isDisabled) {
		icon = null
	}
	else if (isSelected) {
		icon = <CheckboxChecked size={20}/>
	}
	else {
		icon = <Checkbox size={20}/>
	}
	return (
		<SelectCustomOptionFlexbox 
			flexAlign="center"
			hasCheckbox
		>
			{icon}
			<div>{title}</div>
		</SelectCustomOptionFlexbox>
	)
};


// ---------------------------------------- Key Value ------------------------------------------------

export const DefaultKeyValue = ({ value, label, data }) => {
	return (
		<SelectOptionKeyValue 
			title={label}
			subtitle={data.description}
		/>
	)
}


//used for select menu with title/description
export const SelectOptionKeyValue = (
	{ title, subtitle }: 
	{ 
		title: string
		subtitle: string 
	}
) => {
	return (
		<SelectCustomOptionFlexbox flexDirection="column" flexAlign="flex-start" >
			<div className="option-title">{title}</div>
			<div className="option-subtitle">{subtitle}</div>
		</SelectCustomOptionFlexbox>
	)
};


// ---------------------------------------- Styles ------------------------------------------------

type SelectOptionProps = {
	hasCheckbox?: boolean
}

export const SelectCustomOptionFlexbox = styled(UIFlexbox)<SelectOptionProps>`
	border: none;
	box-shadow: none;
	width: 100%;
	box-sizing: border-box;
	
	.option-title {
		font-weight: ${props => props.theme.font.boldWeight};
		color: ${props => props.theme.colors.primary};
	}
	.option-subtitle {
		font-size: 0.9em;
		color: ${props => props.theme.colors.baseTextLight};
	}

	.select__menu-list & {

	}
	.select__value-container & {

	}
	/*do not show checkbox in selected value box*/
	.select__control & {
		border: none;
		svg {
				display: none;
			}
	}
	/*format menu options with and without checkbox, set hiehgt for keyvalue options*/
	.select__option & {
		padding: 10px 12px;
		svg {
			margin: 0 10px 0 0;
			color: lightgrey;
		}
	}
	.select__option--is-selected & {
		background-color: ${props => !props.hasCheckbox && props.theme.colors.primaryLight1};
		svg {
			margin: 0 10px 0 0;
			color: ${props => props.theme.colors.primary};
		}
	}
`
