import React, { useState } from 'react';
import { isMobileScreen } from 'utility';

interface PanelContextInterface {
	isLeftMenuOpen: boolean
	openLeftMenu: () => void
	closeLeftMenu: () => void
	toggleLeftMenu: () => void

	isLeftPanelOpen: boolean
	openLeftPanel: () => void
	closeLeftPanel: () => void
	toggleLeftPanel: () => void

	isRightPanelOpen: boolean
	openRightPanel: () => void
	closeRightPanel: () => void
	toggleRightPanel: () => void
	
	isFullWidth: boolean
	toggleFullWidth: () => void

	isLeftPanelUsed: boolean
	isRightPanelUsed: boolean
}

//https://upmostly.com/tutorials/how-to-use-the-usecontext-hook-in-react
export const PanelContext = React.createContext<PanelContextInterface>({} as PanelContextInterface);

//export provider to use in App high level
export const PanelContentProvider = (props) => {
	const [isLeftPanelUsed, setLeftPanelUsed] = useState(false);
	const [isRightPanelUsed, setRightPanelUsed] = useState(false);
	
	const [isLeftMenuOpen, setLeftMenuOpen] = useState(!isMobileScreen());
	const [isLeftPanelOpen, setLeftPanelOpen] = useState(false);
	const [isRightPanelOpen, setRightPanelOpen] = useState(false);
	
	const [isFullWidth, setFullWidth] = useState(false);

	const toggleFullWidth = () => {
		setFullWidth(!isFullWidth);
	}
	
	function closeLeftMenu() {
		setLeftMenuOpen(false);
	}
	function openLeftMenu() {
		setLeftMenuOpen(true);
	}
	function toggleLeftMenu() {
		setLeftMenuOpen(!isLeftMenuOpen);
	}

	
	function closeLeftPanel() {
		setLeftPanelOpen(false);
	}
	function openLeftPanel() {
		setLeftPanelOpen(true);
	}
	function toggleLeftPanel() {
		setLeftPanelOpen(!isLeftPanelOpen);
	}


	function closeRightPanel() {
		setRightPanelOpen(false);
	}
	function openRightPanel() {
		setRightPanelOpen(true);
	}
	function toggleRightPanel() {
		setRightPanelOpen(!isRightPanelOpen);
	}

	const contextValues = {
		isLeftPanelUsed, setLeftPanelUsed,
		isRightPanelUsed, setRightPanelUsed,

		isLeftMenuOpen, closeLeftMenu, openLeftMenu, toggleLeftMenu,
		isLeftPanelOpen, closeLeftPanel, openLeftPanel, toggleLeftPanel,
		isRightPanelOpen, closeRightPanel, openRightPanel, toggleRightPanel,
		isFullWidth, toggleFullWidth
	}

	return (
		<PanelContext.Provider value={contextValues}>
			{props.children}
		</PanelContext.Provider>
	);
}

