import React from 'react';

import { TableDateMultiField } from 'components/Table/TableDateField';

import { Address } from 'types';
import { ColDefs } from './tableColumnUtility';


type ContactColId = 'firstName' | 'lastName' | 'company' | 'email' | 'phone' | 'address' | 'note' | 'updatedAt';

export const contactCols: ColDefs<ContactColId> = {
	firstName: {
		Header: 'First Name',
		accessor: 'first_name'
	},
	lastName: {
		Header: 'Last Name',
		accessor: 'last_name'
	},
	company: {
		Header: 'Company',
		accessor: 'company_name'
	},
	email: {
		Header: 'Email',
		accessor: 'email'
	},
	phone: {
		Header: 'Phone',
		accessor: 'phone'
	},
	address: {
		Header: 'Address',
		accessor: 'addresses',
		Cell: ({ cell: { value } }) => {
			const defaultAddress: Address = value?.[0] ?? {} as Address;
			return (
				<>
					<div>{defaultAddress.address1}</div>
					<div>{defaultAddress.city} {defaultAddress.state}</div>
				</>
			)
		}
	},
	note: {
		Header: 'Note',
		accessor: 'note'
	},
	updatedAt: {
		Header: 'Last Changed',
		accessor: 'updated_at',
		disableGlobalFilter: true,
		Cell: ({ cell: { value } }) => <TableDateMultiField ISOdateString={value} dateDisplayFormatTop="dateShortWithYear" dateDisplayFormatBottom="time" />
	}
}