import { gql } from '@apollo/client';
import { apolloClient } from 'services/apollo';
import { stepHistoryAllColumns } from '@constants';
import { StepHistoryChanges } from 'types';

const UPDATE_STEP_HISTORY = gql`
	mutation UPDATE_STEP_HISTORY ( 
		$id: Int!
		$changes: step_history_set_input!
	) {
		update_step_history (
			where: { id: { _eq: $id }}
			_set: $changes
		) {
			returning {
				${stepHistoryAllColumns}
			}
		}
	}
`;


export function changeStepHistory(id: number, changes: StepHistoryChanges) {
	apolloClient.mutate({ 
		mutation: UPDATE_STEP_HISTORY,
		variables: {
			id,
			changes: changes
		}
	});
}