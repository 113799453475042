import React, { useContext } from 'react';
import styled from 'styled-components';

import { UIHeading, UITitle, UISubtitle, UIFlexbox, UISelectControlled, SelectCustomOptionFlexbox, GridContainer, GridRow, UINumberInputControlled, useFormCustomRegister, UIAlertContainer } from 'kit';

import { DragDropContext } from 'react-beautiful-dnd';
import matchSorter from 'match-sorter';

import { useDynamicList, UIDroppable, UIDraggable, useThingsIn, useThingsAll, useThingById, DynamicListContext } from 'hooks';
import { PartRawEffect, StepPlan, Part } from 'types';
import { StepFormContext } from './StepFormContext';
import { BrokenLinkAlertBanner } from './BrokenLinkAlertBanner';



export const StepFormPartRaw = ( ) => {
	const { stepPlan } = useContext(StepFormContext);
	const { updateFormValue, isRequiredValidationError } = useFormCustomRegister('inventory_effect.items');

	return (
		<>
			{isRequiredValidationError && <p>Inventory selection is required</p>}
			<PartRawEffectEdit stepPlan={stepPlan} onChange={updateFormValue} />
		</>
	);
}


export const PartRawEffectEdit = (
	{ stepPlan, onChange, allowDragDrop = false }: {
		stepPlan: StepPlan
		onChange: (rows) => void
		allowDragDrop?: boolean
	}
) => {
	const { validationErrors } = useContext(StepFormContext);
	
	useThingsIn('partRaw', stepPlan.inventory_effect?.items.map( eff => eff.id )); //fetch the parts that are definitely in this step
	const dynamicListContext =  useDynamicList<PartRawEffect>(stepPlan.inventory_effect?.items, { onUpdate: onChange, allowZeroLengthArray: false });
	const { rows, addRow, isAdding, AddButton, onDragEnd, deleteRow } = dynamicListContext;

	const isEmpty = !(rows?.length > 0);

	const linkErrorMessages = validationErrors?.linkErrors?.inventory_effect || [];

	function removeByPartId(partId: number){
		const index = rows.findIndex(eff => eff.id === partId);
		deleteRow(index);
	}

	return (
	<>
		{ !isEmpty ?
		<>
			<UIAlertContainer>
				{linkErrorMessages.map( err => {
					const removeFunc = removeByPartId.bind(this, err.invalidLinkId);
					return (
						<BrokenLinkAlertBanner
							key={err.invalidLinkId} 
							removeFunc={removeFunc} 
							err={err}
							categoryName="inventory_effect"
						/> 
					)
				})
				}
			</UIAlertContainer>
			<GridContainer 
				columns={3} 
				gridTemplateColumns="1fr 120px 50px" 
				fullWidth
			>
				<GridRow isTitleRow>
					<ShimmedForDragHandle shouldShim={allowDragDrop}>Material</ShimmedForDragHandle>
					<div>Quantity</div>
					<div></div>
				</GridRow>

				<DragDropContext onDragEnd={onDragEnd}>
					<UIDroppable
						name="destination"
						readonly={false}
						padding="small"
					>
						{rows?.map( (effect, index) => {
							return (
							<RawPartRow
								key={effect.id}
								effect={effect}
								index={index}
								dynamicListContext={dynamicListContext}
								allowDragDrop={allowDragDrop}
							/>
							)}
						)}
					</UIDroppable>
				</DragDropContext>


				<AddButton />
			</GridContainer>
		</>
			:
			<>
				<UIHeading level="4">No materials selected</UIHeading>
				<p>Select a material to get started.</p>
			</>
		}

			{(isAdding || isEmpty) && 
				<RawPartDropDown alreadySelectedIds={rows?.map(part => part.id)} addRow={addRow} />
			}
	</>
	);
}

const ShimmedForDragHandle = styled.div<{ shouldShim: boolean }>`
	padding-left: ${props => props.shouldShim ? '50px' : '10px'};
`;

const StyledGridRow = styled(GridRow)`
	padding: 10px 0;
	.drag-handle {
		margin-left: 0;
	}
	button { /**a weak selector for the delete button */
		margin-left: 10px;
	}
`;




const RawPartRow = (
	{ effect, index, allowDragDrop, dynamicListContext } : {
		effect: PartRawEffect
		index: number
		allowDragDrop: boolean
		dynamicListContext: DynamicListContext<PartRawEffect>
	}
) => {
	const { item: part } = useThingById('partRaw', effect.id);
	const { editSingleValueInRow, DeleteButton } = dynamicListContext

	
	function editQtyChange(consumedValue: number) {
		const oppositeSignValue = consumedValue * -1;
		editSingleValueInRow(index, 'qty_change', oppositeSignValue);
	}


	const RowEditComponent = (
		<StyledGridRow key={effect.id}>	
			<div>
				<UITitle>{part.name}</UITitle>
				<UISubtitle>{part.part_number}</UISubtitle>
			</div>

			<UINumberInputControlled 
				defaultValue={effect.qty_change * -1} 
				onChange={editQtyChange.bind(this)} 
				min={-999999} 
				endLabel={(effect?.qty_change > 1 ? '* ' : '') + part.uom}
			/>

			<DeleteButton index={index} margin="none"/>
		</StyledGridRow>
	)

	return (allowDragDrop ? 
		<UIDraggable
			index={index}
			name={'dest_' + index}
			clone={false}
			key={'drag_' + index}
			content={RowEditComponent} 
		/>
		: RowEditComponent
	)

}



const RawPartDropDown = (
	{ partId, alreadySelectedIds = [], addRow } : {
		partId?: number
		alreadySelectedIds: number[]
		addRow: (effect: PartRawEffect) => any
	}
) => {
	
	const { step } = useContext(StepFormContext);
	const { isLoading, items: parts } = useThingsAll('partRaw');

	const allowedOptions = parts.filter( eachPart => step.raw_parts.includes(eachPart.id) && !alreadySelectedIds.includes(eachPart.id) );
	
	function onSelect(newPartId) {
		if (typeof newPartId === 'number') {
			addRow({  id: newPartId, qty_change: -1 });
		}
	}

	return (
		<UISelectControlled
			name="new-raw-part"
			options={allowedOptions}
			defaultValue={partId}
			isLoading={isLoading}
			customOption={RawPartCustomOption}
			onChange={onSelect}
			defaultMenuIsOpen
			filterOptionKeys={['name', { key: 'part_number', threshold: matchSorter.rankings.CONTAINS }, 'description']}
		/>
	)

}

const RawPartCustomOption = ({ data: part = {} as Part }) => {
	if (!part.id) { return null; }
	return (
		<SelectCustomOptionFlexbox flexDirection="column" flexAlign="flex-start" width="100%">
			<UIFlexbox flexJustify="space-between" width="100%">
				<div className="option-title">{part.name}</div>
				<div>{part.part_stock?.[0]?.part_total_qty} in stock</div>
			</UIFlexbox>

			<OptionSubtitle className="option-subtitle">
				{part.part_number && <span>{part.part_number}</span>}
				{part.description && <span>{part.description}</span>}
			</OptionSubtitle>

		</SelectCustomOptionFlexbox>
	)
}


const OptionSubtitle = styled(UISubtitle)`
	margin-top: 4px;
	color: ${props => props.theme.colors.baseTextLight};
	${props => props.theme.presets.overflowEllipsis};
	span + span {
		margin-left: 10px;
		:before {
			/* content: ' | ' */
		}
	}
`;
