import React from 'react';
import styled from 'styled-components';

import { useGhostStore } from 'store';
import { UIForm, UIHeading, UIInput, UIButton, useModal, UIModal } from 'kit';
import { apolloClient } from 'services/apollo';

const StyledButton = styled.button`
	position: absolute;
	left: 140px;
	bottom: 70px;
	z-index: 999999;
	height: 29px;
	border-radius: 5px;
	border: none;
	cursor: pointer;
	background-color: ${props => props.theme.colors.primary};
	color: white;
	padding: 2px 8px;
`

export const GhostButton = (
	props: { 		
	}
) => {
	const modalProps = useModal();
	const ghostObj = useGhostStore(state => state);

	const submit = (formData) => {
		ghostObj.setOrgId(formData.orgId);
		ghostObj.setRole(formData.role);
		if (formData.userId) { ghostObj.setUserId(formData.userId); }
		apolloClient.resetStore();
		modalProps.toggleModal();
	}

	return (
		<React.Fragment>
			<StyledButton
			//@ts-ignore
				onClick={modalProps.toggleModal}
			>
				{ghostObj.orgId}, {ghostObj.role}, ...{ghostObj.userId.substr(0, -5)}
			</StyledButton>

			<UIModal {...modalProps}>
				<UIForm 
					onSubmit={submit} 
					defaultValues={ghostObj}
					disableAutocomplete
				>
					<UIHeading>
						Ghost to another organization
					</UIHeading>
					
					<UIInput name="orgId" label="Org ID" required />
					<UIInput name="role" label="Role" required />
					<UIInput name="userId" label="User ID" />

					<UIButton type="submit" label="Submit" />
				</UIForm>
			</UIModal>
		

		</React.Fragment>
	);
}