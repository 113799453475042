import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import { UISection, GridContainer, GridRow, UINumberInputControlled, UISelectControlled } from 'kit';

import { useDynamicList, DynamicListContext, useFulfill, useThingById, useThingsAll } from 'hooks';

import { useQuery } from '@apollo/client';
import { GET_ORDER_ITEMS_BY_PART } from 'services/orderFetch';

import { OrderItem, WorkflowProductSelection, WorkflowOptions } from 'types';


export const ProductSelection = ({ workflowOptions, updateProductSelections }: {
	workflowOptions: WorkflowOptions
	updateProductSelections
}) => {
	const dynamicListContext =  useDynamicList<WorkflowProductSelection>(workflowOptions.product?.selections, { onUpdate: updateProductSelections, allowZeroLengthArray: false });
	const { rows, addRow, isAdding, AddButton } = dynamicListContext;

	useEffect(() => {
		if (workflowOptions.product?.method === 'fixed'  && !workflowOptions.product?.selections) {
			// populate selections using default qtys
			workflowOptions.product?.plans.forEach( (plan, index) => {
				addRow({ part_id: plan.part_id, processing_qty: plan.default_qty || 1 });
			});
		}
	}, [workflowOptions.product?.method])


	return (
		!workflowOptions?.product_enabled ?
		<p>No products generated in this workflow.</p>
		:
		<UISection>
			<GridContainer columns={6} disableHover gridTemplateColumns="150px 150px 250px 80px 150px 20px">
				<GridRow>
					<div>Product Name</div>
					<div>Processing Qty</div>
					<div>Use to fulfill order</div>
					<div>Order Qty Needed</div>
					<div>Qty to reserve</div>
					<div> </div>
				</GridRow>

				{rows?.map( (selection, index) => {
					return (
						<ProductRow 
							key={selection.part_id} 
							index={index} 
							dynamicListContext={dynamicListContext} 
						/>
					)
				})}

			</GridContainer>
			{workflowOptions.product.method === 'flex_job' && 
				<>
					<AddButton />
					{isAdding && 
					<AddProductSelection 
						allowedTags={workflowOptions.product?.allowed_tags} 
						alreadySelectedProducts={rows?.map( sel => sel.part_id )} 
						dynamicListContext={dynamicListContext} 
					/>}
				</>
			}
		</UISection>
	)
}




const ProductRow = ( { index, dynamicListContext }: 
	{
		index: number
		dynamicListContext: DynamicListContext<WorkflowProductSelection>
	}
) => {
	const { rows, editRow, editSingleValueInRow, DeleteButton } = dynamicListContext;
	const selection = rows[index];
	const { part_id: partId, processing_qty: processingQty, order_item_id: originalOrderItemId, reserve_qty: reserveQty } = selection;

	// Associated order and reserve qty have to be set in state & use effect 
	// because we have to wait for useFulfill to load before we can send row information back to workflowoptions.
	const [selectedLineItemId, setSelectedLineItemId] = useState();
	const { isLoading, unfulfilledAndNotReservedQty } = useFulfill(selectedLineItemId);

	const reservableQty = unfulfilledAndNotReservedQty;
	const maxReservable = calcMaxReservable(processingQty);

	useEffect(() => {
		if (maxReservable > 0) {
			updateOrderLineItem()
		}
	}, [selectedLineItemId, isLoading])

	const { item: part } = useThingById('partProduct', partId);

	const { 
		data: { order_item: orderItems } = { order_item: [] as OrderItem[] }
		// NOTE: GET_ORDER_ITEMS_BY_PART returns part of the order info in addition to stardard order item columns
	} = useQuery(GET_ORDER_ITEMS_BY_PART, { variables: { partId: partId }, fetchPolicy: 'cache-and-network' }); // make sure orders are always up to date



	function calcMaxReservable(processingQtyRequested) {
		return Math.max(Math.min(reservableQty, processingQtyRequested), 0);
	}

	function updateProcessingQty(newProcessingQty) {
		let newReserveQty = reserveQty;
		if (processingQty === reserveQty || // if processing and reserve qtys matched before, keep them matched
			newProcessingQty < reserveQty // if the new qty is less than reserve
		) {
			newReserveQty = calcMaxReservable(newProcessingQty);
		}
		const newRow: WorkflowProductSelection = {
			...selection,
			processing_qty: newProcessingQty,
			reserve_qty: newReserveQty

		}
		editRow(index, newRow);
	}
	
	function updateReserveQty(newReserveQty: number) {
		editSingleValueInRow(index, 'reserve_qty', newReserveQty);
	}

	function updateOrderLineItem() {
		const newRow: WorkflowProductSelection = {
			...selection,
			order_item_id: selectedLineItemId,
			reserve_qty: maxReservable

		}
		editRow(index, newRow);
	}
	
	return (
		<GridRow>
			<div>{part.name}</div>
			<UINumberInputControlled 
				defaultValue={processingQty}
				onChange={updateProcessingQty}
				endLabel={part.uom}
			/>
			<UISelectControlled
				name="notregistered"
				defaultValue={originalOrderItemId}
				onChange={setSelectedLineItemId}
				options={orderItems.filter( item => item.status !== 'fulfilled')}
				labelAccessor="order.name"
			/>
			<div>{reservableQty}</div>
			<UINumberInputControlled 
				defaultValue={reserveQty}
				onChange={updateReserveQty}
				endLabel={part.uom}
				min={0}
				max={maxReservable}
				disabled={typeof selectedLineItemId !== 'number'}
			/>
			<DeleteButton index={index} />
		</GridRow>
	)
}

const AddProductSelection = ( { allowedTags = [], alreadySelectedProducts = [], dynamicListContext: { addRow } }: {
	allowedTags: number[],
	alreadySelectedProducts: number[]
	dynamicListContext: DynamicListContext<WorkflowProductSelection>
}) => {
	const { isLoading, items: parts } = useThingsAll('partProduct');


	const allowedOptions = allowedTags.length > 0 ? 
		parts.filter( item => 
			!alreadySelectedProducts.includes(item.id) &&
			item.tags.filter( tagId => allowedTags.includes(tagId) ).length > 0
		) : 
		parts.filter( item => !alreadySelectedProducts.includes(item.id) )
	;
	
	function onSelect(partId) {
		if (typeof partId === 'number') {
			addRow({ part_id: partId, processing_qty: 1 });
		}
	}

	//if (allowedOptions.length === 0) { return null; }

	return (
		<SelectContainer>
			<UISelectControlled 
				name="part-select" label="Product"
				options={allowedOptions}
				defaultValue={null}
				isLoading={isLoading}
				onChange={onSelect}
			/>
		</SelectContainer>
	);
}

const SelectContainer = styled.div`
	max-width: 400px;
`;