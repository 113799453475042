import { gql } from '@apollo/client';

import { stepHistoryAllColumns } from '@constants';

export const GET_STEP_HISTORY_BY_JOB = gql`
	query GET_STEP_HISTORY_BY_JOB  (
		$jobId: Int!
	){
		step_history(
			where: { job_id: { _eq: $jobId } }
			order_by: { start_at: asc_nulls_last }
		) {
			${stepHistoryAllColumns}
		}
	}
`;

export const GET_STEP_HISTORY_BY_JOBS = gql`
	query GET_STEP_HISTORY_BY_JOBS  (
		$jobIds: [Int!]
	){
		step_history(
			where: { job_id: { _in: $jobIds } }
			order_by: { start_at: asc_nulls_last }
		) {
			${stepHistoryAllColumns}
		}
	}
`;

export const GET_STEP_HISTORY_BY_ID = gql`
	query GET_STEP_HISTORY_BY_ID  (
		$id: Int
		$step_id: Int
	){
		step_history(
			where: {
				id: { _eq: $id }
				step_id: { _eq: $step_id }
			}
		) {
			${stepHistoryAllColumns}
			job {
				name
			}
		}
	}
`;

export const GET_STEP_HISTORY_BY_JOB_INDEX = gql`
	query GET_STEP_HISTORY_BY_JOB_INDEX  (
		$jobId: Int!
		$index1: Int! #Index starts at 1, not 0!
	){
		step_history (
			where: {
				job_id: { _eq: $jobId }
				job_step_index: { _eq: $index1 }
			}
		) {
			${stepHistoryAllColumns}
		}
	}
`;
