import React, { useMemo } from 'react';
import { UIHeading, UICard } from 'kit';

import { Table } from 'components/Table/Table';
import { stepHistoryCols, transformStepHistoryData } from 'components/Table/tableRef';

import { useQuery } from '@apollo/client';
import { GET_JOBS_IN } from '@constants/gql';
import { GET_STEP_HISTORY_BY_JOBS } from 'root/services/stepHistoryFetch';

import { StepHistoryRaw, Job } from 'types';
import { UIRoutedLink } from 'routing';

const stepHistoryColumnDefs = [
	stepHistoryCols.jobName,
	stepHistoryCols.name,
	stepHistoryCols.tool,
	stepHistoryCols.recipe,
	stepHistoryCols.startUser,
	stepHistoryCols.startAt,
	stepHistoryCols.stopAt,
	stepHistoryCols.duration
];


export function InventoryUniqueStepHistory (
	props: {
		jobIds: number[]
		itemId: number
	}
) {
	const { 
		loading: stepLoading, 
		data: { step_history: stepData } = { step_history: [] as Array<StepHistoryRaw> }
	} = useQuery(GET_STEP_HISTORY_BY_JOBS, { 
		variables: { jobIds: props.jobIds },
		skip: props.jobIds.length === 0
	});

	const columns = useMemo(() => stepHistoryColumnDefs, []);
	const data = useMemo(() => 
		transformStepHistoryData(stepData.filter(
			dStep => dStep.inventory_unique_items.indexOf(props.itemId) > -1
			))
	, [stepLoading]);
	
	return (
		<React.Fragment>
			<UIHeading level="3">Step History</UIHeading>
			<Table 
				columns={columns}
				data={data}
				hasFilterableColumns
				hasGlobalFilter
				hasSortableColumns
			/>

		</React.Fragment>
	)
}

export function InventoryUniqueJobHistory (
	props: {
		jobIds: number[]
	}
) {
	const { 
		data: { job: jobs } = { job: [] as Array<Job> }
	} = useQuery(GET_JOBS_IN, { 
		variables: { ids: props.jobIds },
		skip: props.jobIds.length === 0
	});

	return (
		<UICard	
			title={<>Jobs</>}
		>
			{jobs.map( job => 
				(
				<UIRoutedLink 
					key={job.id}
					display="block"
					text={job.name} 
					linkToRoute="jobById"
					linkToVars={{ id: job.id }}
				/>
				)
			)}
		</UICard>
	)
}