import React from 'react';

import { UIRoutedLink } from 'routing';

import { TableDateMultiField } from 'components/Table/TableDateField';
import { SelectColumnFilter } from 'components/Table/TableFilters';

import { ColDefs } from './tableColumnUtility';
import { StepHistoryRaw } from 'types';


import { genStepHistoryHelpers, StepHistoryHelper } from 'services/stepHydrate';
import { elapseTime } from 'utility';


import { PartList, ProductList } from 'components/Parts/PartList';

interface TransformedStepHistory extends StepHistoryRaw, StepHistoryHelper  {
	duration?: number
}

export function transformStepHistoryData(stepHistoryArr: StepHistoryRaw[]): TransformedStepHistory[] {
	return stepHistoryArr.map(
		stepHistory => {
			const stepHistoryHelper = genStepHistoryHelpers(stepHistory);
			return { 
				...stepHistory, 
				...stepHistoryHelper,
				duration: elapseTime(stepHistory.start_at, stepHistory.stop_at, false, true)
			};
		}
	)
}

type StepHistoryColId = 'jobName' | 'name' | 'tool' | 'recipe' | 'startUser' | 'startAt' | 'stopAt' | 'duration' | 'forms' | 'partRaw' | 'partProduct';

export const stepHistoryCols: ColDefs<StepHistoryColId> = {
	jobName: {
		Header: 'Job',
		accessor: 'job_name',
		className: 'emphasis',
		Cell: (table) => {
			const item: TransformedStepHistory = table.row.original;
			return (
				<UIRoutedLink
					text={item.job.name}
					linkToRoute="jobById"
					linkToVars={{ id: item.job_id }}
				/> )
		}
	},
	name: {
		Header: 'Step Name',
		accessor: 'name',
		className: 'emphasis',
		Cell: (table) => {
			const item: TransformedStepHistory = table.row.original;
			return (
				<UIRoutedLink
					text={item.step?.name}
					linkToRoute="operateStep"
					linkToVars={{ id: item.job_id, index: item.job_step_index }}
				/> )
		}
	},
	tool: {
		Header: 'Tool',
		accessor: 'tool.name',
		Filter: SelectColumnFilter
	},
	recipe: {
		Header: 'Recipe',
		accessor: 'recipe.name',
		Filter: SelectColumnFilter
	},
	startUser: {
		Header: 'Start User',
		accessor: 'startUserName',
		Filter: SelectColumnFilter
	},
	startAt: {
		Header: 'Started',
		accessor: 'start_at',
		disableGlobalFilter: true,
		Cell: ({ cell: { value } }) => <TableDateMultiField ISOdateString={value} dateDisplayFormatTop="dateShortWithYear" dateDisplayFormatBottom="time" />
	},
	stopAt: {
		Header: 'Stopped',
		accessor: 'stop_at',
		disableGlobalFilter: true,
		Cell: ({ cell: { value } }) => <TableDateMultiField ISOdateString={value} dateDisplayFormatTop="dateShortWithYear" dateDisplayFormatBottom="time" />
	},
	duration: {
		Header: 'Duration',
		accessor: 'duration',
		disableGlobalFilter: true
		
	},
	forms: {
		Header: 'Forms',
		accessor: 'form_name',
		disableGlobalFilter: true,
		Cell: (table) => {
			const item: TransformedStepHistory = table.row.original;
			return (
				item.forms.map( form => {
					return (<div key={form.form_definition.name}>
						{form.form_definition.name}
					</div>)
				})
			
			)
		}
	},
	partProduct: {
		Header: 'Products',
		accessor: 'step_history_yields.length',
		//disableGlobalFilter: true,
		Cell: (table) => {
			const item: TransformedStepHistory = table.row.original;
			if (item.step_history_yields?.length < 1) {return ''}
			return (
				<ProductList label={item.step_history_yields?.length + ' item(s)'} products={item.step_history_yields}/>
			)
		}
	},
	partRaw: {
		Header: 'Materials',
		accessor: 'part_txs.length',
		//disableGlobalFilter: true,
		Cell: (table) => {
			const item: TransformedStepHistory = table.row.original;
			if (item.part_txs.length < 1) {return ''}
			return (
				<PartList label={item.part_txs.length + ' item(s)'} parts={item.part_txs}/>
			)
		}
	}
}