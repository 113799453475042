import React from 'react';
import styled, { css } from 'styled-components';


import Chip from '@material-ui/core/Chip';

type UIChipProps = {
	icon?: JSX.Element
	label?: string | number
	className?: string

	clickable?: boolean
	onClick?: () => void
	onDelete?: (any) => void

	// size / container modifiers
	size?: MuiSize | CustomSize
	noMarginBottom?: boolean

	// fill modifiers
	outline?: boolean
	light?: boolean

	// color modifiers
	muted?: boolean
	success?: boolean
	error?: boolean
	warning?: boolean
	disabled?: boolean

	capitalize?: boolean
} 

type MuiSize = 'small' | 'medium';
type CustomSize = 'xsmall' | null;

export const UIChip = (	
	{ 
		icon, label, className, clickable, onClick, onDelete,
		disabled, 
		outline = false,
		size = 'small',
		...restProps
	}: UIChipProps
) => {
	const isCustomSize = !(size === 'medium' || size === 'small');
	const muiSize: MuiSize = !isCustomSize ? size as MuiSize : 'small';
	const customSize: CustomSize = isCustomSize ? size as CustomSize : null;
	return (
		<StyledChip 
			icon={icon ? icon : void 0}
			label={label}
			size={muiSize}
			clickable={clickable}
			onClick={onClick}
			onDelete={onDelete}
			disabled={disabled}
			color="primary"
			variant={outline ? 'outlined' : void 0} 
			className={className}
			{...restProps}
			customSize={customSize}
		/>
	);
}

const StyledChip = styled(Chip).withConfig({
// Material UI emits a console error with props that are not native to it. Filter out the props that we are using for our style overrides
	shouldForwardProp: (prop) => !['customSize', 'noMarginBottom', 'light', 'success', 'error', 'warning', 'muted', 'capitalize'].includes(prop)
})<UIChipProps & { customSize: CustomSize }>`
	font-weight: 500;
	margin-bottom: ${props => !props.noMarginBottom && '8px'};
	&:not(:last-of-type) {
		margin-right: 8px;
	}

	text-transform: ${props => props.capitalize && 'capitalize'};

	${props => !props.customSize && props.size === 'medium' && css`
		font-size: 1rem;
	`}
	${props => !props.customSize && props.size === 'small' && css`
		font-size: 0.9rem;
		svg {
			height: 0.9rem;
			width: 0.9rem;
		}
	`}


	${props => props.customSize === 'xsmall' && css`
		height: 18px;
		span {
			padding: 0 8px;
		}
		&& svg { /* Bump up the specificity with && */
			height: 10px;
			width: 10px;
			margin-right: -4px;
			margin-left: 4px;
		}
	`}

	background: ${props => props.success && props.theme.colors.success};

	&.MuiChip-outlined {
		background: none;
		${props => props.muted && css`
			color: ${props.theme.colors.muted};
			border-color: ${props.theme.colors.muted};
		`}
		${props => props.success && css`
			color: ${props.theme.colors.success};
			border-color: ${props.theme.colors.success};
		`}
		${props => props.error && css`
			color: ${props.theme.colors.error};
			border-color: ${props.theme.colors.error};
		`}
		${props => props.warning && css`
			color: ${props.theme.colors.warning};
			border-color: ${props.theme.colors.warning};
		`}
	}




	${props => props.light && css`
	 	background: ${props.theme.colors.primaryLight1};
		color: ${props.theme.colors.primary};
		border-color: ${props.theme.colors.primaryLight2};
	`}
	${props => props.light && props.error && css`
	 	background: ${props.theme.colors.errorLight};
		color: ${props.theme.colors.error};
		border-color: ${props.theme.colors.error};
	`}
	${props => props.light && props.success && css`
	 	background: ${props.theme.colors.successLight};
		color: ${props.theme.colors.success};
		border-color: ${props.theme.colors.success};
	`}
	${props => props.light && props.muted && css`
	 	background: ${props.theme.colors.mutedLight};
		color: ${props.theme.colors.muted};
		border-color: ${props.theme.colors.muted};
	`}
	${props => props.light && props.disabled && css`
		background: white;
		color: ${props.theme.colors.baseText};
		border-color: ${props.theme.colors.colorDefinitions.grey4};
	`}
`;