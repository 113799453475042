import React, { useContext } from 'react';

import { UIIconButton, UITooltipIcon } from 'kit';

import { AddCircle } from '@styled-icons/remix-line/AddCircle';
import { Redo } from '@styled-icons/material/Redo';

import { WorkflowEditorContext } from './WorkflowEditorContext';
import { isStepSkipped, generateMod, removeMod } from './modFunctions';
import { updateIndexesOnSkip } from './workflowOptionsInventoryUniqueFunctions';

import { SupplementalStepInfo } from 'types';


export const WorkflowEditorRowActionButtons = (	
	{ 
		dbStep, index
	}: {
		dbStep
		index
	} 
) => {
	const { 
		workflowOptions, updateWorkflowOptions,
		isAmendment, firstAmendableStep0,
		updateSuppPartial, dynamicListContext 
	} = useContext(WorkflowEditorContext);

	const { rows: databaseSteps, DeleteButton } = dynamicListContext;


	function markSkipped(){
		const existingMod = supp?.workflow_mod;
		updateSuppPartial({ workflow_mod: generateMod(existingMod, 'skip') }, index);
		const newWorkflowOptions = updateIndexesOnSkip(workflowOptions, databaseSteps, index, firstAmendableStep0);
		updateWorkflowOptions(newWorkflowOptions);
	}
	
	function addBackSkippedStep(){
		const existingMod = supp?.workflow_mod;
		const newMod = removeMod(existingMod, 'skip');
		updateSuppPartial({ workflow_mod: newMod }, index);
	}
	
	const supp = dbStep.supp;
	const isCompleted = index < firstAmendableStep0;
	const isAdded = supp?.workflow_mod?.add;
	const isSkipped = isStepSkipped(dbStep);
	const isStillEditable = index >= firstAmendableStep0;


	return (
		
		<div className="action-buttons">
			{(!isAmendment || (isAmendment && isAdded)) && isStillEditable && <DeleteButton index={index} />}
			{isAmendment && !isCompleted && !isSkipped && !isAdded && <UIIconButton muted icon={<Redo />} onClick={markSkipped} /> }
			{isSkipped && <UIIconButton muted icon={<AddCircle />} onClick={addBackSkippedStep} /> }
			<ModExplanation 
				workflow_mod={supp?.workflow_mod} 
				isStillEditable={isStillEditable}
			/>
		</div>
	)
}

const ModExplanation = (
	{ workflow_mod = {} as SupplementalStepInfo['workflow_mod'], isStillEditable }
	: {
		workflow_mod: SupplementalStepInfo['workflow_mod']
		isStillEditable
	}
) => {
	let infoText = '';
	if (!isStillEditable) {
		infoText += `This step has completed and can no longer be changed. `;
	}
	if (workflow_mod.add) {
		infoText += `This step was added after the job was created. ${isStillEditable ? 'Click the trash icon to the left to remove it. ' : ''}`;
	}
	if (workflow_mod.skip) {
		infoText += `This step ${isStillEditable ? 'will be' : 'was'} skipped. ${isStillEditable ? 'Click the plus icon to the left to cancel skipping. ' : ''}`;
	}
	if (workflow_mod.reorder) {
		infoText += 'This step was reordered. ';
	}

	return (
		infoText && <UITooltipIcon tooltipContent={infoText} />
	)
}
