import React from 'react';
import { UIPage, UIFlexChild, UIHeading, UIButton, UIText, UILine, UICard } from 'kit';

import { useTransientStore } from 'store';

import { AddInventoryUniqueTypeButtonModal, AddInventoryUniqueStageButtonModal } from 'components/InventoryUnique/InventoryUniqueForms';
import { GET_INVENTORY_UNIQUE_TYPES } from 'services/inventoryUniqueFetch';
import { InventoryUniqueType } from 'types';
import { useQuery } from '@apollo/client';
import { ArrowBack } from '@styled-icons/boxicons-regular/ArrowBack'
import { AddCircle } from '@styled-icons/remix-line/AddCircle';
import { capitalizeFirstLetter } from 'utility';
import { FormDefinitionList } from './FormDefinitionPage';
import { useNavMain } from 'routing';



export function AddInventoryUniquePage() {
	const navigateToFormBuilder = useNavMain('formBuilderDraft');
	const navigateToFormBuilderbyId = useNavMain('formBuilderById');
	const navigateToInventoryUniquePage = useNavMain('inventoryUnique');
	
	const setTransientData = useTransientStore(state => state.setValue);

	const {	
		data: { inventory_unique_type: typeData } = { inventory_unique_type: [] as Array<InventoryUniqueType> }
	} = useQuery(GET_INVENTORY_UNIQUE_TYPES);


	function navToForm(formDefinition){		
		navigateToFormBuilderbyId(formDefinition.id)
	}

	function addNewFormDef(typeId: number) {		
		setTransientData('formSetupInvUniqueTypeId', typeId);
		navigateToFormBuilder();
	}

	return (
		<UIPage id="addInventoryUniquePage" contentContainerSize="medium">
			<UIButton icon={<ArrowBack color="white"/>} label="Back" onClick={navigateToInventoryUniquePage} />
			<AddInventoryUniqueTypeButtonModal />


			{typeData.map( (type) => {
				const form_definitions = type.form_definitions;
				
				return (
					<UICard key={'type_' + type.id}>
						<UIFlexChild flexDirection="row" >
							<UIHeading level="3">{capitalizeFirstLetter(type.name)}</UIHeading>
							<UILine />										

							<UIButton
								icon={<AddCircle  />}
								label="Add Form"
								onClick={addNewFormDef.bind(this, type.id)}
							/>
							<FormDefinitionList 
								headingText=""
								formDefinitions={form_definitions.filter( def => def.inventory_unique_type_id === type.id )} 
								onClick={navToForm}
							/>

							<UILine />										
							<AddInventoryUniqueStageButtonModal typeData={type}/>
							{type.stages.map( (stage) => {
								return <UIText text={stage.name} key={'stage' + stage.id} />
							})}
						</UIFlexChild>
					</UICard>								
				)
			})}
			
		</UIPage>
	)
}
