import { ApolloClient, HttpLink, ApolloLink, InMemoryCache, concat, DataProxy, FieldFunctionOptions, gql } from '@apollo/client';
import { onError } from 'apollo-link-error';

import { useGhostStore, useAuthStore } from 'store';
import { toast } from 'react-toastify';
import { ThingName, thingLookup, thingColsLookup } from '@constants';


const errorHandler = onError(( { graphQLErrors, response, networkError } ) => {
		
	if (response?.errors?.[0].extensions?.code === 'invalid-jwt') {
		toast.info('Session Expired', {
			position: toast.POSITION.BOTTOM_CENTER,  autoClose: 4000
		});
	}
	else if (typeof response !== 'undefined') {
		let message = 'error';
		switch (response?.errors?.[0].extensions?.code) {
			case 'constraint-violation':
				message = 'Duplicate value';
				break;
			case 'validation-failed':
				message = 'Insufficient authorization.';
				break;
			default:
				message = 'error';
		}

		toast.error(message + (process.env.NODE_ENV === 'development' ? ':' + response?.errors?.[0]?.message : ''), {
			position: toast.POSITION.BOTTOM_CENTER,  autoClose: 4000
		});	
	}
	else if (typeof networkError !== 'undefined') {
		toast.error('Network Error! Check Internet Connection ' + networkError, {
			position: toast.POSITION.BOTTOM_CENTER,  autoClose: false
		});
	}
	
})

let gqlEndpoint = process.env.REACT_APP_GQL_ENDPOINT;

// https://github.com/cypress-io/cypress/issues/3083#issuecomment-637778161
// This was the easiest way to differentiate different gql calls in cypress
const httpLink = new HttpLink({ 
	//@ts-ignore
	uri: ({ operationName }) => window.Cypress ?
		`${gqlEndpoint}?${operationName}` :
		gqlEndpoint
});

const authMiddleware = new ApolloLink((operation, forward) => {
	let headers = {};
	if (process.env.NODE_ENV === 'development' && process.env.REACT_APP_SPOOF_USER === 'true') {
		const ghostObj = useGhostStore.getState();
		headers = {
			'X-Hasura-Admin-Secret': process.env.REACT_APP_HASURA_SECRET,
			'X-Hasura-Role': ghostObj.role,
			'X-Hasura-Org-Id': ghostObj.orgId,
			'X-Hasura-User-Id': ghostObj.userId
		}
	}
	else {
		const token = useAuthStore.getState().token;
		headers = {
			authorization: 'Bearer ' + (token || null)
		};
	}

	// add the authorization to the headers
	operation.setContext({
		headers: headers
	});
	return forward(operation);
});

// error handling middleware didnt work when i tried with 3.0 beta 16 https://www.apollographql.com/docs/react/v3.0-beta/networking/network-layer/

export const apolloClientAnonymous = new ApolloClient({
	//@ts-ignore
	link: errorHandler.concat(httpLink),
	cache: new InMemoryCache()
});

export const apolloClient = new ApolloClient({
	//link: concat(authMiddleware, httpLink),
	//@ts-ignore
	link: errorHandler.concat(concat(authMiddleware, httpLink)),
	cache: new InMemoryCache({
		typePolicies: {
			step_history: {
				//keyFields: ['job_id', 'job_step_index']
			},
			part_stock: {
				keyFields: ['part_id', 'job_id', 'inventory_unique_id']
			},
			Query: {
				fields: {
					//@ts-ignore
					step_history (existingData, { variables, canRead, toReference }) {
						if (existingData) { return existingData; }
						// If there are problems with the caching, make sure the variables are integers, not strings!!!
						const cacheReference = toReference({ __typename: 'step_history', job_id: parseInt(variables.jobId), job_step_index: parseInt(variables.index1) });
						if (canRead(cacheReference)) { return [cacheReference]; } // put this in an array so it's consistent with normal query return
						return {}; // return empty in case data is undefined - this prompts the query to actually run, as of @apollo/client 3.0.0-beta.14
					},
					job (existingData, options) {
						return cacheReadById(existingData, options);
					},
					step (existingData, options) {
						return cacheReadById(existingData, options);
					},
					form_definition (existingData, options) {
						return cacheReadById(existingData, options);
					},
					order_item (existingData, options) {
						return cacheReadById(existingData, options);
					},
					part (existingData, options) {
						return cacheReadById(existingData, options);
					},
					recipe (existingData, options) {
						return cacheReadById(existingData, options);
					},
					tool (existingData, options) {
						return cacheReadById(existingData, options);
					}
				}
			}
		}
	})
});

function cacheReadById(existingData, options) {
	//@ts-ignore
	const { variables, toReference, canRead, fieldName } = options as FieldFunctionOptions;
	if (existingData) { return existingData; }
	const cacheReference = toReference({ __typename: fieldName, id: variables.id });
	if (canRead(cacheReference)) { return [cacheReference]; } // put this in an array so it's consistent with normal query return
	return {}; // return empty in case data is undefined - this prompts the query to actually run, as of @apollo/client 3.0.0-beta.14
}

/**
 * Updates the cache after an insert or add mutation.  Assumes Hasura convention, where mutation alias is 'insert_[tableName]'
 * @param cache same as update function from mutation
 * @param result same as update function from mutation
 * @param tableName the table name that is getting written to
 * @param queryDocument The GET_ query that defines what cache to update
 * @param queryVariables variables object associated with query, if applicable. NOTE: I think this needs ids (numbers) to be cast to string
 * @param isUpdate If the mutation is actually an update operation instead of an insert operation
 */

export function updateCacheAfterInsert(cache: DataProxy, result, tableName: string, queryDocument, queryVariables = {}, isUpdate = false, addToBeginning = false){
	const queryResponse = result.data;
	const addedRows = queryResponse[(isUpdate ? 'update_' : 'insert_') + tableName].returning;
	const { [tableName]: cachedData }: any = cache.readQuery({ query: queryDocument, variables: queryVariables });
	cache.writeQuery({
		query: queryDocument,
		variables: queryVariables,
		data: { [tableName]: addToBeginning ? addedRows.concat(cachedData) : cachedData.concat(addedRows) }
	});
}

export function queryFromCacheById(thingName: ThingName, id: number) {
	const lookup = thingLookup[thingName];
	const lookupCols = thingColsLookup[thingName];

	const cachedItem = apolloClient.readFragment({
		id: `${lookup.tableName}:${id}`,
		fragment: gql`
			fragment CACHED_ITEM on ${lookup.tableName} {
				${lookupCols}
			}`
	});
	return cachedItem;
}