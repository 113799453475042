import { usePrompt } from 'react-router-dom';

export function useLeavePage(shouldPromptBeforeLeave: boolean, overrideMessage?: string){
	usePrompt(
		overrideMessage || 'Are you sure you want to leave? Changes may not be saved.', 
		shouldPromptBeforeLeave
	);
}

export function useLeaveDialog(overrideMessage?: string){
	function confirmLeave(){
		return confirm(overrideMessage || 'Are you sure you want to close this menu? Changes may not be saved.');
	}
	return { confirmLeave }; 
}
