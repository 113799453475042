
import { apolloClient, updateCacheAfterInsert } from 'services/apollo';
import { gql } from '@apollo/client';

import { GET_WORKFLOWS } from 'services/workflowFetch';
import { workflowAllColumns } from '@constants';

import { WorkflowInsertUpdateObj } from 'types';

const ADD_WORKFLOW = gql`
	mutation ADD_WORKFLOW ( 
		$insertingObjs: [workflow_insert_input!]!
	) {
		insert_workflow (
			objects: $insertingObjs
		) {
			returning {
				${workflowAllColumns}
			}
		}
	}
`;

export function addWorkflow(workflow: WorkflowInsertUpdateObj) {
	return apolloClient.mutate({ 
		mutation: ADD_WORKFLOW, 
		variables: { 
			insertingObjs: [workflow]
		},
		update(cache, result) {
			updateCacheAfterInsert(cache, result, 'workflow', GET_WORKFLOWS);
		}
	}).then( response => {
		return response.data.insert_workflow.returning[0];
	});
}

const UPDATE_WORKFLOW = gql`
	mutation UPDATE_WORKFLOW ( 
		$id: Int!
		$changes: workflow_set_input!
	) {
		update_workflow (
			where: { id: { _eq: $id } },
			_set: $changes
		) {
			returning {
				${workflowAllColumns}
			}
		}
	}
`;


export function updateWorkflow(id: number, changes: WorkflowInsertUpdateObj) {
	apolloClient.mutate({ 
		mutation: UPDATE_WORKFLOW,
		variables: {
			id,
			changes
		}
	});
}