import React from 'react';
import styled, { css } from 'styled-components';

import { UIIconButton, UIFlexbox, UIFlexChild } from 'kit';

import { EditOutline as Edit } from '@styled-icons/evaicons-outline/EditOutline';
import { FlexboxProps, marginStyles, MarginProps, PaddingProps, paddingStyles, flexboxStyles } from '@constants';

type UICardProps = {
	onClick?: any
	title?
	actionOnClick?
	overrideEditIcon?
	children: any
	width?: string
} & FlexboxProps & MarginProps & PaddingProps

export const UICard = (	
	props: UICardProps
) => {
	const { title, children, actionOnClick, ...restProps } = props;
	return (
		<Card {...restProps} actionOnClick={actionOnClick} >
			<UIFlexbox flexAlign="flex-start">
				{title && 
					<UIFlexChild className="title" asFlexbox flexAlign="center">  {title}  </UIFlexChild>
				}
				{actionOnClick && 
					<UIIconButton 
						icon={props.overrideEditIcon ? props.overrideEditIcon : <Edit />} 
						onClick={actionOnClick} 
						className="action-button"
					/> 
				}
				
			</UIFlexbox>

			{children}
		</Card>
	);
}

const Card = styled.div<UICardProps>`
	position: relative;
	background-color: white;
	font-family: ${props => props.theme.font.body};

	border-radius: 8px;
	border: 1px solid ${props => props.theme.colors.colorDefinitions.grey3};

	width: fit-content;
	width: ${props => props.width};
	
	
	${marginStyles}
	${paddingStyles}
	${flexboxStyles}

	transition: 0.3s;

	.action-button {
		margin: -6px -2px 0 8px;
	}

	.title {
		box-sizing: border-box;
		font-size: 1.2em;
		font-weight: ${props =>props.theme.font.boldWeight};
		font-family: ${props => props.theme.font.title};
		padding: 0 0 12px 0;
		padding-right: ${props => props.actionOnClick && '20px'};
		margin-bottom: 16px;
		border-bottom: 1px solid ${props => props.theme.colors.colorDefinitions.grey3};
		svg {
			height: 1em;
			width: 1em;
			margin-right: 6px;
			color: ${props => props.theme.colors.baseTextLight};
		}
	}

	${props => props.onClick && css`
		cursor: pointer;
		&:hover {
			${props.theme.shadow.depth3};
		}
	`}
`
UICard.defaultProps = {
	flexAlign: 'center',
	flexDirection: 'column',
	margin: 'mediumVertical',
	padding: 'medium'
}