import React from 'react';
import styled from 'styled-components';

import { UICard, useModal, UIKeyValueHorizontal } from 'kit';
import { File } from '@styled-icons/boxicons-regular/File';

import { FormInstanceModal_EditExistingForm } from './FormInstanceModal';
import { RenderedFormField } from 'components/FormBuilder/RenderedFormField';

import { FormFieldSpec, FormInstanceLinked } from 'types';
import { ThingName } from '@constants';

export const FormInstanceAll = (
	props: {
		forms: FormInstanceLinked[]
		formType: ThingName
		isEditable?: boolean
	}
) => {
	return (
		<>
		{
			props.forms.map( formInstance => {
				return (
					<FormInstanceLinkedView
						key={formInstance.id}
						linkedFormInstance={formInstance}
						isEditable
						formType={props.formType}
					/>
				)
			})
		}
		</>
	)
}

FormInstanceAll.defaultProps = {
	forms: []
}

export const FormInstanceLinkedView = (
	props: {
		linkedFormInstance: FormInstanceLinked
		isEditable: boolean
		formType: ThingName
	}
) => {
	const modalProps = useModal();

	return (
		<ListViewCard
			title={<><File />{props.linkedFormInstance.form_definition.name}</>}
			actionOnClick={props.isEditable && modalProps.toggleModal}
		>
			<FormInstanceContents
				formFields={props.linkedFormInstance.form_definition.fields}
				formValues={props.linkedFormInstance.values}
			/>

			{
				props.isEditable ?
				<FormInstanceModal_EditExistingForm
					modalProps={modalProps}
					formDefinition={props.linkedFormInstance.form_definition}
					existingForm={props.linkedFormInstance}
				/>
				: null
			}
		</ListViewCard>
	)
}

const ListViewCard = styled(UICard)`
	max-width: 400px;
`;

FormInstanceLinkedView.defaultProps = {
	formValues: {},
	showEditButton: false
}

export const FormInstanceContents = (
	{ formFields = [], formValues = {}, isEditable = false }: {
		formFields: Array<FormFieldSpec>
		formValues?: { [key: string]: any }
		isEditable?: boolean
	}
) => {

	return (
		<React.Fragment>
			{formFields.map( (formFieldDef, index) => {
				if (isEditable) {
					if (formFieldDef.status === 'archive') { return; } // skip and move to next field
					return (
						<RenderedFormField
							formFieldDef={formFieldDef}
							value={formValues[formFieldDef.key]}
							key={formFieldDef.key}
							isLastAndScrollSelect={index === formFields.length - 1}
						/>						
					)
				}
				// if read-only
				//	if archived, and no value, don't show anything
				//	if archived, but value exists, show the value.
				if (formFieldDef.status === 'archive' && typeof formValues[formFieldDef.key] === 'undefined') { return; } // skip and move to next field
				return (
					<UIKeyValueHorizontal
						key={formFieldDef.key}
						title={formFieldDef.name}
						text={formValues[formFieldDef.key]}
					/>
				)
			})}
		</React.Fragment>
	)
}
