import { css } from 'styled-components';


export interface ContainerProps {
	contentContainerSize?: 'large' | 'medium' | 'small' | 'xlarge'
}

export const containerStyles = css<ContainerProps>`
	${props => props.contentContainerSize === 'xlarge' && props.theme.container.xlarge}
	${props => props.contentContainerSize === 'large' && props.theme.container.large}
	${props => props.contentContainerSize === 'medium' && props.theme.container.medium}
	${props => props.contentContainerSize === 'small' && props.theme.container.small}
`

//----------------------------------------------------------------------------------------------------------------

export interface MarginProps {
	margin?: 'none' | 'medium' | 'large' | 'mediumVertical' | 'largeVertical'
}

export const marginStyles = css<MarginProps>`
	${props => props.margin === 'none' && props.theme.margin.none}
	${props => props.margin === 'medium' && props.theme.margin.medium}
	${props => props.margin === 'large' && props.theme.margin.large}
	${props => props.margin === 'mediumVertical' && props.theme.margin.mediumVertical}
	${props => props.margin === 'largeVertical' && props.theme.margin.largeVertical}
`

//----------------------------------------------------------------------------------------------------------------


export interface PaddingProps {
	padding?: 'none' | 'small' | 'medium' | 'large'
}

export const paddingStyles = css<PaddingProps>`
	${props => props.padding === 'small' && props.theme.padding.small}	
	${props => props.padding === 'medium' && props.theme.padding.medium}	
	${props => props.padding === 'large' && props.theme.padding.large}	
`

//----------------------------------------------------------------------------------------------------------------


export interface FlexboxProps {
	asFlexbox?: boolean
	flexDirection?: 'row' | 'row-reverse' | 'column' | 'column-reverse'
	flexWrap?: 'nowrap' | 'wrap' | 'wrap-reverse';
	flexJustify?: 'flex-start' | 'flex-end' | 'center' | 'space-between' | 'space-around' | 'space-evenly';
	flexAlign?: 'stretch' | 'flex-start' | 'flex-end' | 'center' | 'baseline';
	width?
	height?
	childrenGrow?: '50/50' | '67/33'
	className?
	scrollableY?: boolean
}

export const flexboxStyles = css<FlexboxProps>`
	${props => props.asFlexbox && 'display: flex'};
	${props => props.asFlexbox && 'flex-direction: '  + (props.flexDirection || 'row')};
	${props => props.asFlexbox && 'flex-wrap: '       + (props.flexWrap || 'nowrap')};
	${props => props.asFlexbox && 'justify-content: ' + (props.flexJustify || 'flex-start')};
	${props => props.asFlexbox && 'align-items: '     + (props.flexAlign || 'stretch')};
	width: ${props => props.asFlexbox && props.width};
	${props => props.asFlexbox && 'height: '           + (props.height || 'auto')};

	
	${props => props.scrollableY && css`overflow-y: auto;`};
	${props => props.theme.scrollbar.light}

	${props => props.asFlexbox && props.childrenGrow === '50/50' &&
		`& > *:not(:last-child) {
			margin-right: 20px;
		}
		& > * {
			flex: 1;
		}`
	};
	${props => props.asFlexbox && props.childrenGrow === '67/33' &&
		`& > *:first-child {
			flex: 2;
			margin-right: 20px;
		}
		& > * {
			flex: 1;
		}`
	};


	
`