import { useEffect } from 'react';
import { useLocation, matchRoutes } from 'react-router-dom';
import { mainRoutes } from 'routing/routes';
import { fullRoutes } from 'routing/fullRoutes';
import { useNavMain } from 'routing/useNav';
import { FullRouteDefinition, MainRouteDefinition } from 'routing/routeInterfaces';

export function goHome(){
	const navigate = useNavMain('home');
	navigate();
}

export function useCurrentFullRoute() {
	let location = useLocation();
	//@ts-ignore
	const matchedRoutes = matchRoutes(fullRoutes, location.pathname);
	if (matchedRoutes) { 
		//@ts-ignore
		return matchedRoutes[0].route as FullRouteDefinition;
	}
	return {} as FullRouteDefinition
}

export function useCurrentMainRoute() {
	let location = useLocation();
	//@ts-ignore
	const matchedRoutes = matchRoutes(mainRoutes, location.pathname);
	if (matchedRoutes) { 
		//@ts-ignore
		return matchedRoutes[0].route as MainRouteDefinition;
	}
	return {} as MainRouteDefinition
}

export function useDocumentTitle(routeType: 'main' | 'full', substitutions: string[] = []){
	const route = routeType === 'main' ? useCurrentMainRoute() : useCurrentFullRoute();

	const [backText, activeText] = substitutions;

	useEffect(() => {
		let documentTitle = 'MES';

		if (route.id) { 
			if (route.documentTitle) {
				documentTitle = route.documentTitle + ' | JetlMESine';
			}
			else {
				//@ts-ignore
				documentTitle = route.label + ' | MES';
			}
			if (documentTitle.includes('%1')) {
				documentTitle = documentTitle.replace('%1', activeText ?? '');
			}
			if (documentTitle.includes('%2')) {
				documentTitle = documentTitle.replace('%2', backText ?? '');
			}
		}


		document.title = documentTitle;
	}, [ route.id, substitutions.toString() ] );
}