import React from 'react';
import styled, { css } from 'styled-components';
import { FormLabel, FormSubtitle, FormInputContainer } from 'kit';
import { RadioOption } from 'types';

import { useFormContext, UseFormMethods } from 'react-hook-form';

export const UIRadioList = (	
	{ 
		name, label, subtitle, defaultValue, required, options, disabled, 
		type = 'radio', 
		skipRegister = false
	}: { 
		name: string
		label: string
		subtitle?: string
		defaultValue?: string | number
		required?: boolean
		options: RadioOption[]
		disabled?: boolean
		type?: 'radio' | 'checkbox'
		skipRegister?: boolean
	} 
) => {
	const formContext = skipRegister ? {} as UseFormMethods : useFormContext();
	const { register } = formContext;

	return (
		<FormInputContainer>
			<GroupLabel name={name} label={label} required={required} />
			<GroupSubtitle>{subtitle}</GroupSubtitle>

				{options.map( option => (
					<RadioCheckboxContainer 
						type={type} 
						key={option.id} 
						disabled={disabled}
					>
						<input 
							type={type}
							name={name} 
							id={`${name}_${option.id}`} 
							value={option.id} 
							ref={skipRegister ? null : register({ required: required })} 
							disabled={disabled}
						/>
						<div className="custom-control" />
						<Heading>{option.name}</Heading>
						<FormSubtitle>{option.subtitle}</FormSubtitle>
					</RadioCheckboxContainer>
				) )}
		</FormInputContainer>
	)
}


export const UICheckbox = (	
	{ name, label, subtitle, defaultValue, required, disabled, skipRegister = false }: { 
		name: string
		label?: string
		subtitle?: string
		defaultValue?: boolean
		required?: boolean
		disabled?: boolean
		skipRegister?: boolean
	} 
) => {
	const formContext = skipRegister ? {} as UseFormMethods : useFormContext();
	const { register } = formContext;

	return (
		<FormInputContainer>
			<RadioCheckboxContainer 
				type="checkbox"
				disabled={disabled}
			>
				<input 
					type="checkbox"
					name={name} 
					ref={skipRegister ? null : register({ required: required })} 
					disabled={disabled}
					defaultChecked={defaultValue}
				/>
				<div className="custom-control" />
				<Heading>{label}</Heading>
				<FormSubtitle>{subtitle}</FormSubtitle>
			</RadioCheckboxContainer>
		</FormInputContainer>
	)
}

const GroupLabel = styled(FormLabel)`
	font-size: 1.1em;
`;
const GroupSubtitle = styled(FormSubtitle)`
	margin-bottom: 1rem;
`;

const Heading = styled.div`
	/* font-weight: ${props =>props.theme.font.boldWeight}; */
	margin-bottom: 3px;
`;

// https://codepen.io/KenanYusuf/pen/PZKEKd?editors=1100
export const RadioCheckboxContainer = styled.label<{ type: 'radio' | 'checkbox', disabled: boolean }>`
	display: block;
	position: relative;
	padding-left: 30px;
	padding-right: 20px;
	margin-bottom: 20px;
	cursor: pointer;
	${props => props.disabled && props.theme.presets.inputDisabled}
	color: ${props => props.disabled && props.theme.colors.baseTextLight};
	background-color: transparent; /** counteracts the disabled background color */

 	input {
		position: absolute;
		z-index: -1;
		opacity: 0;
	}
	.custom-control {
		position: absolute;
		top: 2px;
		left: 0;
		height: 16px;
		width: 16px;
		border: 1px solid ${props => props.theme.colors.colorDefinitions.grey4};
		box-sizing: border-box;
		background-color: white;
		:after {
			content: '';
			position: absolute;
			display: none;
		}	
	}
	${props => props.type === 'radio' && css`
		.custom-control {
			border-radius: 50%;
		}
		.custom-control:after {
			left: 4px;
			top: 4px;
			height: 6px;
			width: 6px;
			border-radius: 50%;
			background: #fff;
		}
		input:disabled ~ .custom-control:after {
  			background: #7b7b7b;
		}

	`}
	${props => props.type === 'checkbox' && css`
		.custom-control {
			border-radius: 4px;
		}
		.custom-control:after {
			left: 5px;
			top: 1px;
			width: 3px;
			height: 8px;
			border: solid #fff;
			border-width: 0 2px 2px 0;
			transform: rotate(45deg);
		}
		input:disabled ~ .custom-control:after {
			border-color: #7b7b7b;
		}

	`}
	&:hover input ~ .custom-control,
	& input:focus ~ .custom-control {
		background: ${props => props.theme.colors.primaryLight1};
		border: 1px solid ${props => props.theme.colors.primaryLight2};
	}
	input:checked ~ .custom-control {
		background: ${props => props.theme.colors.primaryLight2};
	}
	&:hover input:not([disabled]):checked ~ .custom-control,
	input:checked:focus ~ .custom-control {
		background: ${props => props.theme.colors.primary};
	}
	input:disabled ~ .custom-control {
		opacity: 0.6;
		pointer-events: none;
		${props => props.theme.presets.inputDisabled}
	}
	input:checked ~ .custom-control:after {
		display: block;
	}
`;