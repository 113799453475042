export interface ColumnDef {
	accessor: string | ((origRow, rowIndex) => any)
	id?: string
	columns?
	Header?: string | ((tableInstance) => any) | JSX.Element | null
	Footer?: string | ((tableInstance) => any) | JSX.Element
	Cell?: ((tableInstance) => any) | JSX.Element | JSX.Element[]
	width?: number
	minWidth?: number
	maxWidth?: number

	filter?: 'fuzzyText' | 'includes' | 'includesAll'
	Filter?: (tableInstance) => JSX.Element
	disableGlobalFilter?: boolean
	className?: string
}

export type ColDefs<TColId> = {
	//@ts-ignore
	[key in TColId]: ColumnDef
}

export function arrayOfAllColumns(object) {
	return Object.entries(object).map(([key, value]) => value)
}