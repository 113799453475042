import { gql } from '@apollo/client';
import { threadAllColumns, messageAllColumns } from '@constants';


export const GET_THREAD = gql`
	query GET_THREAD (
		$id: Int!
	) {
		thread (
			where: { 
				id: { _eq: $id } 
			}
		) {
			${threadAllColumns}
		}
	}
`;

export const GET_MESSAGES_BY_THREAD = gql`
	query GET_MESSAGES_BY_THREAD (
		$thread_id: Int!
	) {
		message (
			where: { 
				thread_id: { _eq: $thread_id } 
			}
		) {
			${messageAllColumns}
		}
	}
`;
