import React from 'react';
import styled from 'styled-components';
import { FlexboxProps, flexboxStyles, PaddingProps, paddingStyles, MarginProps, marginStyles } from '@constants';


type SectionProps = {
	children
	asFlexbox?: boolean
	bottomBorder?: boolean
	scrollable?: boolean
} & FlexboxProps & PaddingProps & MarginProps


const StyledUISection = styled.section<SectionProps>`
	box-sizing: border-box;
	${flexboxStyles}
	${paddingStyles}
	${marginStyles}
	margin-bottom: 2rem;
	border-bottom: ${props => props.bottomBorder && '1px solid lightgrey' || 'none'};
	overflow: ${props => props.scrollable && 'auto'};
`;

export const UISection = (
	props: SectionProps
) => (
	<StyledUISection {...props} />
);

UISection.defaultProps = {
	asFlexbox: false,
	padding: 'medium'
}