import React, { useMemo } from 'react';

import { Table } from 'components/Table/Table';
import { jobCols, transformJobData } from 'components/Table/tableRef';
import { TableActionButton } from 'components/Table/TableActionButton';

import { useQuery } from '@apollo/client';
import { GET_JOBS } from '@constants/gql';

import { Job } from 'types';


const jobTableColumnDef = [
	jobCols.nameNoLink, 
	jobCols.workflow,
	jobCols.createdAt,
	jobCols.updatedAt,
	jobCols.stepCount
];


export function JobDraftTable(	
	{ onSelect = () => {} }: 
{
	onSelect: () => void
}
) {
	const { loading, error: queryJobError, data: queryJobData } = useQuery(GET_JOBS, { 
		variables: { status: ['draft'] },
		fetchPolicy: 'no-cache'
	});

	const columns = useMemo(() => [{
			Header: '',
			id: 'actions',
			className: 'center-align show-on-hover one-line',
			Cell: (table) => {				
				const job: Job = table.row.original;

				return (
					<TableActionButton 
						type="edit"
						routeName="jobDraftById"
						routeVars={{ id: job.id }}
						onClick={onSelect}
					/> 
				)
			}
			//@ts-ignore
		}].concat(jobTableColumnDef)
	, []);

	let data = useMemo(() => loading ? [] : transformJobData(queryJobData.job), [loading, queryJobData]);
	
	if (loading) { return <p>Loading...</p>; }
	else if (queryJobError) { return <p>Error :(</p>; }	
		
	return (
		<Table
			columns={columns}
			data={data}
			hasSortableColumns
			hasFilterableColumns
			hasGlobalFilter
		/>
	)
}