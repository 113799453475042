import { useState, useEffect } from 'react';
import { useDialogStore, dialogStore } from 'store';

import { ModalHookProps } from 'kit';

export interface GlobalDialogData {
	title: string
	description: string
	Icon?: JSX.Element
	supplementalContent?: JSX.Element | JSX.Element[]
	onSubmit?
	showSubmit?: boolean
	showCancel?: boolean
	buttonText?: string
}

export const useDialog = () => {
	
	const globalModalProps: ModalHookProps<GlobalDialogData> = useDialogStore(state => state.modalProps);
	const setShowAlert = useDialogStore(state => state.setShowAlert);

	// These states and useEffect are needed in case an alert is called as soon as the page loads. 
	// Given the nature of states, it is possible that the showAlert function could be called before the modal is ready.
	//
	const [isModalReady, setIsModalReady] = useState(false);
	const [queuedDialogData, setQueuedDialogData] = useState({} as GlobalDialogData);
	
	useEffect(() => {
		if (globalModalProps.setModalData) {
			setIsModalReady(true);
			setShowAlert(showAlert);
			if (queuedDialogData?.description) {
				open(queuedDialogData);
				setQueuedDialogData(null);
			}
		}
		else {
			setIsModalReady(false);
		}
	}, [globalModalProps.setModalData, queuedDialogData?.description]);
	// ^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^

	function open(dialogData: GlobalDialogData){
		setMessageAndOpenModal(globalModalProps, dialogData)
	}
	
	
	// ----------------------------------- Exported functions  ---------------------------------------
	
	const showAlert = isModalReady ? open : setQueuedDialogData;


	return { showAlert };
};

function setMessageAndOpenModal(globalModalProps: ModalHookProps<GlobalDialogData>, dialogData: GlobalDialogData){
	globalModalProps.setModalData(dialogData);
	globalModalProps.openModal();
}


// Use the hook version if possible! This will not work reliably on mount but will work fine if the app has been loaded
export const showAlertDialog = (dialogData: GlobalDialogData) => {
	const globalModalProps = dialogStore.getState().modalProps;
	setMessageAndOpenModal(globalModalProps, dialogData);
}