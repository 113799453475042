import React, { useEffect, useContext } from 'react';
import { UISwitch, UISelect, UIHeading, UIRadioList } from 'kit';
import { useQuery } from '@apollo/client';
import { GET_INVENTORY_UNIQUE_TYPES } from 'services/inventoryUniqueFetch';
import { getObjectFromArray } from 'utility';
import { useFormContext } from 'react-hook-form';
import { WorkflowOptions, InventoryUniqueType } from 'types';
import { WorkflowEditorContext } from './WorkflowEditorContext';


export const WorkflowOptionsInventoryUnique = (
	{ isHidden }: {
	isHidden: boolean
}) => {
	const { workflowOptions, databaseSteps, isAmendment, firstAmendableStep0 } = useContext(WorkflowEditorContext);

	const { watch, setValue, register, unregister } = useFormContext<WorkflowOptions>();
	const watchedOptions = watch();
	const hasInventoryUnique = watchedOptions.inventory_unique_enabled;
	const selectedTypeId = watchedOptions.inventory_unique?.type_id;
	const attachIndex = watchedOptions.inventory_unique?.attach?.step_index;
	const detachIndex = watchedOptions.inventory_unique?.detach?.step_index;

	const wasInvUnEnabledBefore = workflowOptions.inventory_unique_enabled;
	const attachDisabled = wasInvUnEnabledBefore && isAmendment && attachIndex < firstAmendableStep0 + 1;
	const detachDisabled = wasInvUnEnabledBefore && isAmendment && detachIndex < firstAmendableStep0 + 1;

	const { 
		data: { inventory_unique_type: types } = { inventory_unique_type: [] as InventoryUniqueType[] } 
	} = useQuery(GET_INVENTORY_UNIQUE_TYPES);

	const selectedType = getObjectFromArray(types, 'id', selectedTypeId);

	useEffect( () => {
		// Make the workflowOptions object populate with some reasonable default values for indexes (which aren't shown in the form)
		if (hasInventoryUnique) {
			// set value only works with registered components. Since the attach/detach step indexes don't have input components in this form, register them manually so we can set the value
			register('inventory_unique.attach.step_index'); 
			register('inventory_unique.detach.step_index');
			register('inventory_unique.converts');

			setValue('inventory_unique.converts', workflowOptions.inventory_unique?.converts ?? []); // set converts to empty array if it doesn't exist

			const existingAttachIndex = workflowOptions.inventory_unique?.attach?.step_index;
			if (typeof existingAttachIndex !== 'number') {
				setValue('inventory_unique.attach.step_index', 1); 
			}
			const existingDetachIndex = workflowOptions.inventory_unique?.detach?.step_index;
			if (typeof existingDetachIndex !== 'number') {
				setValue('inventory_unique.detach.step_index', Math.max(databaseSteps.length || 1));
			}
		}
		else {
			unregister('inventory_unique.attach.step_index');
			unregister('inventory_unique.detach.step_index');
			unregister('inventory_unique.converts');
		}
		}, [hasInventoryUnique]
	)
	
	return (
	<div style={{ display: isHidden ? 'none' : 'block' }}>
		<UISwitch 
			label="Use Unique Inventory" 
			name="inventory_unique_enabled" 
			default={workflowOptions.inventory_unique_enabled} 
			disabled={attachDisabled}
		/>
		{hasInventoryUnique &&
		<React.Fragment>
			<UISelect 
				name="inventory_unique.type_id" 
				label="Inventory Type" 
				options={types} 
				defaultValue={selectedTypeId} 
				hasConditionalComponents={true} 
				required 
				disabled={attachDisabled}
			/>
			
			<UIHeading level="3">Attach</UIHeading>
			
			<UIRadioList 
				name={`inventory_unique.attach.source`}
				label="Source"
				subtitle={`Choose how the inventory items will be be selected.`}
				options={[
					{ 
						name: 'Manually select from inventory', id: 'choose', 
						subtitle: `` 
					}/*,
					{ 
						lanamebel: 'Convert from raw material', id: 'convert_part', 
						subtitle: `The user selects thob is created, during the job review process. You can provide a del as filter down the list ofs.` 
					}*/
				]}
				required
				disabled={attachDisabled}
			/>


			<UISelect 
				isMulti
				name="inventory_unique.attach.stage_ids" 
				label="Stage" 
				subtitle="Filter eligible stages that can be attached. Leave blank to allow all."
				defaultValue={workflowOptions.inventory_unique?.attach?.stage_ids || []}
				options={selectedType.stages} 
				disabled={attachDisabled}
			/>
		
			<UIHeading level="3">Detach</UIHeading>
			
			<UIRadioList 
				name={`inventory_unique.detach.status`}
				label="Change Status to"
				subtitle={`When the inventory leaves a job, what state the items will change to.`}
				options={invUniqueStatusOptions}
				required
				disabled={detachDisabled}
			/>
		</React.Fragment>}
	</div>);
};

export const invUniqueStatusOptions = [
	{ 
		name: 'Stock', id: 'stock', 
		subtitle: `Items are placed back in usable inventory.` 
	},
	{ 
		name: 'Consumed', id: 'consumed', 
		subtitle: `Items in consumed status cannot be used in jobs.` 
	},
	{ 
		name: 'Shipped', id: 'shipped', 
		subtitle: `Items in shipped status cannot be used in jobs.` 
	},
	{ 
		name: 'Scrapped', id: 'scrapped', 
		subtitle: `Items in scrapped status cannot be used in jobs.` 
	}
];