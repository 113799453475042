import React from 'react';

import { UIChip } from 'kit';

import { StepLinkableThing } from './LinkStepsFromItem';
import { updateStepToDeleteLink } from 'root/services/stepLink';

import { useThingById } from 'hooks';

import { Step } from 'types';


export const LinkChip = <K extends StepLinkableThing, >({ step, linkedThingName, linkedId, originatingType, onDelete = () => {} }: {
	step: Step
	linkedThingName: K
	linkedId: number
	originatingType: 'step' | K
	onDelete?: () => void
}) => {
	const { item } = useThingById(linkedThingName, linkedId);

	return (
		<UIChip 
			label={originatingType === 'step' ? item.name : step.name} 
			outline 
			onDelete={originatingType === 'step' ? updateStepToDeleteLink.bind(this, step, linkedThingName, linkedId, originatingType !== 'step') : onDelete} 
		/>
	);
};
