import { gql } from '@apollo/client';
import { apolloClient, updateCacheAfterInsert } from './apollo';
import { GET_CONTACTS } from './contactFetch';
import { contactAllColumns } from '@constants';
import { ContactInsertObj, ContactUpdateObj } from 'types';


const ADD_CONTACT = gql`
	mutation ADD_CONTACT ( 
		$insertingObjs: [contact_insert_input!]!
	) {
		insert_contact (
			objects: $insertingObjs
		) {
			returning {
				${contactAllColumns}
			}
		}
	}
`;

export function addContact(newContact: ContactInsertObj) {
	apolloClient.mutate({ 
		mutation: ADD_CONTACT,
		variables: {
			insertingObjs: [newContact]
		},
		update(cache, result) {
			updateCacheAfterInsert(cache, result, 'contact', GET_CONTACTS);
		}
	});
}

const UPDATE_CONTACT = gql`
	mutation UPDATE_CONTACT ( 
		$id: Int!
		$changes: contact_set_input!
	) {
		update_contact (
			where: { id: { _eq: $id } }
			_set: $changes
		) {
			returning {
				${contactAllColumns}
			}
		}
	}
`;


export function updateContact(id: number, changes: ContactUpdateObj) {
	apolloClient.mutate({ 
		mutation: UPDATE_CONTACT,
		variables: {
			id,
			changes
		}
	});
}