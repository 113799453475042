import { useQuery } from '@apollo/client';
import { apolloClient } from 'services/apollo';
import { Workflow } from 'types';
import { GET_WORKFLOWS } from './workflowFetch';

export function getWorkflowById(workflowId): Promise<Workflow> {
	return apolloClient.query({
		query: GET_WORKFLOWS,
		variables: {
			id: workflowId
		},
		fetchPolicy: 'network-only'
	}).then((queryResponse) => {
		return queryResponse.data?.workflow?.[0] ?? {} as Workflow;
	});
}

export const useWorkflows = () => {
	const {
		error,
		loading,
		data: { workflow: workflows } = { workflow: [] as Workflow[] }
	} = useQuery(GET_WORKFLOWS);

	return { loading, error, workflows };
};
