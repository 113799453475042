import React from 'react';
import { UIModalSimpleAction, ModalHookProps, UISelect, useUIForm, UIForm2 } from 'kit';

import { invUniqueStatusOptions } from './WorkflowOptionsInventoryUnique';

import { Bomb } from '@styled-icons/boxicons-regular/Bomb';

import { useQuery, gql } from '@apollo/client';
import { apolloClient } from 'root/services/apollo';
import { GET_JOB_WIP } from 'root/services/stepStart';
import { detachInventoryFragment } from 'root/services/stepInventoryUniqueEffects';

import { useTags } from 'hooks';
import { arrayString } from 'utility';
import { jobAllColumns, partWipTransactionAllColumns, stepHistoryYieldAllColumns, stepHistoryAllColumns } from '@constants';
import { Job, InventoryUniqueItem, PartWIP, JobUpdateObj, WorkflowOptions, PartWipTxInsertObject } from 'types';
import { useAuthStore } from 'store';


export const AbortWorkflowModal = (
	{ job = {} as Job, modalProps }: 
	{
		job: Job
		modalProps: ModalHookProps
	}
) => {
	const { formMethods, isDirty } = useUIForm<any>();
	
	const { 
		data: { 
			inventory_unique: attachedItems, 
			part_wip: productWipResult 
		} = { 
			inventory_unique: [] as InventoryUniqueItem[], 
			part_wip: [] as PartWIP[]
		}
	} = useQuery( GET_JOB_WIP, {
		variables: { jobId: job.id },
		skip: !(job.id > 0) || !modalProps.isModalOpen
	} );

	const { isTagsLoading, tags } = useTags('deviation_tag');

	const submit = formMethods.handleSubmit((formData) => {
		const jobObj: JobUpdateObj = {
			job_status: 'aborted', 
			stop_at: new Date(),
			deviation_tags: arrayString(formData.deviation_tags)
		}

		abortJob(job, jobObj, attachedItems, formData.invUnStatus, productWipResult);
	})
	
	return (
		<UIModalSimpleAction 
			{...modalProps} 
			Icon={<Bomb />}
			title="Abort Job?"
			buttonText="Abort"
			onSubmit={submit}
			showCancel
			supplementalContent={
				<>
					<p>Stop the job now? Remaining steps will not be executed.</p>

					<ul>
						<p>If the current step is in progress:</p>
						<li>The step will be stopped without any additional data entry.</li>
						<li>Raw part transactions will not occur</li>
						{productWipResult.length > 0 && <li>Yield for the current step will be recoreded as zero</li>}
						{productWipResult.length > 0 && <li>Product WIP will be zeroed out</li>}
						{productWipResult.length > 0 && <li>Any WIP reservations associated with an order will be zeroed out</li>}
						{attachedItems.length > 0 && <li>Any attached items will be detached from the job and returned to stock.</li>}
						{attachedItems.length > 0 && <li>Stage conversion will not take place</li>}
					</ul>

					<p> If any of the above is an issue, go back and stop the step first before aborting the job. </p>
					<br />
					<UIForm2 formMethods={formMethods} margin="largeVertical">
						<UISelect
							isMulti
							name="deviation_tags" label="Deviation Tags"
							subtitle="Optionally, set deviation tags for this job."
							defaultValue={job.deviation_tags}
							options={tags}
							isLoading={isTagsLoading}
						/>

						{attachedItems.length > 0 &&
							<UISelect
								name="invUnStatus" label="Item Status Change"
								subtitle="Optionally, change status for currently attached items."
								defaultValue={void 0}
								options={invUniqueStatusOptions}
							/>
						}
					</UIForm2>
				</>
			}
			clickOutsideToClose={!isDirty}
		/>
	);
};

const abortJob = (
	job: Job, 
	changes: JobUpdateObj, 
	attachedItems: InventoryUniqueItem[], 
	forceDetachStatus: WorkflowOptions['inventory_unique']['detach']['status'], 
	partsInWip: PartWIP[]
) => {
	const wipTxs: PartWipTxInsertObject[] = partsInWip.map( partWip => {
		if (partWip.type === 'wip')	{
			return {
				wip_type: 'wip',
				type: 'job_abort',
				part_id: partWip.part_id, 
				qty_change: (partWip.qty * -1),
				job_id: job.id,
				order_item_id: partWip.order_item_id
			} as PartWipTxInsertObject
		}
		else if (partWip.type === 'reserve') {
			return {
				wip_type: 'reserve',
				type: 'reservation_aborted',
				part_id: partWip.part_id, 
				qty_change: (partWip.qty * -1),
				job_id: job.id,
				order_item_id: partWip.order_item_id
			} as PartWipTxInsertObject
		}
	});

	const hasPartWipTx = wipTxs.length > 0;
	
	const ABORT_JOB = gql`
		mutation ABORT_JOB ( 
			$id: Int!
			$job_step_index: Int!
			$changes: job_set_input!
			${hasPartWipTx 	? '$wipTxs: [part_wip_tx_insert_input!]!' : ''}
		) {
			# Change relevant job columns
			update_job (
				where: { id: { _eq: $id } },
				_set: $changes
			) {
				returning {
					${jobAllColumns}
				}
			}

			# Detach items
			${detachInventoryFragment(forceDetachStatus, attachedItems)}

			# Update product wip
			${hasPartWipTx ? `
				insert_part_wip_tx (
					objects: $wipTxs
				) {
					returning {
						${partWipTransactionAllColumns}
					}
				}` : ''}

			# Stop the step, if it isn't already stopped
			update_step_history_yield (
				where: {
					step_history: {
						job_id: { _eq: $id}, 
						job_step_index: {_eq: $job_step_index }, 
						start_at: { _is_null: false }
						stop_at: { _is_null: true }
					}
				}
				_set: { qty_out: 0 }, 
			) {
				returning {
					${stepHistoryYieldAllColumns}
				}
			}

			update_step_history (
				where: {
					job_id: { _eq: $id}, 
					job_step_index: {_eq: $job_step_index }, 
					start_at: { _is_null: false }
					stop_at: { _is_null: true }
				}
				_set: {
					stop_at: "now()", 
					stop_user_id: "${useAuthStore.getState().internalUser.id}"
				}
			){
				returning {
					${stepHistoryAllColumns}
				}
			}

  			


		}
	`;

	let variables: any = { id: job.id, changes, job_step_index: job.current_step_index };
	if (hasPartWipTx) {
		variables.wipTxs = wipTxs;
	}

	return apolloClient.mutate({ 
		mutation: ABORT_JOB, 
		variables,
		refetchQueries: [
			{ query: GET_JOB_WIP, variables: { jobId: job.id } } //refetch for wip updates
		], 
		awaitRefetchQueries: false
	}).then( mutationData => {
		return true;
	}).catch( error => {
		return false
	});
}