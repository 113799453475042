import React, { useMemo } from 'react';

import { UIHeading, UIFlexbox, UICard } from 'kit';

import { Table } from 'components/Table/Table';
import { arrayOfAllColumns, transformStepHistoryData, stepHistoryCols } from 'components/Table/tableRef';

import { useQuery } from '@apollo/client';
import { GET_STEP_HISTORY_BY_ID } from 'services/stepHistoryFetch';

import { StepHistoryRaw } from 'types';
import { durationString } from 'utility';
import { useThingById } from 'hooks';


const stepColumnDefs = arrayOfAllColumns(stepHistoryCols);


export const StepTxTable = (
	{
		savedStepId
	}:
	{
		savedStepId: number
	}
) => {
	let totalDuration = 0


	const { item: step } = useThingById('step', savedStepId);

	const {
		loading,
		error,
		data: { step_history: stepHistoryList } = { step_history: [] as Array<StepHistoryRaw> }
	} = useQuery(GET_STEP_HISTORY_BY_ID, { 
		variables: { step_id: savedStepId },
		skip: !(savedStepId > 0)
	});

	const columns = useMemo(() => stepColumnDefs, []);
	let data = useMemo(() => {

		const stepHistoryDataWithExtras = transformStepHistoryData(stepHistoryList);
		totalDuration = stepHistoryDataWithExtras.reduce((a, b) => a + b.duration, 0);
		return stepHistoryDataWithExtras;
	}, [loading, stepHistoryList]);

	if (loading) { return <p>Loading...</p>; }
	else if (error) { return <p>Error :(</p>; }

	return (
		<React.Fragment>

			<UIFlexbox flexJustify="space-between" flexWrap="wrap" margin="none">
				<UIHeading>{step.name}</UIHeading>
				<UICard
					title={'Records: ' + stepHistoryList.length}
					margin="none"
				>					
					Avg Duration: {durationString(totalDuration / stepHistoryList.length)}
				</UICard>
			</UIFlexbox>

			<Table
				columns={columns}
				data={data}
				hasSortableColumns
				hasGlobalFilter
				hasPagination
				hasSelectablePageSize
				hasFilterableColumns
			/>

		</React.Fragment>
	);
}
