import React from 'react';
import styled from 'styled-components';

import { UIFlexbox, UIFlexChild, UIHeading } from 'kit';

import { RoutedButton } from 'routing/RoutedButton'; // Have to import from the file (not the module) to avoid circ dep


export const VerticalMenu = (
	{ menuButtons, footerContent, className, ...props }: {
		menuButtons	
		footerContent?		
		className?: string
	}
) => {
	return (
		<VerticalMenuContainer flexDirection="column" className={className} {...props}>
			<NavLinkContainer>
				{menuButtons}
			</NavLinkContainer>

			<MenuFooter>
				{footerContent}
			</MenuFooter>
		</VerticalMenuContainer>
	)
}


const NavLinkContainer = styled(UIFlexChild)`
	width: 100%;
	padding: 20px 0px;
	@media ${props => props.theme.mediaQuery.mobile} {
		line-height: 3em;
	}	
`;

const MenuFooter = styled.div`
	text-align: center;
	word-break: break-word;
`;

const VerticalMenuContainer = styled(UIFlexbox)`
	height: 100%;
	overflow: auto;
	z-index: 100;
	position: relative;
	${props => props.theme.scrollbar.light}
	border-right: 1px solid ${props => props.theme.colors.colorDefinitions.grey3};
	box-sizing: border-box;
`;

export const VerticalMenuButton = styled(RoutedButton).withConfig({
	// Material UI emits a console error with props that are not native to it. Filter out the props that we are using for our style overrides
	shouldForwardProp: (prop) => ![].includes(prop)
}).attrs( props => ({
	full: true,
	quiet: true
}))`
	&.link-active {
		color: ${props => props.theme.colors.primary};
		background-color: ${props => props.theme.colors.primaryLightAlpha};
	}
	
	font-size: 1.1em;

	/* padding: 7px 7px 7px 30px; */

	&& svg {
		margin-right: 10px;
	}
	justify-content: flex-start;
	margin-bottom: 0;
`;

export const VerticalMenuHeading = styled(UIHeading).attrs( props => ({
	level: '3'
}))`
	/* color: ${props => props.theme.colors.primaryLight2}; */
	padding: 10px;
	padding-left: 30px;

	border-radius: 0;
	margin-bottom: 0;

	&:not(:first-child) {
		border-top: 1px solid grey;
		padding-top: 30px;
		margin-top: 20px;
	}

	svg {
		height: 20px;
		width: 20px;
		margin-right: 8px !important;
	}
`;