import { gql } from '@apollo/client';
import { apolloClient, updateCacheAfterInsert } from 'services/apollo';
import { GET_MESSAGES_BY_THREAD } from 'services/messageFetch';

import { messageAllColumns, thingLookup, ThingName } from '@constants';
import { getDetailViewRefetchQuery } from './thingHelper';

export const ADD_MESSAGE_NEW_THREAD = gql`
	mutation ADD_MESSAGE_NEW_THREAD ( 
		$text: String!
		$foreign_table: smallint!
		$reference_id: Int!
	) {
		insert_message (
			objects: {
				text: $text
				thread: {
					data: {
						foreign_table: $foreign_table 
						foreign_id: $reference_id
					}
				}
			}
		) {
			returning {
				${messageAllColumns}
			}
		}
	}
`;

export const ADD_MESSAGE_EXISTING_THREAD = gql`
	mutation ADD_MESSAGE_EXISTING_THREAD ( 
		$text: String!
		$thread_id: Int!
	) {
		insert_message (
			objects: {
				text: $text
				thread_id: $thread_id
			}
		) {
			returning {
				${messageAllColumns}
			}
		}
	}
`;

export const addMessage = (
	text: string, 
	isNewThread: boolean,
	referenceId: number,
	referenceTable?: ThingName,
	threadId?: number
) => {
	if (isNewThread) {
		const refetchQueries = getDetailViewRefetchQuery(thingLookup[referenceTable].sharedTableId, referenceId);

		return apolloClient.mutate({ 
			mutation: ADD_MESSAGE_NEW_THREAD, 
			variables: { 
				text: text,
				foreign_table: thingLookup[referenceTable].sharedTableId,
				reference_id: referenceId
			},
			refetchQueries: refetchQueries,
			awaitRefetchQueries: true
		}).then( (queryResponse) => {
			return true;
		}).catch( () => {
			return false;
		});
	}
	//else
	return apolloClient.mutate({ 
		mutation: ADD_MESSAGE_EXISTING_THREAD, 
		variables: { 
			text: text,
			thread_id: threadId
		},
		update(cache, result) {
			updateCacheAfterInsert(cache, result, 'message', GET_MESSAGES_BY_THREAD, { thread_id: threadId } );
		}
	}).then( (queryResponse) => {
		return true;
	}).catch( () => {
		return false;
	});
};
