
import { useQuery } from '@apollo/client';

import { thingLookup, ThingName, ThingTypes, thingQueryLookupById, thingQueryLookupByIds } from '@constants';


export const useThingById = <K extends ThingName>(
	thingName: K, 
	id: number,
	options?: { 
		skip?: boolean 
	}): 
{ 
	isLoading: boolean, 
	item: ThingTypes[K] 
} => {
	const { skip = false } = options || {};


	const { tableName } = thingLookup[thingName];
	const GET_QUERY = thingQueryLookupById[thingName];

	const isParamValid = typeof id === 'number' && id > 0;

	const {
		loading,
		data: { [tableName]: [item = {}] } = { [tableName]: [] }
	} = useQuery(GET_QUERY, { 
		variables: { id },
		skip: !isParamValid || skip
	});
	
	
	const returnValues =  {
		isLoading: loading,
		item
	};

	return returnValues;
};

export const useThingsIn = <K extends ThingName>(
	thingName: K, 
	ids: number[],
	options?: { 
		skip?: boolean 
	}
): 
{ 
	isLoading: boolean, 
	items: Array<ThingTypes[K]>
} => {

	const { skip = false } = options || {};
	const { tableName } = thingLookup[thingName];
	const GET_QUERY_IN = thingQueryLookupByIds[thingName];

	const isParamValid = Array.isArray(ids) && ids.length > 0;

	const {
		loading,
		data: { [tableName]: items } = { [tableName]: [] }
	} = useQuery(GET_QUERY_IN, { 
		variables: { ids },
		skip: !isParamValid || skip
	});
	
	const returnValues =  {
		isLoading: loading,
		items
	};

	return returnValues;
};


export const useThingsAll = <K extends ThingName>(
	thingName: K, 
	options?: { 
		skip?: boolean 
		vars?
	}
): 
{ 
	isLoading: boolean, 
	items: Array<ThingTypes[K]>
} => {
	const { skip = false, vars = {} } = options || {};

	const { tableName } = thingLookup[thingName];
	const GET_QUERY = thingQueryLookupById[thingName];

	const {
		loading,
		data: { [tableName]: items } = { [tableName]: [] }
	} = useQuery(GET_QUERY, { skip, variables: vars });
	
	const returnValues =  {
		isLoading: loading,
		items
	};

	return returnValues;
};