import React, { useState, useEffect, useContext } from 'react';
import { useModal, UIModalForm } from 'kit';

import { WorkflowOptions, currentWorkflowOptionsVersion } from 'types';

import { InventoryUniqueDisplayCard, ProductDisplayCard } from './WorkflowOptionsDisplayCard';

import { WorkflowEditorContext } from './WorkflowEditorContext';
import { WorkflowOptionsProduct } from './WorkflowOptionsProduct';
import { updateWorkflowOptionsToCurrentVersion } from './workflowOptionsVersion';
import { WorkflowOptionsInventoryUnique } from './WorkflowOptionsInventoryUnique';

import { validateWorkflowOptionsSchema } from 'services/validateSchema';
import { thingLookup, ThingDef } from '@constants';


export const WorkflowOptionsCardModal = ( props: {
} ) => {
	const { type, id, workflowOptions, updateWorkflowOptions, databaseSteps, validateInvUnique } = useContext(WorkflowEditorContext);
	const modalProps = useModal();
	const [currentlyEditing, setCurrentlyEditing] = useState<keyof Pick<ThingDef, 'inventory_unique' | 'partProduct'>>();
	
	useEffect(() => {
		// Update the workflow options to the latest version, if needed.
		if (workflowOptions?.version && workflowOptions.version !== currentWorkflowOptionsVersion) {
			const updatedOptions = updateWorkflowOptionsToCurrentVersion(workflowOptions);
			updateWorkflowOptions(updatedOptions);
		}
	}, [workflowOptions?.version]);

	function openModalWithInventoryUnique(){
		setCurrentlyEditing('inventory_unique');
		modalProps.toggleModal();
	}
	function openModalWithProduct(){
		setCurrentlyEditing('partProduct');
		modalProps.toggleModal();
	}

	function submit(formData){
		const { ...all } = formData;
		let newWorkflowOptions: WorkflowOptions = all;
		if (!newWorkflowOptions.version) { newWorkflowOptions.version = currentWorkflowOptionsVersion; }

		// validate
		const isSchemaValid = validateWorkflowOptionsSchema(type, id, newWorkflowOptions);
		if (isSchemaValid) {
			updateWorkflowOptions(newWorkflowOptions);
			validateInvUnique(databaseSteps, newWorkflowOptions);
			modalProps.toggleModal();
		}
	}
	
	return (
		<React.Fragment>
			<InventoryUniqueDisplayCard workflowOptions={workflowOptions} openModal={openModalWithInventoryUnique} />
			<ProductDisplayCard workflowOptions={workflowOptions} openModal={openModalWithProduct} />

			<UIModalForm 
				{...modalProps} 
				onSubmit={submit} 
				defaultValues={workflowOptions} 
				width="small"
				HeaderCenter={thingLookup[currentlyEditing]?.nameSingle}
			>
				{/* Render the whold form, just hide it if it's not currently being edited. This is so the whole options object can be maintained in the form */}
				<WorkflowOptionsInventoryUnique 
					isHidden={currentlyEditing !== 'inventory_unique'}
				/>
				<WorkflowOptionsProduct 
					isHidden={currentlyEditing !== 'partProduct'}
				/>
			</UIModalForm>
		</React.Fragment>
	);
}



