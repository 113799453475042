import React, { useContext } from 'react';
import styled from 'styled-components';

import { UISubtitle } from 'kit';
import { PanelContext } from './PanelState';
import { VerticalMenu, VerticalMenuButton } from './VerticalMenu';

import { MainRouteName, FullRouteName, LinkVars } from 'routing';
import { isMobileScreen } from 'utility';


export const LeftPanel = styled(VerticalMenu)`
	background-color: #f5f5f5;
`;

export const LeftPanelListItem = ({
	labelComponent, linkToRoute, linkToVars
}: {
	labelComponent: JSX.Element
	linkToRoute: MainRouteName | FullRouteName
	linkToVars: LinkVars
}) => {

	const { closeLeftPanel } = useContext(PanelContext);
	
	return (
		<CustomMenuButton 
			label={labelComponent}
			linkToRoute={linkToRoute}
			linkToVars={linkToVars}
			onClick={isMobileScreen() ? closeLeftPanel :  void 0}
		/>
	)
}


const CustomMenuButton = styled(VerticalMenuButton)`
	font-weight: 400;
	font-size: 1rem;
	${UISubtitle} {
		font-size: 0.9rem;
	}

	padding-top: 10px;
	padding-bottom: 10px;
	padding-right: 36px;

	&.link-active {
		:after {
			position: absolute;
			right: 16px;
			color: ${props => props.theme.colors.primary};
			content: '●';
			font-size: 24px;
		}
	}
`;
