import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';

import { UIForm2, useUIForm, UIHeading, UIKeyValue, SaveButtonBlock, UIInput, UITextArea, UIFlexbox, TopBar, BalanceBar, BottomBar, UITabBody, useTabs, DrawerHookContext, UIDrawer } from 'kit';

import { StepFormCategory, stepFormCategoryLookup } from '@constants';
import { StepFormTabs } from './StepFormTabs';
import { StepFormMain } from './StepFormMain';

import { ParameterSetup } from 'components/StepSetup/ParameterSetup';
import { StepFormPartRaw } from 'components/StepSetup/StepFormPartRaw';
import { FormFieldSelection } from 'components/StepSetup/StepFormInventoryUniqueFields';
import { AttachFormToStep } from './FormAttach';

import { stripStepDefinition } from 'services/stepHelper';

import { StepPlan, WorkflowDatabaseStep } from 'types';
import { toast } from 'react-toastify';
import { WorkflowEditorContext, staleLinkCheckStep, WorkflowEditHookProps } from '../WorkflowEditor';
import { useThingById } from 'hooks';
import { StepFormContext } from './StepFormContext';


export const StepFormInDrawer = (
	{ drawerContextForm }: {
		drawerContextForm: DrawerHookContext
	}
) => {
	const [isDirty, setIsDirty] = useState();
	const { updatePlan, databaseSteps, selectedIndex } = useContext(WorkflowEditorContext);

	const MemoStepForm = React.useMemo(() => (
		<StepForm
			key={'stepform_' + selectedIndex + '_' + databaseSteps.length}
			index0={selectedIndex}
			onSave={drawerContextForm.closeDrawer}
			onDirtyChange={setIsDirty}
			onCancel={drawerContextForm.closeDrawer}
			updatePlan={updatePlan}
			dbStep={databaseSteps[selectedIndex]}
		/>
	), [selectedIndex])

	return (
		<UIDrawer anchorFrom="right" {...drawerContextForm} padding="none" isFormDirty={isDirty}>
			{MemoStepForm}
		</UIDrawer>
	)
}


export type SectionState = {
	[key in StepFormCategory]: boolean
}

export interface ValidationErrors {
	hasBrokenLink: boolean,
	linkErrors?: {
		[key in StepFormCategory]?: {
			message: string
			invalidLinkId: number
			action?: 'remove'
		}[]
	}
}

const StepForm = (	
	{ onSave = () => {}, index0, onCancel, allowInfoEditing, onDirtyChange, updatePlan, dbStep }: {
		index0: number
		onSave //opening and closing the drawer
		onCancel  //opening and closing the drawer
		allowInfoEditing?: boolean
		onDirtyChange
		updatePlan: WorkflowEditHookProps['updatePlan']
		dbStep: WorkflowDatabaseStep
	}
) => {
	const { step_id: stepId, plan: initialStepPlan = {} as StepPlan } = dbStep;

	const { item: step } = useThingById('step', stepId);
	const tabContext = useTabs(); 
	const { formMethods } = useUIForm<any>(initialStepPlan, { onDirtyChange: onDirtyChange });

	// State (mostly for tabs)
	const [sectionIsTracked, setSectionIsTracked] = useState<SectionState>({} as SectionState);
	const [sectionIsFormErrored, setSectionIsFormErrored] = useState<SectionState>({} as SectionState);
	const [validationErrors, setValidationErrors] = useState<ValidationErrors>({} as ValidationErrors);

	// Helper functions for state
	function updateSectionStateObject(type: 'isTracked' | 'isFormErrored', checkAgainstObj) {
		let newObj = {} as SectionState;
		let catName: StepFormCategory; // Need to cast the type explicitly here because typescript won't automatically identify it in the for in syntax
		for (catName in stepFormCategoryLookup) {
			if (!stepFormCategoryLookup.hasOwnProperty(catName)) { return; }

			let newValue;
			if (type === 'isTracked') {
				newValue = checkFormIsTrackedForCat(catName);
			}
			else if (type === 'isFormErrored') {
				newValue = checkFormErrorsForCat(checkAgainstObj, catName);
			}

			newObj[catName] = newValue;
		}

		if (type === 'isTracked') {
			setSectionIsTracked(newObj);
		}
		else if (type === 'isFormErrored') {
			setSectionIsFormErrored(newObj);
		}
	}

	function checkFormIsTrackedForCat(catName: StepFormCategory): boolean {
		return catName === 'overview' || initialStepPlan.hasOwnProperty(catName);
	}
	function checkFormErrorsForCat(errors = {}, catName: StepFormCategory): boolean {
		return errors.hasOwnProperty(catName);
	}


	// Initialize state
	useEffect(() => {
		// initialize isTracked
		updateSectionStateObject('isTracked', {});
		// initialize validationErrors
		if (step.id) {
			setValidationErrors(staleLinkCheckStep(initialStepPlan, step));
		}
	}, [JSON.stringify(initialStepPlan), step]) // initialize


	// handle state in reponse to changes
	function validateForm(){
		formMethods.trigger().then(success => {
			updateSectionStateObject('isFormErrored', formMethods.errors);
		})
	}

	useEffect(() => {
		validateForm();
	}, [tabContext.tabIndex])


	function handleFormChange(formData){
		if (validationErrors.hasBrokenLink) {
			setValidationErrors(staleLinkCheckStep(formData, step));
		}
		if (Object.keys(formMethods.errors).length > 0) {
			validateForm();
		}
	}


	/** Have to handle submit manually because the save button resides outside of the form*/
	const customHandleSubmit = formMethods.handleSubmit(() => {
		formMethods.trigger().then(success => {
			updateSectionStateObject('isFormErrored', formMethods.errors);
			if (success) {
				submitStep(formMethods.getValues());
				onSave();
			}
			else {
				toast.error('Step was not saved - please correct validation errors before trying again.', {
					position: toast.POSITION.BOTTOM_CENTER
				});
			}
		})
	})

	function submitStep(formData) {
		const { name, description, ...stepPlanFields } = formData;
		
		const newStepPlan = stripStepDefinition(stepPlanFields);

		// Make sure any categories that were switched to untracked are plucked out of the object. 
		// This used to happen automatically on umount of components but I think this isn't hanppening anymore because the form comopnents are passed in as props
		
		let catName: StepFormCategory; // Need to cast the type explicitly here because typescript won't automatically identify it in the for in syntax
		for (catName in sectionIsTracked) {
			if (Object.prototype.hasOwnProperty.call(sectionIsTracked, catName)) {
				const dState = sectionIsTracked[catName];
				if (!dState) {
					delete newStepPlan[catName];
				}
			}
		}
		
		//TODO - validate
		// const isValid = validateStepDefinitionSchema(stepInfo.rev_id, newStepPlan);
		// if (!isValid) { return }
		
		updatePlan(newStepPlan, index0);
		
	}

	return (
		<StepFormContext.Provider value={{
			step,
			stepPlan: initialStepPlan,
			sectionIsTracked,
			sectionIsFormErrored,
			validationErrors,
			tabContext,
			setSectionIsTracked
		}}>
		<TopBar><UIHeading noMarginBottom>{step.name}</UIHeading></TopBar>
		<BalanceBar hasBottomBar hasTopBar>
			<UIFlexbox width="fit-content" height="100%">

			<StepFormTabs />
			
			<StyledForm
				key={stepId}
				formMethods={formMethods}
				onChange={handleFormChange}
			>
				<UITabBody bodyIndex={0} keepMounted tabContext={tabContext} >
					<StepFormMain
						catName="overview"
						formComponents={allowInfoEditing ? 
							<React.Fragment>
								<UIInput name="name" label="Step Name" required />
								<UITextArea name="description" label="Step Description" />
							</React.Fragment>
							:
							<React.Fragment>
								<UIKeyValue title="Name" text={step.name} />
								<UIKeyValue title="Description" text={step.description} line />
							</React.Fragment>}
						isTabActive={tabContext.tabIndex === 0}
					/>
				</UITabBody>
				<UITabBody bodyIndex={1} keepMounted tabContext={tabContext} >
					<StepFormMain
						catName="tool"
						formComponents={<ParameterSetup parameterName="tool" stepPlan={initialStepPlan} stepId={stepId} />}
						isTabActive={tabContext.tabIndex === 1}
					/>
				</UITabBody>
				<UITabBody bodyIndex={2} keepMounted tabContext={tabContext} >
					<StepFormMain
						catName="recipe"
						formComponents={<ParameterSetup parameterName="recipe" stepPlan={initialStepPlan} stepId={stepId} />}
						isTabActive={tabContext.tabIndex === 2}
					/>
				</UITabBody>
				<UITabBody bodyIndex={3} keepMounted tabContext={tabContext} >
					<StepFormMain
						catName="inventory_effect"
						formComponents={<StepFormPartRaw />}
						isTabActive={tabContext.tabIndex === 3}
					/>
				</UITabBody>
				<UITabBody bodyIndex={4} keepMounted tabContext={tabContext} >
					<StepFormMain
						catName="forms"
						formComponents={<AttachFormToStep existingForms={initialStepPlan.forms} />}
						isTabActive={tabContext.tabIndex === 4}
					/>
				</UITabBody>
				<UITabBody bodyIndex={5} keepMounted tabContext={tabContext} >
					<StepFormMain
						catName="inventory_unique_form"
						formComponents={<FormFieldSelection />}
						isTabActive={tabContext.tabIndex === 5}
					/>
				</UITabBody>
				
			</StyledForm>
		</UIFlexbox>
		</BalanceBar>
		
		<BottomBar flexJustify="space-between">
			<SaveButtonBlock onSave={customHandleSubmit} isTypeSubmit={false} onCancel={onCancel} />
		</BottomBar>
		</StepFormContext.Provider>
	)
}

const StyledForm = styled(UIForm2)`
	width: 500px;
	@media (max-width: 500px) {
		width: 100%;
	}
	.ReactCollapse--collapse {
		height: 100%;
		transition: none;
	}
	padding: 40px;
	overflow: auto;
	${props => props.theme.scrollbar.light}
	position: relative; /**Need this for absolute positioned child elements */
	box-sizing: border-box;
`;