import React, { useMemo } from 'react';
import styled from 'styled-components';

import { UIPage, useModal, UIModal, useUIForm, UIForm2, UIInput, UITextArea, SaveButtonBlock, UIHeading } from 'kit';


import { NavbarPortal } from 'components/Navigation/NavbarPortal';
import { AddNewItemButton } from 'components/AddNewItemButton';

import { Table } from 'components/Table/Table';

import { useThingsAll } from 'hooks';
import { arrayOfAllColumns, stepCols } from 'root/components/Table/tableRef';
import { StepInsertObject } from 'types';
import { addThing } from 'root/services/thingMutate';

export function SavedStepsList() {
	const { items: steps } = useThingsAll('step');

	const columns = useMemo(() => savedStepColumnDefs, [steps.length]);
	const data = useMemo(() => steps, [steps.length]);

	return (
		<UIPage id="steps" contentContainerSize="large">
			<NavbarPortal>
				<AddStepShortcutModal />	
			</NavbarPortal>

			<Table
				columns={columns}
				data={data}
				hasSortableColumns
				hasFilterableColumns
				hasGlobalFilter
				hasPagination
				hasSelectablePageSize
			/>	
		</UIPage>
	)
}


const savedStepColumnDefs = arrayOfAllColumns(stepCols);

export const AddStepShortcutModal = (
	{ savedObj, overrideLabel }: {
		savedObj?: Omit<StepInsertObject, 'is_saved'>
		overrideLabel?: string
	}
) => {
	const modalProps = useModal();
	const { formMethods, isDirty } = useUIForm(savedObj, { resetTrigger: modalProps.isModalOpen });

	const submit = formData => {
		const addObj: StepInsertObject = {
			...formData,
			is_saved: true
		}
		addThing('step', addObj).then(thingMutateResp => {
			modalProps.toggleModal();
		});
	}

	return (
		<React.Fragment>
			<StyledAddNewItemButton type="step" onClick={modalProps.toggleModal} overrideLabel={overrideLabel} />		
			
			<UIModal {...modalProps} isFormDirty={isDirty} >
				<UIHeading>Add New Step</UIHeading>
				
				<UIForm2 formMethods={formMethods} onSubmit={submit}>

					<UIInput name="name" label="Name" required />
					
					<UITextArea name="description" label="Description" />

					<SaveButtonBlock onCancel={modalProps.toggleModal} />
				</UIForm2>
			</UIModal>
		</React.Fragment>
	)
}



const StyledAddNewItemButton = styled(AddNewItemButton)`
	margin: 20px;
`;