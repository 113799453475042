import React from 'react';
import styled from 'styled-components';

import { UIFlexbox, UIFlexChild, UICircleButton } from 'kit';
import { Message } from 'types';
import { dateFormat, newLineToBr } from 'utility';
import { userInitials } from 'services/userHelper';


const MessageContainer = styled(UIFlexbox)`
	padding: 10px;
	:hover {
		background-color: ${props => props.theme.colors.colorDefinitions.grey2};
	}
	cursor: default;
	margin-bottom: 1rem;
`;

const MessageContent = styled(UIFlexChild)`
	flex-shrink: 0;
	margin-left: 20px;
	border-radius: 5px;
`;


export const MessageComponent = (
	props: {		
		message: Message
	}
) => {
	return (		
		<MessageContainer>
			<UICircleButton label={userInitials(props.message.user)} margin="none" />
			<MessageContent>
				<MessageHeader message={props.message} />
				<p>
					{newLineToBr(props.message.text)}
				</p>
			</MessageContent>
		</MessageContainer>
    );
}

const MessageAuthor = styled.span`
	font-weight: ${props =>props.theme.font.boldWeight};
	font-size: 0.9em;
	font-family: ${props => props.theme.font.title};
	margin-bottom: 0.2em;
`;
const MessageTime = styled.span`
	margin-left: 10px;
	color: grey;
	font-size: 0.9em;
`;


const MessageHeader = (
	props: {	
		message: Message	
	}
) => {
	return (		
		<div>
			<MessageAuthor>{props.message.user.first_name} {props.message.user.last_name}</MessageAuthor>
			<MessageTime>{dateFormat(props.message.created_at, 'dateTimeSlash')}</MessageTime>
		</div>
    );
}