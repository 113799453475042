import React from 'react';
import styled from 'styled-components';

import { UIIconButton, UITooltipWrapper } from 'kit';
import { Detail } from '@styled-icons/boxicons-regular/Detail';
import { TargetLock } from '@styled-icons/boxicons-regular/TargetLock';
import { Edit } from '@styled-icons/boxicons-regular/Edit';
import { AddToQueue } from '@styled-icons/boxicons-regular/AddToQueue';
import { Calculator } from '@styled-icons/icomoon/Calculator';
import { MainRouteName, FullRouteName, RoutedIconButton } from 'routing';


export const TableActionButton = (
	{ type = 'view', routeName, routeVars = {}, onClick = () => {} }
: {
	type: 'view' | 'currentStep' | 'edit' | 'add' | 'adjustQty'

	routeName?: MainRouteName | FullRouteName
	routeVars?: { id?: number, index?: number } 

	onClick?: () => void
}) => {
	let icon = <Detail />;
	let color: IconColors;
	let toolTipText = 'View Details';

	switch (type) {
		case 'currentStep':
			icon = <TargetLock />;
			color = 'green';
			toolTipText = 'Go To Current Step';
			break;
		case 'edit':
			icon = <Edit />;
			toolTipText = 'Edit';
			break;
		case 'add':
			icon = <AddToQueue />;
			color = 'green';
			toolTipText = 'Add';
			break;
		case 'adjustQty':
			icon = <Calculator />;
			color = 'green';
			toolTipText = 'Adjust Quantity';
			break;
		default:
			break;
	}

	return (
	<UITooltipWrapper tooltipContent={toolTipText} delayOnEntry useRefHelper>
		<IconActionButton 
			icon={icon} color={color} onClick={onClick}

			as={typeof routeName === 'string' ? RoutedIconButton : UIIconButton}
			linkToRoute={routeName}
			linkToVars={routeVars}
		/>
	</UITooltipWrapper>
	);
};


type IconColors = 'green' | null;


const IconActionButton = styled.div.withConfig({
	// Material UI emits a console error with props that are not native to it. Filter out the props that we are using for our style overrides
	shouldForwardProp: (prop) => !['color'].includes(prop)
})<{ color: IconColors, linkToRoute, linkToVars }>`
	color: ${props => props.theme.colors.primary};
	color: ${props => props.color === 'green' && props.theme.colors.success};
	svg {
		height: 24px; 
		width: 24px;
	}
	text-align: center;
`;