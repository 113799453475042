import React from 'react';

import { UIPage, UIHeading, UIButton, UIFlexbox, UIFlexChild } from 'kit';

import { activateJob } from 'services/jobCreate';
import { isReviewInputRequired, reviewJobWorkflow } from 'components/WorkflowEditor';

import { JobReviewStepForm } from 'components/Workflows/JobReviewStepForm';
import { ProductSelection } from 'components/Jobs/JobProductSelect';
import { StepCard } from 'components/WorkflowEditor/StepCard';

import { useWorkflowEdit } from 'components/WorkflowEditor';

import { useNavMain } from 'routing';
import { useHydrateStepFromJob } from 'services/stepHydrate';


export const JobReview = (	
) => {	
	const navigateToJobById = useNavMain('jobById');

	const { 
		databaseSteps, workflowOptions, updateProductSelections, 
		selectedIndex, setSelectedIndex, 
		saveToDB, updateSuppPartial,
		formMethods, id: jobId
	} = useWorkflowEdit();

	const { getWorkflowStepFull } = useHydrateStepFromJob(jobId);


	const { areAllStepsComplete, needReviewArray } = reviewJobWorkflow(databaseSteps);

	const activate = formMethods.handleSubmit(() => { //do this to get past useLeavePage fron useWorkflowEdit
		if (!areAllStepsComplete) { return; }

		activateJob(jobId, databaseSteps, workflowOptions).then( (success) => {
			if (success) {
				navigateToJobById(jobId);
			}
		});
	})

	function selectNextInvalidStep(){
		const startIndex = (selectedIndex === null ? -1 : selectedIndex) + 1;
		const nextIndex = needReviewArray[needReviewArray.length - 1] > startIndex ? needReviewArray.filter( value => value >= startIndex )[0] : needReviewArray[0];
		setSelectedIndex(nextIndex);
	}

	const handleCardClick = React.useCallback( (index) => {
		setSelectedIndex(index)
	}, [])

	return (
		<UIPage id="job-review" contentContainerSize="medium">
			{areAllStepsComplete
				? <UIButton label="Activate Job" onClick={activate} margin="mediumVertical" />
				: <UIButton error label="Next required Field" onClick={selectNextInvalidStep} margin="mediumVertical"/>
			}
			<UIButton label="Save Draft" onClick={saveToDB} margin="mediumVertical"/>


			<UIHeading level="2">Product</UIHeading>
			<ProductSelection 
				workflowOptions={workflowOptions} 
				updateProductSelections={updateProductSelections} 
			/>

			<UIHeading level="2">Steps</UIHeading>
			<UIHeading level="3">Steps with red icons have required fields</UIHeading>
			
			<UIFlexbox>
				<UIFlexChild flex="2" padding="medium">
					{databaseSteps.map(
						(dStep, index) => {
							const { stepPlan, stepInfo, supp } = getWorkflowStepFull(index);
							return (						
								<StepCard
									key={'step_' + index} 
									onClick={handleCardClick.bind(this, index)}
									indicator={isReviewInputRequired(stepPlan, supp) ? 'alert' : index + 1}
									stepInfo={stepInfo}
									stepPlan={stepPlan}
									isSelected={index === selectedIndex}
								/>
							)
						}
					)}
				</UIFlexChild>
				<UIFlexChild flex="2" padding="medium">
					{
						selectedIndex === null ? <UIHeading level="3">Select a step to edit options</UIHeading>
						: 				
						<JobReviewStepForm		
							key={'stepform_' + selectedIndex + '_' + databaseSteps.length}
							jobId={jobId}
							index0={selectedIndex}
							updateSuppPartial={updateSuppPartial}
						/>
					}	
				</UIFlexChild>
			</UIFlexbox>
		</UIPage>
	)
}