import React, { useMemo } from 'react';

import { useModal, UIButton, UIHeading, UIFlexbox } from 'kit';
import { AddToList } from '@styled-icons/entypo/AddToList';

import { AdjustPartForm } from 'components/Parts/PartForm';

import { Table } from 'components/Table/Table';
import { TableDateMultiField } from 'components/Table/TableDateField';
import { SelectColumnFilterArray } from 'components/Table/TableFilters';

import { useQuery } from '@apollo/client';
import { GET_PART_TXS } from '@constants/gql';

import { PartTransaction, Part } from 'types';
import { roundWithDecimal } from 'utility';


export const PartTxTable = (
	{
		part, partType
	}:
	{		
		part: Part
		partType: 'raw' | 'product'
	}
) => {
	const modalProps = useModal();

	const {
		loading,
		data: { part_tx: transactionList } = { part_tx: [] as Array<PartTransaction> }
	} = useQuery(GET_PART_TXS, { variables: { part_id: part.id } });

	const columns = useMemo(() => 
		partTxColumnDefs
	, [transactionList]);
	
	let data = useMemo(() => transactionList, [loading, transactionList]);

	return (
		<React.Fragment>
			
			<UIFlexbox flexAlign="flex-start" flexJustify="space-between" flexWrap="wrap">
				<UIHeading>Transaction History: {part.name}</UIHeading>
				<UIButton icon={<AddToList/>} label="Add Transaction" onClick={modalProps.toggleModal}/>
			</UIFlexbox>

				<Table
					columns={columns} 
					data={data} 
					hasSortableColumns 
					hasGlobalFilter 
					hasPagination 
					hasSelectablePageSize					
					hasFilterableColumns 
				/>

				<AdjustPartForm
					modalProps={modalProps}
					part={part}
					partType={partType}
				/>

		</React.Fragment>
	);
}


const partTxColumnDefs = [		
		{
			Header: 'Date',
			accessor: 'created_at',
			className: 'emphasis',
			disableGlobalFilter: true,
			Cell: ({ cell: { value } }) => <TableDateMultiField ISOdateString={value} dateDisplayFormatTop="dateShortWithYear" dateDisplayFormatBottom="time" />
		},
		{
			Header: 'Type',
			accessor: 'type',
			Filter: SelectColumnFilterArray,
			filter: 'includesAll'
		},		
		{
			Header: 'Job',
			accessor: 'job.name'
		},
		{
			Header: 'Change',
			accessor: 'qty_change',
			Cell: ({ cell: { value = 0 } }) => roundWithDecimal(value)
		},
		{
			Header: 'Quantity',
			accessor: 'new_qty',
			Cell: ({ cell: { value = 0 } }) => roundWithDecimal(value)
		},
		{
			Header: 'Comment',
			accessor: 'comment'
		}
	];