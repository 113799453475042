import React, {  } from 'react';
import { UIForm } from 'kit';
import { ParameterSelectSingle } from 'components/StepSetup/ParameterComponentsSelectSingle';
import styled from 'styled-components';


export const ParameterSelect = (
	{ itemType, itemValue, updateValue, stepId }: {
	itemType: 'recipe' | 'tool'
	itemValue: number
	updateValue
	stepId: number
}) => {

	const submit = formData => {
	};


	// ParameterSelectSingle uses form context, so you have to wrap this in a form even though it doesn't really use it.
	return (
		<StyledForm onSubmit={submit} >
			<ParameterSelectSingle 
				parameterName={itemType} 
				nameForForm={'value'} 
				defaultValue={itemValue} 
				label=""
				stepId={stepId}
				required={false}
				onChange={updateValue}
			/>
		</StyledForm>
	);
};

const StyledForm = styled(UIForm)`
	margin: 10px auto;
`;