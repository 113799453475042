import React, { useState, useEffect } from 'react';

import { UIPage, UIForm2, useUIForm, UIInput, UISelect, SaveButtonBlock } from 'kit';

import { FormBuilder } from 'components/FormBuilder/FormBuilderDragDrop';
import { addFormDefinition, updateFormDefinition } from 'root/services/formDefinitionMutate';
import { LinkStepsFromItem } from 'root/components/StepLink/LinkStepsFromItem';

import { useFormDefinition, useInvUniqueTypes } from 'hooks';
import { formDefinitionTableOptions, thingLookup } from '@constants';
import { useParams } from 'react-router-dom';
import { useNavMain } from 'routing';

import { useTransientStore } from 'store';

export function FormBuilderPage() {
	const { id } = useParams(); // Fetch type and id from URL
	const idInt: number = parseInt(id);

	const { formDefinition } = useFormDefinition(idInt);
	
	const [ fields, setFields ] = useState();
	const [ forceTypeId, setForceTypeId ] = useState(0);
	const isForceInvUnique = forceTypeId > 0;

	const { formMethods } = useUIForm(formDefinition, { resetTrigger: formDefinition.id });

	const consumeTransientData = useTransientStore(state => state.consumeValue);
	useEffect(() => {
		setForceTypeId(consumeTransientData('formSetupInvUniqueTypeId'));
	}, [])


	const navigateToFormSetup = useNavMain('formSetup');
	const navigateToFormBuilderById = useNavMain('formBuilderById');
	const isInvUniqueForm = formMethods.watch('foreign_table') === thingLookup.inventory_unique.sharedTableId;
	const isStepForm = formMethods.watch('foreign_table') === thingLookup.step_history.sharedTableId;
	const { invUniqueTypes } = useInvUniqueTypes(!isInvUniqueForm);

	const isNewForm = !(idInt > 0);

	const linkStepsRef = React.useRef<{ updateStepsFromItem(itemId: number): void }>();

	const submit = formData => {
		if (isNewForm) {
			addFormDefinition(formData.name, fields, formData.foreign_table, formData.inventory_unique_type_id).then( addedFormDef => {
				if (isStepForm) {
					linkStepsRef.current.updateStepsFromItem(addedFormDef.id);
				}
				navigateToFormBuilderById(addedFormDef.id);
			});
		}
		else {
			updateFormDefinition(formDefinition.id, formData.name, fields).then(() => {
				if (isStepForm) {
					linkStepsRef.current.updateStepsFromItem(formDefinition.id);
				}
				navigateToFormSetup();
			});
		}
	}
	
	return (
		<UIPage id="form-builder" contentContainerSize="small">
			<UIForm2 
				formMethods={formMethods}
				onSubmit={submit} 
			>
				<UIInput name="name" label="Name" required />
				<UISelect
					name="foreign_table"
					label="Form Type"
					options={formDefinitionTableOptions}
					defaultValue={formDefinition.foreign_table || (isForceInvUnique ? thingLookup.inventory_unique.sharedTableId : false)}
					required
					hasConditionalComponents={true}
					disabled={!isNaN(formDefinition.foreign_table)} // user cannot change form type after it is created
				/>
				{isInvUniqueForm && 
					<UISelect
						name="inventory_unique_type_id"
						label="Inventory Type"
						options={invUniqueTypes}
						defaultValue={formDefinition.inventory_unique_type_id || forceTypeId}
						required
						hasConditionalComponents={false}
						disabled={!isNaN(formDefinition.inventory_unique_type_id)} // user cannot change type after it is created
					/>
				}
				{isStepForm && 
					<LinkStepsFromItem
						ref={linkStepsRef}
						linkingId={idInt}
						parameterName="form"
					/>
				}

				
				<FormBuilder 
					formDefinition={formDefinition}
					onUpdate={setFields}
				/>

				<SaveButtonBlock 
					onCancel={navigateToFormSetup} 
				/>
			</UIForm2>



		</UIPage>
	)
}
