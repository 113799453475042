import React from 'react';
import styled from 'styled-components';

import { UIFlexbox, UIFlexChild } from 'kit';
import { PaddingProps, paddingStyles } from '@constants';
import { DragIndicator } from '@styled-icons/material/DragIndicator';

import { Droppable, Draggable } from 'react-beautiful-dnd';

export const UIDroppable = (
	props: { 
		name: string
		readonly: boolean
		children
		containerDragOverStyle?
		className?: string
	} &  PaddingProps
) => {
	return (		
		<Droppable droppableId={props.name} isDropDisabled={props.readonly} className={props.className}>
			{(provided, snapshot) => {
				return (
					<DroppableContainer
						ref={provided.innerRef} {...provided.droppableProps}
						style={ snapshot.isDraggingOver && props.containerDragOverStyle ? props.containerDragOverStyle : {} }
						className={`${snapshot.draggingFromThisWith ? 'droppable-source-dragging' : ''} ${props.className}`}
						snapshot={snapshot}
						padding={props.padding}
					>
						{props.children}
						<div style={props.readonly ? { display: 'none' } : {}}>{provided.placeholder}</div>

					</DroppableContainer>
				)
			}}
		</Droppable>
	)
}

const DroppableContainer = styled.div<{ snapshot: any }>`
	width: 100%;
	height: -webkit-fill-available;
	border: 1px solid transparent; /* Put a transparent border so things don't shift when you drag*/
	border: ${props => props.snapshot.draggingFromThisWith && ('1px dashed ' + props.theme.colors.colorDefinitions.grey3)};
	border: ${props => props.snapshot.isDraggingOver && ('1px dashed ' + props.theme.colors.colorDefinitions.grey4)};
	${paddingStyles}
	border-radius: 10px;
`;

/**
 * class "draggable" is always accessible
 * class "draggable-dragging" is applied to the draggable container when the component is actively dragging.  
 * */
export const UIDraggable = (
	props: { 
		name: string
		clone: boolean
		index: number
		content
		className?: string
	}
) => {

	return (
		<Draggable key={props.name} draggableId={props.name} index={props.index} >
			{(provided, snapshot) => {
				return (
					<React.Fragment>
						<div
							ref={provided.innerRef}
							{...provided.draggableProps}
							
							style={getItemStyle(
								snapshot.isDragging,
								provided.draggableProps.style
							)}

							className={snapshot.isDragging ? 'draggable draggable-dragging' : 'draggable'}
						>
							<DraggableContainer 
								flexAlign="center"
								className={props.className}
							>
								<div {...provided.dragHandleProps} className="drag-handle" ><DragIndicator size={26} /> </div>
								<UIFlexChild>{props.content}</UIFlexChild>
							</DraggableContainer>
						</div>
						{props.clone && snapshot.isDragging && (
							<Cloned>
								<DraggableContainer flexAlign="center">
									<div {...provided.dragHandleProps} className="drag-handle" ><DragIndicator size={26} /> </div>
									{props.content}
								</DraggableContainer>
							</Cloned>
						)}
						
					</React.Fragment>
				)
			} }
		</Draggable>
	)
}

const DraggableContainer = styled(UIFlexbox)`
	width: 100%;
	.drag-handle {
		color: ${props => props.theme.colors.colorDefinitions.grey4};
		margin-right: 14px;
		margin-left: 14px;
	}
`;


const getItemStyle = (isDragging, draggableStyle) => ({
	// some basic styles to make the items look a bit nicer
	userSelect: 'none',
	//padding: 10 * 2,
	//margin: `0 0 ${10}px 0`,
  
	// change background colour if dragging
	//background: isDragging ? 'violet' : 'transparent',
	//border: '1px solid lightgrey',
  
	// styles we need to apply on draggables
	...draggableStyle
  });

const Cloned = styled.div`
	~ div {
		transform: none!important;
	}
`;

export interface DragDropOptions {
	beforeReorder?(reorderedItem: any, provisionalNewList, origIndex: number, newIndex: number): any
	onReorder?(newList: any[], originalIndex: number, newIndex: number): void
	onAdd?(newList: any[], addedRow: number, addedIndex0: number): void
	multiDropIds?: DropIds
	sourceList?: any[]
}
interface DropIds {
	originatingBinId: string
	destinationBinId: string
	deleteBinId: string
}

export const useDragDrop = (dragDropOptions: DragDropOptions = {} ) => {
	const { 
		beforeReorder = (a, b, c, d) => a,
		onReorder = (a, b, c) => {}, 
		onAdd = (a, b, c) => {},
		multiDropIds = {} as DropIds,
		sourceList = []
	} = dragDropOptions;

	function handleDragEvent(dragEvent, destinationList: any[], selectedIndex = 0) {
		//https://github.com/atlassian/react-beautiful-dnd/issues/216#issuecomment-475837094
			let newSelectedIndex = selectedIndex;
			let newDestList = destinationList.slice(); //duplicate the array


			if (dragEvent.destination === null)  { /*do nothing*/ }
			else if (dragEvent.source.droppableId.indexOf(multiDropIds.originatingBinId) > -1 && dragEvent.destination.droppableId === multiDropIds.deleteBinId ) { /*do nothing*/ }
			else if (dragEvent.source.droppableId === multiDropIds.destinationBinId && dragEvent.destination.droppableId === multiDropIds.deleteBinId) {  // delete
				newDestList.splice(dragEvent.source.index, 1);
				newSelectedIndex = null;
			}
			else if (dragEvent.destination.droppableId === dragEvent.source.droppableId) {//reorder
				const origIndex = dragEvent.source.index;
				const newIndex = dragEvent.destination.index;
				if (origIndex !== newIndex) {
					const [reorderingItem] = newDestList.splice(origIndex, 1);
	
					let provisionalNewDestList = destinationList.slice()
					provisionalNewDestList.splice(origIndex, 1);
					provisionalNewDestList.splice(newIndex, 0, reorderingItem);
	
					const modifiedReorderingItem = beforeReorder(reorderingItem, provisionalNewDestList, origIndex, newIndex);
	
					newDestList.splice(newIndex, 0, modifiedReorderingItem);
	
					newSelectedIndex = shiftIndexWithDrag(selectedIndex, origIndex, newIndex);
					
					onReorder(newDestList, origIndex, newIndex);
				}
			}
			else { //copying from source
				const addingRow = sourceList[dragEvent.source.index];
				const addingIndex0 = dragEvent.destination.index;
				newDestList.splice(addingIndex0, 0, addingRow);
				onAdd(newDestList, addingRow, addingIndex0);
			}

			return {
				newDestinationList: newDestList,
				newSelectedIndex
			}
	}

	return {
		handleDragEvent,
		UIDroppable,
		UIDraggable
	}
}
/**
 * Returns the new index of a given item in a list, when a list is reordered around it
 * @param refI reference index
 * @param oi original index of the dragged item
 * @param ni new index of the dragged item
 */
export function shiftIndexWithDrag(refI, oi, ni) {
	let newIndex = refI;
	if (oi === refI) {
		newIndex = ni;
	}
	else if (oi < refI && ni >= refI) {
		newIndex = refI - 1;
	}
	else if (oi > refI && ni <= refI) {
		newIndex = refI + 1;
	}
	return newIndex;
}