import React, { useState } from 'react';
import { UIPage, UIFlexbox, UIRadioSegment } from 'kit';
import { AddCircle } from '@styled-icons/remix-line/AddCircle';

import { UniqueInventoryAllTables, UniqueInventoryTableViewMode } from 'components/InventoryUnique/InventoryUniqueTables';
import { RoutedButton } from 'routing';


export function InventoryUniquePage() {
	const [viewMode, setViewMode] = useState<UniqueInventoryTableViewMode>('single');

	return (
		<UIPage id="inventoryUniquePage" contentContainerSize="xlarge">			
			
			<UIFlexbox flexJustify="space-between" flexAlign="center">
				<UIRadioSegment
					options={[
						{ name: 'Single Table', id: 'single' },
						{ name: 'Separated by Stage', id: 'stage' }
					]}
					name="displayType"
					label=""
					default="single"
					onChange={setViewMode}
				/>
				<RoutedButton icon={<AddCircle color="white"/>} label="Inventory Setup" linkToRoute="inventoryUniqueSetup" />
			</UIFlexbox>

			{<UniqueInventoryAllTables mode={viewMode} />}
			
		</UIPage>
	)
}