
import { gql } from '@apollo/client';
import { apolloClient } from 'services/apollo';
import { getDetailViewRefetchQuery } from 'services/thingHelper';

export const formAllColumns = `
	id
	created_at
	updated_at
	values
`;

const ADD_FORM_INSTANCE = gql`
	mutation ADD_FORM_INSTANCE ( 
		$form_definition_id: Int!
		$foreign_id: Int!
		$values: jsonb!
	) {
		insert_form (
			objects: { 
				form_definition_id: $form_definition_id
				foreign_id: $foreign_id
				values: $values
			}
		) {
			affected_rows
		}
	}
`;

const UPDATE_FORM_INSTANCE = gql`
	mutation UPDATE_FORM_INSTANCE ( 
		$id: Int!
		$values: jsonb!
	) {
		update_form (
			where: { 
				id: { _eq: $id }
			},
			_set: {
				values: $values
			}
		) {
			affected_rows
		}
	}
`;

export const addFormInstance = (
	formDefId: number, 
	values: any, 
	referenceTableId: number, 
	referenceId: number
): Promise<boolean> => {
	const refetchQueries = getDetailViewRefetchQuery(referenceTableId, referenceId);
	return apolloClient.mutate({ 
		mutation: ADD_FORM_INSTANCE, 
		variables: { 
			form_definition_id: formDefId,
			foreign_id: referenceId,
			values: values
		},
		refetchQueries: refetchQueries,
		awaitRefetchQueries: true
	}).then( queryResponse => {
		return true;
	});
};

export const updateFormInstance = (
	existingFormId: number,
	values: any,
	referenceTableId: number, 
	referenceId: number
): Promise<boolean> => {

	const refetchQueries = getDetailViewRefetchQuery(referenceTableId, referenceId);

	return apolloClient.mutate({ 
		mutation: UPDATE_FORM_INSTANCE, 
		variables: { 
			id: existingFormId,
			values: values
		},
		refetchQueries: refetchQueries,
		awaitRefetchQueries: true
	}).then( queryResponse => {
		return true;
	});
};
