import React, { useState } from 'react';
import styled from 'styled-components';

import { Tabs, Tab } from 'grommet';
import { convertHexToRGBA } from 'theme';
import { Chat  } from '@styled-icons/boxicons-regular/Chat';
import { Star } from '@styled-icons/boxicons-regular/Star';
import { File } from '@styled-icons/boxicons-regular/File';
import { InfoCircle } from '@styled-icons/boxicons-regular/InfoCircle';


export function TabMenu(
	props: {
		index?
		content?
		comment?
		details?
		form?
	}
) {
	const [index, setIndex] = useState(props.index || 0);	
	const onActive = nextIndex => setIndex(nextIndex);
	
	return (
		<StyledDiv>
			<Tabs activeIndex={index} onActive={onActive} justify="start">
				{props.comment && 
					<StyledTab className={index === 0 ? 'selected' : ''} title={<Chat size="20" />} plain>				
						{props.comment}
					</StyledTab>
				}
				{props.content && 
					<StyledTab className={index === 1 ? 'selected' : ''} title={<Star size="20" />} plain>
						{props.content}
					</StyledTab>
				}
				{props.details && 
					<StyledTab className={index === 2 ? 'selected' : ''} title={<InfoCircle size="20" />} plain>
						{props.details}
					</StyledTab>
				}
				{props.form && 
					<StyledTab className={index === 3 ? 'selected' : ''} title={<File size="20"/>} plain>
						{props.form}
					</StyledTab>
				}
			</Tabs>
		</StyledDiv>
	)
}

const StyledDiv = styled.div`	
	@media ${props => props.theme.mediaQuery.mobile} {
		width: 100vw;
	}	
`;

const StyledTab = styled(Tab)`	
	border-radius: 50%;
	margin: 10px 10px 0px 10px;
	/* padding: 10px; */
	height: 40px;
	width: 40px;
	text-align: center;
	:focus {		
		box-shadow: none;
		border: none;	//set focus border here
	}	
	svg {
		margin: 0 auto !important;
	}
	&.selected {
		background-color: ${props => convertHexToRGBA(props.theme.colors.primary, 0.2)};
	}
	&:hover {
		background-color:  ${props => convertHexToRGBA(props.theme.colors.primary, 0.4)};
	}
`
