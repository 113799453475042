import { thingLookup, ThingName } from '@constants';
import { GET_STEP_HISTORY_BY_ID } from 'services/stepHistoryFetch';
import { GET_JOBS } from '@constants/gql';
import { GET_INVENTORY_UNIQUE } from 'services/inventoryUniqueFetch';
import { GET_ORDERS } from 'services/orderFetch';


export function getThingNameFromId(tableNumber: number): ThingName {
	return Object.keys(thingLookup).find((key: ThingName) =>
		(thingLookup[key]?.sharedTableId ?? 'undefined table number') === tableNumber
	) as ThingName;
}

export function getDetailViewRefetchQuery(tableNumber: number, referenceId) {
	const thingName = getThingNameFromId(tableNumber);
	let refetchQueries = [];
	switch (thingName) {
		case 'step_history':
			refetchQueries = [{ query: GET_STEP_HISTORY_BY_ID, variables: { id: referenceId } }]
			break;
		case 'job':
			refetchQueries = [{ query: GET_JOBS, variables: { id: referenceId } }]
			break;
		case 'inventory_unique':
			refetchQueries = [{ query: GET_INVENTORY_UNIQUE, variables: { id: referenceId } }]
			break;
		case 'order':
			refetchQueries = [{ query: GET_ORDERS, variables: { status: 'active' } }]
			break;
		default:
			break;
	}

	return refetchQueries;
}
