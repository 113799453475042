import React from 'react';
import styled from 'styled-components';

import { UIHeading, UIPage, UIFlexbox, UIIconButton, UIFlexChild, UIKeyValueHorizontal } from 'kit';
import { ChevronRight } from '@styled-icons/feather/ChevronRight';
import { ChevronLeft } from '@styled-icons/feather/ChevronLeft';

import { StepInfoBlocks } from 'components/OperateStep/StepInfoBlocks';
import { FormInstanceAll } from 'components/Form/FormInstance';
import { OperateFormUniqueInventory } from 'components/OperateStep/OperateFormInventoryUnique';
import { JobInfo } from 'components/Jobs/JobInfo';

import { useOperateStep } from 'components/OperateStep/useOperateStep';
import { useStepDetails } from 'hooks';
import { useChange, ChangeSpec } from 'components/common/useChange';
import { changeStepHistory } from 'services/stepMutate';
import { ThreadsList, ThreadsContainer } from 'components/Messages/Thread';

import { RightPortal } from 'components/Navigation/RightPortal';
import { TabMenu } from 'components/Navigation/TabMenu';
import { NavbarPortal } from 'components/Navigation/NavbarPortal';

import { Thread, StepHistoryRaw, StepHistoryChanges } from 'types';
import { dateFormat, elapseTime, isMobileScreen } from 'utility';
import { useParams } from 'react-router-dom';
import { useNavMain } from 'routing';


export function StepDetailPage() {
	const navigateJobStep = useNavMain('operateStep');	
	const { id: jobIdString, index: stepURLIndex } = useParams();
	const jobId = parseInt(jobIdString);
	const stepIndex1 = parseInt(stepURLIndex);
	
	const{
		job,
		hasStepStarted,
		stepInfo, 
		stepPlan, 
		stepHistory, stepHistoryHelper, stepState,
		uniqueItems
	} = useStepDetails(jobId, stepIndex1);
	
	const { ActionButton, TaskCards } = useOperateStep(jobId, stepIndex1);
	
	const specList: ChangeSpec<StepHistoryRaw, StepHistoryChanges>[] = [
		{
			key: 'start_at',
			formatValue: 'datetime',
			input_type: 'datetime',
			isEditDisabled: !stepHistoryHelper.isStarted
		},
		{
			key: 'stop_at',
			name: 'Completed At',
			formatValue: 'datetime',
			input_type: 'datetime',
			isEditDisabled: !stepHistoryHelper.isCompleted
		}
	]

	const { OpenChangeButton, ChangeModal } = useChange({
		title: 'Edit Step',
		specList: specList,
		item: stepHistory, 
		saveFunction: changeStepHistory
	});	

	const threads: Thread[] = stepHistory.threads;	
	
	function nextStep(){
		const nextStepIndex = stepIndex1 + 1;
		if (nextStepIndex > job.steps.length) {
			// do nothing
		}
		else {
			navigateJobStep(jobId, nextStepIndex);
		}
	}
	
	function previousStep(){
		const previousStepIndex = stepIndex1 - 1;
		if (previousStepIndex === 0) {
			// do nothing
		}
		else {
			navigateJobStep(jobId, previousStepIndex);
		}
	}

	return (
		<UIPage id="step-detail" contentContainerSize="medium" >
			<NavbarPortal>
				<UIFlexbox flexAlign="center">
					<UIIconButton muted icon={<ChevronLeft size="10" />} onClick={previousStep} />
					<StepNumber level="3">Step {stepIndex1}</StepNumber>
					<UIIconButton muted icon={<ChevronRight />} onClick={nextStep} />
				</UIFlexbox>
			</NavbarPortal>

			<RightPortal>
				<TabMenu
					content={job.id && <JobInfo job={job}/>}
					comment={
						<ThreadsContainer flex="1">
							<UIHeading>Comments</UIHeading>
							{stepHistory.id ? 
								<ThreadsList
									threads={threads} 
									referenceType="step_history" 
									referenceId={stepHistory.id} 
								/>
								: <div>Comments are closed until the step is started.</div>
							}
						</ThreadsContainer>
					}
				/>
			</RightPortal>

			<UIFlexbox flexJustify="space-between" flexAlign="flex-start" width="100%" margin="mediumVertical">
				<DetailsHeader>
					{(stepState === 'complete' || stepState === 'processing') && OpenChangeButton}
					{/* <StepName level="1">{stepInfo.name}</StepName> */}
					<div className="description">{stepInfo.description}</div>
					<UIKeyValueHorizontal title="Job" text={job.name} />
					<UIKeyValueHorizontal title="Workflow" text={job?.workflow?.name ?? 'n/a - custom'} />
					<UIKeyValueHorizontal title="Start Time" text={dateFormat(stepHistory.start_at, 'dateTimeSlash')} />
					<UIKeyValueHorizontal title="Started By" text={stepHistoryHelper.startUserName} />
					<UIKeyValueHorizontal title="Stop Time" text={dateFormat(stepHistory.stop_at, 'dateTimeSlash')} />
					<UIKeyValueHorizontal title="Stopped By" text={stepHistoryHelper.stopUserName} />
					<UIKeyValueHorizontal title="Duration" text={elapseTime(stepHistory.start_at, stepHistory.stop_at)} />
			
				</DetailsHeader>
				
				{!isMobileScreen() && ActionButton}
			</UIFlexbox>

			{isMobileScreen() && ActionButton}
			
			{TaskCards}

			<StepInfoBlocks
				jobId={jobId}
				stepIndex1={stepIndex1}
			/>

			{hasStepStarted && <FormInstanceAll forms={stepHistory.forms} formType="step_history" /> }

			{hasStepStarted && <OperateFormUniqueInventory 
				uniqueItems={uniqueItems}
				stepPlan={stepPlan}
				viewMode="card"
			/>
			}

			{ChangeModal}

		</UIPage>
	)
}

const StepNumber = styled(UIHeading)`
	margin: 0 10px 4px;
`;


const DetailsHeader = styled(UIFlexChild)`
	position: relative;
	.description {
		line-height: 1.3em;
		min-height: 1.3em;
		margin-bottom: 2rem;
		color: ${props => props.theme.colors.baseTextLight};
	}
	margin-bottom: 20px;
`;