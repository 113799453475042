
import { gql } from '@apollo/client';

export const tagAllColumns = `
	id
	created_at
	updated_at
	table_id
	name
`;

export const GET_TAGS_BY_TABLE = gql`
	query GET_TAGS_BY_TABLE ( 
		$table_id: Int!
	){
		tag (
			where: { table_id: { _eq: $table_id } }
		) {
			${tagAllColumns}
		}
	}
`;