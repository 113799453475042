import create from 'zustand';
import { ModalHookProps } from 'kit';

type DialogStore = {
	modalProps: ModalHookProps
	setModalProps: (obj: ModalHookProps ) => void

	onConfirm: () => void
	setOnConfirm

	showAlert
	setShowAlert
}


/**
 * Used for global modal used for alerts and dialogs
 */
export const useDialogStore = create<DialogStore>((set, get) => ({
	modalProps: {} as ModalHookProps,
	setModalProps: obj => set({ modalProps: obj }),

	onConfirm: () => {},
	setOnConfirm: func => set({ onConfirm: func }),

	showAlert: () => {},
	setShowAlert: func => set({ showAlert: func })

}));

export const dialogStore = useDialogStore;