import { useQuery } from '@apollo/client';
import { GET_FORM_DEFINITIONS, GET_FORM_DEFINITIONS_BY_IDS } from 'services/formFetch';

import { thingLookup, ThingName } from '@constants';
import { FormDefinition } from 'types';

export const useFormDefinition = (id: number) => {
	const { 
		loading,
		data: { form_definition: [formDefinition = {} as FormDefinition] } = { form_definition: [] }
	} = useQuery(GET_FORM_DEFINITIONS, { 
		variables: { id: id },
		skip: !(id > 0)
	});

	return {
		isLoading: loading,
		formDefinition
	}
}

export const useFormDefinitions = (
	whereType: 'ids' | 'table' | 'all', 
	whereValue?: { ids?: number[], tableName?: ThingName}
) => {
	let QUERY, variables, skip;
	switch (whereType) {
		case 'ids':
			QUERY = GET_FORM_DEFINITIONS_BY_IDS;
			variables = { ids: whereValue.ids };
			if (!(whereValue?.ids?.length > 0)) {
				skip = true;
				console.error('An array of ids is required.');
			}
			break;
		case 'table':
			QUERY = GET_FORM_DEFINITIONS;
			variables = { foreign_table: thingLookup[whereValue.tableName].sharedTableId };
			if (!whereValue?.tableName) {
				skip = true;
				console.error('Table Name is required.');
			}
			break;
		case 'all':
			QUERY = GET_FORM_DEFINITIONS;
			break;
		default:
			break;
	}

	const { 
		loading,
		data: { form_definition: formDefinitions } = { form_definition: [] as FormDefinition[] }
	} = useQuery(QUERY, { variables, skip });

	return {
		isLoading: loading,
		formDefinitions
	}
}