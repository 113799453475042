import React from 'react';
import { UISelect, DefaultKeyValue } from 'kit';

import { useThingById, useThingsIn } from 'hooks';

export const ParameterSelectSingle = (
	props: {
		stepId: number
		parameterName: 'recipe' | 'tool'
		nameForForm: string
		defaultValue
		label: string
		subtitle?: string
		disabled?: boolean
		required: boolean
		scrollOnOpen?: boolean
		onChange?
	}
) => {
	const { isLoading: isStepLoading, item: step } = useThingById('step', props.stepId);

	const paramColName = `${props.parameterName}s`
	const { isLoading: isItemsLoading, items } = useThingsIn(props.parameterName, step[paramColName]);



	// const allowedTags = allowedTagsInProgress || props.stepPlan?.[props.parameterName]?.allowed_tags || [];

	// const allowedOptions = (
	// 	allowedTags.length > 0 ?
	// 	items.filter( item =>
	// 		item.tags.filter( tagId => allowedTags.includes(tagId) ).length > 0
	// 	) :
	// 		items
	// );

	return (
		<UISelect
			name={props.nameForForm}
			label={props.label}
			subtitle={props.subtitle}
			required={props.required}
			defaultValue={props.defaultValue}
			options={items}
			isLoading={isStepLoading || isItemsLoading}
			disabled={props.disabled}
			customOption={DefaultKeyValue}
			scrollOnOpen={props.scrollOnOpen}
			onChange={props.onChange}
		/>
	);
}
