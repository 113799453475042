import React from 'react';

import { UIFlexbox, UIChip } from 'kit';

import { changeJob } from 'services/jobMutate';

import { CardViewChange } from 'components/common/CardViewChange';
import { ChangeSpec } from 'components/common/useChange';

import { Job, JobChanges } from 'types';

export const JobInfo = (
	{ job, isEditable = false }
	: {
		job: Job
		isEditable?: boolean
	}
) => {
	const specList: ChangeSpec<Job, JobChanges>[] = [
		{
			key: 'name'
		},
		{
			key: 'job_status',
			isEditDisabled: true
		},
		{
			key: 'start_at',
			formatValue: 'datetime',
			input_type: 'datetime'
		},
		{
			key: 'created_user_id',
			name: 'Created By',
			isEditDisabled: true,
			valueDisplayAccessor: 'created_user.first_name'
		},
		{
			key: 'stop_at',
			name: 'Completed At',
			formatValue: 'datetime',
			input_type: 'datetime',
			isEditDisabled: job.job_status !== 'complete'
		},
		{
			key: 'updated_at',
			name: 'Last Updated',
			isEditDisabled: true,
			formatValue: 'datetime'
		}
	]


	const progress = job.current_step_index > job.steps?.length ? null : job.current_step_index + '/' + job.steps?.length;

	return (
		<CardViewChange
			title={`${job.name} Details`} 
			specList={specList} 
			item={job} 
			saveFunction={changeJob}
			canEdit={isEditable}

			viewPrepend={ 
				<UIFlexbox flexAlign="center" margin="mediumVertical">
					<UIChip 
						light 
						capitalize 
						label={job.job_status} 
						size="medium" 
						muted={job.job_status === 'draft'} 
						success={job.job_status === 'complete'} 
						error={job.job_status === 'aborted'} 
						noMarginBottom 
					/>
					{progress && <div>Progress: {progress}</div>}
				</UIFlexbox> 
			}
		/>
	)
}

// //job info components
// export const CurrentStepInfo = (	
// 	props: {		
// 		job: Job
// 	} 
// ) => {
// 	if (props.job.job_status === 'complete') { return null; }

// 	const { stepInfo } = hydrateCurrentStepFromJob(props.job);

// 	return (
// 		<UICard flexAlign="flex-start" width="100%">
// 			<UIFlexbox flexAlign="center">
// 				<LinkIntact size="30" color="primary" />
// 			</UIFlexbox>

// 			<UIKeyValue title="Current step" text={stepInfo.name} line/>
// 		</UICard>
// 	);
// }