import React, { useState, useContext } from 'react';
import styled from 'styled-components';

import { UIButton, UITooltipWrapper, UIFlexbox, UIHeading, UIChip } from 'kit';
import { ArrowRightAlt } from '@styled-icons/material/ArrowRightAlt';

import { updateAttachIndex, updateDetachIndex, updateConvertStage } from './workflowOptionsInventoryUniqueFunctions';
import { WorkflowEditorContext } from './WorkflowEditorContext';
import { isStepSkipped } from './modFunctions';

import { useInvUniqueType } from 'hooks';
import { ConvertPlan } from 'types';


export const SidecarContentsInventoryUnique = ({
	index0
}: {
	index0: number
}) => {
	const { databaseSteps, workflowOptions, updateWorkflowOptions, firstAmendableStep0 } = useContext(WorkflowEditorContext);
	const isSkippedStep = isStepSkipped(databaseSteps[index0]);

	const index1 = index0 + 1;
	const ai = workflowOptions.inventory_unique?.attach?.step_index;
	const di = workflowOptions.inventory_unique?.detach?.step_index;
	const converts = workflowOptions.inventory_unique?.converts || [];
	const convertIndices = converts.map( convert => convert.step_index) || [];
	
	const [isInteractionActive, setIsInteractionActive] = useState(false);

	function moveAttachHere(){
		const newWorkflowOptions = updateAttachIndex(workflowOptions, index1);
		updateWorkflowOptions(newWorkflowOptions);
	}

	function moveDetachHere(){
		const newWorkflowOptions = updateDetachIndex(workflowOptions, index1);
		updateWorkflowOptions(newWorkflowOptions);
	}

	function updateConvertStageForThisStep(newStageId: number | 'delete'){
		const newWorkflowOptions = updateConvertStage(workflowOptions, index1, newStageId);
		updateWorkflowOptions(newWorkflowOptions);
	}

	return (
	<SidecarContainer className={isInteractionActive && 'row-interacting'}>
		{/* -------------------------- Active effects --------------------------*/}
		{ai === index1 && 
			<UITooltipWrapper tooltipContent="Attaching during this step" useRefHelper>
				<UIButton label="Attach" onClick={() => {}} />
			</UITooltipWrapper>
		}
		{di === index1 && 
			<UITooltipWrapper tooltipContent="Detaching during this step" useRefHelper>
				<UIButton label="Detach" onClick={() => {}} />
			</UITooltipWrapper>
		}


		{converts.map( convert =>  convert.step_index === index1 && 
			<ConvertButton 
				key={convert.step_index} 
				typeId={workflowOptions.inventory_unique?.type_id}
				convert={convert}
				updateConvert={updateConvertStageForThisStep}
				setIsInteractionActive={setIsInteractionActive}
				isDisabled={!(convert.step_index > firstAmendableStep0)}
			/>
		)}

		{/* -------------------------- Hover / Move Here effects --------------------------*/}
		
		{	index1 !== ai && // This is already the attach step, no point in showing an option to move it here
			index1 <= di &&  // attach must happen before, or in same step, as detach
			index1 > firstAmendableStep0 && // Only editable steps are eligible
			ai > firstAmendableStep0 && // if attach has already happened, do not show this option
			!isSkippedStep && // don't offer this option if the step is skipped
			<UITooltipWrapper tooltipContent="Move attach operation to this step" useRefHelper>
				<HoverButton label="Attach" onClick={moveAttachHere} />
			</UITooltipWrapper>
		}
		{	index1 >= ai && // convert must happen after, or in same step, as attach
			index1 <= di && // convert must happen before, or in same step, as detach
			!convertIndices.includes(index1) && // This is already a convert step, no point in showing an option to move it here
			index1 > firstAmendableStep0 && // Only editable steps are eligible
			!isSkippedStep && // don't offer this option if the step is skipped
			<ConvertButton 
				typeId={workflowOptions.inventory_unique?.type_id}
				convert={{} as ConvertPlan}
				updateConvert={updateConvertStageForThisStep}
				setIsInteractionActive={setIsInteractionActive}
				isDisabled={!(index1 > firstAmendableStep0)}
			/>
		}
		{
			index1 >= ai && // detach must happen after, or in same step, as attach
			index1 !== di && // This is already the detach step, no point in showing an option to move it here
			index1 > firstAmendableStep0 && // Only editable steps are eligible
			di > firstAmendableStep0 && // if detach has already happened, do not show this option
			!isSkippedStep && // don't offer this option if the step is skipped
			<UITooltipWrapper tooltipContent="Move detach operation to this step" useRefHelper>
				<HoverButton label="Detach" onClick={moveDetachHere} />
			</UITooltipWrapper>
		}
	</SidecarContainer>
	)
}

const SidecarContainer = styled.div`
	button {
		margin: 4px;
		padding: 4px 8px;
		font-size: 0.9em;
		svg {
			height: 1em;
			width: 1em;
		}
	}
`;

const HoverButton = styled(UIButton).attrs(props => ({
	ghost: true
}))`
	opacity: 0;
	.steprow:hover & {
		opacity: 1;
	}
	.row-interacting & {
		opacity: 1;
	}
`;




const ConvertButton = ({ typeId, convert = {} as ConvertPlan, updateConvert, setIsInteractionActive, isDisabled = false }: {
	typeId: number
	convert: ConvertPlan
	updateConvert:(newStageId: number | 'delete') => void
	setIsInteractionActive
	isDisabled: boolean
}) => {
	const [isVisible, setIsVisible] = useState(false);
	function toggleVisible(){
		setIsVisible(!isVisible)
		setIsInteractionActive(!isVisible)
	}
	
	const { getStageNameFromId } = useInvUniqueType(typeId);

	return (
	<UITooltipWrapper 
		tooltipContent={
			<ConvertStageMenu 
				typeId={typeId}
				convert={convert}
				updateConvert={updateConvert}
				closeMenu={toggleVisible}
			/>} 
		useRefHelper 
		placement="bottom"
		isVisible={isVisible} 
		onClickOutside={toggleVisible}
	>
		{typeof convert.dest_stage_id === 'number' ? 
			<UIButton label={<>Convert <ArrowRightAlt /> {getStageNameFromId(convert.dest_stage_id)} </>}  onClick={isDisabled ? () => {} : toggleVisible}/> 
			:
			<HoverButton label="Convert to..." onClick={isDisabled ? () => {} : toggleVisible} />
		}
	</UITooltipWrapper>
	)
}

const ConvertStageMenu = ({ typeId, convert = {} as ConvertPlan, closeMenu, updateConvert }: {
	typeId: number
	convert: ConvertPlan
	updateConvert:(newStageId: number | 'delete') => void
	closeMenu:() => void
}) => {
	
	const { invUnType } = useInvUniqueType(typeId);

	function selectNewStage(stageId: number) {
		updateConvert(stageId);
		closeMenu();
	}

	return (
		<ConvertMenuContainer flexDirection="column" flexJustify="center">
			<UIHeading level="3">Convert to Stage...</UIHeading>
			<div>
				{invUnType.stages?.map( stage => (
					<UIChip 
						key={stage.id}
						label={stage.name} 
						clickable={convert.dest_stage_id !== stage.id}
						onClick={selectNewStage.bind(this, stage.id)}
						outline={convert.dest_stage_id !== stage.id} 
					/>) 
				) }
			</div>

			{convert.dest_stage_id && <UIButton outline label="Remove Convert Operation" onClick={selectNewStage.bind(this, 'delete')} />}
		</ConvertMenuContainer>
	)
}

const ConvertMenuContainer = styled(UIFlexbox)`
	padding: 20px;
	text-align: center;
`;