
import { gql } from '@apollo/client';
import { tagAllColumns, GET_TAGS_BY_TABLE } from './tagFetch';
import { apolloClient, updateCacheAfterInsert } from './apollo';
import { thingLookup } from '@constants';

export const ADD_TAG = gql`
	mutation (		
		$table_id: Int!
		$name: citext!
	){
		insert_tag (
			objects: {
				table_id: $table_id
				name: $name				
			}
		) {
			returning {
				${tagAllColumns}
			}			
		}
	}
`;

export const UPDATE_TAG = gql`
	mutation (		
		$id: Int!
		$table_id: Int!
		$name: citext!
	){
		update_tag (
			where: { 
				id: { _eq: $id }
			},
			_set: {				
				name: $name				
			}
		) {
			returning {
				${tagAllColumns}
			}			
		}
	}
`;

export const addTag = (tableName: string, name: string, tagId?: number) => {
	const tableId = thingLookup[tableName].sharedTableId;
	if (tagId) {
		return apolloClient.mutate({
			mutation: UPDATE_TAG,
			variables: {
				id: tagId,
				table_id: tableId,
				name: name
			}
		}).then( (queryResponse) => {
			return queryResponse?.data?.insert_tag?.returning?.[0] ?? {}
		});
	}
	return apolloClient.mutate({ 
		mutation: ADD_TAG, 
		variables: {
			table_id: tableId,
			name: name
		},
		update(cache, result) {
			updateCacheAfterInsert(cache, result, 'tag', GET_TAGS_BY_TABLE, { table_id: tableId } );
		}
	}).then( (queryResponse) => {		
		return queryResponse.data?.insert_tag?.returning?.[0] ?? {}
	});
};