import React from 'react';

import { UIRoutedLink } from 'routing';

import { TableDateField } from 'components/Table/TableDateField';


import { FormInstanceWithForeignObjects } from 'types';
import { ColDefs } from './tableColumnUtility';


type FormInstanceColId = 'jobName' | 'stepName' | 'invUniqueName' | 'orderName' | 'updatedAt';

export const formInstanceCols: ColDefs<FormInstanceColId> = {
	jobName: {
		Header: 'Job',
		accessor: `step_history.job.name`,
		Cell: (table) => {				
			const item: FormInstanceWithForeignObjects = table.row.original;
			return (<UIRoutedLink 
					text={item.step_history?.job?.name} 
					linkToRoute="jobById"
					linkToVars={{ id: item.step_history?.job_id }}
				/> ) 
		}
	},
	stepName: {
		Header: 'Step',
		accessor: `step_history.step_revision.step_info.name`,
		Cell: (table) => {				
			const item: FormInstanceWithForeignObjects = table.row.original;
			return (<UIRoutedLink 
					text={item.step_history?.step_revision?.step_info?.name} 
					linkToRoute="operateStep"
					linkToVars={{ id: item.step_history?.job_id, index: item.step_history?.job_step_index }}
				/> ) 
		}
	},
	invUniqueName: {
		Header: 'ID',
		accessor: `inventory_unique.name`,
		Cell: (table) => {				
			const item: FormInstanceWithForeignObjects = table.row.original;
			return (<UIRoutedLink 
					text={item.inventory_unique?.name} 
					linkToRoute="inventoryUniqueById"
					linkToVars={{ id: item.foreign_id }}
				/> ) 
		}
	},
	orderName: {
		Header: 'Order',
		accessor: `order.name`,
		Cell: (table) => {				
			const item: FormInstanceWithForeignObjects = table.row.original;
			return (<UIRoutedLink 
					text={item.order?.name} 
					linkToRoute="orderById"
					linkToVars={{ id: item.foreign_id }}
				/> ) 
		}
	},
	updatedAt: {
		Header: 'Last Updated',
		accessor: 'updated_at',
		disableGlobalFilter: true,
		Cell: ({ cell: { value } }) => <TableDateField ISOdateString={value} dateDisplayFormat="timeTodayDateOther" />
	
	}
}