import { isStepSkipped } from 'components/WorkflowEditor';
import { getObjectFromArray } from 'utility';
import { StepPlan, WorkflowDatabaseStep, SupplementalStepInfo, WorkflowOptions, InventoryUniqueType, Step } from 'types';
import { ValidationErrors } from '../StepSetup/StepForm';
import { StepFormCategory } from '@constants';

export function isReviewInputRequired(stepPlan: StepPlan, supp: SupplementalStepInfo) {
	if (stepPlan?.tool?.method === 'flex_engineer' && !supp?.tool_value) { 
		return true; 
	}
	else if (stepPlan?.recipe?.method === 'flex_engineer' && !supp?.recipe_value) { 
		return true; 
	}
	
	return false;
}

/**
 * This function reviews each step and determined of "engineer select" is needed for anything
 * 
 * @param {WorkflowDatabaseStep[]} steps Workflow Steps
 * @returns {Object} If review is complete, and which stels still need review
 */
export function reviewJobWorkflow(steps: WorkflowDatabaseStep[]): {
	areAllStepsComplete: boolean, 
	needReviewArray: Array<number>
} {
	// Assumes that all steps are in cache already. You should do this in whatever component is calling this fn

	const indexArrayUnfiltered = steps.map( (step, index) => {	
		const { plan, supp } = step;
		return isReviewInputRequired(plan, supp) ? index : -1;
	});

	const needReview =  indexArrayUnfiltered.filter( value => { return value > -1 });
	
	return { 
		areAllStepsComplete: needReview.length === 0,
		needReviewArray: needReview
	}
}

export function validateWorkflowInventoryUnique(
	steps: WorkflowDatabaseStep[],
	workflowOptions: WorkflowOptions, 
	invUniqueType: InventoryUniqueType // pass this in to get the stage info
){
	let warningMessage = '';

	function returnError(msg) {
		return {
			isValid: false,
			errorMessage: msg,
			warningMessage: warningMessage
		}
	}
	function returnValidResponse() {
		return {
			isValid: true,
			errorMessage: '',
			warningMessage: warningMessage
		}
	}

	function isStageIdValid(stageId: number) {
		const invUniqueStage = getObjectFromArray(invUniqueType.stages, 'id', stageId);
		const isStageValid = invUniqueStage.id;
		return isStageValid;
	}

	const isEnabled = workflowOptions.inventory_unique_enabled;

	if (isEnabled){

		const attachIndex = workflowOptions.inventory_unique?.attach?.step_index;
		const detachIndex = workflowOptions.inventory_unique?.detach?.step_index;
		
		// Check that the attach and detach are on valid steps
		if (attachIndex < 1 || attachIndex > steps.length) { return returnError('Attach position is invalid.') }
		if (detachIndex < 1 || detachIndex > steps.length) { return returnError('Detach position is invalid.') }
		if (isStepSkipped(steps[attachIndex - 1])) { return returnError('Attach cannot occur in a skipped step.') }
		if (isStepSkipped(steps[detachIndex - 1])) { return returnError('Detach cannot occur in a skipped step.') }
		
		
		// Check that qty and order of attach, detach, and convert are within constraints
		if (attachIndex > detachIndex) { return returnError('Attach operation much occur before detach operation.') } // And same step is OK too

		const converts = workflowOptions.inventory_unique?.converts;
		// if (converts.length === 0) { warningMessage = 'There is no convert operation in this workflow.' }
		for (let i = 0; i < converts?.length; i++) { // can't use forEach here because we need to return out of the entire function
			const convert = converts[i];
			if ( (convert.step_index > detachIndex) || (convert.step_index < attachIndex) ) { 
				return returnError('Convert operation much occur between attach and detach.') 
			}
		}

		// Check that all stage IDs match with type ID
		const attachStageNeeded = workflowOptions.inventory_unique?.attach?.source === 'choose';
		const attachStageIds = workflowOptions.inventory_unique?.attach?.stage_ids;
		if (attachStageNeeded && attachStageIds) {
			attachStageIds.forEach( stageId => {
				if(!isStageIdValid(stageId)) { return returnError(`Attach stage does not match with type "${invUniqueType.name}"`) }
			})
		}

		if (converts?.length > 0) {
			converts.forEach( convert => {
				if(!isStageIdValid(convert.dest_stage_id)) { return returnError(`Convert stage does not match with type "${invUniqueType.name}"`) }
			})
		}
	}

	return returnValidResponse();
}

export function staleLinkCheckWorkflow(workflowSteps: WorkflowDatabaseStep[], allSteps: Step[]) {
	// cross reference wf settings with available ids in steps
	const errorsArray = workflowSteps.map( dbStep => {
		const step = getObjectFromArray(allSteps, 'id', dbStep.step_id);
		return staleLinkCheckStep(dbStep.plan || {} as StepPlan, step);

	});

	return errorsArray;
}

export function staleLinkCheckStep(stepPlan: StepPlan, step: Step = {} as Step /** put a default in case query has not loaded fully */): ValidationErrors {
	let validationReturn: ValidationErrors = {
		hasBrokenLink: false,
		linkErrors: {}
	}

	let errors: ValidationErrors['linkErrors'][StepFormCategory] = [];
	const recipePlan = stepPlan.recipe;
		if (recipePlan?.method === 'flex_engineer' || recipePlan?.method === 'flex_operator') {
			if (!(step.recipes?.length > 0)) {
				errors.push({ message: 'There are no recipes linked with this step.', invalidLinkId: 0 });
			}
		}
		if (recipePlan?.value && !step.recipes?.includes(recipePlan?.value)) {
			errors.push({ message: 'Assigned recipe "__name__" is not currently linked to this step', invalidLinkId: recipePlan?.value });
		}
		if (recipePlan?.default_value && !step.recipes?.includes(recipePlan?.default_value)) {
			errors.push({ message: 'Default recipe "__name__" is not currently linked to this step', invalidLinkId: recipePlan?.default_value });
		}
		if (errors.length > 0) {
			validationReturn.linkErrors.recipe = errors;
		}


	const toolPlan = stepPlan.tool;
		errors = [];
		if (toolPlan?.method === 'flex_engineer' || toolPlan?.method === 'flex_operator') {
			if (!(step.tools?.length > 0)) {
				errors.push({ message: 'There are no tools linked with this step.', invalidLinkId: 0 });
			}
		}
		if (toolPlan?.value && !step.tools?.includes(toolPlan?.value)) {
			errors.push({ message: 'Assigned tool "__name__" is not currently linked to this step', invalidLinkId: toolPlan?.value });
		}
		if (toolPlan?.default_value && !step.tools?.includes(toolPlan?.default_value)) {
			errors.push({ message: 'Default tool "__name__" is not currently linked to this step', invalidLinkId: toolPlan?.default_value });
		}
		if (errors.length > 0) {
			validationReturn.linkErrors.tool = errors;
		}


	const rawPartPlan = stepPlan.inventory_effect;
		errors = [];
		rawPartPlan?.items
			?.filter(eff => !step.raw_parts?.includes(eff.id))
			?.forEach( eff => {
				errors.push({ message: 'Material "__name__" is not currently linked to this step', invalidLinkId: eff.id, action: 'remove' });
			});
		if (errors.length > 0) {
			validationReturn.linkErrors.inventory_effect = errors;
		}

	const forms = stepPlan.forms;
		errors = [];
		forms?.filter(formId => !step.forms?.includes(formId))
			?.forEach( formId => {
				errors.push({ message: 'Form "__name__" is not currently linked to this step', invalidLinkId: formId, action: 'remove' });
			});
		if (errors.length > 0) {
			validationReturn.linkErrors.forms = errors;
		}



	if (Object.keys(validationReturn.linkErrors).length > 0) {
		validationReturn.hasBrokenLink = true;
	}

	return validationReturn;
}