import React, { useEffect } from 'react';
import styled from 'styled-components';

import App from 'root/App';
import { ErrorBoundary } from 'root/ErrorBoundary';
import { GhostButton } from 'components/Ghosting/GhostControl';

import { UIModalSimpleAction, useModal } from 'kit';
import { GlobalDialogData } from 'hooks';
import { useDialogStore } from 'store';

import 'root/index.css';

import { hot } from 'react-hot-loader/root';
import { BrowserRouter as Router } from 'react-router-dom';
import { history } from 'routing';

import { ApolloProvider } from '@apollo/client';

import { Grommet } from 'grommet';
import { theme, mUiTheme, baseAppStyles } from 'root/theme.ts';
// import { StylesProvider, ThemeProvider } from '@material-ui/core/styles'; // using require helped with intellisense speed https://github.com/microsoft/TypeScript/issues/34801#issuecomment-633622448
const StylesProvider = require('@material-ui/core/styles').StylesProvider;
const ThemeProvider = require('@material-ui/core/styles').ThemeProvider;
import { apolloClient } from 'services/apollo';


import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { PanelContentProvider } from './components/Navigation/PanelState';

import { useLocation } from 'react-router-dom';

toast.configure();

import { Auth0Provider } from '@auth0/auth0-react';

export const WrappedApp = () => {
	
	// vv	Initialize global alert dialog
	const modalProps = useModal<GlobalDialogData>();
	const setGlobalModal = useDialogStore(state => state.setModalProps);
	useEffect(() => {
		setGlobalModal(modalProps);
	}, [] )
	// ^^	Initialize global alert dialog

	return (
	<Router>
		<Auth0Provider
			domain={process.env.REACT_APP_AUTH0_DOMAIN}
			clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
			redirectUri={window.location.origin}
		>
		<ApolloProvider client={apolloClient}>
			<ThemeProvider theme={mUiTheme}>  {/* Material-UI's theme */}
			<StylesProvider injectFirst>  {/* injectFirst => Now, you can override Material-UI's styles. */}
				<StyledGrommet theme={theme} full style={{ overflow: 'hidden' }}>
					<PanelContentProvider>				
							<ScrollToTop />
							<ErrorBoundary history={history}>
								<App />

								<UIModalSimpleAction
									{...modalProps.modalData}
									{...modalProps}
								/>

								{process.env.NODE_ENV === 'development' ? <GhostButton /> : null}
							</ErrorBoundary>
					</PanelContentProvider>
				</StyledGrommet>
			</StylesProvider>
			</ThemeProvider>
		</ApolloProvider>
		</Auth0Provider>
	</Router>
	);
}

const StyledGrommet = styled(Grommet)`
	${baseAppStyles}
`;

export const HotWrappedApp = hot(WrappedApp);



export default function ScrollToTop() {
	const { pathname } = useLocation();

	useEffect(() => {
		const scrollWindow = document.querySelector('.mainpage') || window; // This will error if the selector is not found on the page, so default to window in case element is not found
		scrollWindow.scrollTo(0, 0); 
	}, [pathname]);

	return null;
}