import create from 'zustand';
import { GenericSet } from './internal';
import { User } from 'types';

type AuthStore = {
	token: string;
	internalUser: User;
};

export const useAuthStore = create<AuthStore & GenericSet<AuthStore>>(set => ({
	token: null,
	internalUser: {} as User,
	setValue: (key, value) => set({ [key]: value })
}));
