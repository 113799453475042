import React from 'react';

import { StepTask, TaskData, isFormComplete } from './useOperateStep';
import { ParameterSelect } from './OperateParameterSelect';
import { OperatePartRaw } from './OperatePartRaw'
import { OperateAttachInventory } from './OperateAttachInventory';

import { deepCopy, getObjectFromArray } from 'utility';
import { StepPlan, SupplementalStepInfo, Job, FormDefinition, StepHistoryRaw, FormInstanceLinked, InventoryUniqueItem } from 'types';
import { TaskBannerPresentational } from './TaskBannerPresentational';
import { OperateFormButtonModal } from './OperateForms';
import { OperateFormUniqueInventory } from './OperateFormInventoryUnique';
import { useFormDefinitions } from 'hooks';
import { StepHistoryHelper } from 'root/services/stepHydrate';


export const OperateTaskBanners = (
	props: {
		taskType: StepTask
		allTaskData: TaskData
		setTaskData
		isTaskComplete
		stepId: number
		stepPlan: StepPlan
		job: Job
		supp: SupplementalStepInfo
		stepHistory: StepHistoryRaw
		stepHistoryHelper: StepHistoryHelper
		uniqueItems: InventoryUniqueItem[]
	}
) => {
	const taskData = props.allTaskData[props.taskType];

	let ActionComponent;
	if (props.taskType === 'recipe' || props.taskType === 'tool') {
		ActionComponent = (
			<ParameterSelect
				stepId={props.stepId}
				itemType={props.taskType}
				itemValue={taskData}
				updateValue={updateTaskValue}
			/>
		);
	}
	else if (props.taskType === 'attach') {
		ActionComponent = (
			<OperateAttachInventory
				stagedIds={taskData}
				updateStagedIds={updateTaskValue}
				job={props.job}
			/>
		);
	}
	else if (props.taskType === 'partRaw') {
		ActionComponent = (
			<OperatePartRaw
				stepPlan={props.stepPlan}
				updateInvEffects={updateTaskValue}
			/>
		);
	}
	else if (props.taskType === 'inventory_unique_form') {
		ActionComponent = (
			<OperateFormUniqueInventory
				stepPlan={props.stepPlan}
				uniqueItems={props.uniqueItems}
				viewMode="button"
			/>
		);
	}
	else if (props.taskType === 'form') {
		const { formDefinitions } = useFormDefinitions('ids', { ids: props.stepPlan.forms });


		const banners = formDefinitions.map( (formDefinition: FormDefinition) => {
			const existingForm: FormInstanceLinked = getObjectFromArray(props.stepHistory.forms || [], 'form_definition_id', formDefinition.id);
			ActionComponent =  (
				<OperateFormButtonModal
					key={formDefinition.id}
					formDefinition={formDefinition}
					existingForm={existingForm}
					stepHistoryHelper={props.stepHistoryHelper}
					stepHistoryId={props.stepHistory.id}
				/>
			)
			return (
				<TaskBannerPresentational
					key={formDefinition.id}
					taskType={props.taskType}
					isTaskCompleted={isFormComplete(formDefinition.id, props.stepHistory)}
					ActionComponent={ActionComponent}
				/>
			);
		})
	return <>{banners}</>;
	}
	
	function updateTaskValue(newValue) {
		let newTaskData = deepCopy(props.allTaskData);
		newTaskData[props.taskType] = newValue;
		props.setTaskData(newTaskData);
	}


	return (
		<TaskBannerPresentational
			taskType={props.taskType}
			isTaskCompleted={props.isTaskComplete(props.taskType)}
			ActionComponent={ActionComponent}
		/>
	);
}
