import React from 'react';

import { TableDateMultiField } from 'components/Table/TableDateField';
import { ColDefs } from './tableColumnUtility';

import { UIRoutedLink } from 'routing';
import { Step } from 'types';



type StepColId = 'name' | 'description' | 'createdAt' | 'updatedAt';

export const stepCols: ColDefs<StepColId> = {
	name: 	{
		Header: 'Step',
		accessor: 'name',
		filter: 'fuzzyText',
		className: 'emphasis',
		Cell: (table) => {
			const item: Step = table.row.original;
			return (
				<UIRoutedLink
					text={item.name}
					linkToRoute={'stepSetupById'}
					linkToVars={{ id: item.id }}
				/> 
			)
		}
	},
	description: {
		Header: 'Description',
		accessor: 'description',
		filter: 'fuzzyText'
	},
	createdAt: {
		Header: 'Created',
		accessor: 'created_at',
		disableGlobalFilter: true,
		Cell: ({ cell: { value } }) => <TableDateMultiField ISOdateString={value} dateDisplayFormatTop="dateShortWithYear" dateDisplayFormatBottom="time" />
	},
	updatedAt: {
		Header: 'Last Changed',
		accessor: 'updated_at',
		disableGlobalFilter: true,
		Cell: ({ cell: { value } }) => <TableDateMultiField ISOdateString={value} dateDisplayFormatTop="dateShortWithYear" dateDisplayFormatBottom="time" />
	}
}