import React, { useState, useCallback, useEffect } from 'react';

import { useUIForm, useTabs, TabContext, useFormCustomRegister, useModal, ModalHookProps } from 'kit';
import { Unlink } from '@styled-icons/fa-solid/Unlink';

import { useQuery } from '@apollo/client';
import { GET_STEP_HISTORY_BY_JOB_INDEX } from 'services/stepHistoryFetch';
import { GET_JOBS } from '@constants/gql';
import { GET_WORKFLOWS } from 'services/workflowFetch';
import { GET_INVENTORY_UNIQUE_TYPES } from 'services/inventoryUniqueFetch';

import { addWorkflow, updateWorkflow } from 'services/workflowMutate';

import { updateSuppReorder, wasOriginalOrderRestored, removeMod } from './modFunctions';
import { validateWorkflowInventoryUnique, staleLinkCheckWorkflow } from './validateWorkflow';
import { updateWorkflowOptionsToCurrentVersion } from './workflowOptionsVersion';
import { updateIndexesOnAdd, updateIndexesOnDelete, updateIndexesOnReorder } from './workflowOptionsInventoryUniqueFunctions';
import { validateWorkflowOptionsSchema, validateStepSuppSchema } from 'services/validateSchema';
import { ValidationErrors } from 'components/StepSetup/StepForm';

import { WorkflowDatabaseStep, WorkflowOptions, SupplementalStepInfo, currentSuppVersion, currentStepVersion, InventoryUniqueType, currentWorkflowOptionsVersion, WorkflowProductSelection, WorkflowInsertUpdateObj, StepHistoryRaw, JobUpdateObj, StepPlan, Job, Workflow, Step } from 'types';
import { useDynamicList, DynamicListContext, useThingsAll, useDialog } from 'hooks';
import { useNavFull } from 'routing';
import { deepCopy, getObjectFromArray, arrayString } from 'utility';
import { toast } from 'react-toastify';
import { UseFormMethods } from 'react-hook-form';
import { useAuthStore, useTransientStore } from 'store';
import { useParams, useLocation } from 'react-router-dom';
import { updateThing } from 'root/services/thingMutate';
import isEqual from 'lodash.isequal';

export type WorkflowEditHookProps = {
	id: number
	type: WorkflowEditType
	workflowOptions: WorkflowOptions
	updateWorkflowOptions: (opts: WorkflowOptions) => void
	dynamicListContext: DynamicListContext<WorkflowDatabaseStep>
	databaseSteps: WorkflowDatabaseStep[]
	//setDatabaseSteps: React.Dispatch<any>
	validateInvUnique(steps: WorkflowDatabaseStep[], workflowOptions: WorkflowOptions): boolean
	isValid: boolean
	selectedIndex: number
	setSelectedIndex: React.Dispatch<number>
	updatePlan(newPlan: StepPlan, index: number): WorkflowDatabaseStep[]
	removeSuppKey(key: keyof SupplementalStepInfo, index?: number): WorkflowDatabaseStep[]
	updateSuppPartial(newPartialSupp: Partial<SupplementalStepInfo>, index?: number): WorkflowDatabaseStep[]
	updateProductSelections(selections: WorkflowProductSelection[]): WorkflowOptions
	saveToDB(event?: any): void
	isJobDraft: boolean
	item //job or saved workflow
	isAmendment: boolean
	isCustomWorkflow: boolean
	firstAmendableStep0: number
	formMethods: UseFormMethods
	leftPanelTabContext: TabContext
	tagModalProps: ModalHookProps
	deviationTags: number[]
	setDeviationTags: React.Dispatch<number[]>
	setDragDropSourceList: React.Dispatch<any[]>
	workflowValidationErrors: ValidationErrors[]
}


export type WorkflowEditType = 'job' | 'workflow';

const defaultWFOptions = { 
	inventory_unique_enabled: true,
	inventory_unique: {
		type_id: 1, 
		attach: {
			source: 'choose',
			step_index: 1
		},
		detach: {
			status: 'stock',
			step_index: 1

		}
	} 
} as WorkflowOptions

export const useWorkflowEdit = (): WorkflowEditHookProps => {
	// Fetch
		const initialize = useCallback((fetchedItem: Job | Workflow) => {
			let fetchedOptions = fetchedItem.workflow_options || {} as WorkflowOptions;
			if (fetchedOptions.version !== currentWorkflowOptionsVersion) {
				fetchedOptions = updateWorkflowOptionsToCurrentVersion(fetchedOptions);
			}
			updateWorkflowOptions(fetchedOptions);
			validateInvUnique(fetchedItem.steps, fetchedOptions); // validate once you have the data so that the save button can enable
		}, [])

		const { item, type, id, isWorkflowDraftFromUrl, isAmendment, firstAmendableStep0, isCustomWorkflow } = useFetchWorkflowEdit(initialize);
		const isJobDraft = item.job_status === 'draft';
			
		const { items: steps } = useThingsAll('step');

		const { 
			data: { inventory_unique_type: typeDefinitions } = { inventory_unique_type: [] as InventoryUniqueType[] }
		} = useQuery(GET_INVENTORY_UNIQUE_TYPES);	// just fetch all types just in case



	// Tab Context
		const leftPanelTabContext = useTabs(); 
	// Nav functions
		const navigateToWorkflowById = useNavFull('workflowEditorWorkflow');
	// Modal
		const tagModalProps = useModal();
	// alert dialog for link validation
		const { showAlert } = useDialog();


	// Form Context
		const { formMethods } = useUIForm(item, { resetTrigger: item?.id });
		const { updateFormValue: updateFormValue_Steps } = useFormCustomRegister('steps', { formMethods: formMethods });
		const { updateFormValue: updateFormValue_Options } = useFormCustomRegister('workflow_options', { formMethods: formMethods });

	// various hooks for handling transfer from job to workflow editor when broken links found
		const navToSavedWorkflow = useNavFull('workflowEditorWorkflow');
		const setTransientData = useTransientStore(state => state.setValue);
		const consumeTransientData = useTransientStore(state => state.consumeValue);

	// State
		const [workflowOptions, setWorkflowOptions] = useState<WorkflowOptions>({ } as WorkflowOptions);
		const [workflowValidationErrors, setWorkflowValidationErrors] = useState<ValidationErrors[]>();
		const [deviationTags, setDeviationTags] = useState<number[]>(item.deviation_tags || []);
		const [isValid, setIsValid] = useState(false);
		const [wasRedirected] = useState(consumeTransientData('isRedirectedFromJobBrokenLink'));
		const [hasBrokenLinkAlertBeenShown, setHasBrokenLinkBeenShown] = useState(false);
		const [dragDropSourceList, setDragDropSourceList] = useState<WorkflowDatabaseStep[]>();

	// Drag Drop and Dynamic List
		
		useEffect(() => (
			setDragDropSourceList(
				steps.map( step => convertStepToDragDropSourceObj(step, isAmendment) )
			)
		), [steps.length, isAmendment]);
	
		const dynamicListContext =  useDynamicList<WorkflowDatabaseStep>(item.steps, { 
			onUpdate: onUpdateSteps,
			onDelete: onDelete, 
			allowZeroLengthArray: true,
			dragDropOptions: { 
				onReorder,
				beforeReorder,
				onAdd: onAdd, 
				multiDropIds: {
					deleteBinId: 'trash', //not currently used
					originatingBinId: 'source',
					destinationBinId: 'destination'
				},
				sourceList: dragDropSourceList
			} 
		});
		const { rows: databaseSteps, editSingleValueInRow: updateDatabaseStep, selectedIndex, setSelectedIndex } = dynamicListContext;

		// initialize
		useEffect(() => {
			validateLinkErrors(databaseSteps);
		}, [steps.length, item.id, databaseSteps.length])


		function onUpdateSteps(rows: WorkflowDatabaseStep[]){
			updateFormValue_Steps(rows);
			validateLinkErrors(rows);
		}

		function beforeReorder(reorderedStep: WorkflowDatabaseStep, provisionalNewSteps, originalIndex0, newIndex0){
			if (!isAmendment) { // If this isn't an amendment to a workflow, don't change anything and just return the original step
				return reorderedStep;
			}

			const prevReorderData = reorderedStep.supp?.workflow_mod?.reorder;
			let mod = reorderedStep?.supp?.workflow_mod;
			if (mod?.add) {
				// do nothing - don't track reorders for added steps
			}
			else if (prevReorderData) {
				// check if the new reorder actually puts it back in its original location
				if (wasOriginalOrderRestored(provisionalNewSteps, reorderedStep, newIndex0)){
					mod = removeMod(reorderedStep.supp?.workflow_mod, 'reorder');
				}
			}
			else {
				// record the original order data in to supp
				mod = updateSuppReorder(databaseSteps, reorderedStep, originalIndex0);
			}
			const moddedStep = deepCopy(reorderedStep);
			const newSupp = {
				...moddedStep.supp,
				workflow_mod: mod
			}
			if (typeof mod === 'undefined' && Object.keys(newSupp).length === 1) {
				// The mod is the onlything that exists inside of supp, and it's undefined, delete it
				delete moddedStep.supp
			}
			else {
				moddedStep.supp = newSupp;
			}
			return moddedStep
		}


		function onReorder(newSteps: WorkflowDatabaseStep[], originalIndex0, newIndex0){
			const newWorkflowOptions = updateIndexesOnReorder(workflowOptions, newSteps, originalIndex0, newIndex0, firstAmendableStep0);
			updateWorkflowOptions(newWorkflowOptions);
			validateInvUnique(newSteps, newWorkflowOptions);
		}

		function onDelete(newSteps, deletedRow, deletedIndex0){
			const newWorkflowOptions = updateIndexesOnDelete(workflowOptions, newSteps, deletedRow, deletedIndex0, firstAmendableStep0);
			updateWorkflowOptions(newWorkflowOptions);
			validateInvUnique(newSteps, newWorkflowOptions);
		}

		function onAdd(newSteps, addeddRow, addedIndex0){
			const newWorkflowOptions = updateIndexesOnAdd(workflowOptions, newSteps, addeddRow, addedIndex0, firstAmendableStep0);
			updateWorkflowOptions(newWorkflowOptions);
			validateInvUnique(newSteps, newWorkflowOptions);
		}

		
	// Helper functions
		function updateWorkflowOptions(newOpts: WorkflowOptions) {
			if (!isEqual(workflowOptions, newOpts)) {
				setWorkflowOptions(newOpts);
				updateFormValue_Options(newOpts);
				validateInvUnique(databaseSteps, newOpts);
			}
		}

		useEffect(() => {
			if (!item?.id) {
				updateWorkflowOptions(defaultWFOptions)
			}
		}, [item?.id])

		/** Updates the entire plan object for a given step index */
		function updatePlan(plan: StepPlan, index: number) {
			let newPlan = { ...plan }

			if (Object.keys(newPlan).length === 0 || (Object.keys(newPlan).length === 1 && newPlan.version) ) {
				// If empty, set the value to undefined. This will remove the object from the parent object
				return updateDatabaseStep(index, 'plan', void 0);
			}
			else if (Object.keys(newPlan).length > 0) {
				newPlan.version = currentStepVersion;
				// validate 
				//TODO???
				// const isSuppValid = validateStepSuppSchema(type, id, index, newSupp);
				// if (!isSuppValid) {
				// 	return; //exit the function and down allow supp to save
				// }
				return updateDatabaseStep(index, 'plan', newPlan);
			}
			
		}


		/**Updates only the keys that are present in the passed in object */
		function updateSuppPartial(newPartialSupp: Partial<SupplementalStepInfo>, indexToUpdate?: number) {
			const index = typeof indexToUpdate !== 'number' ? selectedIndex : indexToUpdate;
			const supp = databaseSteps[index]?.supp || {} as SupplementalStepInfo;

			let newSupp = {
				...supp,
				...newPartialSupp
			}
			return updateSupp(newSupp, index);
		}

		function removeSuppKey(key: keyof SupplementalStepInfo, indexToUpdate?: number) {
			const index = typeof indexToUpdate !== 'number' ? selectedIndex : indexToUpdate;

			const supp = deepCopy(databaseSteps[index]?.supp) || {} as SupplementalStepInfo;
			delete supp[key]

			return updateSupp(supp, index);
		}

			/** Updates the entire supp object. Generally you should use the partial version of this function, so that existing values won't get overwrritten. For now this shouldn't be exposed externally*/
		function updateSupp(supp: SupplementalStepInfo, index: number) {
			let newSupp = { ...supp }
			for (const suppKey in newSupp) {
				if (Object.prototype.hasOwnProperty.call(newSupp, suppKey)) {
					if (typeof newSupp[suppKey] === 'undefined') {
						delete newSupp[suppKey];
					}
				}
			}

			if (Object.keys(newSupp).length === 0 || (Object.keys(newSupp).length === 1 && newSupp.version) ) {
				// If supp is empty, set the value to undefined. This will remove supp from the parent object
				return updateDatabaseStep(index, 'supp', void 0);
			}
			else if (Object.keys(newSupp).length > 0) {
				newSupp.version = currentSuppVersion;
				// validate Supp
				const isSuppValid = validateStepSuppSchema(type, id, index, newSupp);
				if (!isSuppValid) {
					return; //exit the function and down allow supp to save
				}
			}
			else {
				newSupp = {} as SupplementalStepInfo;
			}
			
			return updateDatabaseStep(index, 'supp', newSupp);
		}

		function updateProductSelections(selections: WorkflowProductSelection[]) {
			if (!workflowOptions?.product) { return }
			let newOptions = deepCopy(workflowOptions);
			newOptions.product.selections = selections;
			
			const isSchemaValid = validateWorkflowOptionsSchema(type, id, newOptions);
		
			if (isSchemaValid) {
				updateWorkflowOptions(newOptions);
				return newOptions;
			}
			return workflowOptions;
		}

		//Need to use use handleSubmit here so that isDirty gets reset properly. 
		const saveToDB = () => {
			const formData: any = formMethods.getValues();
			console.log(formMethods.errors, formData)
			// Make sure you call getValues first before any of the other methods (like trigger) since that seems to mess with the values....
			if (type === 'job') {
				const jobObj: JobUpdateObj = {
					name: formData.name,
					steps: formData.steps, 
					workflow_options: formData.workflow_options,
					deviation_tags: arrayString(deviationTags)
				}
				updateThing('job', id, jobObj);
			}
			else if (type === 'workflow') {
				const isNewWorkflow = isWorkflowDraftFromUrl;
				
				// First, form validation
				formMethods.trigger().then(success => {
					if (!success) {
						leftPanelTabContext.setTabIndex(0);
						toast.error('Workflow not saved - please address errors in form.', {
							position: toast.POSITION.BOTTOM_CENTER
						});
					}
					else {
						//Next, steps validation
						if (databaseSteps.length === 0) {
							toast.error('There must be at least one step in the workflow.', {
								position: toast.POSITION.BOTTOM_CENTER
							});
						}
						else {
							const workflowObj: WorkflowInsertUpdateObj = {
								name: formData.name,
								description: formData.description,
								steps: formData.steps, 
								is_active: true,
								workflow_options: formData.workflow_options
							}
							if (isNewWorkflow) {
								addWorkflow(workflowObj).then( newWorkflow => {
									navigateToWorkflowById(newWorkflow.id, { replace: true });
								});
							}
							else {
								updateWorkflow(item.id, workflowObj);
							}
						}
					}
				})
			}
		}
		
		function validateInvUnique(newSteps: WorkflowDatabaseStep[], options){
			if (!(newSteps?.length > 0)) {
				setIsValid(false);
				return false;
			}
			const typeDefinition = getObjectFromArray(typeDefinitions, 'id', options?.inventory_unique?.type_id);
			const validationResult = validateWorkflowInventoryUnique(newSteps, options, typeDefinition);
			if (validationResult.isValid) {
				//saveToDB(steps, options);
			}
			
			if (validationResult.errorMessage) {
				toast.error(validationResult.errorMessage, {
					position: toast.POSITION.BOTTOM_CENTER,  autoClose: 4000
				});	
			}
			if (validationResult.warningMessage) {
				toast.warn(validationResult.warningMessage, {
					position: toast.POSITION.BOTTOM_CENTER,  autoClose: 4000
				});	
			}
			setIsValid(validationResult.isValid);
			return validationResult.isValid;
		}

		function acceptAndNavToWF(){
			setTransientData('isRedirectedFromJobBrokenLink', true);
			navToSavedWorkflow(item.workflow_id);
		}


		function validateLinkErrors(newSteps){
			if (item.id && steps.length > 0 && databaseSteps.length) {
				const linkErrors = staleLinkCheckWorkflow(newSteps, steps);
				if (!isEqual(linkErrors, workflowValidationErrors)) {
					setWorkflowValidationErrors(linkErrors);
				}
	
				const countBrokenLinks = linkErrors.filter(err => err.hasBrokenLink).length;
				if (!wasRedirected && countBrokenLinks > 0 && !hasBrokenLinkAlertBeenShown) {
					setHasBrokenLinkBeenShown(true);
					showAlert({ 
						title: 'Broken Links', 
						description: `There ${countBrokenLinks > 1 ? 'are steps' : 'is a step'} that use links that are no longer active. This errors must be corrected before starting a job with this workflow.`,
						Icon: <Unlink />,
						buttonText: isJobDraft ? 'Go to workflow' : 'OK',
						onSubmit: isJobDraft ? acceptAndNavToWF : () => {}
					});
	
				}
			}
		}
	
	return {
		type, id, isCustomWorkflow,
		workflowOptions, updateWorkflowOptions, updateProductSelections,
		deviationTags, setDeviationTags,
		dynamicListContext, 
		formMethods, leftPanelTabContext, tagModalProps, 
		databaseSteps, setDragDropSourceList,
		selectedIndex, setSelectedIndex,
		validateInvUnique, isValid,
		updatePlan, removeSuppKey, updateSuppPartial, saveToDB,
		isJobDraft,
		item, isAmendment, firstAmendableStep0,
		workflowValidationErrors
	};
};


const useFetchWorkflowEdit = (initialize) => {

	const { id: idString } = useParams(); // Fetch type and id from URL
	const id = parseInt(idString);
	const isWorkflowDraftFromUrl = isNaN(id);
	const location = useLocation(); 
	
	let type = '' as WorkflowEditType;
	if (location.pathname.includes('job')) {
		type = 'job';
	}
	else {
		type = 'workflow';
	}
	// Fetch
	let tableName = '';
	let query;
	if (type === 'job') {
		tableName = 'job';
		query = GET_JOBS;
	}
	else if (type === 'workflow') {
		tableName = 'workflow';
		query = GET_WORKFLOWS;
	}

	
	const { 
		data: { [tableName]: [item = {} as Job | Workflow] } = { [tableName]: [] } 
	} = useQuery(query, {
		skip: isWorkflowDraftFromUrl,
		variables: { id: id },
		//@ts-ignore
		onCompleted: ({ [tableName]: [fetchedItem = {}] } = { [tableName]: [] } ) => {
			initialize(fetchedItem);
		}
	});


	// see if the job has started yet
	const hasJobStarted = type === 'job' && typeof item.start_at !== 'undefined' && item.start_at !== null;
	
	const { 
		data: { step_history: [currentStep = {} as StepHistoryRaw] } = { step_history: [] } 
	} = useQuery(GET_STEP_HISTORY_BY_JOB_INDEX, {
		skip: !hasJobStarted || !item.current_step_index,
		variables: { jobId: id, index1: item.current_step_index }
	});

	const hasCurrentStepStarted = typeof currentStep.start_at !== 'undefined' && currentStep.start_at !== null;
	let firstAmendableStep0 = -1; 
	if (hasJobStarted) {
		firstAmendableStep0 = (item.current_step_index || -1) - 1 + (hasCurrentStepStarted ? 1 : 0);
	} 

	const isAmendment = type === 'job' && item.job_status !== 'draft';
	const isCustomWorkflow = type === 'job' && !item.workflow_id;


	return { item, type, id, isWorkflowDraftFromUrl, hasJobStarted, isAmendment, firstAmendableStep0, isCustomWorkflow }
}

export function convertStepToDragDropSourceObj(step: Step, isAmendment: boolean) {
	let workflowStep: WorkflowDatabaseStep = {
		step_id: step.id
	}
	if (isAmendment) {
		const currentUserId = useAuthStore.getState().internalUser.id;
		workflowStep.supp = {
			version: '2',
			workflow_mod: {
				add: true,
				user_id: currentUserId,
				time: new Date()
			}
		}
	}
	return workflowStep;
}