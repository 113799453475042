import { gql } from '@apollo/client';

import { jobAllColumns } from '@constants';

import { getObjectFromArray } from 'utility';
import { Job, JobAndStepStatus } from 'types';


export const GET_JOBS = gql`
	query GET_JOBS (
		$id: Int
		$status: [String!]
	) {
		job (
			where: { 
				job_status: { _in: $status },
				id: { _eq: $id }
			}
			order_by: { updated_at: desc }
		) {
			${jobAllColumns}
		}
	}
`;

export const GET_JOBS_IN = gql`
	query GET_JOBS_IN (
		$ids: [Int!]
	) {
		job (
			where: { 
				id: { _in: $ids }
			}
			order_by: { id: asc }
		) {
			${jobAllColumns}
		}
	}
`;

export function calcJobAndStepStatus(job: Job): JobAndStepStatus {
	const currentStepHistory = getObjectFromArray(job.step_histories_recent, 'job_step_index', job.current_step_index);
	if (job.job_status === 'complete') {
		return 'complete';
	}
	if (job.job_status === 'draft') {
		return 'draft';
	}

	if (!currentStepHistory?.start_at) {
		return 'queue';
	}
	if (currentStepHistory?.start_at) {
		return 'processing';
	}
	
	return 'active';
}


export const UPDATE_JOB = gql`
	mutation UPDATE_JOB ( 
		$id: Int!
		$changes: job_set_input!
	) {
		update_job (
			where: { id: { _eq: $id }}
			_set: $changes
		) {
			returning {
				${jobAllColumns}
			}
		}
	}
`;