import React from 'react';

import { UIChip } from 'kit';
import { UIRoutedLink } from 'routing';

import { SelectColumnFilter } from 'components/Table/TableFilters';
import { TableDateField } from 'components/Table/TableDateField';

import { capitalizeFirstLetter, dateFormat, deepCopy } from 'utility';

import { Order } from 'types';
import { ColDefs } from './tableColumnUtility';

interface OrderWithTableHelpers extends Order {
	customer_name?: string
}

export function transformOrderData(orders: Order[]): OrderWithTableHelpers[] {
	return orders.map( order => {
		let orderCopy: OrderWithTableHelpers = deepCopy(order);
		orderCopy.customer_name = order.contact?.company_name ?? (`${order.contact?.first_name ?? ''} ${order.contact?.last_name ?? ''}`);
		return orderCopy;
	})
}

type OrderColId = 'name' | 'customer' | 'po' | 'dueDate' | 'startTime' | 'fulfillStatus';

export const orderCols: ColDefs<OrderColId> = {
	name: {
		Header: 'Order',
		accessor: 'name',
		Cell: (table) => {
			const item: Order = table.row.original;
			return (
				<UIRoutedLink
					text={item.name}
					linkToRoute="orderById" 
					linkToVars={{ id: item.id }}
				/> 
			)
		}
	},
	startTime: {
		Header: 'Start Date',
		accessor: 'created_at',
		disableGlobalFilter: true,
		Cell: ({ cell: { value } }) => <TableDateField ISOdateString={value} dateDisplayFormat="timeTodayDateWithYearOther" />
	},
	customer: {
		Header: 'Customer',
		accessor: 'customer_name',
		Filter: SelectColumnFilter,
		filter: 'includes'
	},
	po: {
		Header: 'PO',
		accessor: 'po'
	},
	dueDate: {
		Header: 'Due Date',
		accessor: 'due_date',
		disableGlobalFilter: true,
		Cell: ({ cell: { value } }) => dateFormat(value, 'dateShortWithYear')
	},
	fulfillStatus: {
		Header: 'Status',
		accessor: 'fulfillment_status',
		Filter: SelectColumnFilter,
		filter: 'includes',
		className: 'center-align',
		Cell: (table) => {
			const item: Order = table.row.original;
			const status = item.fulfillment_status;
			return (
				<UIChip
					outline
					noMarginBottom
					size="xsmall"
					label={capitalizeFirstLetter(status)}
					success={status === 'fulfilled'}
				/> 
			)
		}
	}
}