import React from 'react';

import { UIPage, UICard, UIHeading, UIFlexbox, UIChip } from 'kit';

import { useQuery } from '@apollo/client';
import { GET_ORDERS } from 'services/orderFetch';

import { useParams } from 'react-router-dom';

import { RightPortal } from 'components/Navigation/RightPortal';
import { TabMenu } from 'components/Navigation/TabMenu';
import { ThreadsContainer, ThreadsList } from 'components/Messages/Thread';


import { CardViewChange } from 'components/common/CardViewChange';
import { ChangeSpec } from 'components/common/useChange';
import { changeOrder } from 'services/orderMutate';

import { Order, OrderChanges } from 'types';
import { FormInstanceAll } from 'components/Form/FormInstance';


import { OrderLineItems } from 'components/Orders/OrderLineItems';
import { AddressCard } from 'root/components/Contacts/Address';

export function OrderDetail() {	
	const { id } = useParams();
	const orderId = parseInt(id);
	
	const { 
		loading,
		error,
		data: { order: [order = {} as Order] } = { order: [] }
	} = useQuery(GET_ORDERS, { variables: { id: orderId } });

	if (loading) { return <p>Loading...</p>; }
	else if (error) { return <p>Error :(</p>; }

	return (
		<UIPage id="order-detail" contentContainerSize="large">
			<UIFlexbox childrenGrow="67/33" flexWrap="wrap">
				<OrderInfo order={order} isEditable />
				<UICard title="Customer">
					{order.contact_id ? 
						<>
							<div>{order.contact?.company_name}</div>
							<div>{order.contact?.first_name} {order.contact?.last_name}</div>

							<UIHeading level="4">Shipping Address</UIHeading>
							<AddressCard address={order.shipping_address} emptyMessage="No shipping address" />
							
							<UIHeading level="4">Billing Address</UIHeading>
							<AddressCard address={order.billing_address} emptyMessage="No billing address" />)
						</>
						: 
						<UIHeading level="6">No Customer Selected</UIHeading>
					}
				</UICard>
			</UIFlexbox>
			<OrderLineItems orderId={orderId} isEditable={order.fulfillment_status !== 'fulfilled'} />			

			<FormInstanceAll
				forms={order.forms}
				isEditable
				formType="order"
			/>

			<RightPortal>
				<TabMenu					
					comment={
						<ThreadsContainer>
							<UIHeading>Comments</UIHeading>
								<ThreadsList
									threads={order.threads} 
									referenceType="order" 
									referenceId={orderId} 
									//newThreadEffect={refresh}
								/>
						</ThreadsContainer>
					}
				/>
			</RightPortal>
		</UIPage>
	)
}



//order info components
export const OrderInfo = (
	{ order, isEditable = false }: {
		order: Order
		isEditable?: boolean
	}
) => {
	
	const specList: ChangeSpec<Order, OrderChanges>[] = [
		{
			key: 'name'
		},
		{
			key: 'fulfillment_status',
			isEditDisabled: true
		},
		{
			key: 'start_at',
			formatValue: 'datetime',
			input_type: 'datetime'
		},
		{
			key: 'stop_at',
			name: 'Completed At',
			formatValue: 'datetime',
			input_type: 'datetime',
			isEditDisabled: order.status !== 'complete'
		},
		{
			key: 'po',
			name: 'PO',
			is_required: false
		},
		{
			key: 'due_date',
			formatValue: 'date',
			input_type: 'date',
			is_required: false
		},
		{
			key: 'updated_at',
			name: 'Last Updated',
			isEditDisabled: true,
			formatValue: 'datetime'
		}
	]

	return (
		<CardViewChange
			title={`Order ${order.name}`} 
			specList={specList} 
			item={order} 
			saveFunction={changeOrder}
			canEdit={isEditable}

			viewPrepend={ 
				<UIFlexbox flexAlign="center" margin="mediumVertical">
					<UIChip light capitalize label={order.status} size="medium" success={order.status === 'complete'} />
				</UIFlexbox> 
			}
		/>
	)
}
