import React, { useState, useMemo, useContext } from 'react';
import { UIPage, UIButton, Placeholder, UISubtitle } from 'kit';

import { LeftPortal } from 'components/Navigation/LeftPortal';
import { PanelContext } from 'components/Navigation/PanelState';
import { VerticalMenuHeading } from 'root/components/Navigation/VerticalMenu';
import { useFormDefinition, useFormDefinitions } from 'hooks';

import { Table } from 'components/Table/Table';
import { formInstanceCols, ColumnDef } from 'components/Table/tableRef';

import { File } from '@styled-icons/boxicons-regular/File';
import { ArrowheadLeftOutline } from '@styled-icons/evaicons-outline/ArrowheadLeftOutline';
import { ArrowheadRightOutline } from '@styled-icons/evaicons-outline/ArrowheadRightOutline';

import { useQuery } from '@apollo/client';
import { GET_FORMS_BY_DEF_ID } from 'root/services/formFetch';

import { FormDefinition, FormFieldSpec, FormInstance } from 'types';
import { isMobileScreen, arrayToString } from 'utility';

import { getThingNameFromId } from 'root/services/thingHelper';
import { useParams } from 'react-router';
import { LeftPanelListItem, LeftPanel } from '../components/Navigation/LeftPanel';
import { thingLookup } from '@constants';
import { NavbarPortal } from 'root/components/Navigation/NavbarPortal';


export function FormInstancePage() {
	const { id } = useParams();
	const formDefId = parseInt(id);

	const { formDefinition } = useFormDefinition(formDefId)

	const { isLeftPanelOpen, toggleLeftPanel } = useContext(PanelContext);

	return (
		<UIPage id="formPage" contentContainerSize="xlarge">	
			<LeftPortal key="no">								
				<FormDefMenu />		
			</LeftPortal>

			{isMobileScreen() && 
				<NavbarPortal>
					<UIButton
						icon={isLeftPanelOpen ? <ArrowheadLeftOutline/> : <ArrowheadRightOutline/>}
						onClick={toggleLeftPanel}
						label="Form List"				
					/>
				</NavbarPortal>
			}

			{!formDefId && <Placeholder text="Select a form from the list in the left panel" /> }

			<FormInstanceTable 
				formDefinition={formDefinition} 
				key={'form_' + formDefId} // provide a key to re-render on definition change
			/>
		</UIPage>
	)
}

const FormDefListLabel = ({ def }: { def: FormDefinition }) => {
	return (
		<LeftPanelListItem
			labelComponent={
				<div>
					<div>{def.name}</div>
					<UISubtitle>{arrayToString(def.fields.filter(item => item.status === 'active').map(item => { return item.name; }))}</UISubtitle>
				</div>
			}
			linkToRoute="formInstanceById"
			linkToVars={{ id: def.id }}
		/>
	)
}
const FormDefMenu = () => {
	const { formDefinitions } = useFormDefinitions('all');

	const stepForms = formDefinitions.filter( def => def.foreign_table === thingLookup.step_history.sharedTableId);
	const jobForms = formDefinitions.filter( def => def.foreign_table === thingLookup.job.sharedTableId);
	const inventoryForms = formDefinitions.filter( def => def.foreign_table === thingLookup.inventory_unique.sharedTableId);
	const orderForms = formDefinitions.filter( def => def.foreign_table === thingLookup.order.sharedTableId);
	
	let menuButtons = [];
	if (stepForms.length > 0) {
		menuButtons.push(<VerticalMenuHeading key="step"><File />Step Forms</VerticalMenuHeading>)
		menuButtons.push(stepForms.map( def => ( <FormDefListLabel key={def.id} def={def} /> ) ) )
	}
	if (jobForms.length > 0) {
		menuButtons.push(<VerticalMenuHeading key="job"><File />Job Forms</VerticalMenuHeading>)
		menuButtons.push(jobForms.map( def => ( <FormDefListLabel key={def.id} def={def} /> ) ) )
	}
	if (inventoryForms.length > 0) {
		menuButtons.push(<VerticalMenuHeading key="inv"><File />Inventory Forms</VerticalMenuHeading>)
		menuButtons.push(inventoryForms.map( def => ( <FormDefListLabel key={def.id} def={def} /> ) ) )
	}
	if (orderForms.length > 0) {
		menuButtons.push(<VerticalMenuHeading key="order"><File />Order Forms</VerticalMenuHeading>)
		menuButtons.push(orderForms.map( def => ( <FormDefListLabel key={def.id} def={def} /> ) ) )
	}

	return (
		<LeftPanel
			menuButtons={menuButtons}
		/>
	)
}



function generateFormInstanceColumns(
	formFields: FormFieldSpec[], 
	tableId: number
) {
	let cols = [];
	const tableName = getThingNameFromId(tableId);
	switch (tableName) {
		case 'step_history':
			cols = [
				formInstanceCols.jobName,
				formInstanceCols.stepName
			]
			break;
		case 'inventory_unique':
			cols = [formInstanceCols.invUniqueName]
			break;
		case 'order':
			cols = [formInstanceCols.orderName]
			break;
	
		default:
			break;
	}

	cols.push(formInstanceCols.updatedAt);

	cols = cols.concat(
		formFields.filter( def => def.status !== 'archive' ).map( (fieldDef, index) => {			
			let column: ColumnDef = {
				Header: fieldDef.name,
				accessor: `values[${fieldDef.key}]`
			}
			
			return column
		})
	)

	return cols;
}

export function FormInstanceTable(
	{ formDefinition }
	: {
		formDefinition: FormDefinition		
	}
) {
	if (!formDefinition.id) { return null }

	const [selectedIndex, setSelectedIndex] = useState();
	
	const {
		data: { form: forms } = { form: [] as FormInstance[] }
	} = useQuery(GET_FORMS_BY_DEF_ID, { 
		variables: { defId: formDefinition.id } 
	});
	
	const columns = useMemo(() => 
		generateFormInstanceColumns(formDefinition.fields, formDefinition.foreign_table)
	, [formDefinition.fields?.length]);

	let data = useMemo(() => forms, [forms.length]);
	
	//link to job or step??
	const onRowSelect = (selectedObj, newIndex) => {		
		setSelectedIndex(newIndex)
	}
	
	if (forms.length < 1) {return <Placeholder text="No completed forms found"/>}
	return (
		<Table
			columns={columns}
			data={data}
			selectedIndex={selectedIndex}
			onRowSelect={onRowSelect}
			hasSortableColumns
			hasFilterableColumns
			hasGlobalFilter
			hasPagination
			hasSelectablePageSize
		/>
	)
}