import { getLocalStorage, setLocalStorage } from 'services/localStorage';

export function getStoreQueryStringForPath(path: string) {
	const localStoreQsAll = getLocalStorage('tableParams');
	const qs = localStoreQsAll?.[path];
	return qs
}

export function setStoreQueryString(pathName: string, newString: string) {
	// const newQueryStringByPage = useUrlStore.getState().queryString
	const existingQs = getLocalStorage('tableParams');
	const newQs = { 
		...existingQs, 
		[pathName]: newString 
	} 
	setLocalStorage('tableParams', newQs);
}
