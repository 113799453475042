
import { gql } from '@apollo/client';
import { apolloClient, updateCacheAfterInsert } from './apollo';
import { GET_ORDERS_BY_IDS } from './orderFetch';
import { partTransactionAllColumns, partWipTransactionAllColumns } from '@constants';
import { PartTransactionInsertObject_Fulfill, PartWipTxInsertObject, PartTransactionInsertObject_Adjust } from 'types';
import { GET_FULFILLMENT_WIP } from 'hooks';
import { GET_PRODUCTS, GET_RAW_PARTS, GET_PART_TXS } from 'root/@constants/gql';

const INSERT_PART_TX = gql`
	mutation INSERT_PART_TX ( 
		$txs: [part_tx_insert_input!]!
	) {
		insert_part_tx (
			objects: $txs
		) {
			returning {
				${partTransactionAllColumns}
			}
		}
	}
`;


export function fulfillFromInventory(orderId: number, qty: number, orderItemId: number, partId: number, jobId: number, invUniqueId: number) {
	if (qty <= 0) {
		console.error('Fulfillment value should be positive. Transaction was cancelled');
		return;
	}
	const fulfillTx: PartTransactionInsertObject_Fulfill = {
		type: 'fulfill',
		part_id: partId, 
		qty_change: qty * -1, // We are actually deducting from inv, so make the number negative
		stock_job_id: jobId,
		stock_inventory_unique_id: invUniqueId,
		order_item_id: orderItemId
	}
	apolloClient.mutate({ 
		mutation: INSERT_PART_TX,
		variables: {
			txs: [fulfillTx]
		},
		refetchQueries: [{ query: GET_ORDERS_BY_IDS, variables: { ids: [orderId] } }]
	});
}

const INSERT_PART_WIP_TX = gql`
	mutation INSERT_PART_WIP_TX ( 
		$txs: [part_wip_tx_insert_input!]!
	) {
		insert_part_wip_tx (
			objects: $txs
		) {
			returning {
				${partWipTransactionAllColumns}
			}
		}
	}
`;

export function reserveFromInventory(orderId: number, qty: number, orderItemId: number, partId: number, jobId: number, invUniqueId: number) {
	const reserveTx: PartWipTxInsertObject = {
		wip_type: 'reserve',
		type: 'reserve_stock',
		part_id: partId, 
		qty_change: qty,
		job_id: jobId,
		inventory_unique_id: invUniqueId,
		order_item_id: orderItemId
	}
	apolloClient.mutate({ 
		mutation: INSERT_PART_WIP_TX,
		variables: {
			txs: [reserveTx]
		},
		// refetchQueries: [{ query: GET_ORDERS_BY_IDS, variables: { ids: [orderId] } }]
		refetchQueries: [{ query: GET_FULFILLMENT_WIP, variables: { part_id: partId, order_item_id: orderItemId } }]
	});
}


// Combining the two mutations into the same graph document ensures that they happen in the same db transaction, and get rollback together in case something goes wrong
const INSERT_PART_AND_PART_WIP_TX = gql`
	mutation INSERT_PART_AND_PART_WIP_TX ( 
		$txs: [part_tx_insert_input!]!
		$wipTxs: [part_wip_tx_insert_input!]!
	) {
		insert_part_tx (
			objects: $txs
		) {
			returning {
				${partTransactionAllColumns}
			}
		}
		insert_part_wip_tx (
			objects: $wipTxs
		) {
			returning {
				${partWipTransactionAllColumns}
			}
		}
	}
`;


export function fulfillFromReservation(orderId: number, qty: number, orderItemId: number, partId: number, jobId: number) {
	if (qty <= 0) {
		console.error('Fulfillment value should be positive. Transaction was cancelled');
		return;
	}
	const fulfillTx: PartTransactionInsertObject_Fulfill = {
		type: 'fulfill',
		part_id: partId, 
		qty_change: qty * -1, // We are actually deducting from inv, so make the number negative
		job_id: jobId,
		order_item_id: orderItemId,
		stock_job_id: null,
		stock_inventory_unique_id: null
	};

	const reserveReleaseTx: PartWipTxInsertObject = {
		wip_type: 'reserve',
		type: 'reservation_fulfilled',
		part_id: partId, 
		qty_change: qty * -1, // We are actually deducting from the reservation bucket, so make the number negative
		job_id: jobId,
		order_item_id: orderItemId
	};

	apolloClient.mutate({ 
		mutation: INSERT_PART_AND_PART_WIP_TX,
		variables: {
			txs: [fulfillTx],
			wipTxs: [reserveReleaseTx]
		},
		refetchQueries: [
			{ query: GET_ORDERS_BY_IDS, variables: { ids: [orderId] } },
			{ query: GET_FULFILLMENT_WIP, variables: { part_id: partId, order_item_id: orderItemId } }
		]
	});
}

//add part_tx
export function adjustPartQty(isRaw: boolean, isProduct: boolean, qty: number, partId: number, jobId: number, invUniqueId: number, comment: string) {

	const addTx: PartTransactionInsertObject_Adjust = {
		type: 'adjust',
		part_id: partId, 
		qty_change: qty,
		stock_job_id: jobId,
		stock_inventory_unique_id: invUniqueId,
		comment: comment
	}
	apolloClient.mutate({ 
		mutation: INSERT_PART_TX,
		variables: {
			txs: [addTx]
		},
		refetchQueries: [
			{ query: isRaw ? GET_RAW_PARTS : GET_PRODUCTS }
		],
		update(cache, result) {
			updateCacheAfterInsert(cache, result, 'part_tx', GET_PART_TXS, { part_id: partId }, false, true);
		}
	});
}