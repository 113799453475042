import React, { useState, useEffect } from 'react';

import { UIPage, UIButton, UIForm, UIForm2, useUIForm, UIInput, useModal,
	UIModal, UIDateTimePicker, UIFlexbox,
	UISelect, UIHeading } from 'kit';
import { AddCircleOutline } from '@styled-icons/material/AddCircleOutline';

import { EditOrderLineItems } from 'components/Orders/EditOrderLineItem';

import { addNewOrder } from 'services/orderMutate';
import { addFormInstance } from 'services/formSubmit';

import { useFormDefinitions, useContacts, useParse } from 'hooks';

import { thingLookup } from '@constants';
import { deepCopy, getObjectFromArray } from 'utility';
import { useNavMain } from 'routing';
import { FormDefinition, OrderItemInsertObject, Contact, Order, OrderInsertObject } from 'types';
import { toast } from 'react-toastify';
import { useFormContext } from 'react-hook-form';
import { RenderedFormField } from 'components/FormBuilder/RenderedFormField';


export const OrderDraft = (
) => {
	const modalProps = useModal();
	const navigateToOrderById = useNavMain('orderById');
	const [lineItems, setLineItems] = useState<OrderItemInsertObject[]>([])

	const { formMethods } = useUIForm<Order>();

	//fill order form from order or parse orderform
	const [dataFormValues, setDataFormValues] = useState();
	const [dataFormDef, setDataFormDef] = useState<FormDefinition>();
	const intakeOrderDataForm = (dataFormData, formDef: FormDefinition) => {
		//strip form of defined items
		const stripFormItems = ['name', 'po', 'due_date'];

		let strippedForm = { ...dataFormData }; // generate a new object
		stripFormItems.forEach( nativeKey => {
			if (dataFormData.hasOwnProperty(nativeKey)) {
				// populate the data into the native order data
				formMethods.setValue(nativeKey, dataFormData[nativeKey]);

				// remove the entry from the data form that will be saved
				delete strippedForm[nativeKey];
			}
		})
		setDataFormValues(strippedForm);
		setDataFormDef(formDef);
	}



	const saveOrder = (formData: Order) => {
		//save to DB
		if (lineItems.length === 0) {
			toast.error('At least one line item is requred.', {
				position: toast.POSITION.BOTTOM_CENTER
			});
		}
		else {
			let insertObj: OrderInsertObject = { 
				...formData, 
				status: 'active',
				start_at: new Date(),
				order_items: {
					data: lineItems
				}
			};
			
			addNewOrder(insertObj)
			.then( addedOrder => {
				if (dataFormDef && dataFormValues) {
					addFormInstance(dataFormDef.id, dataFormValues, thingLookup.order.sharedTableId, addedOrder.id)
					.then( () => {
						navigateToOrderById(addedOrder.id)
					});
				}
				else {
					navigateToOrderById(addedOrder.id)

				}
			});
		}
	}


	return (
		<UIPage id="add-order" contentContainerSize="medium">

			<UIForm2 onSubmit={saveOrder} formMethods={formMethods} >
				<UIInput name="name" label="Name" required placeholder="enter a unique order name"/>
				<UIInput name="po" label="Purchase Order"/>

				<UIDateTimePicker name="due_date" label="Due Date" isDateOnly/>
				<SelectCustomer />
				<UIHeading>Line Items</UIHeading>
				<EditOrderLineItems
					isNewOrder={true}
					commitLineItems={setLineItems}
					existingLineItems={[]}
				/>

				<UIFlexbox>
					<UIButton label={'Create Order'} type="submit" />
					<UIButton quiet icon={<AddCircleOutline/>} label={dataFormDef?.id ? `Edit ${dataFormDef.name}` : 'Fill order from form'} onClick={modalProps.toggleModal} />
				</UIFlexbox>
			</UIForm2>


			<AddFormOrderModal handleFormSubmit={intakeOrderDataForm} modalProps={modalProps} />


		</UIPage>
	)
}

//add order from parsed text into a dataform
export const AddFormOrderModal = (
	{ handleFormSubmit, modalProps }
: {
	handleFormSubmit(formValues, formDef: FormDefinition): void
	modalProps
} ) => {
	const [selectedFormId, setSelectedFormId] = useState();

	const { isLoading, formDefinitions } = useFormDefinitions('table', { tableName: 'order' });

	const selectedFormDefinition: FormDefinition = getObjectFromArray(formDefinitions, 'id', selectedFormId);

	const {
		parsedObject,
		parseKey,
		Input
	} = useParse(selectedFormDefinition?.fields);

	function onSubmit(formValues){
		handleFormSubmit(formValues, selectedFormDefinition);
		modalProps.toggleModal();
	}

	return (
		<React.Fragment>
			

			<UIModal {...modalProps} >
				<UIHeading>Prefill order details from an order form</UIHeading>
				<UISelect
					name="forms"
					label="Select order form"
					defaultValue={selectedFormId}
					isLoading={isLoading}
					options={formDefinitions}
					required={true}
					onChange={setSelectedFormId}
					hasConditionalComponents={false}
					skipRegister
				/>

				{typeof selectedFormId === 'number' && 
				<>	
					<UIHeading>Use spreadsheet data</UIHeading>
					{Input}
				</>
				}

				<UIHeading>{selectedFormDefinition?.name}</UIHeading>

				<UIForm
					key={parseKey}
					defaultValues={parsedObject}
					onSubmit={onSubmit}
				>
					{selectedFormDefinition?.fields?.map( (formFieldDef, index) => {
						return (
							<RenderedFormField
								formFieldDef={formFieldDef}
								value={parsedObject[formFieldDef.key]}
								isLastAndScrollSelect={index === selectedFormDefinition.fields.length - 1}
							/>
						)
					})}
					<UIButton type="submit" label="Submit" />
				</UIForm>

			</UIModal>

		</React.Fragment>
	)
};


export const SelectCustomer = () => {
	const { contacts } = useContacts();
	const { watch } = useFormContext();

	const [ addressOptions, setAddressOptions ] = useState([])
	const selectedContactId = watch(`contact_id`);

	useEffect(() => {
		if (selectedContactId) {
			const selectedContact = getObjectFromArray(contacts, 'id', selectedContactId) as Contact;
			const addressList = selectedContact.addresses.map( (item) => {
				return { label: `${item.address1} ${item.address2}, ${item.city} ${item.state} - ${item.country}`, value: item }
			})
			setAddressOptions(addressList)
		}
	}, [selectedContactId]);

	const contactOptions = contacts.map( contact => {
		type ExtendedContact =  Contact & { displayName?: string };
		let newContact: ExtendedContact = deepCopy(contact);
		const fullName = `${newContact.first_name} ${newContact.last_name}`;
		if (newContact.company_name) {
			newContact.displayName = contact.company_name + ' ' + fullName;
		}
		else {
			newContact.displayName = fullName;
		}
		return newContact;
	})

	return (
		<React.Fragment>
			<UISelect
				label="Customer"
				name="contact_id"
				defaultValue={void 0}
				options={contactOptions}
				labelAccessor="displayName"
				hasConditionalComponents={true}
			/>

			{selectedContactId > 0 &&
			<React.Fragment>
				<UISelect
					label="Billing Address"
					name="billing_address"
					defaultValue={void 0}
					options={addressOptions}
					hasConditionalComponents={false}
				/>

				<UISelect
					label="Shipping Address"
					name="shipping_address"
					defaultValue={void 0}
					options={addressOptions}
					hasConditionalComponents={false}
				/>
			</React.Fragment>}

		</React.Fragment>
	)
}

