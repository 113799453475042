import React from 'react';

import { UIPage, useModal, UIModal } from 'kit';

import { PartTable } from 'components/Parts/PartTable';

import { NavbarPortal } from 'components/Navigation/NavbarPortal';
import { AddNewItemButton } from 'components/AddNewItemButton';

import { EditPartForm } from 'components/Parts/PartForm';
import { PartTxTable } from 'root/components/Parts/PartTransactionTable';

import { PartProduct } from 'types';

export function ProductPage() {
	const modalProps = useModal<PartProduct>();
	const txModalProps = useModal<PartProduct>();

	function toggleEdit(item: PartProduct) {
		modalProps.setModalData(item);
		modalProps.toggleModal();
	}

	function toggleAdjust(item: PartProduct) {
		txModalProps.setModalData(item);
		txModalProps.toggleModal();
	}

	return (
		<UIPage id="product-page" contentContainerSize="large">
			<NavbarPortal>
				<AddNewItemButton type="partProduct" onClick={toggleEdit.bind(this, {})} />
			</NavbarPortal>

			<PartTable
				actionType="edit"
				actionFunction={toggleEdit}
				adjustActionFunction={toggleAdjust}
				isProduct
			/>

			<EditPartForm
				modalProps={modalProps}
				originatingPartType="product"
			/>

			<UIModal {...txModalProps}>				
				<PartTxTable
					part={txModalProps.modalData}
					partType="product"
				/>
			</UIModal>
		</UIPage>
	)
}