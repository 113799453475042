import { useCallback } from 'react';
import { StepHistoryRaw,  StepPlan, WorkflowDatabaseStep, SupplementalStepInfo, Step } from 'types';
import { useThingById, useThingsIn } from './hooks/useThing';
import { getObjectFromArray, stringifyArrayByKey } from 'utility';


interface WorkflowStepFull {
	dataIsReady: boolean
	index0: number
	stepInfo: Step
	stepPlan: StepPlan
	supp: SupplementalStepInfo
}




export function useHydrateStepFromJob(jobId: number) {
	const { isLoading: isJobLoading, item: job } = useThingById('job', jobId);
	const { isLoading: isStepsLoading, items: steps } = useThingsIn('step', job.steps?.map( dbStep => dbStep.step_id ));

	const getWorkflowStepFull = useCallback(
		(index0: number): WorkflowStepFull => {
			const wfStep = job.steps?.[index0] || {} as WorkflowDatabaseStep;
			return {
				dataIsReady: !(isJobLoading || isStepsLoading),
				index0,
				stepInfo: getObjectFromArray(steps, 'id', wfStep.step_id),
				stepPlan: wfStep.plan || {} as StepPlan,
				supp: wfStep.supp || {} as SupplementalStepInfo
			}
		},
		[isJobLoading, isStepsLoading, job.id, job.steps?.length, stringifyArrayByKey(steps, 'updated_at')]
	);

	return { isDataReady: !(isJobLoading || isStepsLoading),  getWorkflowStepFull };
}

export interface StepHistoryHelper {
	stepHistoryExists: boolean
	startUserName: string
	stopUserName: string
	isStarted: boolean
	isCompleted: boolean
}

export function genStepHistoryHelpers(rawStepHistory: StepHistoryRaw): StepHistoryHelper {
	return {
		stepHistoryExists: typeof rawStepHistory.id === 'number',
		startUserName:  (rawStepHistory.start_user?.first_name ?? '') + ' ' + (rawStepHistory.start_user?.last_name ?? ''),
		stopUserName:  (rawStepHistory.stop_user?.first_name ?? '') + ' ' + (rawStepHistory.stop_user?.last_name ?? ''),
		isStarted: typeof rawStepHistory.start_at === 'string',
		isCompleted: typeof rawStepHistory.stop_at === 'string'
	}		
}