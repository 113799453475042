import React, { useContext } from 'react';
import styled from 'styled-components';

import { UIChip, UIFlexbox, useModal, UIModalSimpleAction, useUIForm, UIForm2 } from 'kit';
import { Tools } from '@styled-icons/entypo/Tools';
import { Beaker } from '@styled-icons/octicons/Beaker';
import { EditOutline as Edit } from '@styled-icons/evaicons-outline/EditOutline';

import { WorkflowEditorContext } from './WorkflowEditorContext';

import { useThingById, useThingsIn } from 'hooks';
import { StepPlan, SupplementalStepInfo } from 'types';
import { ParameterSelectSingle } from '../StepSetup/ParameterComponentsSelectSingle';


export const EngineerSelectReview = (	
	{ 
		stepId,
		stepPlan = {} as StepPlan,
		supp = {} as SupplementalStepInfo,
		index
	}: {
		stepId: number
		stepPlan: StepPlan
		supp: SupplementalStepInfo
		index: number
	} 
) => {
	
	const { 
		updateSuppPartial, removeSuppKey 
	} = useContext(WorkflowEditorContext);


	const modalProps = useModal<'recipe' | 'tool'>(false, 'recipe');
	const modalParam = modalProps.modalData;
	const nameForForm = `${modalParam}_value` as 'recipe_value' | 'tool_value';
	const { formMethods, isDirty } = useUIForm<any>();

	const isToolReviewNeeded = stepPlan?.tool?.method === 'flex_engineer';
	const isRecipeReviewNeeded = stepPlan?.recipe?.method === 'flex_engineer';

	const { item: selectedTool } = useThingById('tool', supp.tool_value);
	const { item: selectedRecipe } = useThingById('recipe', supp.recipe_value);

	const submit = formMethods.handleSubmit((formData) => {
		if (formData[nameForForm]) { //value was set
			updateSuppPartial(formData, index);
		}
		else { // value was cleared
			removeSuppKey(nameForForm, index);
		}
		modalProps.toggleModal();
	});

	function openSelectMenu(type: 'recipe' | 'tool'){
		modalProps.setModalData(type);
		modalProps.openModal();
	}


	return (
		<>
			<UIFlexbox flexAlign="center">
				<div>
					{isToolReviewNeeded && <div>
						<UIChip  
							icon={<Tools />} 
							label={selectedTool.name || 'Needs selection'}
							outline
							success={typeof selectedTool.name === 'string'}
							error={!selectedTool.name}
							size="small"
							onClick={openSelectMenu.bind(this, 'tool')} 
						/>
					</div>}
					{isRecipeReviewNeeded && <div>
						<UIChip 
							icon={<Beaker />} 
							label={selectedRecipe.name || 'Needs selection'}
							outline
							success={typeof selectedRecipe.name === 'string'}
							error={!selectedRecipe.name}
							size="small"
							onClick={openSelectMenu.bind(this, 'recipe')} 
						/>
					</div> }
				</div>
			</UIFlexbox>
			

			<UIModalSimpleAction 
				{...modalProps} 
				Icon={<Edit />}
				title={`Change ${modalParam}`}
				description={`Select the ${modalParam} that will be used when the workflow reaches this step.`}
				supplementalContent={
					<>
						<UIForm2 formMethods={formMethods} style={{ marginBottom: '4rem' }}>
							<ParameterSelectSingle 
								parameterName={modalParam}
								label=""
								defaultValue={supp[nameForForm]}
								nameForForm={nameForForm}
								required={false}
								stepId={stepId}	
								scrollOnOpen
							/>
						</UIForm2>
					</>
				}
				buttonText="Update"
				onSubmit={submit}
				autoCloseModalOnSubmit={false}
				showCancel
				clickOutsideToClose={!isDirty}
			/>
		</>
	)
}

export const ToolRecipePreview = (	
	{ 
		type,
		stepPlan = {} as StepPlan
	}: {
		type: 'tool' | 'recipe'
		stepPlan: StepPlan
	} 
) => {
	const def = stepPlan[type];
	const isTracked = typeof def !== 'undefined';
	if (!isTracked) { return <>--</>; }

	const method = def?.method;
	const { item } = useThingById(type, method === 'fixed' ? def?.value : def?.default_value);

	const prefix = method === 'fixed' ? '' : 'Default: ';

	return (
		<React.Fragment>
			<UIChip  
				label={method}
				outline
				size="xsmall"
			/>
			<InfoLine>{prefix}<i>{item.name ? item.name : 'no default'}</i></InfoLine>
		</React.Fragment>
	)
}

export const MaterialPreview = (	
	{ 
		stepPlan = {} as StepPlan
	}: {
		stepPlan: StepPlan
	} 
) => {
	const isTracked = typeof stepPlan.inventory_effect !== 'undefined';
	if (!isTracked) { return <>--</>; }

	return (
		<InfoLine>{stepPlan.inventory_effect?.items?.length} item(s)</InfoLine>
	)
}

export const FormPreview = (	
	{ 
		stepPlan = {} as StepPlan
	}: {
		stepPlan: StepPlan
	} 
) => {
	const isTracked = typeof stepPlan.forms !== 'undefined';
	if (!isTracked) { return <>--</>; }

	const { items: formDefs } = useThingsIn('form', stepPlan.forms);

	return (
		<>{formDefs.map( formDef => <InfoLine>{formDef.name}</InfoLine>)}</>
	)
}

export const InvUnFormPreview = (	
	{ 
		stepPlan = {} as StepPlan
	}: {
		stepPlan: StepPlan
	} 
) => {
	const isTracked = typeof stepPlan.forms !== 'undefined';
	if (!isTracked) { return <>--</>; }

	const { item: formDef } = useThingById('form', stepPlan.inventory_unique_form?.form_definition_id);

	return (
		<InfoLine>{formDef.name}</InfoLine>
	)
}


const InfoLine = styled.div`
	font-size: 0.9em;
`;