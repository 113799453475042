import { gql } from '@apollo/client';

import { partAllColumns } from '@constants';
import { partStockAllColumns, partTransactionAllColumns } from '../columns';

export const GET_RAW_PARTS = gql`
	query GET_RAW_PARTS (
		$id: Int
	) {
		part (
			where: { 
				is_raw: { _eq: true } 
				id: { _eq: $id }
			}
		) {
			${partAllColumns}
		}
	}
`;

export const GET_PRODUCTS = gql`
	query GET_PRODUCTS (
		$id: Int
	) {
		part (
			where: { 
				is_product: { _eq: true } 
				id: { _eq: $id }
			}
		) {
			${partAllColumns}
		}
	}
`;

export const GET_PARTS_IN = gql`
	query GET_PARTS_IN (
		$ids: [Int!]
	){
		part (
			where: { 
				is_raw: { _eq: true } 
				id: { _in: $ids } 
			} 
		) {
			${partAllColumns}
		}
	}
`;


export const ADD_PART = gql`
	mutation ADD_PART (
		$insertingObjs: [part_insert_input!]!
	) {
		insert_part (
			objects: $insertingObjs
		) {
			returning {
				${partAllColumns}
			}
		}
	}
`;

export const UPDATE_PART = gql`
	mutation UPDATE_PART ( 
		$id: Int!
		$changes: part_set_input!
	) {
		update_part (
			where: { id: { _eq: $id } },
			_set: $changes
		) {
			returning {
				${partAllColumns}
			}
		}
	}
`;






export const GET_PART_STOCK = gql`
	query GET_PART_STOCK(
		$part_id: Int!
		$job_id: Int
		$inventory_unique_id: Int
	) {
		part_stock (
			where: { part_id: { _eq: $part_id } }
		) {
			${partStockAllColumns}
		}
	}
`;

export const GET_PART_TXS = gql`
	query GET_PART_TXS(
		$part_id: Int!
		$job_id: Int
		$inventory_unique_id: Int
	) {
		part_tx (
			where: { part_id: { _eq: $part_id } },
			order_by: {id: desc}
		) {
			${partTransactionAllColumns}
		}
	}
`;