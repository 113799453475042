import React from 'react';
import { Step, StepPlan } from 'types';
import { SectionState, ValidationErrors } from './StepForm';
import { TabContext } from 'kit';

interface StepFormContext {
	step: Step
	stepPlan: StepPlan,
	sectionIsTracked: SectionState
	sectionIsFormErrored: SectionState
	setSectionIsTracked: React.Dispatch<SectionState>
	validationErrors: ValidationErrors
	tabContext: TabContext
}

export const StepFormContext = React.createContext({} as StepFormContext);
