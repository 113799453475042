import React from 'react';
import styled from 'styled-components';

import { UIHeading, UIButton, UIPage } from 'kit';
import { Rocket } from '@styled-icons/boxicons-regular/Rocket';

import { deepCopy } from 'utility';
import { goHome } from 'routing';
import { logError } from 'services/errorHelper';
import { gradientBlue } from './theme';


export class ErrorBoundary extends React.Component<
	{ history: any }, 
	{ hasError: boolean, errorId: string }
> {
	constructor(props) {
		super(props);
		this.state = { 
			hasError: false,
			errorId: ''
		};
	}

	static getDerivedStateFromError(error) {
		// Update state so the next render will show the fallback UI.
		return { hasError: true };
	}

	componentDidCatch(error, errorInfo) {
		let location = deepCopy(this.props.history.location);
		location.length = this.props.history.length;
		this.executeLogError(error.toString(), errorInfo.componentStack, location);
	}

	executeLogError(error, stack, location) {
		logError(error, stack, location).then( errorId => {
			this.setState({
				errorId
			})
		});
	}

	clear() {
		this.setState({
			hasError: false,
			errorId: ''
		});
	}

	clearAndGoHome = () => {
		this.clear();
		goHome();
	}
	
	refresh = () => {
		window.location.reload();
	}

	render() {
		if (this.state.hasError) {
			return (
				<StyledPage id="error-page" padding="large" asFlexbox flexDirection="column" flexAlign="center" flexJustify="center" fullHeight>
					<Rocket />
					<StyledHeading level="1">Something went wrong.</StyledHeading>

					<UIButton outline full white label="Refresh the current page" onClick={this.refresh} />
					<UIButton outline full white label="Return to the home page" onClick={this.clearAndGoHome} margin="mediumVertical" />

					{ this.state.errorId && <p>Error {this.state.errorId} has been logged.</p> }
				</StyledPage>
			)
		}

		return this.props.children; 
	}
}
const StyledHeading = styled(UIHeading)`
	font-size: 3rem;
	line-height: 1.5em;
	margin-top: 1em;;
`;

const StyledPage = styled(UIPage)`
	color: white;
	text-align: center;
	color: white;
	background: ${gradientBlue};
	svg {
		transform: rotate(150deg);
		height: 120px;
		width: 120px;

		animation-duration: 0.5s;
		animation-name: wiggle;
		animation-iteration-count: infinite;
		animation-direction: alternate;
		transition-timing-function: ease-out;
	}

	@keyframes wiggle {
		0%   { transform: rotate(155deg); }
		50%  { transform: rotate(160deg); }
		100% { transform: rotate(155deg); }
	}
`;