import React, { useState } from 'react';
import styled from 'styled-components';

import { UIInput, UIButton } from 'kit';

import { Controller } from 'react-hook-form';

import DatePicker  from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import { TimePicker, TimePrecision } from '@blueprintjs/datetime';
import '@blueprintjs/datetime/lib/css/blueprint-datetime.css';

import { isToday } from 'date-fns'
import { dateFormat } from 'utility';
import { PartialBy } from 'types';

interface DateTimeProps {
	defaultValue: string

	name: string
	label?: string
	subtitle?: string
	required?: boolean
	disabled?: boolean
	skipRegister?: boolean
	className?: string

	isDateOnly?: boolean
	isPastOnly?: boolean
}

export const UIDateTimePicker = (
	{ defaultValue, ...restProps }
	: PartialBy<DateTimeProps, 'defaultValue'>
) => {
	return (
		<Controller 
			as={
				<UIDateTimePickerUnregistered 
					defaultValue={defaultValue}
					onChange={() => {}}
					{...restProps}
				/>
			} 
			name={restProps.name}
			rules={{ required: restProps.required }}
			defaultValue={defaultValue}
		/>
	)
}

export const UIDateTimePickerUnregistered = (
	{ 
		defaultValue, 
		onChange, 
		isDateOnly = false,
		isPastOnly = false,
		...restProps 
	}: {
		onChange: (time: Date) => void
	} & DateTimeProps
) => {
	const [selectedDateObj, setSelectedDateObj] = useState(defaultValue ? new Date(defaultValue) : null);

	function handleCalendarOpen() {
		if (selectedDateObj === null) {
			setSelectedDateObj(new Date());
		}
	}

	function handleDateChange(newDateObj) {
		setSelectedDateObj(newDateObj)
		onChange(newDateObj)
	}

	const CustomTimeInput = () => {
		return (
			<>
				{!isDateOnly &&
				<TimePicker 
					showArrowButtons
					precision={TimePrecision.SECOND}

					maxTime={isToday(selectedDateObj) ? new Date() : void 0} // only enforce max time if it's today
					value={selectedDateObj}
					onChange={handleDateChange}
				/>
				}
				<div className="date-display">{dateFormat(selectedDateObj, isDateOnly ? 'dateWithYear' : 'dateTimeShort')}</div>
				<UIButton quiet label="Close" onClick={closePicker} margin="none" />
			</>
		)
	};

	const PickerRef = React.useRef<DatePicker>();
	const closePicker = () => {
		// used the following line as i'm using time as well, but it's not obvious that 
		// you need to click it to close the pickup calendar
		PickerRef.current.setOpen(false); 
	}

	return (
		<StyledDateWrapper>
			<DatePicker
				ref={PickerRef}
				selected={selectedDateObj}
				onChange={handleDateChange}
				onCalendarOpen={handleCalendarOpen}
				shouldCloseOnSelect={false}

				dateFormat={isDateOnly ? 'MMMM d, yyyy' : 'MMM d, yyyy h:mm aa'}
				maxDate={isPastOnly ? new Date() : void 0}
				customInput={
					<UIInput
						skipRegister
						name={restProps.name}
						label={restProps.label}
					/>
				}
				required={restProps.required}
				disabled={restProps.disabled}

				showTimeInput
				customTimeInput={ <CustomTimeInput /> }
				showPopperArrow={false}
				popperModifiers={{
					offset: {
						enabled: true,
						offset: '5px, -30px'
					},
					flip: {
						enabled: false //need to do this so we can use negative offet in react-popper v1
					},
					preventOverflow: {
						enabled: true
					}
				}}
			/>
		</StyledDateWrapper>
	);
}


export const StyledDateWrapper = styled.div`

	.react-datepicker-wrapper {
		width: 100%;
		div {
		}
	}
	.react-datepicker {
		font-family: ${props => props.theme.font.body};
		font-size: 0.9rem;
		user-select: none; /*Prevent furious clicking from selecting random parts of the calendar and timepicker*/
		*:focus { /**Chrome will also apply highlighting to other elements such as DIV's used as modals. To prevent the highlight on those and all other elements as well, you can do: */
			outline: 0;
		}
	}
	.react-datepicker__header {
		background-color: ${props => props.theme.colors.primaryLight1};
	}
	.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input {
		width: 33px; /*from blueprint*/
	}
	.react-datepicker__input-time-container {
		text-align: center;
		margin-left: 0;
	}
	.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input {
		margin-left: 0;
	}
	.react-datepicker-time__caption {
		display: none;
	}


	.react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range, .react-datepicker__month-text--selected, .react-datepicker__month-text--in-selecting-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--selected, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--selected, .react-datepicker__year-text--in-selecting-range, .react-datepicker__year-text--in-range {
		background-color: ${props => props.theme.colors.primary};
	}

	.bp3-timepicker input {
		font-family: ${props => props.theme.font.body};
	}
	.bp3-icon svg {
		fill: ${props => props.theme.colors.colorDefinitions.grey4};
	}

	.date-display {
		margin: 10px 0;
	}
`