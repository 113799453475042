import { apolloClient } from 'services/apollo';
import { JobChanges } from 'types';
import { UPDATE_JOB } from '@constants/gql';


export function changeJob(id: number, changes: JobChanges) {
	apolloClient.mutate({ 
		mutation: UPDATE_JOB,
		variables: {
			id,
			changes: changes
		}
	});
}