import React, {  } from 'react';
import { useModal } from 'kit';
import { FormInstanceModal, FormInstanceModal_NewForm, FormInstanceModal_EditExistingForm } from 'components/Form/FormInstanceModal';


import { FormDefinition, FormInstanceLinked } from 'types';
import { OperateTaskButton } from './TaskBannerPresentational';
import { StepHistoryHelper } from 'root/services/stepHydrate';


// Needs to be a separate component to have a different modal per form
export const OperateFormButtonModal = (
	{ formDefinition, existingForm, stepHistoryHelper, stepHistoryId }: {
		formDefinition: FormDefinition
		existingForm: FormInstanceLinked
		stepHistoryHelper: StepHistoryHelper
		stepHistoryId: number
	}
) => {
	const modalProps = useModal();
	const newFormNeeded = stepHistoryHelper.stepHistoryExists && !existingForm.values;
	return (
		<React.Fragment key={'form_' + formDefinition.id}>
			<OperateTaskButton 
				label={formDefinition.name}
				onClick={modalProps.toggleModal}
			/>

			{ newFormNeeded && 
				<FormInstanceModal_NewForm 
					modalProps={modalProps}
					formDefinition={formDefinition}
					referenceId={stepHistoryId}
					hasParseButton
				/> 
			}

			{ existingForm.id && !stepHistoryHelper.isCompleted &&
				<FormInstanceModal_EditExistingForm
					modalProps={modalProps}
					formDefinition={formDefinition}
					existingForm={existingForm}
					hasParseButton
				/> 
			}

			{ existingForm.id && stepHistoryHelper.isCompleted &&
				<FormInstanceModal
					modalProps={modalProps}
					formDefinition={formDefinition}
					existingForm={existingForm}
				/> 
			}

		</React.Fragment>
	)
}