
import { apolloClient } from 'services/apollo';
import { Job, Workflow, WorkflowOptions, PartWipTxInsertObject, JobUpdateObj, WorkflowDatabaseStep } from 'types';
import { gql } from '@apollo/client';
import { jobAllColumns } from '@constants';
import { getWorkflowById } from 'services/getWorkflowById';
import { updateThing, ThingMutateResponse } from './thingMutate';

const ADD_JOB_DRAFT = gql`
	mutation ADD_JOB_DRAFT ( 
		$name: citext!
		$expense_category: Int
		$workflow_id: Int
		$workflow_options: jsonb
		$steps: jsonb
	) {
		insert_job (
			objects: { 
				name: $name
				expense_category: $expense_category
				workflow_id: $workflow_id
				workflow_options: $workflow_options
				steps: $steps
				job_status: "draft" 
			}
		) {
			returning {
				${jobAllColumns}
			}
		}
	}
`;

export async function addJobDraft(formData: Job, workflow_id = null) {
	let variables: any = { 
		name: formData.name,
		steps: [],
		workflow_options: {}
	};
	if (formData.expense_category) {
		variables.expense_category = formData.expense_category;
	}
	if (workflow_id) {
		const workflow: Workflow = await getWorkflowById(workflow_id);
		variables.workflow_id = workflow_id;
		variables.workflow_options = workflow.workflow_options;
		variables.steps = workflow.steps;
	}

	return apolloClient.mutate({ 
		mutation: ADD_JOB_DRAFT, 
		variables
	}).then(queryResponse => {
		const addedJob: Job = queryResponse?.data?.insert_job?.returning?.[0] ?? {} as Job;
		return addedJob;
	});
}



// If you edit this, make sure to edit the _WITH_WIP version below too
const ACTIVATE_JOB = gql`
	mutation ACTIVATE_JOB ( 
		$id: Int!
		$steps: jsonb
		$workflow_options: jsonb
	) {
		update_job (
			where: { 
				id: { _eq: $id }
			},
			_set: {
				job_status: "active"
				steps: $steps
				workflow_options: $workflow_options
				start_at: "now()"
			}
		) {
			returning {
				${jobAllColumns}
			}
		}
	}
`;

const ACTIVATE_JOB_WITH_WIP = gql`
	mutation ACTIVATE_JOB_WITH_WIP ( 
		$id: Int!
		$steps: jsonb
		$workflow_options: jsonb
		$partWipTxs: [part_wip_tx_insert_input!]!
	) {
		update_job (
			where: { 
				id: { _eq: $id }
			},
			_set: {
				job_status: "active"
				steps: $steps
				workflow_options: $workflow_options
				start_at: "now()"
			}
		) {
			returning {
				${jobAllColumns}
			}
		}
		insert_part_wip_tx (
			objects: $partWipTxs
		) {
			affected_rows
		}
	}
`;


export function updateJobFormOnly(jobId, formData: Job): Promise<ThingMutateResponse<'job'>> {
	let variables: any = { 
		id: jobId, 
		name: formData.name
	};
	if (formData.expense_category) {
		variables.expense_category = formData.expense_category;
	}

	return updateThing('job', jobId, variables);
}



export async function updateJobFormAndWorkflow(existingJob: Job, formData: Job): Promise<ThingMutateResponse<'job'>> {
	const workflow: Workflow = await getWorkflowById(formData.workflow_id);

	let variables: JobUpdateObj = { 
		name: formData.name, 
		workflow_id: formData.workflow_id,
		workflow_options: workflow.workflow_options
	};
	if (new Date(workflow.updated_at) > new Date(existingJob.updated_at) || stepsArrForCompare(workflow.steps) !== stepsArrForCompare(existingJob.steps)) {
		variables.steps = workflow.steps
	}
	if (formData.expense_category) {
		variables.expense_category = formData.expense_category;
	}
	
	return updateThing('job', existingJob.id, variables);
}

function stepsArrForCompare(steps: WorkflowDatabaseStep[]) {
	// this is a really weak check to see if the steps are different, but just decided to not put much more effort into it right now
	return steps.map( s => s.step_id ).toString()
}


export function activateJob(jobId: number, workflow, workflowOptions: WorkflowOptions) {
	let MUTATION = ACTIVATE_JOB;
	let variables: any = { 
		id: jobId, 
		steps: workflow,
		workflow_options: workflowOptions
	}

	if (workflowOptions.product_enabled) {
		MUTATION = ACTIVATE_JOB_WITH_WIP;
		
		const partWipTxs: PartWipTxInsertObject[] = workflowOptions.product?.selections?.map( selection => ({
			job_id: jobId,
			part_id: selection.part_id,
			qty_change: selection.processing_qty,
			wip_type: 'wip',
			type: 'job_start'
		}));

		const partReserveTxs: PartWipTxInsertObject[] = workflowOptions.product?.selections?.filter(selection =>
			typeof selection.order_item_id === 'number'
			).map( plan => ({
				job_id: jobId,
				part_id: plan.part_id,
				order_item_id: plan.order_item_id,
				qty_change: plan.reserve_qty,
				wip_type: 'reserve',
				type: 'job_start'
			}) );

		variables.partWipTxs = partWipTxs.concat(partReserveTxs);
	}

	return apolloClient.mutate({ 
		mutation: MUTATION, 
		variables
		// don't update the cache here, instead assume that the active jobs table always fetches from the network
	}).then( queryResponse => {
		const updatedJob: Job = queryResponse.data?.update_job?.returning?.[0] ?? {} as Job;
		return updatedJob.job_status === 'active'
	} );
}