import React, { useContext } from 'react';
import { useUIForm, UIForm2, UIButton, useModal, UIModalSimpleAction } from 'kit';
import { CopyAlt } from '@styled-icons/boxicons-regular/CopyAlt';

import { WorkflowSelect } from 'pages/JobDraft';

import { WorkflowEditorContext } from './WorkflowEditorContext';
import { getWorkflowById } from 'services/getWorkflowById';

import { toast } from 'react-toastify';


export const ImportWorkflowButtonModal = (props: {
}) => {
	const { dynamicListContext, updateWorkflowOptions, validateInvUnique } = useContext(WorkflowEditorContext);

	const modalProps = useModal();
	const { formMethods, isDirty } = useUIForm<any>();

	// function submit(formData) {
	const submit = formMethods.handleSubmit((formData) => {
		getWorkflowById(formData.workflow_id).then(workflow => {
			const { setRows } = dynamicListContext;
			setRows(workflow.steps);
			updateWorkflowOptions(workflow.workflow_options);
			validateInvUnique(workflow.steps, workflow.workflow_options);
			toast.success(`Workflow ${workflow.name} was imported successfully. Changes have not yet been saved.`, {
				position: toast.POSITION.BOTTOM_CENTER
			});
			modalProps.toggleModal();
		});
	})

	return (
	<React.Fragment>

		<UIButton 
			quiet 
			label="Import" 
			onClick={modalProps.toggleModal}  
			data-cy="last-element-in-left-menu" // Don't use the duplicate button as the anchor because it isn't shown in a custom workflow.
		/>
		

		<UIModalSimpleAction 
			{...modalProps} 
			Icon={<CopyAlt />}
			title="Import Workflow"
			description="Use a saved workflow as a starting point and import all steps and options"
			supplementalContent={
				<>
					<UIForm2 formMethods={formMethods}>
						<WorkflowSelect 
							isRequired
							customLabel="Workflow to Import"
						/>
					</UIForm2>
					<p>Caution! All steps and options in the current workflow will be overwritten.</p>
				</>
			}
			buttonText="Import"
			onSubmit={submit}
			autoCloseModalOnSubmit={false}
			showCancel
			clickOutsideToClose={!isDirty}
		/>

	</React.Fragment>
	);
};
