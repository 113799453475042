import React from 'react';
import styled from 'styled-components';
import { UICard, UIFlexChild, UIChip } from 'kit';

import { Check } from '@styled-icons/fa-solid/Check';
import { LockAlt } from '@styled-icons/boxicons-regular/LockAlt';
import { Redo } from '@styled-icons/material/Redo';
import { AlertCircleOutline } from '@styled-icons/evaicons-outline/AlertCircleOutline';


import { Step, StepPlan } from 'types';
import { stepFormCategoryLookup, StepFormCategory } from '@constants';
import { theme } from 'root/theme';




export const StepCard = React.memo( function StepCard( { stepInfo, stepPlan = {} as StepPlan, onClick, indicator, isCompact = false, isSelected, noMargin, className }: {
	stepInfo: Step
	stepPlan: StepPlan
	indicator?: number | 'complete' | 'skip' | 'lock' | 'alert'
	isCompact?: boolean
	isSelected?: boolean
	noMargin?: boolean
	onClick?
	className?: string
} ) {
	let IndicatorEl: number | JSX.Element;
	switch (indicator) {
		case 'complete':
			IndicatorEl = <Check color={theme.colors.success} />;
			break;
		case 'skip':
			IndicatorEl = <Redo color={theme.colors.muted} />;
			break;
		case 'lock':
			IndicatorEl = <LockAlt color={theme.colors.error} />;
			break;
		case 'alert':
			IndicatorEl = <AlertCircleOutline color={theme.colors.error} />;
			break;
		default:
			IndicatorEl = indicator;
			break;
	}

	return (
		<StyledCard onClick={onClick} asFlexbox flexDirection="row" isSelected={isSelected} noMargin={noMargin} className={className} >
			{IndicatorEl && <div className="indicator">{IndicatorEl}</div>}
			<UIFlexChild>

				<div className="step-title">{stepInfo.name}</div>
				<StepCardChips stepPlan={stepPlan} isCompact={isCompact} />

			</UIFlexChild>
		</StyledCard>
	);
})

const StyledCard = styled(UICard)<{ isSelected: boolean, noMargin: boolean }>`
	width: 100%;
	padding: 0.8rem 1.1rem;
	margin: ${props => props.noMargin && 0};
	border: ${props => props.isSelected && `1px solid ${props.theme.colors.primary}`};
	${props => props.theme.shadow.depth1};
	.indicator {
		width: 2rem;
		text-align: center;
		margin-right: 1.6rem;
		font-size: 1.4rem;
		font-weight: ${props =>props.theme.font.boldWeight};
		svg {
			height: 2rem;
			width: 2rem;
		}
	}
	.step-title {
		font-family: ${props => props.theme.font.title};
		font-size: 1.1rem;
		line-height: 1.1rem;
		font-weight: ${props =>props.theme.font.boldWeight};
		margin-bottom: 0.8rem;
	}
`;



export const StepCardChips = ( { stepPlan, isCompact = false }: {
	stepPlan: StepPlan
	isCompact?: boolean
} ) => {

	return (
		<ChipContainer>
			<StepCategoryChip category="recipe" stepPlan={stepPlan} isCompact={isCompact} />
			<StepCategoryChip category="tool" stepPlan={stepPlan} isCompact={isCompact} />
			<StepCategoryChip category="inventory_effect" stepPlan={stepPlan} isCompact={isCompact} />
			<StepCategoryChip category="forms" stepPlan={stepPlan} isCompact={isCompact} />
			<StepCategoryChip category="inventory_unique_form" stepPlan={stepPlan} isCompact={isCompact} />
		</ChipContainer>
	);
}

const ChipContainer = styled.div`
	/** This should counter the margin from the chip. 
		This is so the chips can wrap if needed, and have a margin between any wrapped chips, 
		but have 0 margin to the bottom of the card.
	*/
	margin-bottom: -8px; 
`;

const StepCategoryChip = ({ stepPlan, category, isCompact }
: { 
	stepPlan: StepPlan
	category: StepFormCategory
	isCompact: boolean
}) => {
	const fullDisplayName =  stepFormCategoryLookup[category].display;
	const DisplayIcon = stepFormCategoryLookup[category].icon;
	return (
		<UIChip 
			icon={isCompact && <DisplayIcon />}
			label={isCompact ? fullDisplayName.charAt(0) : stepFormCategoryLookup[category].display}
			outline 
			light 
			size="xsmall" 
			disabled={!stepPlan.hasOwnProperty(category)} 
		/>
	)
}

