import { apolloClient, queryFromCacheById } from 'services/apollo';
import { UPDATE_STEP_ADD_RECIPE, UPDATE_STEP_ADD_TOOL, UPDATE_STEP_ADD_RAW_PART, GET_STEPS_FOR_RECIPE, GET_STEPS_FOR_TOOL, GET_STEPS_FOR_PART, GET_STEPS_FOR_FORM, UPDATE_STEP_ADD_FORM } from '@constants/gql';
import { Step } from 'types';
import { updateThing } from './thingMutate';
import { removeFromArrayByValue } from 'utility';
import { StepLinkableThing } from 'root/components/StepLink/LinkStepsFromItem';


export function updateStepToAddRecipe(stepId: number, recipeId: number, isAddingFromRecipe = false) {
	apolloClient.mutate({ 
		mutation: UPDATE_STEP_ADD_RECIPE,
		variables: {
			id: stepId,
			recipeId: recipeId
		},
		refetchQueries: isAddingFromRecipe ? [{ query: GET_STEPS_FOR_RECIPE, variables: { id: recipeId } }] : []
	});
}

export function updateStepToAddTool(stepId: number, toolId: number, isAddingFromTool = false) {
	apolloClient.mutate({ 
		mutation: UPDATE_STEP_ADD_TOOL,
		variables: {
			id: stepId,
			toolId: toolId
		},
		refetchQueries: isAddingFromTool ? [{ query: GET_STEPS_FOR_TOOL, variables: { id: toolId } }] : []
	});
}

export function updateStepToAddRawPart(stepId: number, partId: number, isAddingFromPart = false) {
	apolloClient.mutate({ 
		mutation: UPDATE_STEP_ADD_RAW_PART,
		variables: {
			id: stepId,
			partId: partId
		},
		refetchQueries: isAddingFromPart ? [{ query: GET_STEPS_FOR_PART, variables: { id: partId } }] : []
	});
}

export function updateStepToAddForm(stepId: number, formId: number, isAddingFromForm = false) {
	apolloClient.mutate({ 
		mutation: UPDATE_STEP_ADD_FORM,
		variables: {
			id: stepId,
			formId: formId
		},
		refetchQueries: isAddingFromForm ? [{ query: GET_STEPS_FOR_FORM, variables: { id: formId } }] : []
	});
}

export function updateStepsFromItem(itemType: StepLinkableThing, itemId: number, originalStepIds: number[], newStepIds: number[]){
	const deletedIds = originalStepIds.filter(x => !newStepIds.includes(x));
	const addedIds = newStepIds.filter(x => !originalStepIds.includes(x));

	deletedIds.forEach(id => {
		const step = queryFromCacheById(itemType, id);
		updateStepToDeleteLink(step, itemType, itemId, true);
	});
	addedIds.forEach(id => {
		if (itemType === 'recipe') {
			updateStepToAddRecipe(id, itemId, true);
		}
		if (itemType === 'tool') {
			updateStepToAddTool(id, itemId, true);
		}
		if (itemType === 'partRaw') {
			updateStepToAddRawPart(id, itemId, true);
		}
		if (itemType === 'form') {
			updateStepToAddForm(id, itemId, true);
		}
	});
}

export function getStepColName(linkedParam: StepLinkableThing) {
	return `${linkedParam === 'partRaw' ? 'raw_part' : linkedParam}s`;
} 

export function updateStepToDeleteLink(step: Step, linkedParam: StepLinkableThing, linkedId: number, isAddingFromLinkedItem = false) {
	const stepColName = getStepColName(linkedParam);
	const newArr = removeFromArrayByValue(step[stepColName], linkedId);
	const changes = { 
		[stepColName]: newArr
	};

	let refetchGQL;
	if (linkedParam === 'recipe') {
		refetchGQL = GET_STEPS_FOR_RECIPE;
	}
	else if (linkedParam === 'tool') {
		refetchGQL = GET_STEPS_FOR_TOOL;
	}
	else if (linkedParam === 'partRaw') {
		refetchGQL = GET_STEPS_FOR_PART;
	}
	else if (linkedParam === 'form') {
		refetchGQL = GET_STEPS_FOR_FORM;
	}

	updateThing('step', step.id, changes, 
		{ refetchQueries: isAddingFromLinkedItem ? [{ query: refetchGQL, variables: { id: linkedId } }] : [] }
	);
}