import { useLocation } from 'react-router-dom';
import { MainRouteDefinition, BreadcrumbDef,  mainRoutes, useNavMain, useCurrentMainRoute } from 'routing';

import { getObjectFromArray } from 'utility';
import { useThingById } from 'hooks';



export function useBreadcrumbFetch(){
	const location = useLocation();
	const currentRoute = useCurrentMainRoute();

	const backlinkDef = currentRoute.backlink ?? {};
	const labelDef = currentRoute.labelVar ?? {};

	const urlCrumbs = location.pathname?.split('/').filter( chunk => chunk.length > 0) ?? [];
	const defnCrumbs = currentRoute.path?.split('/').filter( chunk => chunk.length > 0) ?? [];
	
	// can't use useParams because the breadcrumb componemt is outside of the main container
	const paramId = parseInt(urlCrumbs[defnCrumbs.indexOf(':id')]) || 0; //id should be a number
	const paramIndex = parseInt(urlCrumbs[defnCrumbs.indexOf(':index')]) || 0;

	const { isLoading: isJobLoading, item: job } = useThingById('job', paramId, { skip: backlinkDef.queryType !== 'job' && labelDef.queryType !== 'job' });
	const { isLoading: isOrderLoading, item: order } = useThingById('order', paramId, { skip: backlinkDef.queryType !== 'order' && labelDef.queryType !== 'order' });
	const { isLoading: isInvUniqueLoading, item: invUnique } = useThingById('order', paramId, { skip: backlinkDef.queryType !== 'inventory_unique' && labelDef.queryType !== 'inventory_unique' });
	const { isLoading: isWorkflowLoading, item: workflow } = useThingById('workflow', paramId, { skip: backlinkDef.queryType !== 'workflow' && labelDef.queryType !== 'workflow' });
	const { isLoading: isFormLoading, item: formDef } = useThingById('form', paramId, { skip: backlinkDef.queryType !== 'form' && labelDef.queryType !== 'form' });

	const stepID = currentRoute.id === 'operateStep' ? job.steps?.[paramIndex - 1]?.step_id : paramId;
	const { isLoading: isStepLoading, item: step } = useThingById('step', stepID, { skip: backlinkDef.queryType !== 'step' && labelDef.queryType !== 'step' });

	const isAnyLoading = isJobLoading || isOrderLoading || isWorkflowLoading || isInvUniqueLoading || isFormLoading || isStepLoading;


	function getCrumbLabel(def: BreadcrumbDef) {
		let label = '';
		let data;
		if (!def) {
			return { label, data };
		}
		if (def.linkTo && !def.pathVariable) {
			const linkedRoute: MainRouteDefinition = getObjectFromArray(mainRoutes, 'id', def.linkTo);
			label = linkedRoute.label;
		}
		else {
			switch (def.queryType) {
				case 'order':
					if (!isAnyLoading && Object.keys(order).length > 0) {
						data = order;
					}
					break;
				case 'job':
					if (!isAnyLoading && Object.keys(job).length > 0) {
						data = job;
					}
					break;
				case 'step':
					if (!isAnyLoading && Object.keys(step).length > 0) {
						data = step;
						if (!data.name) { // If , for some reason, we don't have the data in cache, just show the step name
							data = { name: `Step ${paramIndex}` }
						}
					}
					break;
				case 'workflow':
					if (!isAnyLoading && Object.keys(workflow).length > 0) {
						data = workflow;
					}
					break;
				case 'inventory_unique':
					if (!isAnyLoading && Object.keys(invUnique).length > 0) {
						data = invUnique;
					}
					break;
				case 'form':
					if (!isAnyLoading && Object.keys(formDef).length > 0) {
						data = formDef;
					}
					break;
				default:
					break;
			}
			label = data ? (data[def.queryResultKey] ?? '') : '';
		}
		const prependLabel = def.crumbTitlePrependText ? def.crumbTitlePrependText : '';
		label = prependLabel + label;

		return { label, data };
	}

	

	const { label: backlinkText, data: backlinkData } = getCrumbLabel(backlinkDef);
	const { label: labelText } = getCrumbLabel(labelDef);
	const backlinkNavFunc = useCrumbNav(backlinkDef, backlinkData);

	return {
		backlinkText, backlinkNavFunc,
		labelText,
		isAnyLoading
	}
}

export function useCrumbNav(def: BreadcrumbDef, data){
	let navFunc;
	const navFuncRaw = useNavMain(def.linkTo ?? 'home');
	if (def.linkTo && !def.pathVariable) {
		navFunc = navFuncRaw;
	}
	else if (def.linkTo) {
		navFunc = () => navFuncRaw(data[def.pathVariable.replace(':', '')]);
	}
	return navFunc;
}