import React, { useState, useCallback, useMemo } from 'react';
import styled from 'styled-components';

import Drawer from '@material-ui/core/Drawer';

import { TopBar, BottomBar, BalanceBar } from 'kit';

import { useLeaveDialog } from 'services/hooks/useLeavePage';  //DON'T import this from the hooks module, because that will cause index.ts to load, which will cause a circular reference


export const useDrawer = (initialOpen = false) => {

	const [isOpen, setOpen] = useState(initialOpen);

	const openDrawer = useCallback(() => {
		setOpen(true);
	}, [])
	const closeDrawer = useCallback(() => {
		setOpen(false);
	}, [])



	const returnObj = useMemo(() => ({
		isDrawerOpen: isOpen,
		openDrawer,
		closeDrawer
	}), [isOpen])
	return returnObj;
}

export type DrawerHookContext = {
	isDrawerOpen: boolean
	openDrawer: () => void
	closeDrawer: () => void
}

interface UIDrawerProps extends DrawerHookContext {
	anchorFrom?: 'top' | 'left' | 'bottom' | 'right'
	hasTopBar?: boolean
	hasBottomBar?: boolean
	padding?: 'none'
	children: JSX.Element | JSX.Element[]
	className?: 'string'
	isFormDirty?: boolean
}

export const UIDrawer = ({ 
	anchorFrom = 'right',
	hasBottomBar = false,
	hasTopBar = false,
	padding,
	children, 
	isDrawerOpen, closeDrawer,
	className,
	isFormDirty
 }: UIDrawerProps) => {

	const { confirmLeave } = useLeaveDialog();
	const onDrawerClose = event => {
		if (isFormDirty) {
			if (confirmLeave()) { 
				closeDrawer();
			}
			else {
				// leave the drawer open
			}
		}
		else {
			closeDrawer();
		}
	};

	return (
		<StyledDrawer
			anchor={anchorFrom}
			open={isDrawerOpen}
			onClose={onDrawerClose}
			padding={padding}
			className={className}
		>
			{hasTopBar && <TopBar />}
			<BalanceBar 
				hasBottomBar={hasBottomBar} 
				hasTopBar={hasTopBar} 
				className="drawer-container"
			>
				{children}
			</BalanceBar>
			{hasBottomBar && <BottomBar />}
		</StyledDrawer>
	)
}

const StyledDrawer = styled(Drawer)<{ anchor: 'top' | 'left' | 'bottom' | 'right', padding: 'none' }>`
	.MuiDrawer-paper {
		height: ${props => (props.anchor === 'left' || props.anchor === 'right') && '100vh'};
	}
	.drawer-container {
		${props => props.theme.scrollbar.light}
		overflow: auto;

		box-sizing: border-box;
		padding: ${props => props.padding === 'none' ? 0 : '40px'};
		min-width: 400px;
		max-width: 100vw;
	}
`;
