import React, { useState } from 'react';

import { UIButton, UIForm, UITextArea } from 'kit';
import { addMessage } from 'services/messageAdd';
import styled from 'styled-components';



export const AddMessage = (
	props: {	
		threadId?: number	
		referenceType?: 'step_history' | 'job' | 'inventory_unique' | 'order'
		referenceId: number	
		newThreadEffect?(): void
	}
) => {
	const [isMutationRunning, setMutationRunning] = useState(false);

	const submit = formData => {
		const isNewThread = !(props.threadId > 0);
		setMutationRunning(true);
		return addMessage(formData.text, isNewThread, props.referenceId, props.referenceType, props.threadId)
			.then( success => {
				setMutationRunning(false);
				if (isNewThread && typeof props.newThreadEffect === 'function') {
					props.newThreadEffect();
				}
				return success;
			});
	}

	return (
		<UIForm onSubmitWithReset={submit}>
			<UITextArea required name="text" label="Message" showLabel={false} />
			<StyledButton label="Post" type="submit" disabled={isMutationRunning} />
		</UIForm>
    );
}

const StyledButton = styled(UIButton)`
	float: right;
`;