import React, { useState } from 'react';
import styled from 'styled-components';

import { paddingStyles, PaddingProps } from '@constants';

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

// ---------------------------- useTabs and the return (TabContext) ------------------------------
export interface TabContext {
	tabIndex: number
	setTabIndex
	handleChange
}

export const useTabs = () => {
	const [tabIndex, setTabIndex] = useState(0); 
	const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
		setTabIndex(newValue);
	};
	return { tabIndex, setTabIndex, handleChange }
}







// ---------------------------------------- UITabs ---------------------------------------------

type UITabsProps = {
	vertical?: boolean
	tabDefs: TabDef[]
	className?: string

	activeTabBackground?: 'primaryLight'

	tabContext: TabContext
} 

interface TabDef {
	/** Optionally, provide a key to force component update. By default the key will just be the label */
	key?: string
	label: string | JSX.Element
}

export const UITabs = (	
	{ 
		tabContext,
		tabDefs,
		vertical,
		activeTabBackground
	}: UITabsProps
) => {
	
	return (
		<StyledTabs
			orientation={vertical ? 'vertical' : 'horizontal'}
			variant="fullWidth"
			indicatorColor="primary"
			value={tabContext.tabIndex}
			onChange={tabContext.handleChange}
		>
			{tabDefs.map( def => ( 
				<StyledTab 
					key={`${def.key || def.label}`} 
					label={def.label} 
					activeTabBackground={activeTabBackground}
				/> 
			))}
		</StyledTabs>
	);
}

const StyledTabs = styled(Tabs)`
	border-right: 1px solid ${props => props.theme.colors.colorDefinitions.grey3};
`;




// ---------------------------------------- UITab ---------------------------------------------

const StyledTab = styled(Tab).withConfig({
// Material UI emits a console error with props that are not native to it. Filter out the props that we are using for our style overrides
	shouldForwardProp: (prop) => !['activeTabBackground'].includes(prop)
})<{ activeTabBackground: 'primaryLight' }>`
	/** vv Override Mui defaults */
	text-transform: none;
	font-size: 1rem;
	min-width: 80px;
	.MuiTabs-flexContainerVertical & {
		text-align: left;
		padding: 6px 18px;
	}
	.MuiTab-wrapper {
		display: block;
	}
	/** ^^ Override Mui defaults */
	
	&.MuiTab-textColorInherit.Mui-selected {
		background: ${props => props.activeTabBackground === 'primaryLight' && props.theme.colors.primaryLight1};
	}
	transition: background 1.5s;
	
	font-family: ${props => props.theme.font.title};
	svg {
		margin-right: 6px;
	}
`;




// ---------------------------------------- UITabBody ---------------------------------------------
export const UITabBody = (	
	{ 
		bodyIndex,
		children, className,
		tabContext,
		keepMounted = false,
		...restProps
	}: { 
		bodyIndex: number
		tabContext: TabContext 
		children
		keepMounted?: boolean
		className?: string
	} & PaddingProps
) => {
	const isActiveTab = tabContext.tabIndex === bodyIndex;
	return (
		<StyledTabBody
			role="tabpanel"
			hidden={!isActiveTab}
			id={`simple-tabpanel-${bodyIndex}`}
			aria-labelledby={`simple-tab-${bodyIndex}`}
			/**Style props */
			className={className}
			isActiveTab={isActiveTab}
			keepMounted={keepMounted}
			{...restProps}
		>
			{(isActiveTab || keepMounted) && 
				children
			}
		</StyledTabBody>
	)
}

const StyledTabBody = styled.div<{ isActiveTab: boolean, keepMounted: boolean }>`
	width: 100%;
	position: relative;
	display: ${props => props.keepMounted && !props.isActiveTab && 'none'};
	${paddingStyles};
`;