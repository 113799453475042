import React from 'react';

import styled from 'styled-components';


const StyledLine = styled.div`
	background-color: lightgrey;
	height: 1px;
	width: 100%;
	align-self: center;
	margin-top: ${(props: {margin?: string}) => (props.margin || '1px') };
	margin-bottom: ${(props: {margin?: string}) => (props.margin || '10px') };
`

//hook method
export const UILine = (
	props: {		
		margin?
	}
) => {
	return (
		<StyledLine
            margin={props.margin}
        />
    );
}

export default UILine;