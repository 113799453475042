import React from 'react';

import { UIPage } from 'kit';

import { JobsHistoryTable } from 'components/Jobs/JobsHistoryTable';


export function JobHistory() {
	return (
		<UIPage id="job-history" contentContainerSize="xlarge" >
			<JobsHistoryTable />

		</UIPage>
	)
}